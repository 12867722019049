export type HMRCMessageContent = {
    id?: string;
    text?: string;
};

export type HMRCMessages = {
    [key in HMRCMessageTypes]: HMRCMessageContent[];
};

export enum HMRCMessageTypes {
    INFO = 'info',
    WARNINGS = 'warnings',
    ERRORS = 'errors'
}
