import React from 'react';
import clsx from 'clsx';
import { ListItem, ListItemText } from '@material-ui/core';
import globalStyles from 'styles/globalStyles';
import { ApariCheckbox } from 'components';
import styles from './styles';
import { NumberServices } from 'utils';

type Props = {
    name: string;
    handleMonthTransactionAdd: (month: number, checked: boolean) => void;
    disabled?: boolean;
    checkedState: boolean;
    monthNumber: number;
    amount: number;
};

const ApariMonthCheckboxField: React.FC<Props> = ({
    name,
    amount,
    handleMonthTransactionAdd,
    checkedState,
    monthNumber,
    disabled = false
}) => {
    const classes = styles();
    const globalClasses = globalStyles();

    const handleMonthSelect = () => {
        handleMonthTransactionAdd(monthNumber, !checkedState);
    };

    return (
        <ListItem
            disabled={disabled}
            button
            disableGutters
            divider
            className={clsx(globalClasses.flexRowStart)}
            classes={{ divider: classes.divider }}
            onClick={handleMonthSelect}
        >
            <div className={clsx(globalClasses.marginRightAuto)}>
                <div className={clsx(globalClasses.flexRowStart)}>
                    <ApariCheckbox checked={checkedState} invertedColors={true} disabled={disabled} />
                    <ListItemText
                        disableTypography
                        className={clsx(globalClasses.flexColumnCenter, globalClasses.font12weight600Light, classes.labelMargin)}
                        primary={name}
                    />
                </div>
            </div>
            <div>
                <ListItemText
                    disableTypography
                    className={clsx(globalClasses.flexColumnCenter, globalClasses.font12weight600Light, classes.text)}
                    primary={NumberServices.format(amount) + ' EUR'}
                />
            </div>
        </ListItem>
    );
};

export default ApariMonthCheckboxField;
