import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    sectionField: {
        border: '1px solid ' + COLORS.apariBlack10,
        borderRadius: 2
    },
    sectionFieldReadOnly: {
        background: COLORS.paperLight
    },
    changedIndicator: {
        width: 3,
        minHeight: '100%',
        background: COLORS.apariLightSilver,
        transition: 'background 0.5s'
    },
    isChanged: {
        background: COLORS.apariLightPurple
    },
    nameInfoContainer: {
        justifyContent: 'flex-start',
        marginBottom: 4
    },
    labelText: {
        fontSize: 10,
        fontWeight: 400,
        color: COLORS.apariBlack60
    },
    infoIcon: {
        fontSize: 12
    },
    fieldContent: {
        padding: 12,
        width: '100%'
    },
    HMRCValue: {
        fontSize: 10,
        fontWeight: 400,
        color: COLORS.apariBlack60,
        marginTop: 8
    },
    sourceText: {
        fontSize: 10,
        fontWeight: 400,
        color: COLORS.apariBlack80,
        marginTop: 8
    }
}));
