import React from 'react';
import {RadioProps, Radio} from '@material-ui/core';
import styles from './styles';

const ApariRadio: React.FC<RadioProps> = props => {
    const classes = styles();

    return <Radio color='primary' classes={{root: classes.root}} {...props}/>;
};

export default ApariRadio;
