import React, { ReactElement, useState } from 'react';
import clsx from 'clsx';
import { IconButton, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import globalStyles from 'styles/globalStyles';
import { MTDField } from 'types';
import { Localisation, NumberServices } from 'utils';
import styles from './styles';

type Props = {
    field?: MTDField;
    isChanged: boolean;
    HMRCValue?: string | number;
    dontFormatHMRCValue?: boolean;
    source?: string;
    sourceAction?: () => void;
    readOnly?: boolean;
    info?: string;
    label?: string;
    adjustment?: boolean;
    delta?: number;
    disableHMRC?: boolean;
    originalValue?: number;
    contentClassName?: string;
    wrapperClassName?: string;
    expandable?: boolean;
    subFields?: ReactElement | ReactElement[];
};

const MTDFieldWrapper: React.FC<Props> = ({
    isChanged,
    HMRCValue,
    dontFormatHMRCValue,
    source,
    adjustment,
    delta,
    originalValue,
    sourceAction,
    readOnly,
    label,
    info,
    disableHMRC,
    children,
    contentClassName,
    wrapperClassName,
    expandable,
    subFields
}) => {
    const classes = styles();
    const globalClasses = globalStyles();
    const [isExpandable, setIsExpandable] = useState(false);

    return (
        <div className={clsx(globalClasses.flexColumn, wrapperClassName)}>
            <div
                className={clsx(
                    globalClasses.flexRow,
                    classes.sectionField,
                    readOnly && classes.sectionFieldReadOnly,
                    expandable && globalClasses.marginBottom32
                )}
            >
                <div className={clsx(classes.changedIndicator, isChanged && classes.isChanged)} />
                <div className={clsx(classes.fieldContent, contentClassName)}>
                    <div className={clsx(globalClasses.flexRowCenter, classes.nameInfoContainer)}>
                        <p className={clsx(globalClasses.noVerticalMargin, globalClasses.marginRight4, classes.labelText)}>{label}</p>
                        {info && (
                            <Tooltip placement="top-end" title={info}>
                                <InfoOutlinedIcon className={clsx(classes.infoIcon)} />
                            </Tooltip>
                        )}
                    </div>
                    {children}
                </div>
                {expandable && (
                    <div className={clsx(globalClasses.alignSelfCenter)}>
                        <IconButton
                            onClick={() => setIsExpandable(!isExpandable)}
                            className={clsx(globalClasses.noPadding, globalClasses.marginRight8)}
                        >
                            {!isExpandable ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        </IconButton>
                    </div>
                )}
            </div>
            <div className={clsx(globalClasses.flexRowSpaceBetween)}>
                {disableHMRC ? (
                    <div />
                ) : (
                    <p className={clsx(classes.HMRCValue)}>
                        {Localisation.localize('OVERVIEW_FORM_SCREEN.HMRC_VALUE') +
                            ': ' +
                            (HMRCValue || HMRCValue === 0
                                ? dontFormatHMRCValue
                                    ? HMRCValue
                                    : '£ ' + NumberServices.format(HMRCValue)
                                : Localisation.localize('general.EMPTY'))}
                    </p>
                )}
                {source && (
                    <p className={clsx(classes.sourceText)}>
                        <span> {Localisation.localize('general.SOURCE') + ': '}</span>
                        <span className={clsx(globalClasses.cursorPointer)} onClick={() => sourceAction && sourceAction()}>
                            <u>{source}</u>
                        </span>
                    </p>
                )}
                {adjustment && (
                    <p className={clsx(classes.sourceText)}>
                        <span>{Localisation.localize('general.ADJUSTMENT_SHORT') + ': '}</span>
                        <span>
                            {'£ ' + originalValue + ' ' + Localisation.localize('general.TO') + ' ' + '£ ' + (originalValue! - delta!)}
                        </span>
                        <span className={clsx(globalClasses.cursorPointer)} onClick={() => sourceAction && sourceAction()}>
                            <u>{source}</u>
                        </span>
                    </p>
                )}
            </div>
            {isExpandable && subFields}
        </div>
    );
};

export default MTDFieldWrapper;
