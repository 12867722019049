import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(8),
        paddingLeft: theme.spacing(3),
        width: 250,
        boxSizing: 'border-box'
    },
    dot: {
        marginRight: theme.spacing(3),
        alignSelf: 'center',
        justifySelf: 'center'
    },
    label: {},
    notCompleted: {
        color: theme.palette.grey[500]
    }
}));
