import { makeStyles } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';

export default makeStyles(() => ({
    backButton: {
        color: COLORS.apariPurple
    },
    backToWebsite: {
        color: COLORS.apariBlack80,
        fontFamily: FONTS.QUATERNARY_FONT,
        fontSize: 16,
        fontWeight: 700
    }
}));
