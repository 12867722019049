import React, { useState } from 'react';
import styles from './styles';
import globalStyles from 'styles/globalStyles';
import { FilesService, GlobalServices, Localisation, NetworkService } from 'utils';
import { REGEX } from 'constants/index';
import clsx from 'clsx';
import { IconButton, ListItem, Menu, MenuItem, Tooltip } from '@material-ui/core';
import FileIcon from 'resources/images/file_icon.svg';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ApariInfoDialog } from 'components';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { FileError } from 'react-dropzone';
import { PreviewState } from 'types/files';

type Props = {
    filename: string;
    fileSize?: number;
    downloadUrl?: string;
    status: PreviewState;
    previewUrl?: string;
    uploadErrors?: FileError[];
    removeFile?: () => void;
    uploadStatus?: number;
    fullWidth?: boolean;
    noDelete?: boolean;
};

const ApariFilePreview: React.FC<Props> = ({
    filename,
    removeFile,
    previewUrl,
    status,
    uploadStatus,
    downloadUrl,
    fileSize,
    uploadErrors,
    fullWidth,
    noDelete
}) => {
    const classes = styles({ status, uploadStatus, fullWidth });
    const globalClasses = globalStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const token = NetworkService.getToken();
    const sourceUrl =
        GlobalServices.isDefined(previewUrl) && REGEX.HTTP_URL.test(previewUrl) ? `${previewUrl}&apari_jwt=${token}` : previewUrl;
    const filenameParts = filename.split('.');
    const type = filenameParts[filenameParts.length - 1].toUpperCase();
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDownload = async () => {
        const link = document.createElement('a');
        link.href =
            GlobalServices.isDefined(downloadUrl) && REGEX.HTTP_URL.test(downloadUrl) ? `${downloadUrl!}&apari_jwt=${token}` : downloadUrl!;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        handleClose();
    };

    const getErrorMessage = (error: FileError) => {
        switch (error.code) {
            case 'file-too-large':
                return Localisation.localize('UploadPdfDialog.fileSizeLimit');
            case 'file-invalid-type':
                return Localisation.localize('UploadPdfDialog.wrongFileType');
            default:
                return Localisation.localize('UploadPdfDialog.uploadFailed');
        }
    };

    const renderRightContent = () => {
        switch (status) {
            case PreviewState.ERROR:
                return (
                    <Tooltip
                        title={
                            <>
                                {uploadErrors?.map((error, index) => (
                                    <span key={index}>{getErrorMessage(error)}</span>
                                ))}
                            </>
                        }
                    >
                        <InfoOutlinedIcon className={clsx(classes.warningIcon)} />
                    </Tooltip>
                );
            case PreviewState.UPLOADING:
            case PreviewState.SUCCESS:
                return (
                    <>
                        <IconButton onClick={handleClick} className={clsx(classes.iconButton)} disabled={status === PreviewState.UPLOADING}>
                            <MoreVertIcon className={clsx(classes.icon)} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            PaperProps={{
                                square: true
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {noDelete ? null : (
                                <MenuItem onClick={() => setOpenDeleteDialog(true)}>{Localisation.localize('general.delete')}</MenuItem>
                            )}
                            <MenuItem onClick={handleDownload}>{Localisation.localize('general.download')}</MenuItem>
                        </Menu>
                        <ApariInfoDialog
                            open={openDeleteDialog}
                            buttonText={Localisation.localize('general.delete')}
                            infoText={Localisation.localize('UploadPdfDialog.deleteWarning')}
                            onCancel={() => {
                                handleClose();
                                setOpenDeleteDialog(false);
                            }}
                            onConfirm={() => {
                                removeFile && removeFile();
                                handleClose();
                                setOpenDeleteDialog(false);
                            }}
                        />
                    </>
                );
        }
    };

    return (
        <div className={clsx(globalClasses.marginTop10, classes.previewCardContainer)}>
            {status === PreviewState.UPLOADING && (
                <div className={clsx(classes.progressBarContainer)}>
                    <div className={clsx(classes.progressBar)} />
                </div>
            )}
            <ListItem className={clsx([globalClasses.flexRowStart, classes.previewCard])}>
                <div className={clsx([globalClasses.flexRowStart, globalClasses.fullWidth])}>
                    <img
                        src={GlobalServices.isDefined(sourceUrl) && FilesService.checkIfFileIsImage(type) ? sourceUrl : FileIcon}
                        className={clsx(globalClasses.marginRight10, classes.previewImage)}
                    />
                    <div className={clsx([globalClasses.flexColumn, globalClasses.fullWidth])}>
                        <div className={clsx([classes.label, globalClasses.ellipsisWrapNoWidth, globalClasses.font13weight500Dark])}>
                            {filename.length > 25 ? filename.slice(0, 25) + '...' : filename}
                        </div>
                        <div
                            className={clsx([
                                classes.fileLabel,
                                globalClasses.ellipsisWrap,
                                globalClasses.font10weight500Darker,
                                globalClasses.flexRow
                            ])}
                        >
                            <span className={clsx(globalClasses.marginRightAuto)}>{`${type} file`}</span>
                            {GlobalServices.isDefined(fileSize) && <span>{GlobalServices.getHumanReadableFileSize(fileSize)}</span>}
                        </div>
                    </div>
                </div>

                <div className={clsx([globalClasses.marginLeftAuto])}>{renderRightContent()}</div>
            </ListItem>
        </div>
    );
};

export default ApariFilePreview;
