import { COLORS } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    paper: {
        width: 288,
        borderRadius: 12,
        padding: 12,
        paddingTop: 20
    },
    spacing: {
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 10
    },
    button: {
        height: 32,
        textTransform: 'none'
    },
    title: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: 0
    },
    backdrop: {
        opacity: '0.8 !important',
        background: COLORS.backgroundTransparentWhite,
        backdropFilter: 'blur(4px)'
    }
}));
