import React, { useContext } from 'react';
import { makeStyles, Snackbar, SnackbarCloseReason } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';
import { AppContext } from 'context/AppContext';
import { NotificationTypes } from 'types';

const styles = makeStyles(theme => ({
    message: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }
    },
    icon: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 17
        }
    }
}));

const CustomAlert = (props: AlertProps) => {
    const { children, ...rest } = props;
    const classes = styles();

    return (
        <Alert classes={{ message: classes.message, icon: classes.icon }} elevation={6} variant="filled" {...rest}>
            {children}
        </Alert>
    );
};

const Notifications: React.FC = () => {
    const { notificationState, hideNotifications } = useContext(AppContext);
    const { showNotification, notificationType, notificationMessage, onNotificationClose } = notificationState;

    const renderNotification = () => {
        switch (notificationType) {
            case NotificationTypes.ERROR:
                return (
                    <CustomAlert onClose={handleClose} severity="error">
                        {notificationMessage}
                    </CustomAlert>
                );
            case NotificationTypes.WARNING:
                return (
                    <CustomAlert onClose={handleClose} severity="warning">
                        {notificationMessage}
                    </CustomAlert>
                );
            case NotificationTypes.INFO:
                return (
                    <CustomAlert onClose={handleClose} severity="info">
                        {notificationMessage}
                    </CustomAlert>
                );
            case NotificationTypes.SUCCESS:
                return (
                    <CustomAlert onClose={handleClose} severity="success">
                        {notificationMessage}
                    </CustomAlert>
                );
        }
    };

    const handleClose = (event: React.SyntheticEvent, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        hideNotifications();

        onNotificationClose && onNotificationClose();
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={showNotification}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            {renderNotification()}
        </Snackbar>
    );
};

export default Notifications;
