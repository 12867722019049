import React from 'react';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';
import styles from './styles';
import { GlobalServices, NumberServices } from 'utils';
import { COLORS } from 'constants/index';

type Props = {
    name: string;
    value?: number | string | boolean;
    hasIndicator?: boolean;
    icon?: React.ReactNode;
    color?: string;
    alwaysInRow?: boolean;
    className?: string;
};

const InformationCard: React.FC<Props> = props => {
    const { color = COLORS.apariLightBlue, hasIndicator = true, name, alwaysInRow, value, icon, className } = props;
    const classes = styles({ color, alwaysInRow });
    const globalClasses = globalStyles();

    const renderValue = () => {
        if (value === null || value === undefined) {
            return '';
        } else if (typeof value === 'number') {
            return '£ ' + NumberServices.format(value);
        } else {
            return String(value);
        }
    };

    return (
        <div className={clsx(classes.card, globalClasses.flexRowStart, className)}>
            {hasIndicator && (
                <div className={clsx(globalClasses.marginRight20, globalClasses.autoHeight, classes.informationIndicator)}>
                    {GlobalServices.isDefined(icon) ? icon : <div className={clsx(classes.square)} />}
                </div>
            )}
            <div className={clsx(classes.cardText, globalClasses.font11weight500Dark, globalClasses.fullWidth)}>
                <p className={clsx(globalClasses.marginRightAuto, classes.cardLabel)}>{name}</p>
                <p className={clsx(classes.cardValue)}>{renderValue()}</p>
            </div>
        </div>
    );
};

export default InformationCard;
