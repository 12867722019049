import React from 'react';
import clsx from 'clsx';
import { TableHead, Table, TableCell, TableRow, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
    CalculationSimpleInfoDtoObligationStatusEnum,
    QuarterResponseDtoEopObligationStatusEnum,
    SubmissionDtoObligationStatusEnum,
    SubmissionOverviewDtoObligationStatusEnum,
    SubmissionSubmitter
} from '@apari/uk-mtd-api/models';
import { FinalisedTag } from 'components';
import { DATE_FORMAT } from 'constants/index';
import globalStyles from 'styles/globalStyles';
import { SubmissionStatusType } from 'types';
import { DateTimeServices, Localisation } from 'utils';
import styles from './styles';

type SubmissionStatusProps = {
    type: SubmissionStatusType;
    className?: string;
    isReady?: boolean;
    status?:
        | QuarterResponseDtoEopObligationStatusEnum
        | SubmissionOverviewDtoObligationStatusEnum
        | CalculationSimpleInfoDtoObligationStatusEnum
        | SubmissionDtoObligationStatusEnum;
    submittedAtDate?: string;
    submittedBy?: SubmissionSubmitter;
    dueDate?: string;
    taxYear?: string;
    showTaxYear?: boolean;
    accountingPeriod?: string;
    hideMessage?: boolean;
};

const SubmissionStatus: React.FC<SubmissionStatusProps> = ({
    type,
    className,
    isReady,
    status,
    submittedAtDate,
    submittedBy,
    dueDate,
    taxYear,
    showTaxYear,
    accountingPeriod,
    hideMessage
}) => {
    const classes = styles();
    const globalClasses = globalStyles();

    const getSubmitter = () => {
        if (submittedBy) {
            if (submittedBy.agencyName) {
                return submittedBy.agencyName;
            } else {
                return submittedBy.firstName + ' ' + submittedBy.lastName;
            }
        }
    };

    const getSubmissionStatus = () => {
        switch (status) {
            case QuarterResponseDtoEopObligationStatusEnum.FINALIZED:
                return Localisation.localize('DATA_OVERVIEW.SUBMISSION_OVERVIEW.SUBMISSION_STATUSES.FINALIZED', {
                    submissionDate: DateTimeServices.getDateInGivenFormat(submittedAtDate, DATE_FORMAT.UK_STANDARD_2),
                    by: getSubmitter() ? 'by' : '',
                    submitter: getSubmitter() || ''
                });
            case QuarterResponseDtoEopObligationStatusEnum.NOT_DUE:
                return Localisation.localize('DATA_OVERVIEW.SUBMISSION_OVERVIEW.SUBMISSION_STATUSES.NOT_DUE');
            case QuarterResponseDtoEopObligationStatusEnum.DUE:
                return Localisation.localize('DATA_OVERVIEW.SUBMISSION_OVERVIEW.SUBMISSION_STATUSES.DUE');
            case QuarterResponseDtoEopObligationStatusEnum.PENDING:
                return Localisation.localize('DATA_OVERVIEW.SUBMISSION_OVERVIEW.SUBMISSION_STATUSES.PENDING');
            case QuarterResponseDtoEopObligationStatusEnum.OVERDUE:
                return Localisation.localize('DATA_OVERVIEW.SUBMISSION_OVERVIEW.SUBMISSION_STATUSES.OVERDUE');
        }
    };

    const getSubmissionStatusClass = () => {
        switch (status) {
            case QuarterResponseDtoEopObligationStatusEnum.DUE:
                if (isReady) return classes.statusColorOrange;
                break;
            case QuarterResponseDtoEopObligationStatusEnum.OVERDUE:
                return classes.statusColorRed;
            default:
                return '';
        }
    };

    const getMessage = () => {
        switch (status) {
            case QuarterResponseDtoEopObligationStatusEnum.FINALIZED:
                return Localisation.localize(`DATA_OVERVIEW.SUBMISSION_OVERVIEW.${type}_SUBMISSION_MESSAGES.FINALIZED`, {
                    taxYear
                });
            case QuarterResponseDtoEopObligationStatusEnum.NOT_DUE:
                return Localisation.localize(`DATA_OVERVIEW.SUBMISSION_OVERVIEW.${type}_SUBMISSION_MESSAGES.NOT_DUE`);
            case QuarterResponseDtoEopObligationStatusEnum.DUE:
                if (isReady) {
                    return Localisation.localize(`DATA_OVERVIEW.SUBMISSION_OVERVIEW.${type}_SUBMISSION_MESSAGES.DUE_READY`, {
                        taxYear
                    });
                } else {
                    return Localisation.localize(`DATA_OVERVIEW.SUBMISSION_OVERVIEW.${type}_SUBMISSION_MESSAGES.DUE_NOT_READY`);
                }
            case QuarterResponseDtoEopObligationStatusEnum.PENDING:
                return Localisation.localize(`DATA_OVERVIEW.SUBMISSION_OVERVIEW.${type}_SUBMISSION_MESSAGES.PENDING`, { taxYear });
            case QuarterResponseDtoEopObligationStatusEnum.OVERDUE:
                return Localisation.localize(`DATA_OVERVIEW.SUBMISSION_OVERVIEW.${type}_SUBMISSION_MESSAGES.OVERDUE`, {
                    taxYear
                });
        }
    };

    return (
        <div className={clsx(globalClasses.flexColumnStart, className)}>
            <Table className={clsx(classes.table)}>
                {showTaxYear && taxYear && (
                    <TableRow>
                        <TableHead>
                            <span className={clsx(globalClasses.font12weight700Dark)}>
                                {Localisation.localize('DATA_OVERVIEW.SUBMISSION_OVERVIEW.TAX_YEAR')}
                            </span>
                        </TableHead>
                        <TableCell>
                            <div className={clsx(globalClasses.flexRowSpaceBetween, globalClasses.fullWidth)}>
                                <span className={clsx(globalClasses.font12weight400Dark)}>{taxYear}</span>
                                {status === QuarterResponseDtoEopObligationStatusEnum.FINALIZED && (
                                    <FinalisedTag containerClasses={globalClasses.marginLeft8} />
                                )}
                            </div>
                        </TableCell>
                    </TableRow>
                )}
                {accountingPeriod && (
                    <TableRow>
                        <TableHead>
                            <span className={clsx(globalClasses.font12weight700Dark)}>
                                {Localisation.localize('DATA_OVERVIEW.SUBMISSION_OVERVIEW.ACCOUNTING_PERIOD')}
                            </span>
                        </TableHead>
                        <TableCell>
                            <div className={clsx(globalClasses.flexRowSpaceBetween, globalClasses.fullWidth)}>
                                <span className={clsx(globalClasses.font12weight400Dark)}>{accountingPeriod}</span>
                                {status === QuarterResponseDtoEopObligationStatusEnum.FINALIZED && (
                                    <FinalisedTag containerClasses={globalClasses.marginLeft8} />
                                )}
                            </div>
                        </TableCell>
                    </TableRow>
                )}
                <TableRow>
                    <TableHead>
                        <span className={clsx(globalClasses.font12weight700Dark)}>
                            {Localisation.localize(`DATA_OVERVIEW.SUBMISSION_OVERVIEW.${type}_SUBMISSION_DEADLINE`)}
                        </span>
                    </TableHead>
                    <TableCell>
                        <span className={clsx(globalClasses.font12weight400Dark)}>
                            {DateTimeServices.getDateInGivenFormat(dueDate, DATE_FORMAT.UK_STANDARD_2)}
                        </span>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableHead>
                        <span className={clsx(globalClasses.font12weight700Dark)}>
                            {Localisation.localize(`DATA_OVERVIEW.SUBMISSION_OVERVIEW.${type}_SUBMISSION_STATUS`)}
                        </span>
                    </TableHead>
                    <TableCell>
                        <span className={clsx(globalClasses.font12weight400Dark, globalClasses.flexRowStart)}>
                            <span className={clsx(getSubmissionStatusClass())}>{getSubmissionStatus()}</span>
                            {status === SubmissionDtoObligationStatusEnum.PENDING && (
                                <Tooltip
                                    placement="top"
                                    arrow
                                    title={Localisation.localize(
                                        'DATA_OVERVIEW.SUBMISSION_OVERVIEW.EOPS_PENDING_HMRC_CONFIRMATION_TOOLTIP'
                                    )}
                                >
                                    <InfoOutlinedIcon className={clsx(classes.infoIcon)} />
                                </Tooltip>
                            )}
                        </span>
                    </TableCell>
                </TableRow>
            </Table>
            {!hideMessage && <div className={clsx(globalClasses.font13weight400Dark, globalClasses.marginTop8)}>{getMessage()}</div>}
        </div>
    );
};

export default SubmissionStatus;
