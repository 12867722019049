import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../constants';

export default makeStyles(theme => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: '0 16px 16px'
        }
    },
    hideImage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    switchElementLabel: {
        lineHeight: '32px'
    },
    hero: {
        [theme.breakpoints.down('sm')]: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    image: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 30
        },

        marginBottom: 42,
        marginTop: 27,
        display: 'flex',
        justifyContent: 'center'
    },
    btn: {
        maxWidth: 288
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            alignItems: 'center'
        }
    },
    formContainer: {
        padding: 20,
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 6fr)',
            justifyContent: 'center'
        }
    },
    typeBtn: {
        borderRadius: 5,
        fontSize: '11px !important'
    },
    form: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 265
        }
    },
    previewTypeBtn: {
        color: COLORS.apariBlack40 + ' !important',
        borderColor: COLORS.apariBlack10 + ' !important'
    },
    previewInput: {
        '& .MuiInputBase-input.Mui-disabled': {
            color: COLORS.apariBlack40
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: COLORS.apariBlack40
        },
        '& .Mui-disabled .MuiInputAdornment-root p': {
            color: COLORS.apariBlack40
        },
        '& fieldset': {
            borderColor: COLORS.apariBlack10 + ' !important'
        }
    },
    previewSelect: {
        '& .MuiFormLabel-root.Mui-disabled': {
            color: COLORS.apariBlack40
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: COLORS.apariBlack40 + ' !important'
        },
        '& fieldset': {
            borderColor: COLORS.apariBlack10 + ' !important'
        }
    },
    previewDatePicker: {
        '& .MuiFormLabel-root': {
            color: COLORS.apariBlack40 + ' !important'
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: COLORS.apariBlack40 + ' !important'
        },
        '& .MuiButtonBase-root': {
            color: COLORS.apariBlack40 + ' !important'
        },
        '& fieldset': {
            borderColor: COLORS.apariBlack10 + ' !important'
        }
    },
    infoIcon: {
        marginLeft: 10,
        color: COLORS.apariBlack40,
        fontSize: 20
    },
    tooltipDesign: {
        maxWidth: 235,
        height: 60,
        padding: '8px 16px 14px 16px',
        backgroundColor: '#B7B7B7',
        color: COLORS.apariWhite,
        fontSize: 10,
        fontWeight: 600
    },
    arrowDesign: {
        color: '#B7B7B7'
    }
}));
