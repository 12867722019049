import React, { useState } from 'react';
import { Chip, TextField, TextFieldProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getIn } from 'formik';
import { difference, without } from 'lodash';
import { Localisation } from 'utils';
import styles from './styles';
import { ApariInfoDialog } from 'components';

type Props = TextFieldProps & {
    formik?: any;
    withModal?: boolean;
};

const ApariChipsInput: React.FC<Props> = props => {
    const { formik, margin, size, variant, label, className, disabled, withModal, ...rest } = props;
    const formikInputValue = getIn(formik.values, rest?.name as string);
    const [items, setItems] = useState<string[]>(formikInputValue || []);
    const [open, setOpen] = useState<Record<string, any>>({ status: false });
    const [inputValue, setInputValue] = useState<string>('');
    const classes = styles();

    const handleChipDelete = (item: string) => () => {
        if (withModal) setOpen({ status: true, item });
        else {
            setItems(prevState => without(prevState, item));
            if (formik) formik.setFieldValue(rest.name as string, without(formikInputValue, item));
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            const duplicatedValues = items.find(item => item === e.target.value);
            if (duplicatedValues) {
                setInputValue('');
                return;
            }
            if (!e.target.value.replace(/\s/g, '').length) return;
            setItems([...items, e.target.value.trim()]);
            if (formik) formik.setFieldValue(rest.name as string, [...items, e.target.value.trim()]);
            setInputValue('');
        }
        if (items.length && !inputValue.length && e.key === 'Backspace') {
            const removedItem = items.slice(0, items.length - 1);
            if (withModal) setOpen({ status: true, item: difference(items, removedItem)[0] });
            else {
                setItems(removedItem);
                if (formik) formik.setFieldValue(rest.name as string, removedItem);
            }
        }
    };

    function handleInputChange(event: any) {
        setInputValue(event.target.value);
    }

    const onConfirm = () => {
        setItems(prevState => without(prevState, open.item));
        if (formik) formik.setFieldValue(rest.name as string, without(formikInputValue, open.item));
        setOpen({ status: false });
    };

    return (
        <>
            <TextField
                InputProps={{
                    startAdornment: items.map(item => (
                        <Chip
                            key={item}
                            tabIndex={-1}
                            label={item}
                            onDelete={handleChipDelete(item)}
                            className={classes.chip}
                            deleteIcon={<CloseIcon />}
                            classes={{ deleteIcon: classes.chipRemoveButton }}
                        />
                    )),
                    classes: { root: classes.input },
                    onChange: event => {
                        handleInputChange(event);
                    }
                }}
                label={label}
                disabled={disabled}
                margin={margin || 'normal'}
                size={size || 'small'}
                variant={variant || 'outlined'}
                value={inputValue}
                onKeyDown={handleKeyDown}
                placeholder={Localisation.localize('general.TYPE_IN')}
                className={className}
                {...rest}
            />
            <ApariInfoDialog
                open={open.status}
                buttonText={Localisation.localize('general.ok')}
                infoText={Localisation.localize('CHARITABLE_GIVING_OVERVIEW_SCREEN.ON_DELETE_CHIP_ITEM')}
                onCancel={() => setOpen({ status: false })}
                onConfirm={onConfirm}
            />
        </>
    );
};

export default ApariChipsInput;
