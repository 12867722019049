import React, { useState, createContext, useEffect } from 'react';
import { User, TaxEntity, UserTwoFactorAuthMethodEnum, PersonalData } from '@apari/core-api';
import { HMRCConnectionStatusForUserDtoHmrcUserConnectionStatusEnum, UkTaxIdentificationPropertyDto } from '@apari/uk-mtd-api';
import { GlobalServices } from '../utils';
import { CURRENT_USER, PERSONAL_DATA } from '../constants/mockData';

interface SettingsContextInterface {
    user: User;
    personalData: PersonalData;
    taxEntity: TaxEntity;
    hmrcAuthorization: UkTaxIdentificationPropertyDto | undefined;
    setUser: (user: User) => void;
    setPersonalData: (personalData: PersonalData) => void;
    setTaxEntity: (taxEntity: TaxEntity) => void;
    setHMRCAuthorization: (hmrcAuthorization: UkTaxIdentificationPropertyDto | undefined) => void;
    updateTFAMethod: (TFAMethod: UserTwoFactorAuthMethodEnum | undefined) => void;
    apiFailed: boolean;
    loading: boolean;
    refreshUserData: () => void;
    refreshPersonalData: () => void;
    getSettingsData: () => void;
    hmrcStatus?: HMRCConnectionStatusForUserDtoHmrcUserConnectionStatusEnum;
}

export const SettingsContext = createContext({} as SettingsContextInterface);

export const SettingsProvider: React.FC = ({ children }) => {
    const [apiFailed, setApiFailed] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<User>({} as User);
    const [personalData, setPersonalData] = useState<PersonalData>({} as PersonalData);
    const [taxEntity, setTaxEntity] = useState<TaxEntity>({} as TaxEntity);
    const [hmrcAuthorization, setHMRCAuthorization] = useState<UkTaxIdentificationPropertyDto | undefined>();
    const [hmrcStatus, setHmrcStatus] = useState<HMRCConnectionStatusForUserDtoHmrcUserConnectionStatusEnum>();

    useEffect(() => {
        getSettingsData();
        setHmrcStatus(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSettingsData = async () => {
        try {
            setLoading(true);
            await GlobalServices.timeout(300);
            await refreshPersonalData();
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setApiFailed(true);
        }
    };

    const updateTFAMethod = (TFAMethod: UserTwoFactorAuthMethodEnum | undefined) => {
        setUser({ ...user, ...{ twoFactorAuthMethod: TFAMethod } });
    };

    const refreshUserData = async () => {
        setLoading(true);
        try {
            await GlobalServices.timeout(500);
            setUser(CURRENT_USER);
        } catch (e) {
            console.log(e);
            setApiFailed(true);
        }
        setLoading(false);
    };

    const refreshPersonalData = async () => {
        setLoading(true);
        try {
            await GlobalServices.timeout(500);
            setPersonalData(PERSONAL_DATA);
        } catch (e) {
            console.log(e);
            setApiFailed(true);
        }
        setLoading(false);
    };

    return (
        <SettingsContext.Provider
            value={{
                apiFailed,
                loading,
                user,
                personalData,
                taxEntity,
                hmrcAuthorization,
                hmrcStatus,
                setUser,
                setPersonalData,
                setTaxEntity,
                setHMRCAuthorization,
                updateTFAMethod,
                refreshUserData,
                refreshPersonalData,
                getSettingsData
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};
