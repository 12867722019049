import { COLORS } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    list: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        gridGap: '10px',
        width: '100%',
        padding: 0,
        ['@media (min-width:650px)']: {
            // eslint-disable-line no-useless-computed-key
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            gridGap: '10px 24px'
        }
    },
    listItem: {
        width: '100%',
        background: theme.palette.background.paper,
        padding: `${theme.spacing(1.5) + 'px ' + theme.spacing(2) + 'px'}`,
        height: 45,
        borderRadius: theme.spacing(3),
        border: `${'2px solid ' + COLORS.transparentLight}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            borderColor: COLORS.transparentLight
        }
    },
    listItemSelected: {
        background: COLORS.transparentLight + ' !important',
        borderColor: theme.palette.primary.main + ' !important',
        '&:hover': {
            borderColor: theme.palette.primary.main + ' !important'
        }
    }
}));
