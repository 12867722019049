import React, { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { withWidth, WithWidthProps } from '@material-ui/core';
import { AuthenticationContext } from 'context/AuthenticationContext';
import { AnimatedTransition, ApariButton, SubHeaderClean, ApTextField } from 'components';
import NewLayout from 'containers/layouts/NewLayout';
import { UIServices, Localisation } from 'utils';
import { TokenErrorTypes } from 'types';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

import validationSchema from './validationSchema';

type Props = WithWidthProps & {
    errorType: TokenErrorTypes | undefined;
};

const AccountConfirmationError: React.FC<Props> = ({ errorType, width }) => {
    const globalClasses = globalStyles();
    const classes = styles();
    const history = useHistory();
    const isInitialMount = useRef(true);

    const { verificationEmailSent, setVerificationEmailSent, resendEmailVerificationToken } = useContext(AuthenticationContext);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (verificationEmailSent) {
                history.replace('/sign-in');
                setVerificationEmailSent(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verificationEmailSent]);

    const submitForm = async (data: any) => {
        await resendEmailVerificationToken(data.email);
    };

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit: submitForm,
        validationSchema: validationSchema
    });

    const renderErrorMessage = () => {
        switch (errorType) {
            case TokenErrorTypes.EXPIRED:
                return Localisation.localize('AccountConfirmationScreen.tokenExpired');
            case TokenErrorTypes.INVALID:
                return Localisation.localize('AccountConfirmationScreen.tokenInvalid');
            default:
                return '';
        }
    };

    const renderMainContent = () => {
        return (
            <>
                <div>
                    <SubHeaderClean
                        title={Localisation.localize('SignInScreen.welcomeToApari')}
                        noSidePadding
                        alternativeFont
                        noBackButton
                        messages={[renderErrorMessage()]}
                    />
                </div>
                <form
                    className={clsx(globalClasses.fullWidth, globalClasses.fullHeight, classes.formContainer)}
                    onSubmit={formik.handleSubmit}
                >
                    <div>
                        <div className={clsx(globalClasses.font12weight400Dark, globalClasses.marginBottom8)}>
                            {Localisation.localize('AccountConfirmationScreen.newLink')}
                        </div>
                        <ApTextField control="email" label={Localisation.localize('general.emailAddress')} formik={formik} />
                    </div>
                    <div className={clsx(classes.confirmButtonContainer)}>
                        <ApariButton disabled={!formik.isValid} type="submit" fullWidth color="primary" variant="contained" size="small">
                            {Localisation.localize('AccountConfirmationScreen.sendVerificationLink')}
                        </ApariButton>
                    </div>
                </form>
            </>
        );
    };

    const renderMobileContent = () => {
        return (
            <div className={clsx(globalClasses.contentWrapper, classes.contentWrapperOverride)}>
                <div className={clsx(globalClasses.fullHeight, globalClasses.fullWidth)}>
                    <div className={clsx(globalClasses.fullHeight)}>
                        <div className={clsx(globalClasses.fullHeight, globalClasses.flexColumn, globalClasses.fontRoboto)}>
                            {renderMainContent()}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderDesktopContent = () => {
        return (
            <div className={clsx(globalClasses.contentWrapper, classes.contentWrapperOverride)}>
                <div className={clsx(globalClasses.fullHeight, globalClasses.fullWidth, globalClasses.flexRow)}>
                    <div className={clsx(globalClasses.flexColumnCenter, globalClasses.fullHeight, globalClasses.fontRoboto)}>
                        {renderMainContent()}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <NewLayout
            whiteBackground
            hideBack
            hideFooter
            hideTitle
            headerText={Localisation.localize('general.registration')}
            enableBackgroundArrows
        >
            <AnimatedTransition addHeight centerVerticallyInDesktop className={classes.marginBottomOverride}>
                {UIServices.isMobile(width) ? renderMobileContent() : renderDesktopContent()}
            </AnimatedTransition>
        </NewLayout>
    );
};

export default withWidth()(AccountConfirmationError);
