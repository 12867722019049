import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    container: {
        width: '100%',
        height: '140px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px',
        fontWeight: 600,
        color: COLORS.apariBlack,
        background: COLORS.backgroundDark,
        borderRadius: '2px',
        border: '1px dashed',
        borderColor: COLORS.backgroundDark
    },
    dragging: {
        backgroundColor: COLORS.backgroundLight,
        borderColor: '#ccc'
    },
    input: {
        position: 'absolute',
        left: '0',
        top: '0',
        width: '1px',
        height: '1px',
        opacity: '0',
        cursor: 'default'
    },
    error: {
        borderColor: theme.palette.error.main,
        borderStyle: 'solid'
    },
    errorText: {
        fontSize: 11,
        marginLeft: 14,
        marginTop: 4,
        color: theme.palette.error.main
    }
}));
