import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    root: {
        marginTop: 4,
        '& .MuiInputBase-input': {
            paddingBottom: 4,
            fontSize: 12
        },
        '& .MuiInputAdornment-root p': {
            fontSize: 12
        }
    }
}));
