import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: theme.spacing(1),
        border: 2,
        borderStyle: 'solid',
        borderColor: COLORS.transparentLight,
        minWidth: 33,
        maxWidth: 33,
        minHeight: 35,
        maxHeight: 35,
        marginRight: 10,
        boxSizing: 'border-box',
        textTransform: 'none',
        fontSize: 12,
        padding: '6px 8.5px',
        background: theme.palette.background.paper,
        '&:hover': {
            borderColor: COLORS.darkPurple
        },
        '&$selected': {
            borderColor: COLORS.darkPurple,
            background: COLORS.backgroundDark
        }
    },
    selected: {},
    indicator: {
        display: 'none'
    }
}));
