export enum CalculationOverviewSelection {
    TAX_RETURN = 'TAX_RETURN',
    TAX_CALCULATION = 'TAX_CALCULATION',
    HMRC_MESSAGES = 'HMRC_MESSAGES'
}

export enum CalculationOverviewOptions {
    INCOME = 'INCOME',
    DEDUCTIONS = 'DEDUCTIONS',
    CURRENT = 'CURRENT',
    END_OF_YEAR = 'END_OF_YEAR',
    MESSAGES = 'MESSAGES'
}

export enum CalculationOverviewEndpointTypes {
    ALLOWANCES_DEDUCTIONS_RELIEFS = 'ALLOWANCES_DEDUCTIONS_RELIEFS',
    END_OF_YEAR_ESTIMATE = 'END_OF_YEAR_ESTIMATE',
    INCOME_TAX_NICS_CALCULATED = 'INCOME_TAX_NICS_CALCULATED',
    TAXABLE_INCOME = 'TAXABLE_INCOME',
    CALCULATION_MESSAGES = 'CALCULATION_MESSAGES',
    TAX_CALCULATION_METADATA = 'TAX_CALCULATION_METADATA'
}
