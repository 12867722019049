import React from 'react';
import { ApariTextField } from 'components';
import { FormikContextType, getIn } from 'formik';
import { TextFieldProps } from '@material-ui/core';

interface Props {
    control: string;
    formik: FormikContextType<any>;
    noShrinkClass?: boolean;
    alternativeFont?: boolean;
}

const ApTextField: React.FC<Props & TextFieldProps> = ({ control, formik, noShrinkClass, alternativeFont, ...rest }) => {
    return (
        <ApariTextField
            name={control}
            value={getIn(formik.values, control) || ''}
            onChange={formik.handleChange}
            onBlur={event => formik.setFieldValue(control, event.target.value.trim())}
            error={getIn(formik.touched, control) && Boolean(getIn(formik.errors, control))}
            helperText={getIn(formik.touched, control) && getIn(formik.errors, control)}
            variant="outlined"
            fullWidth
            margin="dense"
            noShrinkClass={noShrinkClass}
            alternativeFont={alternativeFont}
            {...rest}
        />
    );
};

export default ApTextField;
