import React, { useState, createContext, useEffect, useRef } from 'react';
import { MoneyHubUserConnectionAndStatusDto } from '@apari/banking-api';
import { PageTransactionDto } from '@apari/banking-api/models';
import LocalStorage from 'utils/localStorage';
import { Business, BusinessSourceBasicInfo } from '@apari/core-api';
import { iTiles } from './TilesContext';
import { TaxYearDto } from '@apari/uk-jf-api';

interface MockDataContextInterface {
    userBankConnections: MoneyHubUserConnectionAndStatusDto[];
    setUserBankConnections: React.Dispatch<React.SetStateAction<MoneyHubUserConnectionAndStatusDto[]>>;
    assignedTransactions?: PageTransactionDto;
    setAssignedTransactions: React.Dispatch<React.SetStateAction<PageTransactionDto | undefined>>;
    unassignedTransactions?: PageTransactionDto;
    setUnassignedTransactions: React.Dispatch<React.SetStateAction<PageTransactionDto | undefined>>;
    landlordBusiness?: Business;
    setLandlordBusiness: React.Dispatch<React.SetStateAction<Business | undefined>>;
    selfEmployedBusiness?: Business;
    setSelfEmployedBusiness: React.Dispatch<React.SetStateAction<Business | undefined>>;
    paid: boolean;
    setPaid: React.Dispatch<React.SetStateAction<boolean>>;
    mockTiles: iTiles[];
    setMockTiles: React.Dispatch<React.SetStateAction<iTiles[]>>;
    mockTaxYear: TaxYearDto;
    setMockTaxYear: React.Dispatch<React.SetStateAction<TaxYearDto>>;
    mockIncomeTypes: BusinessSourceBasicInfo[];
    setMockIncomeTypes: React.Dispatch<React.SetStateAction<BusinessSourceBasicInfo[]>>;
}

export const MockDataContext = createContext({} as MockDataContextInterface);

export const MockDataContextProvider: React.FC = ({ children }) => {
    const [userBankConnections, setUserBankConnections] = useState<MoneyHubUserConnectionAndStatusDto[]>([]);
    const [assignedTransactions, setAssignedTransactions] = useState<PageTransactionDto>();
    const [unassignedTransactions, setUnassignedTransactions] = useState<PageTransactionDto>();
    const [landlordBusiness, setLandlordBusiness] = useState<Business>();
    const [selfEmployedBusiness, setSelfEmployedBusiness] = useState<Business>();
    const [paid, setPaid] = useState(false);
    const [mockTiles, setMockTiles] = useState<iTiles[]>([]);
    const [mockTaxYear, setMockTaxYear] = useState<TaxYearDto>({} as TaxYearDto);
    const [mockIncomeTypes, setMockIncomeTypes] = useState<BusinessSourceBasicInfo[]>([]);

    const isInitialMountUserBankConnections = useRef(true);
    const isInitialAssignedTransactions = useRef(true);
    const isInitialUnassignedTransactions = useRef(true);
    const isInitialLandlordBusiness = useRef(true);
    const isInitialSelfEmployedBusiness = useRef(true);
    const isInitialPaid = useRef(true);
    const isInitialTiles = useRef(true);
    const isInitialMockTaxYear = useRef(true);
    const isInitialMockIncomeTypes = useRef(true);

    useEffect(() => {
        const userBankConnectionsStorage = LocalStorage.getJSON('userBankConnections') as MoneyHubUserConnectionAndStatusDto[];
        const assignedTransactionsStorage = LocalStorage.getJSON('assignedTransactions') as PageTransactionDto;
        const unassignedTransactionsStorage = LocalStorage.getJSON('unassignedTransactions') as PageTransactionDto;
        const landlordBusinessStorage = LocalStorage.getJSON('landlordBusiness') as Business;
        const selfEmployedBusinessStorage = LocalStorage.getJSON('selfEmployedBusiness') as Business;
        const paidStorage = localStorage.getItem('paid');
        const mockTilesStorage = LocalStorage.getJSON('tiles') as iTiles[];
        const mockTaxYearStorage = LocalStorage.getJSON('taxYear') as TaxYearDto;
        const mockIncomeTypesStorage = LocalStorage.getJSON('incomeTypes') as BusinessSourceBasicInfo[];

        setUserBankConnections(userBankConnectionsStorage);
        setAssignedTransactions(assignedTransactionsStorage);
        setUnassignedTransactions(unassignedTransactionsStorage);
        setLandlordBusiness(landlordBusinessStorage);
        setSelfEmployedBusiness(selfEmployedBusinessStorage);
        setPaid(paidStorage === 'true');
        setMockTiles(mockTilesStorage);
        setMockTaxYear(mockTaxYearStorage);
        setMockIncomeTypes(mockIncomeTypesStorage);
    }, []);

    useEffect(() => {
        if (isInitialMountUserBankConnections.current) {
            isInitialMountUserBankConnections.current = false;
        } else {
            LocalStorage.setJSON('userBankConnections', (userBankConnections as any) ?? []);
        }
    }, [userBankConnections]);

    useEffect(() => {
        if (isInitialAssignedTransactions.current) {
            isInitialAssignedTransactions.current = false;
        } else {
            LocalStorage.setJSON('assignedTransactions', (assignedTransactions as any) ?? []);
        }
    }, [assignedTransactions]);

    useEffect(() => {
        if (isInitialUnassignedTransactions.current) {
            isInitialUnassignedTransactions.current = false;
        } else {
            LocalStorage.setJSON('unassignedTransactions', (unassignedTransactions as any) ?? []);
        }
    }, [unassignedTransactions]);

    useEffect(() => {
        if (isInitialLandlordBusiness.current) {
            isInitialLandlordBusiness.current = false;
        } else {
            LocalStorage.setJSON('landlordBusiness', landlordBusiness as any);
        }
    }, [landlordBusiness]);

    useEffect(() => {
        if (isInitialSelfEmployedBusiness.current) {
            isInitialSelfEmployedBusiness.current = false;
        } else {
            LocalStorage.setJSON('selfEmployedBusiness', selfEmployedBusiness as any);
        }
    }, [selfEmployedBusiness]);

    useEffect(() => {
        if (isInitialPaid.current) {
            isInitialPaid.current = false;
        } else {
            LocalStorage.setJSON('paid', paid as any);
        }
    }, [paid]);

    useEffect(() => {
        if (isInitialTiles.current) {
            isInitialTiles.current = false;
        } else {
            LocalStorage.setJSON('tiles', (mockTiles as any) ?? []);
        }
    }, [mockTiles]);

    useEffect(() => {
        if (isInitialMockTaxYear.current) {
            isInitialMockTaxYear.current = false;
        } else {
            LocalStorage.setJSON('taxYear', mockTaxYear as any);
        }
    }, [mockTaxYear]);

    useEffect(() => {
        if (isInitialMockIncomeTypes.current) {
            isInitialMockIncomeTypes.current = false;
        } else {
            LocalStorage.setJSON('incomeTypes', mockIncomeTypes as any);
        }
    }, [mockIncomeTypes]);

    return (
        <MockDataContext.Provider
            value={{
                userBankConnections,
                setUserBankConnections,
                assignedTransactions,
                setAssignedTransactions,
                unassignedTransactions,
                setUnassignedTransactions,
                landlordBusiness,
                setLandlordBusiness,
                selfEmployedBusiness,
                setSelfEmployedBusiness,
                paid,
                setPaid,
                mockTiles,
                setMockTiles,
                mockTaxYear,
                setMockTaxYear,
                mockIncomeTypes,
                setMockIncomeTypes
            }}
        >
            {children}
        </MockDataContext.Provider>
    );
};
