import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { AuthenticationContext } from 'context/AuthenticationContext';
import { NotificationsContext } from 'context/NotificationsContext';
import globalStyles from 'styles/globalStyles';
import Header from './Header';
import styles from './styles';
import NavigationContent from './NavigationContent';
import Footer from './Footer';
import InfoBar from './InfoBar';
import NotificationBarAndPopUp from './NotificationBarAndPopUp';

type Props = {
    children: React.ReactElement[] | React.ReactElement;
    handleBack?: () => void;
    headerText?: string;
    hideHeader?: boolean;
    contentClassName?: string;
    navigationClassName?: string;
    hideBack?: boolean;
    whiteBackground?: boolean;
    transparentHeader?: boolean;
    noSidePadding?: boolean;
    hideMenu?: boolean;
    hideFooter?: boolean;
    footerHasBorder?: boolean;
    enableInfoBar?: boolean;
    showLogo?: boolean;
    hideTitle?: boolean;
    hidePopUp?: boolean;
    hideAppSwitch?: boolean;
    disableLogoAction?: boolean;
    enableBackgroundArrows?: boolean;
    enableLaptopAndMobileImage?: boolean;
    bringHeaderOverBackground?: boolean;
    hideUserInfo?: boolean;
    hideBottomLine?: boolean;
    isAgent?: boolean;
    footerWidth?: number;
};

const NewLayout: React.FC<Props> = ({
    children,
    handleBack,
    headerText,
    hideHeader,
    contentClassName,
    navigationClassName,
    hideBack,
    hideMenu,
    whiteBackground,
    transparentHeader,
    noSidePadding,
    hideFooter,
    footerHasBorder,
    enableInfoBar,
    showLogo,
    hideTitle,
    hidePopUp,
    hideAppSwitch,
    disableLogoAction,
    enableBackgroundArrows,
    enableLaptopAndMobileImage,
    bringHeaderOverBackground,
    hideUserInfo,
    hideBottomLine,
    isAgent,
    footerWidth
}) => {
    const [scrollPosition, setScrollPosition] = useState<number | undefined>(undefined);

    const { authenticated, isImpersonating } = useContext(AuthenticationContext);
    const { topBarNotification } = useContext(NotificationsContext);

    const menuHidden = hideMenu || !authenticated;

    const classes = styles({
        noSidePadding,
        hideMenu: menuHidden,
        enableBackgroundArrows,
        enableLaptopAndMobileImage,
        isAgent
    });
    const globalClasses = globalStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        window.addEventListener('scroll', resizeHeaderOnScroll, { passive: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resizeHeaderOnScroll = () => {
        if (window.pageYOffset === 0) {
            setScrollPosition(0);
        }
        if (window.pageYOffset > 0) {
            setScrollPosition(window.pageYOffset);
        }
    };

    return (
        <div
            data-testid="new-layout"
            className={clsx(classes.root, whiteBackground && classes.whiteBackground, globalClasses.fullHeight, globalClasses.flexColumn)}
        >
            {!hideHeader && (
                <Header
                    transparentHeader={transparentHeader || (!isMobile && enableBackgroundArrows && !bringHeaderOverBackground)}
                    text={headerText}
                    handleBack={handleBack}
                    hideMenu={menuHidden}
                    hideBack={hideBack}
                    scrollPosition={scrollPosition}
                    showLogo={showLogo}
                    hideTitle={hideTitle}
                    hideAppSwitch={hideAppSwitch}
                    disableLogoAction={disableLogoAction}
                    hideUserInfo={hideUserInfo}
                    hideBottomLine={hideBottomLine}
                    enableInfoBar={enableInfoBar}
                />
            )}

            {!menuHidden && !isMobile && (
                <div className={clsx(classes.desktopNavigation, navigationClassName)}>
                    <NavigationContent disableLogoAction={disableLogoAction} />
                </div>
            )}

            <div
                id="scrollable-content"
                className={clsx(
                    classes.contentRoot,
                    globalClasses.flexColumn,
                    hideHeader && classes.noTopMargin,
                    contentClassName,
                    isImpersonating && isMobile && classes.marginTop50
                )}
            >
                {(authenticated || enableInfoBar) && !isImpersonating && <InfoBar />}
                {!hidePopUp && authenticated && <NotificationBarAndPopUp />}
                {!isEmpty(topBarNotification) && !hidePopUp && <div style={{ marginTop: '-72px' }} />}
                {children}
                {!hideFooter && <Footer hasBorder={footerHasBorder} width={footerWidth} />}
            </div>
        </div>
    );
};

export default NewLayout;
