import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { withWidth, WithWidthProps } from '@material-ui/core';
import clsx from 'clsx';
import { NewLayout } from 'containers/layouts';
import { AnimatedTransition, ApariButton, DividerWithText, SubHeaderClean, SingleSignOn } from 'components';
import Arrow from 'resources/images/Arrow.png';
import globalStyles from 'styles/globalStyles';
import { SingleSignOnTypes } from 'types';
import { UIServices, Localisation } from 'utils';
import styles from './styles';

const SignUpScreen: React.FC<RouteComponentProps & WithWidthProps> = props => {
    const { history, width } = props;

    const globalClasses = globalStyles();
    const classes = styles();

    const renderMainContent = () => {
        return (
            <>
                <SubHeaderClean
                    title={Localisation.localize('signUpWithEmailScreen.welcomeToApari')}
                    noSidePadding
                    messages={[Localisation.localize('signUpWithEmailScreen.secondaryWelcomeMessage')]}
                />
                <div
                    className={clsx(globalClasses.fullHeight, globalClasses.fullWidth, classes.contentContainer, globalClasses.flexColumn)}
                >
                    <div className={clsx(globalClasses.fullWidth, globalClasses.marginBottomAuto)}>
                        <SingleSignOn type={SingleSignOnTypes.CONTINUE} />
                        <DividerWithText className={clsx(globalClasses.marginTop20, globalClasses.marginBottom12)}>
                            {Localisation.localize('SingleSignOn.or')}
                        </DividerWithText>
                        <ApariButton
                            disableRipple
                            type="submit"
                            color={'primary'}
                            variant={'outlined'}
                            className={clsx(classes.singUpButton)}
                            onClick={() => history.push('/sign-up-with-email')}
                            fullWidth
                            marginVertical
                        >
                            {Localisation.localize('SignUpScreen.signUpWithEmail')}
                        </ApariButton>
                    </div>
                    <div
                        className={clsx(
                            globalClasses.font12weight600Dark,
                            globalClasses.marginBottom4,
                            globalClasses.marginTop12,
                            classes.mobileLink
                        )}
                    >
                        <Link to="/sign-in">{Localisation.localize('SignUpScreen.alreadyHaveAccount')}</Link>
                    </div>
                </div>
            </>
        );
    };

    const renderMobileContent = () => {
        return (
            <div className={clsx(globalClasses.contentWrapper, classes.contentWrapperOverride)}>
                <div className={clsx(globalClasses.fullHeight, globalClasses.fullWidth)}>
                    <div className={clsx(globalClasses.fullHeight)}>
                        <div className={clsx(globalClasses.fullHeight, globalClasses.flexColumnVerticalCenter)}>{renderMainContent()}</div>
                    </div>
                </div>
            </div>
        );
    };

    const renderDesktopContent = () => {
        return (
            <div className={clsx(globalClasses.contentWrapper, classes.contentWrapperOverride)}>
                <div className={clsx(globalClasses.fullHeight, globalClasses.fullWidth, globalClasses.flexRow)}>
                    <div className={clsx(globalClasses.flexColumnCenter, globalClasses.fullHeight)}>{renderMainContent()}</div>
                    <div className={clsx(globalClasses.marginLeftAuto)}>
                        <img alt="login" src={Arrow} height={308} />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <NewLayout
            whiteBackground
            hideHeader={false}
            hideBack
            hideFooter
            headerText={Localisation.localize('signUpWithEmailScreen.welcomeToApari')}
        >
            <AnimatedTransition addHeight centerVerticallyInDesktop>
                {UIServices.isMobile(width) ? renderMobileContent() : renderDesktopContent()}
            </AnimatedTransition>
        </NewLayout>
    );
};

export default withWidth()(SignUpScreen);
