import React, { ChangeEvent } from 'react';
import { FormikContextType } from 'formik';
import InputMask from 'react-input-mask';
import { TextFieldProps } from '@material-ui/core';
import { ApariTextField } from 'components';
import { Localisation } from 'utils';

interface Props {
    control: string;
    formik: FormikContextType<any>;
    isOnPersonalDataScreen?: boolean;
}

const ApNationalInsuranceNumber: React.FC<Props & TextFieldProps> = ({ control, formik, isOnPersonalDataScreen, disabled, ...rest }) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => formik.setFieldValue(control, event.target.value.toUpperCase());
    const inputMaskField = () => (
        <ApariTextField
            name={control}
            error={formik.touched[control] && Boolean(formik.errors[control])}
            helperText={formik.touched[control] && formik.errors[control]}
            variant="outlined"
            fullWidth
            disabled={disabled}
            margin="dense"
            placeholder={isOnPersonalDataScreen ? Localisation.localize('PersonalDataScreen.NINO_PLACEHOLDER') : undefined}
            {...rest}
        />
    );

    return (
        <InputMask
            mask="aa999999a"
            maskPlaceholder=""
            disabled={disabled}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            maskChar=""
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formatChars={{
                '9': '[0-9]',
                a: '[A-Za-z]',
                s: '[ABCDabcd]'
            }}
            onChange={handleChange}
            value={formik.values[control] ?? ''}
        >
            {inputMaskField}
        </InputMask>
    );
};

export default ApNationalInsuranceNumber;
