import React, { useState } from 'react';
import { IconButton, InputAdornment, TextFieldProps } from '@material-ui/core';
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';
import { ApariTextField } from '../index';
import clsx from 'clsx';
import styles from './styles';

type Props = TextFieldProps & {
    darkBackground?: boolean;
    noShrinkClass?: boolean;
    Icon?: React.ElementType;
    onIconPress?: () => void;
    iconStyle?: string;
    alternativeFont?: boolean;
};

const ApariPasswordField: React.FC<Props> = props => {
    const { className, InputLabelProps, InputProps, Icon, onIconPress, iconStyle, disabled, alternativeFont, ...rest } = props;
    const classes = styles();
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(prevState => !prevState);
    const handleShowPasswordMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <ApariTextField
            className={className}
            fullWidth
            disabled={disabled}
            inputProps={{
                passwordrules: 'minlength: 20; required: lower; required: upper; required: digit; required: [-];'
            }}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                className: clsx(alternativeFont && classes.alternativeFont),
                ...InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            disabled={disabled}
                            onClick={onIconPress ? onIconPress : handleShowPassword}
                            onMouseDown={handleShowPasswordMouseDown}
                            className={clsx(classes.showPasswordIcon)}
                        >
                            {Icon ? <Icon className={iconStyle} /> : showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            InputLabelProps={{
                className: clsx(alternativeFont && classes.alternativeFont),
                ...InputLabelProps
            }}
            {...rest}
        />
    );
};

export default ApariPasswordField;
