import React, { useEffect, useRef, useState } from 'react';
import { IconButton, useTheme, withWidth, WithWidthProps } from '@material-ui/core';
import clsx from 'clsx';
import { PulseLoader } from 'react-spinners';
import { motion } from 'framer-motion';
import { ArrowBackIcon } from 'resources/icons';
import globalStyles from 'styles/globalStyles';
import { UIServices, GlobalServices } from 'utils';
import styles from './styles';

type Props = WithWidthProps & {
    messages: string[];
    enableBack?: boolean;
    shrinkOnScroll?: boolean;
    scrollPosition?: number;
    handleBack?: () => void;
    title?: string;
};

const SubHeader: React.FC<Props> = ({ title, width, messages, enableBack, scrollPosition = 0, shrinkOnScroll, handleBack }) => {
    const [secondMessage, setSecondMessage] = useState<string>('');
    const [shrink, setShrink] = useState<boolean>(false);
    const theme = useTheme();
    const classes = styles();
    const globalClasses = globalStyles();
    const isInitialMount = useRef(true);

    useEffect(() => {
        getSecondMessage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (shrinkOnScroll && UIServices.isMobile(width)) {
                if (scrollPosition > 1) {
                    setShrink(true);
                } else {
                    setShrink(false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollPosition]);

    const getSecondMessage = () => {
        if (messages.length > 1) {
            setTimeout(() => {
                setSecondMessage(messages[1]);
            }, 1000);
        }
    };

    const renderSecondMessage = () => {
        if (messages.length === 1) {
            if (messages[1]) {
                return <div className={clsx(classes.message, classes.secondMessage)}>{messages[1]}</div>;
            }
        } else {
            if (GlobalServices.isEmpty(secondMessage)) {
                return (
                    <div className={classes.loaderWrapper}>
                        <PulseLoader color={theme.palette.grey[500]} size={12} />
                    </div>
                );
            } else {
                return (
                    <motion.div initial={{ opacity: 0 }} transition={{ duration: 0.5 }} animate={{ opacity: 1 }}>
                        <div className={clsx(classes.message, classes.secondMessage)}>{secondMessage}</div>
                    </motion.div>
                );
            }
        }
    };

    const renderBackButton = () => {
        if (enableBack && !UIServices.isMobile(width)) {
            return (
                <IconButton onClick={handleBack} className={clsx(globalClasses.marginRight8)}>
                    <ArrowBackIcon />
                </IconButton>
            );
        }
    };

    return (
        <div className={clsx(classes.subHeader, shrink && classes.subHeaderShrink, globalClasses.flexColumn)}>
            <div
                className={clsx(
                    classes.messageWrapper,
                    shrink && classes.messageWrapperShrink,
                    // !enableBack && !UIServices.isMobile(width) && classes.messageWrapperAdditionalPadding,
                    shrinkOnScroll ? classes.fastAnimations : classes.slowAnimations,
                    !GlobalServices.isEmpty(secondMessage) && classes.messageWrapperExtended
                )}
            >
                {((title && !UIServices.isMobile(width)) || (enableBack && !UIServices.isMobile(width))) && (
                    <div className={clsx(classes.titleWrapper, globalClasses.flexRow)}>
                        {renderBackButton()}
                        {title && !UIServices.isMobile(width) && (
                            <div className={clsx(classes.titleText, globalClasses.flexColumnCenter)}>{title}</div>
                        )}
                    </div>
                )}
                <div className={clsx(classes.message)}>{messages[0]}</div>
                {renderSecondMessage()}
            </div>
            <div className={clsx(classes.triangle)} />
        </div>
    );
};

export default withWidth()(SubHeader);
