import React from 'react';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { ApariTextField } from 'components';
import { GlobalServices } from 'utils/index';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = AutocompleteProps<any, false, false, false> & {
    margin?: 'normal' | 'dense' | 'none' | undefined;
    label: string;
};

type AutocompleteOption = {
    id: string;
    label: string;
};

const ApariAutocomplete: React.FC<Props> = props => {
    const { label, size, margin, options } = props;
    const getSize = () => {
        return !GlobalServices.isEmpty(size) ? size : 'small';
    };

    return (
        <Autocomplete
            {...props}
            fullWidth
            options={options}
            renderOption={(option: AutocompleteOption) => option.label}
            getOptionLabel={(option: AutocompleteOption) => option.label}
            getOptionSelected={(option: AutocompleteOption, newValue: AutocompleteOption) => {
                return option.id === newValue.id;
            }}
            renderInput={params => (
                <ApariTextField
                    {...params}
                    margin={margin || 'normal'}
                    size={getSize()}
                    label={label}
                    variant="outlined"
                    inputProps={{ ...params.inputProps, form: { autocomplete: 'off' } }}
                />
            )}
        />
    );
};

export default ApariAutocomplete;
