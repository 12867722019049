import React from 'react';
import { FormikContextType, getIn } from 'formik';
import { ApariTextField } from '../../common/ui';
import styles from './styles';
import { TextFieldProps } from '@material-ui/core';

type Props = TextFieldProps & {
    control: string;
    formik: FormikContextType<any>;
};

const MTDFormInputField: React.FC<Props> = ({ formik, control, ...rest }) => {
    const classes = styles();
    return (
        <ApariTextField
            classes={{
                root: classes.root
            }}
            fullWidth
            variant="standard"
            margin="none"
            name={control}
            onChange={formik.handleChange}
            value={getIn(formik.values, control)}
            error={getIn(formik.touched, control) && Boolean(getIn(formik.errors, control))}
            helperText={getIn(formik.touched, control) && getIn(formik.errors, control)}
            {...rest}
        />
    );
};

export default MTDFormInputField;
