import { COLORS } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: theme.spacing(3),
        padding: theme.spacing(3),
        width: '100%',
        boxSizing: 'border-box',
        height: 120,
        border: 2,
        borderStyle: 'solid',
        borderColor: 'transparent',
        background: theme.palette.background.paper,
        '&:hover': {
            borderColor: COLORS.transparentLight
        }
    },
    checkedCard: {
        background: COLORS.transparentLight,
        borderColor: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main
        }
    },
    icon: {
        width: 22,
        height: 22
    },
    text: {
        wordBreak: 'break-word',
        marginBottom: theme.spacing(2)
    },
    disabledText: {
        color: theme.palette.grey[500]
    },
    checkBoxWrapper: {
        marginTop: 'auto'
    }
}));
