import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import { ApariButton } from 'components';
import { Localisation } from 'utils';
import globalStyles from 'styles/globalStyles';
import styles from './styles';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

type Props = DialogProps & {
    onConfirm?: () => void;
    handleClose?: () => void;
    message: string;
};

const MessageDialog: React.FC<Props> = props => {
    const { open, handleClose, message } = props;
    const classes = styles();
    const globalClasses = globalStyles();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            classes={{
                paper: classes.paper
            }}
            BackdropProps={{ classes: { root: classes.backdrop } }}
        >
            <DialogTitle disableTypography={true} className={clsx(classes.title)}>
                <Typography variant="h6" className={clsx(globalClasses.marginRightAuto)}>
                    {message}
                </Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogActions
                classes={{
                    spacing: classes.spacing
                }}
            >
                <ApariButton variant="contained" onClick={handleClose} color="primary" fullWidth className={clsx(classes.button)}>
                    {Localisation.localize('general.understood')}
                </ApariButton>
            </DialogActions>
        </Dialog>
    );
};
export default MessageDialog;
