import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    filteredLabel: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid ' + COLORS.apariLightBlue,
        borderRadius: 4,
        padding: '3px 6px',
        marginTop: 8,
        marginRight: 8
    },
    filteredLabelText: {
        fontSize: 11,
        fontWeight: 500,
        marginRight: 4,
        color: COLORS.apariLightBlue
    },
    filteredLabelIcon: {
        cursor: 'pointer',
        fontSize: 15,
        color: COLORS.apariLightBlue
    }
}));
