import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    root: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: '72px',
        [theme.breakpoints.up('lg')]: {
            paddingBottom: 20
        }
    },
    content: {
        width: '100%',
        minHeight: 30,
        padding: '15px 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: COLORS.paperLight,
        borderRadius: 2
    },
    iconContainer: {
        marginRight: 16
    },
    informationContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    closeIconContainer: {
        display: 'flex',
        alignSelf: 'flex-start'
    },
    closeIconButton: {
        padding: 0
    },
    closeIcon: {
        color: COLORS.apariGray3
    },
    title: {
        color: COLORS.apariBlack80,
        fontSize: 14,
        fontWeight: 700
    },
    message: {
        color: COLORS.apariBlack80,
        fontSize: 14,
        fontWeight: 400
    },
    link: {
        color: COLORS.apariLightBlue,
        fontWeight: 700,
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    dialog: {
        [theme.breakpoints.down('md')]: {
            marginLeft: 16,
            marginRight: 16
        }
    },
    dialogContent: {
        width: '100%',
        padding: 60,
        [theme.breakpoints.down('md')]: {
            padding: 30
        },
        [theme.breakpoints.down('sm')]: {
            padding: '30px 16px'
        },
        paddingTop: 0
    },
    newFeatureText: {
        color: COLORS.apariFinalisedGreen,
        fontSize: 13,
        fontWeight: 400,
        marginTop: 0,
        textAlign: 'center'
    },
    dialogTitle: {
        color: COLORS.apariBlack80,
        fontSize: 18,
        fontWeight: 400,
        whiteSpace: 'pre-wrap',
        textAlign: 'center'
    },
    dialogDescription: {
        color: COLORS.apariBlack80,
        fontSize: 13,
        fontWeight: 400,
        whiteSpace: 'pre-wrap',
        textAlign: 'center'
    },
    video: {
        margin: '10px 0'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            marginTop: 30
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    button: {
        margin: '0px 10px',
        [theme.breakpoints.down('sm')]: {
            margin: '6px 0px'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 266
        }
    }
}));
