const TILE_NAMES = {
    PROFILE_AND_RESIDENCY: 'Profile & Residency',
    INCOME_FROM_EMPLOYMENT: 'Income from Employment',
    INCOME_FROM_PROPERTY: 'Income from Property',
    INCOME_FROM_SELF_EMPLOYMENT: 'Income from Self-Employment',
    PENSION_INCOME: 'Pension Income',
    CAPITAL_GAINS: 'Capital gains',
    OTHER_INCOME: 'Other Income',
    CHILD_BENEFIT: 'Child benefit',
    OTHER_DEDUCTIONS: 'Other Deductions',
    TAX_RELIEF_ON_INVESTMENTS: 'Tax Relief on Investments',
    STUDENT_LOAN: 'Student loan',
    SA100_SUBMISSION: 'SA100 Submission'
};

export default TILE_NAMES;
