import React, { useContext } from 'react';
import { cond, matches } from 'lodash';
import { ExtractRouteParams, RouteComponentProps } from 'react-router';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AuthenticationContext } from 'context/AuthenticationContext';
import { RoleTypes } from 'types';

type Props = RouteProps & {
    authorized: boolean;
    acceptedTermsAndConditions: boolean;
    onboardingIsDone: boolean;
    component: React.ElementType;
};

const AuthRoute: React.FC<Props> = props => {
    const { authorized, acceptedTermsAndConditions, component: C, ...rest } = props;
    const { user } = useContext(AuthenticationContext);
    const isNotAgent = user?.role?.roleName !== RoleTypes.AGENT;
    const conditions = {
        authorized,
        isNotAgent,
        acceptedTermsAndConditions
    };

    const isFalsy = (condition: string) => matches({ [condition]: false });

    const getRedirectionRoutesByConditions = cond([
        [isFalsy('authorized'), () => '/sign-in'],
        [isFalsy('isNotAgent'), () => '/agent-redirect'],
        [isFalsy('acceptedTermsAndConditions'), () => '/accept-terms-and-conditions']
        // [isFalsy('onboardingIsDone'), () => '/start-trial']
    ]);

    const handleRedirections = (props: RouteComponentProps<ExtractRouteParams<string, string>>) => {
        const redirectionRoute = getRedirectionRoutesByConditions(conditions);
        return redirectionRoute ? <Redirect to={redirectionRoute} /> : <C {...props} />;
    };

    return <Route {...rest} render={handleRedirections} />;
};

export default AuthRoute;
