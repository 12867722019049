import React, { useContext, useEffect, useRef } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import queryString from 'querystring';
import { FullScreenLoading } from 'components/common/ui';
import { AuthenticationContext } from 'context/AuthenticationContext';
import { GlobalServices, LocalStorageServices } from 'utils';
import { OAuthRequestProviderEnum } from '@apari/core-api';

const AuthorizingScreen: React.FC<RouteComponentProps> = ({ location }) => {
    const history = useHistory();
    const isInitialMount = useRef(true);

    const { handleOAuthLogin } = useContext(AuthenticationContext);
    const twoFactorAuthenticationData = LocalStorageServices.getJSON('twoFactorAuthenticationData');

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (!GlobalServices.isEmpty(twoFactorAuthenticationData)) {
                history.push({
                    pathname: '/authentication-code',
                    state: { TFAMethod: twoFactorAuthenticationData?.twoFactorAuthMethod }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [twoFactorAuthenticationData]);

    useEffect(() => {
        const completeSignIn = async () => {
            // if (authenticated) {
            //     history.replace('/dashboard');
            // } else {
            if (GlobalServices.isEmpty(location.search)) {
                history.replace('/sign-in');
            } else {
                await completeLogin();
            }
            // }
        };
        completeSignIn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, location.search, location.pathname]);

    const completeLogin = async () => {
        const parameters = queryString.parse(location.search);
        if (location.pathname.includes('google')) {
            const state = GlobalServices.isEmpty(parameters.state) ? parameters['?state'] : parameters.state;
            const code = GlobalServices.isEmpty(parameters.code) ? parameters['?code'] : parameters.code;
            await handleOAuthLogin(OAuthRequestProviderEnum.google, code as string, state as string, history);
        }
        if (location.pathname.includes('apple')) {
            const state = GlobalServices.isEmpty(parameters.state) ? parameters['?state'] : parameters.state;
            const code = GlobalServices.isEmpty(parameters.code) ? parameters['?code'] : parameters.code;
            await handleOAuthLogin(OAuthRequestProviderEnum.apple, code as string, state as string, history);
        }
        if (location.pathname.includes('facebook')) {
            const state = GlobalServices.isEmpty(parameters.state) ? parameters['?state'] : parameters.state;
            const code = GlobalServices.isEmpty(parameters.code) ? parameters['?code'] : parameters.code;
            await handleOAuthLogin(OAuthRequestProviderEnum.facebook, code as string, state as string, history);
        }
    };

    return <FullScreenLoading />;
};

export default AuthorizingScreen;
