import {makeStyles} from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    control: {
        borderColor: theme.palette.primary.main,
        paddingLeft: theme.spacing(3),
        width: '100%'
    },
    labelMargin: {
        marginLeft: theme.spacing(2)
    },
    cardChecked: {
        background: theme.palette.primary.main,
    },
    checkboxIcon:{
        width: 32,
        height: 32,
        borderRadius: 4,
        border: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
    },
    checkboxCheckedIcon:{
        width: 32,
        height: 32,
        borderRadius: 4,
        border: 2,
        borderStyle: 'solid',
        fill: 'none',
        borderColor: theme.palette.primary.main,
        background: theme.palette.primary.main
    },
    apari80: {
        color: COLORS.apariBlack80
    }
}));
