import { COLORS } from 'constants/index';

export default {
    tooltip: {
        padding: '8px 12px 8px 12px',
        backgroundColor: '#B7B7B7',
        color: COLORS.apariWhite,
        fontSize: 10,
        fontWeight: 500,
        whiteSpace: 'pre-wrap' as any
    },
    arrow: {
        color: '#B7B7B7'
    }
};
