import { makeStyles, Theme } from '@material-ui/core';
import { IncomeExpenseSummaryGroupByEnum } from '@apari/banking-api';
import { GlobalServices } from 'utils';

type Props = {
    groupBy?: IncomeExpenseSummaryGroupByEnum;
};

export default makeStyles<Theme, Props>(theme => ({
    labels: ({ groupBy }) => ({
        '& tspan:first-of-type': {
            fontSize: GlobalServices.isDefined(groupBy) && groupBy === IncomeExpenseSummaryGroupByEnum.WEEKS ? 9 : 10
        }
    }),
    loader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.paper
    },
    dataContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '0px 16px'
        }
    }
}));
