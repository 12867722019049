import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ArrowForwardIcon: React.FC = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 16 16" {...props}>
            <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" />
        </SvgIcon>
    );
};

export const ArrowBackIcon: React.FC = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 16 16" {...props}>
            <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" />
        </SvgIcon>
    );
};

export const NavigationIcon: React.FC = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 18 12" {...props}>
            <path d="M0 2V0H18V2H0ZM0 7H18V5H0V7ZM0 12H18V10H0V12Z" />
        </SvgIcon>
    );
};

export const BarChartIcon: React.FC = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 14 14" {...props}>
            <path d="M5.6 0H8.4V14H5.6V0ZM0 4.2H3V14H0V4.2ZM14 8H11.2V14H14V8Z" fill="black" />
        </SvgIcon>
    );
};

export const ArrowDownIcon: React.FC = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 12 8" {...props}>
            <path d="M1.41 0.294922L6 4.87492L10.59 0.294922L12 1.70492L6 7.70492L0 1.70492L1.41 0.294922Z" />
        </SvgIcon>
    );
};

export const ArrowDownTimeframeIcon: React.FC = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 12 8" {...props}>
            <path d="M0 0.5L5 5.5L10 0.5H0Z" />
        </SvgIcon>
    );
};

export const DoubleArrowIcon: React.FC = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 12 11" {...props}>
            <path d="M0 2.69753L2.54746 5.50048L0 8.30342V11L5 5.50048L0 0V2.69753Z" />
            <path d="M7 2.69753L9.54746 5.50048L7 8.30342V11L12 5.50048L7 0V2.69753Z" />
        </SvgIcon>
    );
};

export const ActiveCheckmark: React.FC = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 12 10" {...props}>
            <path
                d="M3.86332 7.58336L1.08332 4.80336L0.136658 5.74336L3.86332 9.47003L11.8633 1.47003L10.9233 0.530029L3.86332 7.58336Z"
                fill="#3BC275"
            />
        </SvgIcon>
    );
};

export const FilterIcon: React.FC = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 24 24" {...props}>
            <path d="M8.28634 4C7.22907 4 6.3304 4.7224 6.03965 5.71235H3.63436C3.26432 5.71235 3 6.00666 3 6.38124C3 6.75582 3.29075 7.05013 3.66079 7.05013H6.06608C6.3304 8.04008 7.25551 8.76248 8.31278 8.76248C9.39648 8.76248 10.2952 8.04008 10.5859 7.05013H20.3392C20.7093 7.05013 21 6.75582 21 6.38124C21 6.00666 20.7093 5.71235 20.3392 5.71235H10.5859C10.2687 4.7224 9.37004 4 8.28634 4ZM8.31278 5.33778C8.86784 5.33778 9.34361 5.79262 9.34361 6.38124C9.34361 6.96986 8.86784 7.42471 8.31278 7.42471C7.75771 7.42471 7.28194 6.96986 7.28194 6.38124C7.28194 5.79262 7.75771 5.33778 8.31278 5.33778Z" />
            <path d="M16.2423 9.35107C15.185 9.35107 14.2863 10.0735 13.9956 11.0634H3.66079C3.26432 11.0634 3 11.3577 3 11.7323C3 12.1069 3.29075 12.4012 3.66079 12.4012H13.9956C14.2599 13.3912 15.185 14.1136 16.2423 14.1136C17.326 14.1136 18.2247 13.3912 18.5154 12.4012H20.3392C20.7093 12.4012 21 12.1069 21 11.7323C21 11.3577 20.7093 11.0634 20.3392 11.0634H18.5154C18.2247 10.0735 17.326 9.35107 16.2423 9.35107ZM16.2687 10.6889C16.8238 10.6889 17.2996 11.1437 17.2996 11.7323C17.2996 12.3209 16.8238 12.7758 16.2687 12.7758C15.7137 12.7758 15.2379 12.3209 15.2379 11.7323C15.2379 11.1437 15.6872 10.6889 16.2687 10.6889Z" />
            <path d="M11.4581 15.2373C10.4009 15.2373 9.5022 15.9597 9.21145 16.9497H3.66079C3.26432 16.9497 3 17.244 3 17.6185C3 17.9931 3.29075 18.2874 3.66079 18.2874H9.23788C9.5022 19.2774 10.4273 19.9998 11.4846 19.9998C12.5683 19.9998 13.467 19.2774 13.7577 18.2874H20.3392C20.7093 18.2874 21 17.9931 21 17.6185C21 17.244 20.7093 16.9497 20.3392 16.9497H13.7313C13.4405 15.9597 12.5419 15.2373 11.4581 15.2373ZM11.4846 16.5751C12.0396 16.5751 12.5154 17.0299 12.5154 17.6185C12.5154 18.2072 12.0396 18.662 11.4846 18.662C10.9295 18.662 10.4537 18.2072 10.4537 17.6185C10.4802 17.0299 10.9295 16.5751 11.4846 16.5751Z" />
        </SvgIcon>
    );
};

export const ArrowIconForCalculation: React.FC<any> = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 9 13" {...props}>
            <path
                d="M0.979008 11.6001L5.50955 6.9317L0.84106 2.40122L2.21993 0.980356L8.30923 6.88972L2.39987 12.979L0.979008 11.6001Z"
                fill="#6941FA"
            />
        </SvgIcon>
    );
};

export const RefundIcon: React.FC = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.7245 5.45314L13.7089 5.40564L13.6614 5.42125L12.935 5.66001L12.8875 5.67563L12.9031 5.72313C13.0908 6.29447 13.1861 6.89224 13.1861 7.4999C13.1861 10.6347 10.6356 13.1851 7.50084 13.1851C6.08799 13.1851 4.76036 12.6736 3.72946 11.7557H4.69694H4.74694V11.7057V10.941V10.891H4.69694H2.27539H2.22539V10.941V13.2351V13.2851H2.27539H3.04009H3.09009V13.2351V12.3403C3.53365 12.7444 4.03169 13.0888 4.56574 13.3569C5.4816 13.8167 6.46925 14.0498 7.50084 14.0498C9.2503 14.0498 10.8953 13.3685 12.1324 12.1314C13.3695 10.8943 14.0508 9.24936 14.0508 7.4999C14.0508 6.80047 13.941 6.11184 13.7245 5.45314Z"
                fill="black"
                stroke="black"
                strokeWidth="0.1"
            />
            <path
                d="M7.49995 1.8147C8.90346 1.8147 10.2366 2.32751 11.2696 3.2441H10.3039H10.2538V3.2941V4.0588V4.1088H10.3039H12.7254H12.7754V4.0588V1.7647V1.7147H12.7254H11.9607H11.9107V1.7647V2.65796C10.7115 1.56374 9.14779 0.95 7.49995 0.95C5.75049 0.95 4.10549 1.63136 2.86844 2.86844C1.63139 4.10552 0.95 5.75049 0.95 7.49995C0.95 8.24866 1.07542 8.98326 1.32282 9.68321L1.33948 9.73035L1.38662 9.71369L2.10761 9.45889L2.15475 9.44223L2.13809 9.39509C1.92354 8.78799 1.8147 8.15039 1.8147 7.49995C1.8147 4.36515 4.36515 1.8147 7.49995 1.8147Z"
                fill="black"
                stroke="black"
                strokeWidth="0.1"
            />
            <path
                d="M9.03001 9.98556V10.6016H5.83801V9.98556H6.42201V8.68156H5.83801V8.06556H6.42201V7.52956H5.83801V6.91356H6.42201V6.17756C6.42201 5.7669 6.51801 5.46823 6.71001 5.28156C6.90201 5.0949 7.23534 5.00156 7.71001 5.00156H8.80601V5.64156H7.22201V6.91356H8.43801V7.52956H7.22201V8.06556H8.43801V8.68156H7.22201V9.98556H9.03001Z"
                fill="black"
            />
        </svg>
    );
};

export const FileIcon: React.FC = props => {
    return (
        <SvgIcon width="44" height="37" viewBox="0 0 44 37" fill="none" {...props}>
            <path
                d="M42.2762 35.0459H15.9062V23.4759L26.1562 12.8359H42.2762V35.0459Z"
                stroke="#1D1D1B"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M14.9262 4.92773L2.9262 17.3277C1.69268 18.5649 1 20.2407 1 21.9877C1 23.7348 1.69268 25.4106 2.9262 26.6477C3.53833 27.2612 4.26545 27.7479 5.06594 28.08C5.86643 28.4121 6.72456 28.5831 7.5912 28.5831C8.45784 28.5831 9.31597 28.4121 10.1165 28.08C10.9169 27.7479 11.6441 27.2612 12.2562 26.6477L29.9262 8.89773"
                stroke="#1D1D1B"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M27.8162 11.0168L30.2462 8.58681C31.0761 7.75452 31.5421 6.62714 31.5421 5.45181C31.5421 4.27648 31.0761 3.1491 30.2462 2.31681V2.31681C29.8325 1.89956 29.3403 1.56838 28.7979 1.34237C28.2555 1.11636 27.6738 1 27.0862 1C26.4986 1 25.9169 1.11636 25.3745 1.34237C24.8321 1.56838 24.3399 1.89956 23.9262 2.31681L6.2962 20.0168"
                stroke="#1D1D1B"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M7.3862 19.017L6.2462 20.127C6.04541 20.3218 5.88577 20.5549 5.77676 20.8126C5.66774 21.0703 5.61157 21.3472 5.61157 21.627C5.61157 21.9067 5.66774 22.1837 5.77676 22.4413C5.88577 22.699 6.04541 22.9321 6.2462 23.127V23.127C6.63792 23.5172 7.1683 23.7363 7.7212 23.7363C8.27411 23.7363 8.80449 23.5172 9.1962 23.127L26.6562 5.62695"
                stroke="#1D1D1B"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path d="M27.3162 19.0664H37.5562" stroke="#1D1D1B" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M21.6962 23.6875H37.5562" stroke="#1D1D1B" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M21.6962 28.6465H37.5562" stroke="#1D1D1B" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
        </SvgIcon>
    );
};

export const UploadCloudIcon: React.FC<Partial<SvgIconProps>> = props => {
    const { stroke } = props;
    return (
        <SvgIcon width="78" height="54" viewBox="0 0 78 54" fill="none" {...props}>
            <path
                d="M63.154 50.1105C66.1549 50.1105 68.9762 48.9419 71.0982 46.8199C73.2202 44.6978 74.3889 41.8765 74.3889 38.8756C74.3889 35.875 73.2203 33.054 71.0983 30.932C68.9762 28.81 66.1549 27.6414 63.154 27.6414H62.0123H57.9933L58.4218 23.6453C58.4898 23.0105 58.5243 22.363 58.5243 21.7208C58.5243 19.3127 58.0526 16.9784 57.1223 14.7829C56.2228 12.6601 54.9341 10.7527 53.2922 9.11377C51.6498 7.47437 49.7381 6.18763 47.6101 5.28926C45.4084 4.35975 43.0675 3.88845 40.6523 3.88845C36.7416 3.88845 33.0286 5.12508 29.9148 7.46461C28.4148 8.59163 27.1064 9.93958 26.0261 11.471C24.9327 13.0209 24.0996 14.722 23.5498 16.527L22.731 19.2151L19.9242 19.0819C19.6745 19.07 19.4207 19.064 19.1698 19.064C15.0126 19.064 11.1046 20.6795 8.16575 23.6128C5.22864 26.5445 3.61111 30.4418 3.61111 34.5869C3.61111 38.7322 5.22867 42.6296 8.1658 45.5614C11.1047 48.495 15.0127 50.1105 19.1698 50.1105C19.4999 50.1105 19.8335 50.1001 20.1613 50.0796L20.4022 50.0645L20.6429 50.0817C20.9122 50.1008 21.1857 50.1105 21.4559 50.1105H63.154ZM63.154 53.7216H21.4559C21.0965 53.7216 20.7397 53.7088 20.3868 53.6837C19.9841 53.7089 19.5788 53.7216 19.1698 53.7216C8.5826 53.7216 0 45.1546 0 34.5869C0 24.0192 8.5826 15.4529 19.1698 15.4529C19.4799 15.4529 19.7888 15.4603 20.0954 15.4748C22.7745 6.6788 30.9646 0.277344 40.6523 0.277344C52.5174 0.277344 62.1355 9.87784 62.1355 21.7208C62.1355 22.5008 62.0936 23.2719 62.0123 24.0303H63.154C71.3531 24.0303 78 30.6772 78 38.8756C78 47.0747 71.3531 53.7216 63.154 53.7216Z"
                fill={stroke}
            />
            <path d="M39 28.3516V51.9999" stroke={stroke} strokeWidth="3.5" />
            <path d="M30.3123 35.6777L38.9893 27.0006L47.6669 35.6782" stroke={stroke} fill="none" strokeWidth="3.5" />
        </SvgIcon>
    );
};

export const TaxSubmissionIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 20 19" {...props}>
            <path d="M20 19.0002H0V1.0002H2V13.5402L7.5 4.0002L14 7.7802L18.24 0.450195L19.97 1.4502L20 19.0002Z" fill="white" />
        </SvgIcon>
    );
};

export const ProfileAndSettingsIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 30 30" {...props}>
            <path
                d="M12.5 5C11.1739 5 9.90215 5.52678 8.96447 6.46447C8.02678 7.40215 7.5 8.67392 7.5 10C7.5 11.3261 8.02678 12.5979 8.96447 13.5355C9.90215 14.4732 11.1739 15 12.5 15C13.8261 15 15.0979 14.4732 16.0355 13.5355C16.9732 12.5979 17.5 11.3261 17.5 10C17.5 8.67392 16.9732 7.40215 16.0355 6.46447C15.0979 5.52678 13.8261 5 12.5 5ZM21.25 15C21.0875 15 20.95 15.1125 20.925 15.2625L20.6875 16.9125C20.3125 17.075 19.95 17.275 19.625 17.5L18.075 16.875C17.9375 16.875 17.775 16.875 17.6875 17.0375L16.4375 19.2C16.3625 19.3375 16.3875 19.5 16.5125 19.6L17.8375 20.625C17.8125 20.8375 17.8 21.0375 17.8 21.25C17.8 21.4625 17.8125 21.6625 17.8375 21.875L16.5125 22.9C16.4 23 16.3625 23.1625 16.4375 23.3L17.6875 25.4625C17.7625 25.625 17.925 25.625 18.075 25.625L19.625 25C19.95 25.225 20.3 25.4375 20.6875 25.5875L20.925 27.2375C20.95 27.3875 21.075 27.5 21.25 27.5H23.75C23.8875 27.5 24.025 27.3875 24.05 27.2375L24.2875 25.5875C24.6625 25.425 25 25.225 25.3375 25L26.875 25.625C27.0375 25.625 27.2 25.625 27.2875 25.4625L28.5375 23.3C28.6125 23.1625 28.575 23 28.4625 22.9L27.125 21.875C27.15 21.6625 27.175 21.4625 27.175 21.25C27.175 21.0375 27.1625 20.8375 27.125 20.625L28.45 19.6C28.5625 19.5 28.6 19.3375 28.525 19.2L27.275 17.0375C27.2 16.875 27.0375 16.875 26.875 16.875L25.3375 17.5C25 17.275 24.6625 17.0625 24.275 16.9125L24.0375 15.2625C24.025 15.1125 23.8875 15 23.75 15H21.25ZM12.5 17.5C6.975 17.5 2.5 19.7375 2.5 22.5V25H14.6C14.0421 23.8285 13.7518 22.5476 13.75 21.25C13.7525 19.9941 14.0254 18.7535 14.55 17.6125C13.8875 17.5375 13.2 17.5 12.5 17.5ZM22.5 19.375C23.5375 19.375 24.375 20.2125 24.375 21.25C24.375 22.2875 23.5375 23.125 22.5 23.125C21.45 23.125 20.625 22.2875 20.625 21.25C20.625 20.2125 21.4625 19.375 22.5 19.375Z"
                fill="white"
            />
        </SvgIcon>
    );
};

export const HelpIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 28 28" {...props}>
            <path
                d="M14.0002 2.3335C13.4168 2.3335 12.8335 2.55516 12.3552 3.02183L3.02182 12.3552C2.10015 13.2652 2.10015 14.7352 3.02182 15.6452L12.3552 24.9785C13.2652 25.9002 14.7352 25.9002 15.6452 24.9785L24.9785 15.6452C25.9002 14.7352 25.9002 13.2652 24.9785 12.3552L15.6452 3.02183C15.1668 2.55516 14.5835 2.3335 14.0002 2.3335ZM14.0002 8.1085C17.1502 8.23683 18.5152 11.4102 16.6602 13.7785C16.1702 14.3618 15.3885 14.7468 14.9918 15.2485C14.5835 15.7502 14.5835 16.3335 14.5835 16.9168H12.8335C12.8335 15.9252 12.8335 15.0968 13.2418 14.5135C13.6268 13.9302 14.4085 13.5802 14.8985 13.1952C16.3335 11.8768 15.9602 10.0218 14.0002 9.87016C13.0435 9.87016 12.2502 10.6518 12.2502 11.6318H10.5002C10.5002 9.6835 12.0752 8.1085 14.0002 8.1085ZM12.8335 18.0835H14.5835V19.8335H12.8335V18.0835Z"
                fill="white"
            />
        </SvgIcon>
    );
};

export const HomeShareIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <svg width="15" height="13" viewBox="0 0 15 13" fill="none" {...props}>
            <path
                d="M13.8371 10.67C13.5424 10.67 13.2788 10.7897 13.0773 10.9771L10.3133 9.32203C10.3326 9.23031 10.3482 9.13858 10.3482 9.04286C10.3482 8.94715 10.3326 8.85542 10.3133 8.76369L13.0462 7.12455C13.2556 7.32396 13.5308 7.44759 13.8371 7.44759C14.4806 7.44759 15 6.91318 15 6.25114C15 5.5891 14.4806 5.05469 13.8371 5.05469C13.1936 5.05469 12.6741 5.5891 12.6741 6.25114C12.6741 6.34686 12.6896 6.43858 12.709 6.53031L9.97601 8.16945C9.76667 7.97004 9.49143 7.84641 9.18519 7.84641C8.54168 7.84641 8.02222 8.38083 8.02222 9.04286C8.02222 9.7049 8.54168 10.2393 9.18519 10.2393C9.49143 10.2393 9.76667 10.1157 9.97601 9.91627L12.7361 11.5754C12.7167 11.6591 12.7051 11.7468 12.7051 11.8346C12.7051 12.4767 13.2129 12.9991 13.8371 12.9991C14.4612 12.9991 14.969 12.4767 14.969 11.8346C14.969 11.1925 14.4612 10.67 13.8371 10.67ZM13.8371 5.85232C14.0503 5.85232 14.2247 6.03179 14.2247 6.25114C14.2247 6.47049 14.0503 6.64996 13.8371 6.64996C13.6239 6.64996 13.4494 6.47049 13.4494 6.25114C13.4494 6.03179 13.6239 5.85232 13.8371 5.85232ZM9.18519 9.44168C8.97198 9.44168 8.79753 9.26221 8.79753 9.04286C8.79753 8.82351 8.97198 8.64404 9.18519 8.64404C9.3984 8.64404 9.57284 8.82351 9.57284 9.04286C9.57284 9.26221 9.3984 9.44168 9.18519 9.44168ZM13.8371 12.2414C13.6239 12.2414 13.4494 12.0619 13.4494 11.8426C13.4494 11.6232 13.6239 11.4437 13.8371 11.4437C14.0503 11.4437 14.2247 11.6232 14.2247 11.8426C14.2247 12.0619 14.0503 12.2414 13.8371 12.2414Z"
                fill="black"
            />
            <path
                d="M10.2005 4.88475L7.02218 1.94278L3.51109 5.19278V10.8333H4.91553V6.5H7.96591C7.64523 6.92898 7.395 7.41656 7.23303 7.94445H6.31997V12.2778H2.10666V6.5H0L7.02218 0L12.092 4.69282C11.9261 4.67402 11.7574 4.66437 11.5866 4.66437C11.1034 4.66437 10.6377 4.74162 10.2005 4.88475Z"
                fill="black"
            />
        </svg>
    );
};

export const ShareIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
            <path
                d="M13.5 12.0912C12.93 12.0912 12.42 12.3162 12.03 12.6688L6.6825 9.55625C6.72 9.38375 6.75 9.21125 6.75 9.03125C6.75 8.85125 6.72 8.67875 6.6825 8.50625L11.97 5.42375C12.375 5.79875 12.9075 6.03125 13.5 6.03125C14.745 6.03125 15.75 5.02625 15.75 3.78125C15.75 2.53625 14.745 1.53125 13.5 1.53125C12.255 1.53125 11.25 2.53625 11.25 3.78125C11.25 3.96125 11.28 4.13375 11.3175 4.30625L6.03 7.38875C5.625 7.01375 5.0925 6.78125 4.5 6.78125C3.255 6.78125 2.25 7.78625 2.25 9.03125C2.25 10.2762 3.255 11.2812 4.5 11.2812C5.0925 11.2812 5.625 11.0487 6.03 10.6737L11.37 13.7938C11.3325 13.9513 11.31 14.1163 11.31 14.2812C11.31 15.4888 12.2925 16.4713 13.5 16.4713C14.7075 16.4713 15.69 15.4888 15.69 14.2812C15.69 13.0737 14.7075 12.0912 13.5 12.0912ZM13.5 3.03125C13.9125 3.03125 14.25 3.36875 14.25 3.78125C14.25 4.19375 13.9125 4.53125 13.5 4.53125C13.0875 4.53125 12.75 4.19375 12.75 3.78125C12.75 3.36875 13.0875 3.03125 13.5 3.03125ZM4.5 9.78125C4.0875 9.78125 3.75 9.44375 3.75 9.03125C3.75 8.61875 4.0875 8.28125 4.5 8.28125C4.9125 8.28125 5.25 8.61875 5.25 9.03125C5.25 9.44375 4.9125 9.78125 4.5 9.78125ZM13.5 15.0463C13.0875 15.0463 12.75 14.7088 12.75 14.2963C12.75 13.8837 13.0875 13.5463 13.5 13.5463C13.9125 13.5463 14.25 13.8837 14.25 14.2963C14.25 14.7088 13.9125 15.0463 13.5 15.0463Z"
                fill="black"
            />
        </svg>
    );
};

export const ExitAndLogoutIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
            <path
                d="M13.8704 6.7037L12.8259 7.74815L13.9963 8.92593H7.94444V10.4074H13.9963L12.8259 11.5778L13.8704 12.6296L16.8333 9.66667L13.8704 6.7037ZM4.98148 4.48148H10.1667V3H4.98148C4.16667 3 3.5 3.66667 3.5 4.48148V14.8519C3.5 15.6667 4.16667 16.3333 4.98148 16.3333H10.1667V14.8519H4.98148V4.48148Z"
                fill="white"
            />
        </svg>
    );
};

export const DissatisfiedSadIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
                fill={props.fill || '#5C5C5C'}
            />
            <path
                d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
                fill={props.fill || '#5C5C5C'}
            />
            <path
                d="M12 14C9.67 14 7.68 15.45 6.88 17.5H8.55C9.24 16.31 10.52 15.5 12 15.5C13.48 15.5 14.75 16.31 15.45 17.5H17.12C16.32 15.45 14.33 14 12 14ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                fill={props.fill || '#5C5C5C'}
            />
        </svg>
    );
};

export const NeutralIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M9 14H15V15.5H9V14Z" fill={props.fill} />
            <path
                d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
                fill={props.fill || '#5C5C5C'}
            />
            <path
                d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
                fill={props.fill || '#5C5C5C'}
            />
            <path
                d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                fill={props.fill || '#5C5C5C'}
            />
        </svg>
    );
};

export const SatisfiedHappyIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
                fill={props.fill || '#5C5C5C'}
            />
            <path
                d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
                fill={props.fill || '#5C5C5C'}
            />
            <path
                d="M12 16C10.52 16 9.25 15.19 8.55 14H6.88C7.68 16.05 9.67 17.5 12 17.5C14.33 17.5 16.32 16.05 17.12 14H15.45C14.75 15.19 13.48 16 12 16ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                fill={props.fill || '#5C5C5C'}
            />
        </svg>
    );
};

export const PaymentIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <SvgIcon fontSize="inherit" viewBox="0 0 16 14" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.075.77c-.987.422-1.84.804-1.898.847-.2.151-.2.138-.183 1.804.015 1.61.028 1.77.198 2.455.238.964.654 1.701 1.38 2.448.504.52 1.132 1 1.953 1.494.249.15.3.17.452.17.144 0 .204-.02.374-.123 1.86-1.123 2.825-2.168 3.288-3.556.15-.448.289-1.16.306-1.565.003-.09.015.145.026.523.01.379.013-.226.005-1.344-.01-1.567-.023-2.054-.054-2.13a.52.52 0 00-.145-.176C15.612 1.492 12.103 0 11.976 0c-.072 0-.715.26-1.901.77zM.72 3.036c-.189.055-.428.239-.553.427l-.119.178-.018 2.36h3.59c3.404 0 3.589-.003 3.574-.055-.111-.392-.178-1.077-.197-2.032l-.019-.914-3.075.003c-1.93.001-3.115.014-3.183.033zm12.556.017c-.1.041-.264.23-.942 1.078l-.821 1.029-.353-.525c-.325-.484-.364-.53-.497-.584a.452.452 0 00-.467.052.527.527 0 00-.202.473c.014.073.225.42.582.96.508.763.574.85.693.904.162.074.234.075.4.01.113-.046.26-.217 1.181-1.372.631-.79 1.068-1.365 1.09-1.435a.483.483 0 00-.256-.582c-.16-.072-.25-.074-.408-.008zM.017 13.27l.078.154c.1.196.29.387.486.487l.153.078 7.173.009c5.088.006 7.213-.001 7.31-.025.301-.075.656-.418.71-.686.01-.053.03-.104.046-.113.015-.01.027-1.17.027-2.609 0-2.061-.008-2.588-.039-2.575-.021.009-.137.152-.257.318-.417.576-1.146 1.27-1.88 1.79-.784.552-1.283.824-1.62.88-.44.074-.762-.03-1.45-.466-1.156-.73-2.054-1.538-2.595-2.33l-.122-.18H0l.017 5.268zm0-2.673c0 1.436.003 2.023.008 1.305.004-.717.004-1.892 0-2.61-.005-.718-.009-.13-.009 1.305zm2.26-.523c-.15.075-.28.275-.28.43 0 .154.13.354.28.43.136.068.161.07 1.217.07s1.081-.002 1.218-.07c.15-.076.28-.276.28-.43 0-.155-.13-.355-.28-.43-.137-.07-.162-.07-1.218-.07s-1.08 0-1.217.07z"
                fill={props.fill}
            />
        </SvgIcon>
    );
};

export const PaymentIconCircle: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <SvgIcon viewBox="0 0 22 22" fill="none" {...props}>
            <path d="M22 11c0 6.075-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0s11 4.925 11 11z" fill="#fff" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8 5.645c-.85.364-1.585.692-1.635.729-.173.13-.171.118-.157 1.55.013 1.383.024 1.52.17 2.109.205.827.563 1.46 1.188 2.102.435.447.976.86 1.682 1.284.215.13.258.146.39.146.124 0 .175-.017.322-.105 1.6-.966 2.432-1.863 2.831-3.056.129-.385.248-.995.263-1.344.003-.078.014.125.023.45.009.324.011-.195.005-1.155-.01-1.347-.02-1.765-.047-1.83a.446.446 0 00-.125-.151c-.143-.107-3.164-1.39-3.273-1.39-.062 0-.616.224-1.638.661zM4.743 7.594a.99.99 0 00-.476.366l-.103.153-.015 2.028h3.091c2.931 0 3.09-.003 3.078-.047-.096-.338-.153-.926-.17-1.746l-.016-.786-2.648.003c-1.662.001-2.683.012-2.741.029zm10.812.014c-.086.036-.227.198-.811.927l-.707.883-.304-.45c-.28-.417-.313-.455-.428-.502a.39.39 0 00-.402.044.452.452 0 00-.174.406c.012.063.194.362.501.825.437.656.494.73.597.777.14.063.202.065.344.008.097-.04.224-.186 1.018-1.178.543-.68.919-1.173.939-1.233a.415.415 0 00-.222-.5c-.137-.062-.214-.064-.351-.007zM4.138 16.384l.068.133c.085.168.25.332.418.418l.132.067 6.177.008c4.381.005 6.211-.001 6.295-.022.26-.064.564-.358.61-.589.01-.046.028-.09.04-.097.013-.008.024-1.006.024-2.241 0-1.771-.007-2.224-.034-2.213-.018.008-.118.131-.221.274-.359.495-.986 1.09-1.62 1.537-.674.475-1.104.708-1.395.757-.377.063-.655-.026-1.248-.4-.995-.629-1.769-1.322-2.234-2.003l-.105-.154H4.124l.014 4.525zm0-2.296c0 1.234.003 1.738.007 1.122.004-.617.004-1.626 0-2.243s-.007-.112-.007 1.121zm1.946-.45a.474.474 0 00-.24.37c0 .133.111.304.24.37.118.058.14.06 1.049.06.91 0 .93-.002 1.048-.06a.473.473 0 00.24-.37.474.474 0 00-.24-.37c-.117-.059-.139-.06-1.048-.06-.91 0-.931.001-1.049.06z"
                fill={props.fill}
            />
        </SvgIcon>
    );
};

export const FilterSectionEmptyBoxIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
            <path stroke={props.fill} fill="none" d="M0.5 0.5H23.5V23.5H0.5z" />
        </SvgIcon>
    );
};

export const FilterSectionLightBoxIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
            <path fill={props.fill} fillOpacity={0.8} d="M4 4H20V20H4z" />
            <path stroke={props.fill} fill="none" d="M0.5 0.5H23.5V23.5H0.5z" />
        </SvgIcon>
    );
};

export const FilterSectionFullBoxIcon: React.FC<Partial<SvgIconProps>> = props => {
    return (
        <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
            <path fill={props.fill} d="M4 4H20V20H4z" />
            <path stroke={props.fill} fill="none" d="M0.5 0.5H23.5V23.5H0.5z" />
        </SvgIcon>
    );
};
