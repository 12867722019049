import { COLORS } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    listItem: {
        display: 'flex !important',
        flexDirection: 'row',
        justifyContent: 'space-between !important',
        alignItems: 'center !important',
        width: '100%',
        background: theme.palette.background.paper,
        color: theme.palette.primary.main,
        padding: '12px 16px',
        height: 48,
        marginTop: 5,
        marginBottom: 5,
        borderRadius: 10,
        border: `${'2px solid ' + COLORS.transparentLight}`,
        '&:hover': {
            borderColor: COLORS.transparentLight
        }
    },
    label: {
        color: theme.palette.primary.main,
        fontSize: 12,
        fontWeight: 600
    }
}));
