import { makeStyles } from '@material-ui/core';
import { FONTS } from 'constants/index';

export default makeStyles(theme => ({
    root: {},
    contentWrapperOverride: {
        paddingTop: 88,
        [theme.breakpoints.down('md')]: {
            alignSelf: 'center',
            paddingTop: 10,
            paddingBottom: 40
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 50,
            paddingRight: 50
        },
        [theme.breakpoints.up(2100)]: {
            marginTop: '45%',
            marginBottom: 'auto'
        }
    },
    emailInput: {
        width: '100%',
        maxWidth: '100%'
    },
    acceptTerms: {
        '& a': {
            textDecoration: 'underline'
        }
    },
    mainContentWrapper: {
        maxWidth: 450,
        width: '100%'
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignSelf: 'flex-start',
        fontFamily: FONTS.QUATERNARY_FONT,
        [theme.breakpoints.up('md')]: {
            maxWidth: 288
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between'
        }
    }
}));
