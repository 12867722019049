import React, { useState, useEffect, useContext } from 'react';
import jwt_decode from 'jwt-decode';
import { isEmpty } from 'lodash';
import Cookies from 'universal-cookie/lib/Cookies';
import { useLocation, matchPath, useHistory } from 'react-router-dom';
import { ApariFinishModal } from 'components/common/ui';
import { ModalTypes } from 'types';
import { GlobalModalDialogs } from 'constants/globalSocketModalDialogs';
import { GLOBAL, MODAL_NAME } from 'constants/index';
import useSockjs from './useSockJS';
import { FinishModalContext } from 'context/FinishModalContext';

export enum MESSAGE_TYPES {
    HMRCAUTH = 'HMRC_AUTH',
    OBLIGATIONS_SYNCED = 'OBLIGATIONS_SYNCED',
    CALCULATION_TRIGGERED = 'CALCULATION_TRIGGERED',
    CALCULATION_DATA_RETRIEVAL_DONE = 'CALCULATION_DATA_RETRIEVAL_DONE',
    CALCULATION_RETRIEVAL_DONE = 'CALCULATION_RETRIEVAL_DONE',
    QUARTER_SUBMISSION_ERROR = 'QUARTER_SUBMISSION_ERROR',
    QUARTER_SUBMISSION_SUCCESS_DONE = 'QUARTER_SUBMISSION_SUCCESS_DONE',
    HMRC_REQUEST_ERROR = 'HMRC_REQUEST_ERROR',
    HMRC_AUTH_OR_NINO_ERROR = 'HMRC_AUTH_OR_NINO_ERROR',
    PERIODIC_UPDATES_SYNC_SUCCESS = 'PERIODIC_UPDATES_SYNC_SUCCESS',
    PERIODIC_UPDATES_SYNC_ERROR = 'PERIODIC_UPDATES_SYNC_ERROR',
    CALCULATION_TRIGGERING_ERROR = 'CALCULATION_TRIGGERING_ERROR',
    CALCULATION_DATA_RETRIEVAL_ERROR = 'CALCULATION_DATA_RETRIEVAL_ERROR',
    TAX_YEAR_DISABLED = 'TAX_YEAR_DISABLED',
    HMRC_BUSINESS_SYNC_SUCCESS = 'HMRC_BUSINESS_SYNC_SUCCESS',
    HMRC_BUSINESS_SYNC_ERROR = 'HMRC_BUSINESS_SYNC_ERROR',
    BISS_SYNC_SUCCESS = 'BISS_SYNC_SUCCESS',
    BISS_SYNC_ERROR = 'BISS_SYNC_ERROR',
    EOP_SUBMISSION_DONE = 'EOP_SUBMISSION_DONE',
    EOP_SUBMISSION_ERROR = 'EOP_SUBMISSION_ERROR',
    CRYSTALLISATION_DONE = 'CRYSTALLISATION_DONE',
    ANNUAL_SUMMARY_SYNC_SUCCESS = 'ANNUAL_SUMMARY_SYNC_SUCCESS',
    ANNUAL_SUMMARY_SYNC_ERROR = 'ANNUAL_SUMMARY_SYNC_ERROR',
    ANNUAL_SUMMARY_SUBMISSION_ERROR = 'ANNUAL_SUMMARY_SUBMISSION_ERROR',
    ANNUAL_SUMMARY_SUBMISSION_SUCCESS = 'ANNUAL_SUMMARY_SUBMISSION_SUCCESS',
    UK_DIVIDENDS_SUBMISSION_SUCCESS = 'UK_DIVIDENDS_SUBMISSION_SUCCESS',
    UK_DIVIDENDS_SUBMISSION_ERROR = 'UK_DIVIDENDS_SUBMISSION_ERROR',
    UK_DIVIDENDS_SYNC_SUCCESS = 'UK_DIVIDENDS_SYNC_SUCCESS',
    PENSION_CONTRIBUTIONS_SUBMISSION_SUCCESS = 'PENSION_CONTRIBUTIONS_SUBMISSION_SUCCESS',
    PENSION_CONTRIBUTIONS_SUBMISSION_ERROR = 'PENSION_CONTRIBUTIONS_SUBMISSION_ERROR',
    PENSION_CONTRIBUTIONS_SYNC_SUCCESS = 'PENSION_CONTRIBUTIONS_SYNC_SUCCESS',
    GIFT_AID_SUBMISSION_SUCCESS = 'GIFT_AID_SUBMISSION_SUCCESS',
    GIFT_AID_SUBMISSION_ERROR = 'GIFT_AID_SUBMISSION_ERROR',
    GIFT_AID_SYNC_SUCCESS = 'GIFT_AID_SYNC_SUCCESS',
    CIS_DEDUCTIONS_SYNC_SUCCESS = 'CIS_DEDUCTIONS_SYNC_SUCCESS',
    CIS_DEDUCTIONS_SYNC_ERROR = 'CIS_DEDUCTIONS_SYNC_ERROR',
    SAVINGS_ACCOUNT_SUBMISSION_SUCCESS = 'SAVINGS_ACCOUNT_SUBMISSION_SUCCESS',
    SAVINGS_ACCOUNT_SUBMISSION_ERROR = 'SAVINGS_ACCOUNT_SUBMISSION_ERROR',
    SAVINGS_ACCOUNT_SYNC_SUCCESS = 'SAVINGS_ACCOUNT_SYNC_SUCCESS',
    SAVINGS_ACCOUNT_SYNC_ERROR = 'SAVINGS_ACCOUNT_SYNC_ERROR',
    SAVINGS_ACCOUNT_HMRC_DATA_SYNC_SUCCESS = 'SAVINGS_ACCOUNT_HMRC_DATA_SYNC_SUCCESS',
    SAVINGS_ACCOUNT_HMRC_DATA_SYNC_ERROR = 'SAVINGS_ACCOUNT_HMRC_DATA_SYNC_ERROR',
    STATE_BENEFITS_SYNC_ERROR = 'STATE_BENEFITS_SYNC_ERROR',
    STATE_BENEFITS_SYNC_SUCCESS = 'STATE_BENEFITS_SYNC_SUCCESS',
    EMPLOYMENTS_SYNC_SUCCESS = 'EMPLOYMENTS_SYNC_SUCCESS',
    EMPLOYMENTS_SYNC_ERROR = 'EMPLOYMENTS_SYNC_ERROR'
}

interface PayloadInterface {
    error: string | null;
    errorCode: string | null;
    errorDescription: string | null;
    message: string | null;
    submissionId?: string;
}

export interface MessageInterface {
    userId: string;
    webSocketMessageType: MESSAGE_TYPES;
    payload: PayloadInterface | any;
}

export interface WebSocketInterface {
    socketConnected: boolean;
    onMessage: (onMessageCall: (message: MessageInterface) => void) => void;
    setMessageTypes: React.Dispatch<React.SetStateAction<string[]>>;
    disconnect: () => void;
}

type ModalContent = [
    dialog: MODAL_NAME | GlobalModalDialogs,
    content?: Record<string, any> | undefined,
    buttonsContent?: Record<string, any>[] | undefined,
    type?: ModalTypes | undefined
];

export const WebSocketContext = React.createContext({} as WebSocketInterface);

export const WebSocket: React.FC<{
    socketMessages: Record<string, { modal?: ModalContent; routes: string[]; redirection?: string }>;
}> = ({ children, socketMessages }) => {
    const [socketConnected, setSocketConnected] = useState(false);
    const [messageTypes, setMessageTypes] = useState([MESSAGE_TYPES.HMRCAUTH, ...Object.keys(socketMessages)] as string[]);
    const [onMessageCall, onMessage] = useState(() => (message: MessageInterface) => console.log(message));
    const [topics, setTopics] = useState([]);
    const { toggle, setSocketMessage } = useContext(FinishModalContext);
    const location = useLocation();
    const history = useHistory();

    const SOCKET_URL = `${GLOBAL.BASE_URL}/api/uk-mtd/public/ws-mtd`;

    const cookie = new Cookies();

    const jwt_cookie = cookie.get(GLOBAL.JWT_COOKIE_KEY);

    let decoded = '';

    const checkGlobalModalRouteMatch = (messageType: string) => {
        const routes = socketMessages[messageType]?.routes;
        return (
            Object.keys(socketMessages).includes(messageType) &&
            ((!isEmpty(routes) && routes.some(route => matchPath(location.pathname, route))) || true)
        );
    };

    useEffect(() => {
        try {
            if (!jwt_cookie && socketConnected) {
                disconnect();
            } else {
                decoded = jwt_decode(jwt_cookie);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setTopics([`/user/${decoded.id}/queue`]);
            }
        } catch (e) {
            console.log('Catch: ', e);
        }
    }, [jwt_cookie]);

    const { disconnect } = useSockjs({
        url: SOCKET_URL,
        topics,
        onMessage: body => {
            const { webSocketMessageType } = body;
            if (checkGlobalModalRouteMatch(webSocketMessageType)) {
                setSocketMessage(webSocketMessageType);
                if (socketMessages[webSocketMessageType].redirection)
                    history.push(socketMessages[webSocketMessageType].redirection as string);
                else toggle.apply(null, [...(socketMessages[webSocketMessageType].modal as ModalContent)]);
            }
            if (messageTypes.includes(webSocketMessageType)) {
                onMessageCall(body);
            }
        },
        onConnect: () => {
            setSocketConnected(true);
        },
        onDisconnect: () => {
            console.log('socket disconnected');
        }
    });

    return (
        <WebSocketContext.Provider
            value={{
                socketConnected,
                onMessage,
                setMessageTypes,
                disconnect
            }}
        >
            <>
                {children}
                {Object.keys(socketMessages).map(message => {
                    return (
                        socketMessages[message].modal && (
                            <ApariFinishModal key={socketMessages[message].modal![0]} dialog={message as GlobalModalDialogs} />
                        )
                    );
                })}
            </>
        </WebSocketContext.Provider>
    );
};
