import { COLORS } from 'constants/index';

export default {
    root: {
        fontSize: 13,
        color: COLORS.apariBlack80
    },
    select: {
        '&.Mui-disabled': {
            color: COLORS.apariGray
        }
    }
};
