import React, { useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import clsx from 'clsx';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { Country } from '@apari/core-api';
import { AddressInternal, ApCountryField, ApTextField, addressObjectValidationSchema, ApariButton } from 'components';
import { COUNTRIES } from 'constants/countries';
import globalStyles from 'styles/globalStyles';
import { GlobalServices, Localisation } from 'utils';
import styles from './styles';

type ManualAddressFormProps = React.HTMLAttributes<HTMLFormElement> & {
    address: AddressInternal | null;
    onlyUk?: boolean;
    foreign?: boolean;
    countriesList?: Country[];
    title?: string;
    onCancel: () => void;
    onConfirm: (data?: AddressInternal) => void;
    initialErrors?: FormikErrors<AddressInternal>;
    initialTouched?: FormikTouched<AddressInternal>;
    validationSchemaExtension?: any;
};

const ManualAddressForm: React.FC<ManualAddressFormProps> = ({
    address,
    onlyUk,
    foreign,
    title,
    countriesList,
    onCancel,
    onConfirm,
    initialErrors,
    initialTouched,
    className,
    validationSchemaExtension,
    ...rest
}) => {
    const [countries, setCountries] = useState<Country[]>([]);

    const classes = styles({ isIOS });
    const globalClasses = globalStyles();

    const formik = useFormik<AddressInternal>({
        initialValues: {
            streetName: '',
            houseNumber: '',
            houseNumberExtension: '',
            postalCode: '',
            city: '',
            state: '',
            country: onlyUk ? { name: 'United Kingdom', code2: 'GB', code3: 'GBR' } : null
        },
        isInitialValid: !GlobalServices.isEmpty(initialErrors),
        initialErrors,
        initialTouched,
        validationSchema: addressObjectValidationSchema(validationSchemaExtension),
        onSubmit: values => {
            onConfirm(values);
        }
    });

    useEffect(() => {
        if (address) formik.setValues(address);
    }, []);

    useEffect(() => {
        async function getCountries() {
            try {
                if (countriesList) {
                    setCountries(countriesList);
                } else {
                    const response = { data: COUNTRIES };
                    setCountries(
                        response.data.filter(country => {
                            if (onlyUk) {
                                return country.code2 === 'GB';
                            } else if (foreign) {
                                return country.code2 !== 'GB';
                            } else {
                                return country;
                            }
                        })
                    );
                }
            } catch (e) {
                console.log('e', e);
            }
        }
        getCountries();
    }, []);

    return (
        <form onSubmit={formik.handleSubmit} className={clsx(classes.manualForm, className)} {...rest}>
            <p className={clsx(globalClasses.font18weight400Dark)}>{title ?? Localisation.localize('ADDRESS_LOOKUP.EDIT_ADDRESS')}</p>
            <ApTextField
                data-cy="houseNumberExtension"
                control="houseNumberExtension"
                formik={formik}
                label={Localisation.localize('ADDRESS_LOOKUP.BUILDING_NAME')}
            />
            <ApTextField
                data-cy="houseNumber"
                control="houseNumber"
                formik={formik}
                label={Localisation.localize('ADDRESS_LOOKUP.HOUSE_NUMBER')}
            />
            <ApTextField
                data-cy="streetName"
                control="streetName"
                formik={formik}
                label={Localisation.localize('ADDRESS_LOOKUP.STREET_NAME')}
            />
            <ApTextField data-cy="city" control="city" formik={formik} label={Localisation.localize('ADDRESS_LOOKUP.CITY')} />
            <ApTextField data-cy="state" control="state" formik={formik} label={Localisation.localize('ADDRESS_LOOKUP.COUNTY')} />
            <ApCountryField
                dataCy="countryAutocomplete"
                inputDataCy="countryInput"
                control="country"
                label={Localisation.localize('ADDRESS_LOOKUP.COUNTRY')}
                formik={formik}
                disabled={onlyUk}
                customCountries={countries}
            />
            <ApTextField
                data-cy="postalCode"
                control="postalCode"
                formik={formik}
                label={Localisation.localize('ADDRESS_LOOKUP.POSTAL_CODE')}
            />
            <ApariButton
                data-cy="addressConfirm"
                color="primary"
                variant="contained"
                type="submit"
                size="small"
                fullWidth
                className={clsx(classes.manualFormConfirmButton)}
            >
                {Localisation.localize('general.confirm')}
            </ApariButton>
            <ApariButton color="primary" variant="outlined" size="small" fullWidth onClick={onCancel}>
                {Localisation.localize('general.cancel')}
            </ApariButton>
        </form>
    );
};

export default ManualAddressForm;
