import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { ModalTypes } from '../types';
import ModalDialogs from '../constants/modalDialogs';
import { GlobalModalDialogs } from '../constants/globalSocketModalDialogs';

export interface Props {
    modal: Record<string, any>;
    toggle: (
        dialog: ModalDialogs | GlobalModalDialogs,
        content?: Record<string, any>,
        buttonsContent?: Record<string, any>[],
        type?: ModalTypes
    ) => void;
    setModal: React.Dispatch<React.SetStateAction<any>>;
    setTempData: Dispatch<SetStateAction<Record<string, any>>>;
    tempData: Record<string, any>;
    buttons: Record<string, any>[] | undefined;
    closeModal: (dialog: ModalDialogs | GlobalModalDialogs) => void;
    socketMessage: string;
    setSocketMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const FinishModalContext = createContext({} as Props);

/*
    INFO: Socket message can be used anywhere in the dashboard if you need any logic when that message triggers.
    setSocketMessage is used in WebSocket component
 */

export const FinishModalProvider: React.FC = ({ children }) => {
    const [modal, setModal] = useState<Record<string, any>>({});
    const [tempData, setTempData] = useState<Record<string, any>>({});
    const [buttons, setButtons] = useState<Record<string, any>[] | undefined>([]);
    const [socketMessage, setSocketMessage] = useState('');

    const toggle = (
        dialog: ModalDialogs | GlobalModalDialogs,
        content?: Record<string, any>,
        buttonsContent?: Record<string, any>[],
        type?: ModalTypes
    ) => {
        setButtons(buttonsContent);
        setModal({ ...modal, [dialog]: { ...content, type, open: !modal[dialog]?.open } });
    };

    const closeModal = (dialog: ModalDialogs | GlobalModalDialogs) => {
        setModal({ ...modal, [dialog]: { open: false } });
    };

    return (
        <FinishModalContext.Provider
            value={{
                modal,
                setModal,
                toggle,
                setTempData,
                tempData,
                buttons,
                closeModal,
                socketMessage,
                setSocketMessage
            }}
        >
            {children}
        </FinishModalContext.Provider>
    );
};
