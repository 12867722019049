import React, { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { AuthenticationApi } from '@apari/core-api';
import { SubHeaderClean, AnimatedTransition, ApariButton, ApTextField, ApariCheckbox } from 'components';
import NewLayout from 'containers/layouts/NewLayout';
import { GLOBAL } from 'constants/index';
import { AppContext } from 'context/AppContext';
import globalStyles from 'styles/globalStyles';
import { Localisation } from 'utils';
import generateErrorMessage from 'utils/generateErrorMessage';
import { NotificationTypes } from 'types';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import validationSchema from './validationSchema';
import styles from './styles';

const authenticationApi = new AuthenticationApi();

const ForgotPasswordScreen: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] = useState<boolean>(false);
    const [isAgree, setIsAgree] = useState<boolean>(false);
    const globalClasses = globalStyles();
    const classes = styles();
    const history = useHistory();

    const { showLoadingBar, hideLoadingBar, showNotifications } = useContext(AppContext);

    const onBack = () => {
        history.goBack();
    };

    const onDialogClose = () => {
        history.push('/sign-in');
    };

    const submitForm = async (data: any) => {
        setLoading(true);
        showLoadingBar();
        try {
            await authenticationApi.forgotPassword(data.email);
            setForgotPasswordDialogOpen(true);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            if (e.responseCode === 409) {
                showNotifications({
                    type: NotificationTypes.ERROR,
                    message: Localisation.localize('serverErrors.userWithGivenEmailCannotBeFound')
                });
            } else {
                showNotifications({ type: NotificationTypes.ERROR, message: generateErrorMessage(e) });
            }
        }
        hideLoadingBar();
    };

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit: submitForm,
        validationSchema: validationSchema
    });

    return (
        <NewLayout
            whiteBackground
            handleBack={onBack}
            hideFooter
            hideTitle
            enableBackgroundArrows
            headerText={Localisation.localize('FORGOT_PASSWORD_SCREEN.TITLE')}
        >
            <AnimatedTransition addHeight className={clsx(globalClasses.fontRoboto)}>
                <div className={clsx(globalClasses.contentWrapper, classes.contentWrapperOverride)}>
                    <div className={clsx(globalClasses.fullHeight, globalClasses.fullWidth)}>
                        <div className={clsx(globalClasses.fullHeight)}>
                            <div className={clsx(globalClasses.fullWidth, globalClasses.flexColumn, globalClasses.fullHeight)}>
                                <SubHeaderClean
                                    title={Localisation.localize('FORGOT_PASSWORD_SCREEN.MESSAGE')}
                                    messages={[Localisation.localize('FORGOT_PASSWORD_SCREEN.DESCRIPTION')]}
                                    alternativeFont
                                    noBackButton
                                    noSidePadding
                                />
                                <form onSubmit={formik.handleSubmit} className={clsx(classes.formContainer)}>
                                    <div>
                                        <ApTextField
                                            className={clsx(classes.emailInput)}
                                            control="email"
                                            label={Localisation.localize('general.emailAddress')}
                                            formik={formik}
                                            alternativeFont
                                            margin="normal"
                                        />
                                        <div className={clsx(globalClasses.flexRowStart)}>
                                            <ApariCheckbox
                                                checked={isAgree}
                                                onChange={() => setIsAgree(!isAgree)}
                                                className={clsx(globalClasses.noPadding)}
                                            />
                                            <p
                                                className={clsx(
                                                    globalClasses.paddingLeft8,
                                                    globalClasses.font12weight400Dark,
                                                    classes.acceptTerms
                                                )}
                                            >
                                                <Trans i18nKey="FORGOT_PASSWORD_SCREEN.TERMS_AND_PRIVACY_POLICY">
                                                    I accept the
                                                    <a
                                                        style={{ pointerEvents: 'auto' }}
                                                        href={GLOBAL.TERMS_AND_CONDITIONS}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className={clsx(globalClasses.textUnderline)}
                                                    >
                                                        Terms
                                                    </a>
                                                    and
                                                    <a
                                                        style={{ pointerEvents: 'auto' }}
                                                        href={GLOBAL.PRIVACY_POLICY}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className={clsx(globalClasses.textUnderline)}
                                                    >
                                                        Privacy Policy
                                                    </a>
                                                    of APARI.
                                                </Trans>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={clsx(globalClasses.CTAWrapperTabletMode, globalClasses.noHorizontalPadding)}>
                                            <div>
                                                <ApariButton
                                                    className={clsx(globalClasses.marginTop24)}
                                                    fullWidth
                                                    type="submit"
                                                    disabled={!isAgree || loading}
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                >
                                                    {Localisation.localize('general.confirm')}
                                                </ApariButton>
                                            </div>
                                            <span
                                                className={clsx(
                                                    globalClasses.font14weight400Dark60,
                                                    globalClasses.alignSelfCenter,
                                                    globalClasses.marginTop16,
                                                    globalClasses.cursorPointer,
                                                    globalClasses.textUnderline
                                                )}
                                                onClick={onBack}
                                            >
                                                {Localisation.localize('signUpWithEmailScreen.goToSignIn')}
                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </AnimatedTransition>
            <ForgotPasswordDialog open={forgotPasswordDialogOpen} handleClose={onDialogClose} email={formik.values.email} />
        </NewLayout>
    );
};

export default ForgotPasswordScreen;
