import { makeStyles, Theme } from '@material-ui/core';
import { GLOBAL } from 'constants/index';

type Props = {
    noWidthRestriction?: boolean;
    addHeight?: boolean;
};

export default makeStyles<Theme, Props>(theme => ({
    root: ({ noWidthRestriction, addHeight }) => {
        return {
            display: 'flex',
            flex: 1,
            marginTop: GLOBAL.TOP_MARGIN,
            [theme.breakpoints.up('lg')]: {
                maxWidth: noWidthRestriction ? '100%' : 780,
                width: '100%',
                position: 'relative'
            },
            [theme.breakpoints.down('md')]: {
                width: '100%',
                height: addHeight ? '100%' : '',
                position: 'relative'
            }
        };
    },
    centerContentVertically: {
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'center'
        }
    }
}));
