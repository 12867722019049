import { PageTransactionDto } from '@apari/banking-api/models';
import { Business, BusinessTypeEnum, UserCurrentApplicationEnum, UserLoginTypeEnum, UserStatusEnum } from '@apari/core-api';
import { MoneyHubUserConnectionAndStatusDtoConnectionStatusEnum } from '@apari/banking-api';
import { AccountTypeEnum } from '@apari/banking-api/models/account';
import { BusinessSourceBasicInfoTypeEnum } from '@apari/core-api/models/business-source-basic-info';
import { BankAccountTypeEnum } from '@apari/banking-api/models/bank-account';
import { TaxYearDataAndStatusDtoOverviewStatusEnum, TaxYearDtoStatusEnum } from '@apari/uk-jf-api';

export const CURRENT_USER_INFO = {
    firstName: 'John',
    lastName: 'Dale',
    email: 'john.dale@gmail.com',
    birthDate: '2021-10-20',
    role: { id: 4, roleName: 'USER', users: undefined },
    id: '8a808160853d705001853ede508d0614',
    taxEntityId: '8a808160853d705001853ede508d0615',
    acceptedTermsAndConditions: true,
    createdAt: '2022-12-23T13:03:55',
    receiveNewsletter: true,
    mtdWizardDone: true,
    sa100WizardDone: false,
    twoFactorAuthMethod: undefined,
    trialActivated: true,
    agentWizardDone: false,
    agencyName: undefined,
    agencyAddress: undefined,
    hmrcAgentServicesAccountId: undefined,
    hmrcOnlineServicesForAgentsId: undefined,
    agentReferenceNumber: undefined,
    agentAgencyName: undefined
};

export const TRANSACTIONS: PageTransactionDto = {
    content: [
        {
            id: '8a8081b88442e8990184c7d164541b40',
            bankAccount: {
                id: 3030,
                name: 'Current Account',
                bankName: 'OB Mock',
                identification: '3796',
                type: 'CASH_CURRENT',
                swift: undefined,
                taxEntityId: '8a808152849ecee701849ecf279d0001',
                externalId: '6c798dbc-39bc-4daa-b486-98407bf6eead',
                createdBy: '8a808152849ecee701849ecf27840000',
                createdAt: '2022-11-30T10:14:59',
                updatedBy: '8a808152849ecee701849ecf27840000',
                updatedAt: '2022-11-30T10:14:59',
                version: 0
            },
            amount: 25.0,
            originalAmount: 25.0,
            transactionDate: '2022-11-29',
            isIncome: false,
            isParent: false,
            parentTransactionId: undefined,
            splitRate: 1.0,
            vat: undefined,
            businessId: '8a808152849ecee701849ed069e50006',
            propertyId: undefined,
            category: {
                id: 8,
                countryId: 1,
                parentCategory: undefined,
                name: 'Office Costs, Light & Heat, Phone',
                categoryType: 'NON_FHL_LL_EXPENSE'
            },
            type: undefined,
            counterpartName: 'Amazon',
            counterpartBank: undefined,
            purpose: 'Amazon',
            referenceNumber: undefined,
            externalId: 'c19167fa-0330-4f7a-b34f-99028fe76893',
            notes: undefined,
            createdBy: 'Unknown',
            createdAt: '2022-11-30T10:15:00',
            updatedBy: '8a808152849ecee701849ecf27840000',
            updatedAt: '2022-12-28T10:56:18',
            version: 1,
            isSubmitted: false,
            validate: undefined,
            tempId: undefined,
            isChecked: undefined,
            isRefund: false,
            isImportedByCsv: false,
            shared: false,
            address: undefined,
            sharedWithSomeone: undefined,
            copyTransactionId: undefined
        },
        {
            id: '8a8081b88442e8990184c7d164541b41',
            bankAccount: {
                id: 3030,
                name: 'Current Account',
                bankName: 'OB Mock',
                identification: '3796',
                type: 'CASH_CURRENT',
                swift: undefined,
                taxEntityId: '8a808152849ecee701849ecf279d0001',
                externalId: '6c798dbc-39bc-4daa-b486-98407bf6eead',
                createdBy: '8a808152849ecee701849ecf27840000',
                createdAt: '2022-11-30T10:14:59',
                updatedBy: '8a808152849ecee701849ecf27840000',
                updatedAt: '2022-11-30T10:14:59',
                version: 0
            },
            amount: 1800.0,
            originalAmount: 1800.0,
            transactionDate: '2022-11-28',
            isIncome: true,
            isParent: false,
            parentTransactionId: undefined,
            splitRate: 1.0,
            vat: undefined,
            businessId: '8a808152849ecee701849ed069e50006',
            propertyId: undefined,
            category: {
                id: 45,
                countryId: 1,
                parentCategory: undefined,
                name: 'Other Property Income + Interest Received',
                categoryType: 'NON_FHL_LL_INCOME'
            },
            type: undefined,
            counterpartName: 'Salary',
            counterpartBank: undefined,
            purpose: 'Salary',
            referenceNumber: undefined,
            externalId: '931da4e6-b0d8-4eb5-b914-61fdb5bc9dff',
            notes: undefined,
            createdBy: 'Unknown',
            createdAt: '2022-11-30T10:15:00',
            updatedBy: '8a808152849ecee701849ecf27840000',
            updatedAt: '2022-12-28T10:57:24',
            version: 1,
            isSubmitted: false,
            validate: undefined,
            tempId: undefined,
            isChecked: undefined,
            isRefund: false,
            isImportedByCsv: false,
            shared: false,
            address: undefined,
            sharedWithSomeone: undefined,
            copyTransactionId: undefined
        },
        {
            id: '8a8081b88442e8990184c7d166e71b63',
            bankAccount: {
                id: 3029,
                name: 'Savings',
                bankName: 'OB Mock',
                identification: '9855',
                type: 'SAVINGS',
                swift: undefined,
                taxEntityId: '8a808152849ecee701849ecf279d0001',
                externalId: '409c1653-cc7e-488b-bada-e289b8db23f7',
                createdBy: '8a808152849ecee701849ecf27840000',
                createdAt: '2022-11-30T10:14:59',
                updatedBy: '8a808152849ecee701849ecf27840000',
                updatedAt: '2022-11-30T10:14:59',
                version: 0
            },
            amount: 25.0,
            originalAmount: 25.0,
            transactionDate: '2022-11-28',
            isIncome: true,
            isParent: false,
            parentTransactionId: undefined,
            splitRate: 1.0,
            vat: undefined,
            businessId: '8a808152849ecee701849ed069e50006',
            propertyId: undefined,
            category: { id: 47, countryId: 1, parentCategory: undefined, name: 'Rental Income', categoryType: 'NON_FHL_LL_INCOME' },
            type: undefined,
            counterpartName: 'Interest',
            counterpartBank: undefined,
            purpose: 'Interest',
            referenceNumber: undefined,
            externalId: '8d9b63a6-be56-4262-a294-b03321083694',
            notes: undefined,
            createdBy: 'Unknown',
            createdAt: '2022-11-30T10:15:00',
            updatedBy: '8a808152849ecee701849ecf27840000',
            updatedAt: '2022-12-28T10:57:44',
            version: 1,
            isSubmitted: false,
            validate: undefined,
            tempId: undefined,
            isChecked: undefined,
            isRefund: false,
            isImportedByCsv: false,
            shared: false,
            address: undefined,
            sharedWithSomeone: undefined,
            copyTransactionId: undefined
        }
    ],
    pageable: {
        sort: { sorted: true, unsorted: false, empty: false },
        pageNumber: 0,
        pageSize: 10,
        offset: 0,
        paged: true,
        unpaged: false
    },
    last: true,
    totalElements: 3,
    totalPages: 1,
    first: true,
    sort: { sorted: true, unsorted: false, empty: false },
    number: 0,
    numberOfElements: 3,
    size: 10,
    empty: false
};

export const UNASSIGNED_TRANSACTIONS: PageTransactionDto = {
    content: [
        {
            id: '8a8081b88442e8990184c7d164541b40',
            bankAccount: {
                id: 3030,
                name: 'Current Account',
                bankName: 'OB Mock',
                identification: '3796',
                type: 'CASH_CURRENT',
                swift: undefined,
                taxEntityId: '8a808152849ecee701849ecf279d0001',
                externalId: '6c798dbc-39bc-4daa-b486-98407bf6eead',
                createdBy: '8a808152849ecee701849ecf27840000',
                createdAt: '2022-11-30T10:14:59',
                updatedBy: '8a808152849ecee701849ecf27840000',
                updatedAt: '2022-11-30T10:14:59',
                version: 0
            },
            amount: 25.0,
            originalAmount: 25.0,
            transactionDate: '2022-11-29',
            isIncome: false,
            isParent: false,
            parentTransactionId: undefined,
            splitRate: 1.0,
            vat: undefined,
            businessId: undefined,
            propertyId: undefined,
            category: undefined,
            type: undefined,
            counterpartName: 'Amazon',
            counterpartBank: undefined,
            purpose: 'Amazon',
            referenceNumber: undefined,
            externalId: 'c19167fa-0330-4f7a-b34f-99028fe76893',
            notes: undefined,
            createdBy: 'Unknown',
            createdAt: '2022-11-30T10:15:00',
            updatedBy: 'Unknown',
            updatedAt: '2022-11-30T10:15:00',
            version: 0,
            isSubmitted: false,
            validate: undefined,
            tempId: undefined,
            isChecked: undefined,
            isRefund: false,
            isImportedByCsv: false,
            shared: false,
            address: undefined,
            sharedWithSomeone: undefined,
            copyTransactionId: undefined
        },
        {
            id: '8a8081b88442e8990184c7d164541b41',
            bankAccount: {
                id: 3030,
                name: 'Current Account',
                bankName: 'OB Mock',
                identification: '3796',
                type: 'CASH_CURRENT',
                swift: undefined,
                taxEntityId: '8a808152849ecee701849ecf279d0001',
                externalId: '6c798dbc-39bc-4daa-b486-98407bf6eead',
                createdBy: '8a808152849ecee701849ecf27840000',
                createdAt: '2022-11-30T10:14:59',
                updatedBy: '8a808152849ecee701849ecf27840000',
                updatedAt: '2022-11-30T10:14:59',
                version: 0
            },
            amount: 1800.0,
            originalAmount: 1800.0,
            transactionDate: '2022-11-28',
            isIncome: true,
            isParent: false,
            parentTransactionId: undefined,
            splitRate: 1.0,
            vat: undefined,
            businessId: undefined,
            propertyId: undefined,
            category: undefined,
            type: undefined,
            counterpartName: 'Salary',
            counterpartBank: undefined,
            purpose: 'Salary',
            referenceNumber: undefined,
            externalId: '931da4e6-b0d8-4eb5-b914-61fdb5bc9dff',
            notes: undefined,
            createdBy: 'Unknown',
            createdAt: '2022-11-30T10:15:00',
            updatedBy: 'Unknown',
            updatedAt: '2022-11-30T10:15:00',
            version: 0,
            isSubmitted: false,
            validate: undefined,
            tempId: undefined,
            isChecked: undefined,
            isRefund: false,
            isImportedByCsv: false,
            shared: false,
            address: undefined,
            sharedWithSomeone: undefined,
            copyTransactionId: undefined
        },
        {
            id: '8a8081b88442e8990184c7d166e71b63',
            bankAccount: {
                id: 3029,
                name: 'Savings',
                bankName: 'OB Mock',
                identification: '9855',
                type: 'SAVINGS',
                swift: undefined,
                taxEntityId: '8a808152849ecee701849ecf279d0001',
                externalId: '409c1653-cc7e-488b-bada-e289b8db23f7',
                createdBy: '8a808152849ecee701849ecf27840000',
                createdAt: '2022-11-30T10:14:59',
                updatedBy: '8a808152849ecee701849ecf27840000',
                updatedAt: '2022-11-30T10:14:59',
                version: 0
            },
            amount: 25.0,
            originalAmount: 25.0,
            transactionDate: '2022-11-28',
            isIncome: true,
            isParent: false,
            parentTransactionId: undefined,
            splitRate: 1.0,
            vat: undefined,
            businessId: undefined,
            propertyId: undefined,
            category: undefined,
            type: undefined,
            counterpartName: 'Interest',
            counterpartBank: undefined,
            purpose: 'Interest',
            referenceNumber: undefined,
            externalId: '8d9b63a6-be56-4262-a294-b03321083694',
            notes: undefined,
            createdBy: 'Unknown',
            createdAt: '2022-11-30T10:15:00',
            updatedBy: 'Unknown',
            updatedAt: '2022-11-30T10:15:00',
            version: 0,
            isSubmitted: false,
            validate: undefined,
            tempId: undefined,
            isChecked: undefined,
            isRefund: false,
            isImportedByCsv: false,
            shared: false,
            address: undefined,
            sharedWithSomeone: undefined,
            copyTransactionId: undefined
        }
    ],
    pageable: {
        sort: { sorted: true, unsorted: false, empty: false },
        pageNumber: 0,
        pageSize: 20,
        offset: 0,
        paged: true,
        unpaged: false
    },
    last: false,
    totalElements: 3,
    totalPages: 1,
    first: true,
    sort: { sorted: true, unsorted: false, empty: false },
    number: 0,
    numberOfElements: 20,
    size: 20,
    empty: false
};

export const PERSONAL_DATA = {
    firstName: 'John',
    lastName: 'Doe',
    address: {
        fullAddress: 'Lon Refail',
        streetName: 'Lon Refail',
        houseNumber: 'Pen Lon Llan',
        houseNumberExtension: '',
        postalCode: 'LL61 5YN',
        city: 'Llanfairpwllgwyngyll',
        state: 'Gwynedd',
        country: { name: 'United Kingdom', code2: 'GB', code3: 'GBR' }
    },
    telephoneNumber: '445555555',
    taxIdentification: 'OB433977B',
    uniqueTaxPayerReference: '1000000232',
    governmentGatewayId: '15645655',
    birthDate: '2021-10-20'
};

export const CURRENT_USER = {
    createdBy: 'anonymousUser',
    createdAt: '2022-12-23T13:03:55',
    updatedBy: '8a808160853d705001853ede508d0614',
    updatedAt: '2022-12-23T13:09:38',
    id: '8a808160853d705001853ede508d0614',
    version: 3,
    email: 'semir.turkic+nexttask@serapion.net',
    plainPassword: undefined,
    role: { id: 4, roleName: 'USER', users: undefined },
    firstName: 'John',
    lastName: 'Doe',
    birthDate: '2021-10-20',
    honorific: undefined,
    title: undefined,
    infoEmail: undefined,
    receiveTaxReminders: false,
    receiveNewsletter: true,
    telephoneNumber: '445555555',
    status: UserStatusEnum.ACTIVE,
    twoFactorAuthMethod: undefined,
    twoFactorGoogleSecret: undefined,
    twoFactorGoogleRecovery: undefined,
    initialLoginDone: true,
    hubspotContactId: '406651',
    locked: false,
    mtdWizardDone: true,
    sa100WizardDone: false,
    taxEntityUsers: undefined,
    acceptedTermsAndConditionsDate: '2022-12-23',
    lastLogin: '2022-12-23T13:05:54',
    loginType: UserLoginTypeEnum.REGULAR,
    currentApplication: UserCurrentApplicationEnum.UK_MTD,
    agencyName: undefined,
    agencyAddress: undefined,
    hmrcAgentServicesAccountId: undefined,
    hmrcOnlineServicesForAgentsId: undefined,
    agentReferenceNumber: undefined,
    agentWizardDone: false,
    clientReference: undefined,
    canLoginAsClient: undefined,
    referralCode: undefined,
    hubSpotCompanyId: undefined,
    twoFactorAuthenticationEnabled: false,
    acceptedTermsAndConditions: true
};

export const MONEY_HUB_BANKS = [
    {
        id: '1ffe704d39629a929c8e293880fb449a',
        name: 'Moneyhub Open Banking Mock',
        iconLink: 'https://identity.moneyhub.co.uk/bank-icons/default',
        alreadyConnected: false
    },
    {
        id: '5233db2a04fe41dd01d3308ea92e8bd7',
        name: 'Ozone Open Banking',
        iconLink: 'https://identity.moneyhub.co.uk/bank-icons/default',
        alreadyConnected: false
    }
];

export const MONEY_HUB_CONNECTION = {
    userConnectionId: '1ffe704d39629a929c8e293880fb4493',
    name: 'Moneyhub Open Banking Mock',
    connectionIconUrl: 'https://identity.moneyhub.co.uk/bank-icons/default',
    transactionFilterStartDate: '2022-04-06'
};

export const MONEY_HUB_USER_CONNECTIONS = [
    {
        userConnectionId: '1ffe704d39629a929c8e293880fb449a:e7a90e0b-3aed-467b-ae32-fa7ce7e1b171',
        name: 'Moneyhub Open Banking Mock',
        connectionStatus: MoneyHubUserConnectionAndStatusDtoConnectionStatusEnum.ok,
        errorReason: undefined,
        accounts: [
            {
                id: '3559aace-e257-440d-b204-a7d7e674570a',
                name: 'Credit Card',
                type: AccountTypeEnum.CASH,
                iban: '9421',
                initiallyImportedByYolt: false
            },
            {
                id: '409c1653-cc7e-488b-bada-e289b8db23f7',
                name: 'Savings',
                type: AccountTypeEnum.SAVINGS,
                iban: '9855',
                initiallyImportedByYolt: false
            },
            {
                id: '6c798dbc-39bc-4daa-b486-98407bf6eead',
                name: 'Current Account',
                type: AccountTypeEnum.CASH_CURRENT,
                iban: '3796',
                initiallyImportedByYolt: false
            }
        ],
        connectionIconUrl: 'https://identity.moneyhub.co.uk/bank-icons/default',
        transactionFilterStartDate: '2022-04-06',
        existsNotMatchedYoltBankAccount: false
    }
];

export const LANDLORD_BUSINESS: Business = {
    createdBy: 'anonymousUser',
    createdAt: '2022-11-22T11:09:30',
    updatedBy: 'anonymousUser',
    updatedAt: '2022-11-22T11:09:30',
    id: '8a808152849ecee701849ed069e50006',
    version: 0,
    name: 'Landlord (UK Property) business',
    type: BusinessTypeEnum.LANDLORD,
    accountingPeriod: '--04-06',
    taxNumber: '',
    vatNumber: undefined,
    taxEntity: undefined,
    address: {
        createdBy: '8a808160853d705001853ede508d0614',
        createdAt: '2022-12-23T13:06:28',
        updatedBy: '8a808160853d705001853ede508d0614',
        updatedAt: '2022-12-23T13:06:28',
        id: 36283,
        version: 2,
        fullAddress: 'West Haddon Road',
        streetName: 'West Haddon Road',
        country: { name: 'United Kingdom', code2: 'GB', code3: 'GBR' },
        houseNumber: 'Lingles Farm',
        houseNumberExtension: '',
        postalCode: 'NN6 8ET',
        city: 'Northampton',
        state: 'Northamptonshire'
    },
    properties: [
        {
            createdBy: '8a808152849ecee701849ecf27840000',
            createdAt: '2022-12-08T09:10:06',
            updatedBy: '8a808152849ecee701849ecf27840000',
            updatedAt: '2022-12-08T09:10:06',
            id: '8a8081e184ed0aaf0184f0c8d9d002e2',
            version: 0,
            name: 'Split Property 40',
            business: undefined,
            address: {
                createdBy: '8a808152849ecee701849ecf27840000',
                createdAt: '2022-12-08T09:10:06',
                updatedBy: '8a8081dd84ebaa2d0184ecf6b7f402ab',
                updatedAt: '2022-12-08T09:11:22',
                id: 18401,
                version: 2,
                fullAddress: 'Lon Refail',
                streetName: 'Lon Refail',
                country: { name: 'United Kingdom', code2: 'GB', code3: 'GBR' },
                houseNumber: 'Pen Lon Llan',
                houseNumberExtension: '',
                postalCode: 'LL61 5YN',
                city: 'LLANFAIRPWLLGWYNGYLL',
                state: 'Gwynedd'
            },
            boughtDate: undefined,
            saleDate: undefined,
            splitRate: 0.4,
            description: '',
            isActive: true,
            furnishedHolidayLet: false
        }
    ],
    sharedProperties: undefined,
    active: true
};

export const SELF_EMPLOYED_BUSINESS: Business = {
    createdBy: '8a808160853d705001853ede508d0614',
    createdAt: '2022-12-23T13:06:28',
    updatedBy: '8a808160853d705001853ede508d0614',
    updatedAt: '2022-12-23T13:06:28',
    id: '8a808160853d705001853ee0a3ad0624',
    version: 0,
    name: 'My Business',
    type: BusinessTypeEnum.SELF_EMPLOYED,
    accountingPeriod: '--04-06',
    taxNumber: '',
    vatNumber: undefined,
    taxEntity: undefined,
    address: {
        createdBy: '8a808160853d705001853ede508d0614',
        createdAt: '2022-12-23T13:06:28',
        updatedBy: '8a808160853d705001853ede508d0614',
        updatedAt: '2022-12-23T13:06:28',
        id: 36283,
        version: 2,
        fullAddress: 'West Haddon Road',
        streetName: 'West Haddon Road',
        country: { name: 'United Kingdom', code2: 'GB', code3: 'GBR' },
        houseNumber: 'Lingles Farm',
        houseNumberExtension: '',
        postalCode: 'NN6 8ET',
        city: 'Northampton',
        state: 'Northamptonshire'
    },
    properties: [],
    sharedProperties: undefined,
    propertiesSharedWithUser: [],
    active: true
};

export const BUSINESSES_BASIC_INFO = [
    {
        id: '8a808152849ecee701849ed069e50006',
        name: 'Landlord (UK Property) business',
        businessId: '8a808152849ecee701849ed069e50006',
        propertyId: undefined,
        splitRate: undefined,
        fhl: undefined,
        type: BusinessSourceBasicInfoTypeEnum.LANDLORD,
        shared: undefined
    },
    {
        id: '8a808152849ecee701849ed069e50006_8a8081e184ed0aaf0184f0c8d9d002e2',
        name: 'Split Property 40',
        businessId: '8a808152849ecee701849ed069e50006',
        propertyId: '8a8081e184ed0aaf0184f0c8d9d002e2',
        splitRate: 0.4,
        fhl: undefined,
        type: BusinessSourceBasicInfoTypeEnum.PROPERTY,
        shared: undefined
    },
    {
        id: '8a808160853d705001853ee0a3ad0624',
        name: 'My Business',
        businessId: '8a808160853d705001853ee0a3ad0624',
        propertyId: undefined,
        splitRate: undefined,
        fhl: undefined,
        type: BusinessSourceBasicInfoTypeEnum.SELF_EMPLOYED,
        shared: undefined
    }
];

export const SUMMARY_ALL_DATA = {
    sum: 1800.0,
    summaries: [
        {
            id: '8a808152849ecee701849ed069e50006',
            title: 'Landlord (UK Property) business',
            type: 'BUSINESS',
            isSharedProperty: undefined,
            hmrcChanged: false,
            unsubmittedFields: false,
            amount: 1800.0,
            isIncome: true,
            businessSourceType: 'LANDLORD'
        },
        {
            id: '8a808152849ecee701849ed0288f0004',
            title: 'Landlord (Overseas Property) business',
            type: 'BUSINESS',
            isSharedProperty: undefined,
            hmrcChanged: false,
            unsubmittedFields: false,
            amount: 0,
            isIncome: true,
            businessSourceType: 'FOREIGN_LANDLORD'
        },
        {
            id: '8a808152849ecee701849ed028960005',
            title: 'Non-business',
            type: 'BUSINESS',
            isSharedProperty: undefined,
            hmrcChanged: false,
            unsubmittedFields: false,
            amount: undefined,
            isIncome: false,
            businessSourceType: 'NON_BUSINESS'
        }
    ]
};

export const SUMMARY_LANDLORD = {
    sum: 1800.0,
    content: [
        {
            id: '8a8081498538d9e5018539767d1801be',
            title: 'Split Property 40',
            totalAmount: 0.0,
            isFHL: false,
            assignedToProperty: true,
            businessSourceType: 'PROPERTY'
        },
        {
            id: '8a808152849ecee701849ed069e50006',
            title: 'Non allocated to any property',
            totalAmount: 1800.0,
            isFHL: undefined,
            assignedToProperty: false,
            businessSourceType: 'LANDLORD'
        }
    ]
};

export const SUMMARY_PROPERTY = { sum: 0, incomeSum: 0, expenseSum: 0, incomes: [], expenses: [] };

export const SUMMARY_NON_ALLOCATED = {
    sum: 1800.0,
    incomeSum: 1825.0,
    expenseSum: 25.0,
    incomes: [
        {
            amount: 1800.0,
            name: 'otherPropertyIncome',
            isIncome: true,
            apariCategorySummaries: [
                { amount: 1800.0, categoryId: '45', name: 'Other Property Income + Interest Received', type: 'NON_FHL_LL_INCOME' }
            ]
        },
        {
            amount: 25.0,
            name: 'rentIncome',
            isIncome: true,
            apariCategorySummaries: [{ amount: 25.0, categoryId: '47', name: 'Rental Income', type: 'NON_FHL_LL_INCOME' }]
        }
    ],
    expenses: [
        {
            amount: 25.0,
            name: 'premisesRunningCosts',
            isIncome: false,
            apariCategorySummaries: [
                { amount: 25.0, categoryId: '8', name: 'Office Costs, Light & Heat, Phone', type: 'NON_FHL_LL_EXPENSE' }
            ]
        }
    ]
};

export const TAX_YEARS_FOR_CURRENT = [
    {
        id: '8a808167853dc3d101853ee03c2c014a',
        taxEntityId: '8a808160853d705001853ede508d0615',
        year: '2021-22',
        startDate: '2021-04-06',
        endDate: '2022-04-05'
    },
    {
        id: '8a808167853dc3d101853ee03c32014b',
        taxEntityId: '8a808160853d705001853ede508d0615',
        year: '2022-23',
        startDate: '2022-04-06',
        endDate: '2023-04-05'
    }
];

export const TAX_YEARS_SA100_SIMPLE = [{ id: 4403, year: '2021-22', status: 'PENDING' }];

export const BANK_ACCOUNTS = [
    {
        createdBy: '8a808160853d705001853ede508d0614',
        createdAt: '2022-12-23T13:06:35',
        updatedBy: '8a808160853d705001853ede508d0614',
        updatedAt: '2022-12-23T13:06:35',
        id: 6027,
        version: 0,
        name: 'Default',
        bankName: undefined,
        identification: undefined,
        type: BankAccountTypeEnum.STANDARD,
        swift: undefined,
        taxEntityId: '8a808160853d705001853ede508d0615',
        externalId: undefined,
        manuallyCreated: undefined,
        transactions: undefined,
        yoltBankAccount: undefined,
        moneyHubBankAccount: undefined
    }
];

export const TAX_YEARS_WITH_STATUS = [
    {
        taxYearDto: {
            id: 4403,
            year: '2021-22',
            status: TaxYearDtoStatusEnum.PENDING,
            payClass2: true,
            payClass4: true,
            totalPaymentDue: 0.0,
            totalTaxEtcDue: 0.0,
            class2NICsDue: 0.0,
            class4NICsDue: 0.0,
            isClass2NicMandatory: false,
            getRentARoomExemptAmount: 0.0,
            submittedAt: undefined,
            keyInformation: {
                id: 4406,
                gatewayUserId: undefined,
                gatewayUserPassword: undefined,
                countryOfResidence: undefined,
                numberOfDaysSpentInUK: undefined,
                ukNational: undefined,
                version: 0,
                uniqueTaxPayerReference: undefined,
                taxIdentification: undefined
            },
            infoFromEmployments: [],
            pensionInformation: undefined,
            infoFromProperties: undefined,
            infoFromSelfEmployments: [],
            additionalInfo: undefined,
            otherDeductions: undefined,
            valid: true,
            version: 0,
            irMark: undefined
        },
        overviewStatus: TaxYearDataAndStatusDtoOverviewStatusEnum.WORK_IN_PROGRESS
    },
    {
        taxYearDto: {
            id: undefined,
            year: '2022-23',
            status: undefined,
            payClass2: true,
            payClass4: true,
            totalPaymentDue: undefined,
            totalTaxEtcDue: undefined,
            class2NICsDue: undefined,
            class4NICsDue: undefined,
            isClass2NicMandatory: undefined,
            getRentARoomExemptAmount: undefined,
            submittedAt: undefined,
            keyInformation: undefined,
            infoFromEmployments: [],
            pensionInformation: undefined,
            infoFromProperties: undefined,
            infoFromSelfEmployments: [],
            additionalInfo: undefined,
            otherDeductions: undefined,
            valid: undefined,
            version: undefined,
            irMark: undefined
        },
        overviewStatus: TaxYearDataAndStatusDtoOverviewStatusEnum.DISABLED
    }
];

export const TAX_YEAR_CURRENT = {
    id: 4403,
    year: '2021-22',
    status: TaxYearDtoStatusEnum.PENDING,
    payClass2: true,
    payClass4: true,
    totalPaymentDue: 0.0,
    totalTaxEtcDue: 0.0,
    class2NICsDue: 0.0,
    class4NICsDue: 0.0,
    isClass2NicMandatory: false,
    getRentARoomExemptAmount: 0.0,
    submittedAt: undefined,
    keyInformation: {
        id: 4406,
        gatewayUserId: undefined,
        gatewayUserPassword: undefined,
        countryOfResidence: undefined,
        numberOfDaysSpentInUK: undefined,
        ukNational: undefined,
        version: 0,
        uniqueTaxPayerReference: undefined,
        taxIdentification: undefined
    },
    infoFromEmployments: [
        {
            id: 4409,
            employersName: 'Semirasdasdasdas',
            payFromEmployment: undefined,
            employerPAYEReference: '123/A45678',
            taxTakenOffPay: undefined,
            otherExpensesAndCapitalAllowances: undefined,
            benefitsInKind: undefined,
            companyDirector: false,
            version: 0
        }
    ],
    pensionInformation: undefined,
    infoFromProperties: undefined,
    infoFromSelfEmployments: [],
    additionalInfo: undefined,
    otherDeductions: undefined,
    valid: true,
    version: 0,
    irMark: undefined
};
