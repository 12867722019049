import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    root: {},
    contentWrapperOverride: {
        paddingTop: 10,
        alignItems: 'center'
    },
    singUpButton: {
        textTransform: 'none'
    },
    mobileLink: {
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignSelf: 'flex-start',
        [theme.breakpoints.up('lg')]: {
            maxWidth: 288
        }
    }
}));
