import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { TILE_NAMES } from 'constants/index';
import { TAX_YEARS_WITH_STATUS } from 'constants/mockData';
import { TaxYearDataAndStatusDto, TaxYearDataAndStatusDtoOverviewStatusEnum, TaxYearDto } from '@apari/uk-jf-api';
import ErrorIcon from 'resources/images/Error.svg';
import NoStatusIcon from 'resources/images/NoStatus.svg';
import ReadyIcon from 'resources/images/SubmissionReady.svg';
import SubmittedIcon from 'resources/images/Submitted.svg';
import WIPIcon from 'resources/images/WIP.svg';
import { Localisation } from 'utils';
import { MockDataContext } from './MockDataContext';
import TABS from 'constants/tabs';

interface TilesContextInterface {
    selectedTiles: string[];
    setSelectedTiles: React.Dispatch<React.SetStateAction<string[]>>;
    switchSelectedTab: (tile: string, setTab: React.Dispatch<React.SetStateAction<number>>) => void;
    slider: any;
    getOverviewWithStatuses: () => Promise<void>;
    taxYears: TaxYearDataAndStatusDto[];
    getIcon: (type?: TaxYearDataAndStatusDtoOverviewStatusEnum) => string | undefined;
    selectedTaxYear: TaxYearDto;
    setSelectedTaxYear: React.Dispatch<React.SetStateAction<TaxYearDto>>;
    selectedTaxYearFormId?: string;
    setSelectedTaxYearFormId: React.Dispatch<React.SetStateAction<string | undefined>>;
    tiles: iTiles[];
    setTiles: React.Dispatch<React.SetStateAction<iTiles[]>>;
    getCurrentTaxYear: () => Promise<void>;
    getFormVisibility: (taxYear: string) => Promise<void>;
    tempCopy: string[];
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    statusesLoading: boolean;
}

const TilesContext = createContext({} as TilesContextInterface);

export interface iTiles {
    name: string;
    active: boolean;
    tooltip: string;
    hoverTooltip?: string;
}

const tilesData: iTiles[] = [
    {
        name: Localisation.localize('TILE.NAME.PROFILE_AND_RESIDENCY'),
        active: true,
        tooltip: Localisation.localize('TILE.TOOLTIP.PROFILE_AND_RESIDENCY')
    },
    {
        name: Localisation.localize('TILE.NAME.INCOME_FROM_EMPLOYMENT'),
        active: false,
        tooltip: Localisation.localize('TILE.TOOLTIP.INCOME_FROM_EMPLOYMENT')
    },
    {
        name: Localisation.localize('TILE.NAME.INCOME_FROM_PROPERTY'),
        active: false,
        tooltip: Localisation.localize('TILE.TOOLTIP.INCOME_FROM_PROPERTY')
    },
    {
        name: Localisation.localize('TILE.NAME.INCOME_FROM_SELF_EMPLOYMENT'),
        active: false,
        tooltip: Localisation.localize('TILE.TOOLTIP.INCOME_FROM_SELF_EMPLOYMENT')
    },
    {
        name: Localisation.localize('TILE.NAME.PENSION_INCOME'),
        active: false,
        tooltip: Localisation.localize('TILE.TOOLTIP.PENSION_INCOME')
    },
    { name: Localisation.localize('TILE.NAME.CAPITAL_GAINS'), active: false, tooltip: Localisation.localize('TILE.TOOLTIP.CAPITAL_GAINS') },
    { name: Localisation.localize('TILE.NAME.OTHER_INCOME'), active: false, tooltip: Localisation.localize('TILE.TOOLTIP.OTHER_INCOME') },
    { name: Localisation.localize('TILE.NAME.CHILD_BENEFIT'), active: false, tooltip: Localisation.localize('TILE.TOOLTIP.CHILD_BENEFIT') },
    {
        name: Localisation.localize('TILE.NAME.OTHER_DEDUCTIONS'),
        active: false,
        tooltip: Localisation.localize('TILE.TOOLTIP.OTHER_DEDUCTIONS')
    },
    {
        name: Localisation.localize('TILE.NAME.TAX_RELIEF_ON_INVESTMENTS'),
        active: false,
        tooltip: Localisation.localize('TILE.TOOLTIP.TAX_RELIEF_ON_INVESTMENTS')
    },
    {
        name: Localisation.localize('TILE.NAME.STUDENT_LOAN'),
        active: false,
        tooltip: Localisation.localize('TILE.TOOLTIP.STUDENT_LOAN')
    },
    {
        name: Localisation.localize('TILE.NAME.SA100_SUBMISSION'),
        active: true,
        tooltip: Localisation.localize('TILE.TOOLTIP.SA100_SUBMISSION')
    }
];

export const TilesProvider: React.FC = ({ children }) => {
    const { mockTiles } = useContext(MockDataContext);

    const yearFromPath = location.pathname.split('/')[2];
    const slider = useRef<any>();

    const [tiles, setTiles] = useState<iTiles[]>(tilesData);
    const [selectedTiles, setSelectedTiles] = useState<string[]>([TILE_NAMES.PROFILE_AND_RESIDENCY, TILE_NAMES.SA100_SUBMISSION]);
    const [taxYears, setTaxYears] = useState<TaxYearDataAndStatusDto[]>([]);
    const [selectedTaxYear, setSelectedTaxYear] = useState<TaxYearDto>({ year: yearFromPath ?? undefined });
    const [selectedTaxYearFormId, setSelectedTaxYearFormId] = useState<string | undefined>(undefined);
    const [tempCopy, setTempCopy] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [statusesLoading, setStatusesLoading] = useState(true);

    useEffect(() => {
        if (mockTiles?.length) pushToSelectedTilesArr();
    }, [mockTiles]);

    const pushToSelectedTilesArr = () => {
        const x: string[] = [];

        mockTiles.map(tile => {
            if (tile.active) {
                x.push(tile.name);
            }
        });

        setSelectedTiles(x);
    };

    const getCurrentTaxYear = async () => {
        try {
            const response = { data: '2021-22' };
            const { data } = response;
            setSelectedTaxYear({ year: data });
        } catch (error) {
            console.error(error);
        }
    };

    const switchSelectedTab = (tile: string, setTab: React.Dispatch<React.SetStateAction<number>>) => {
        switch (tile) {
            case TILE_NAMES.PROFILE_AND_RESIDENCY:
                setTab(TABS.PROFILE_AND_RESIDENCY);
                break;
            case TILE_NAMES.INCOME_FROM_EMPLOYMENT:
                setTab(TABS.INCOME_FROM_EMPLOYMENT);
                break;
            case TILE_NAMES.INCOME_FROM_PROPERTY:
                setTab(TABS.INCOME_FROM_PROPERTY);
                break;
            case TILE_NAMES.INCOME_FROM_SELF_EMPLOYMENT:
                setTab(TABS.INCOME_FROM_SELF_EMPLOYMENT);
                break;
            case TILE_NAMES.PENSION_INCOME:
                setTab(TABS.PENSION_INCOME);
                break;
            case TILE_NAMES.CAPITAL_GAINS:
                setTab(TABS.CAPITAL_GAINS);
                break;
            case TILE_NAMES.OTHER_INCOME:
                setTab(TABS.OTHER_INCOME);
                break;
            case TILE_NAMES.CHILD_BENEFIT:
                setTab(TABS.CHILD_BENEFIT);
                break;
            case TILE_NAMES.OTHER_DEDUCTIONS:
                setTab(TABS.OTHER_DEDUCTIONS);
                break;
            case TILE_NAMES.TAX_RELIEF_ON_INVESTMENTS:
                setTab(TABS.TAX_RELIEF_ON_INVESTMENTS);
                break;
            case TILE_NAMES.STUDENT_LOAN:
                setTab(TABS.STUDENT_LOAN);
                break;
            case TILE_NAMES.SA100_SUBMISSION:
                setTab(TABS.SA100_SUBMISSION);
                break;
        }
    };

    const getOverviewWithStatuses = async () => {
        try {
            const res = { data: TAX_YEARS_WITH_STATUS };
            setTaxYears(res.data);
            setStatusesLoading(false);
        } catch (e) {
            console.error(e);
            setStatusesLoading(false);
        }
    };

    const getIcon = (type?: TaxYearDataAndStatusDtoOverviewStatusEnum) => {
        switch (type) {
            case TaxYearDataAndStatusDtoOverviewStatusEnum.NO_STATUS:
                return NoStatusIcon;
            case TaxYearDataAndStatusDtoOverviewStatusEnum.DISABLED:
                return NoStatusIcon;
            case TaxYearDataAndStatusDtoOverviewStatusEnum.READY_FOR_SUBMISSION:
                return ReadyIcon;
            case TaxYearDataAndStatusDtoOverviewStatusEnum.WORK_IN_PROGRESS:
                return WIPIcon;
            case TaxYearDataAndStatusDtoOverviewStatusEnum.ERROR:
                return ErrorIcon;
            case TaxYearDataAndStatusDtoOverviewStatusEnum.SUBMITTED:
                return SubmittedIcon;
        }
    };

    const getFormVisibility = async () => {
        try {
            const temp: string[] = [];
            const tilesForMap = mockTiles?.length ? mockTiles : tiles;

            tilesForMap.forEach(updatedTiles => {
                if (updatedTiles.active && !tempCopy.includes(updatedTiles.name)) {
                    setTempCopy(prev => [...prev, updatedTiles.name]);
                } else if (!updatedTiles.active && tempCopy.includes(updatedTiles.name)) {
                    temp.push(updatedTiles.name);
                    setTempCopy(tempCopy.filter(prev => !temp.includes(prev)));
                }
            });

            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    return (
        <TilesContext.Provider
            value={{
                selectedTiles,
                setSelectedTiles,
                switchSelectedTab,
                slider,
                getOverviewWithStatuses,
                taxYears,
                getIcon,
                selectedTaxYear,
                setSelectedTaxYear,
                selectedTaxYearFormId,
                setSelectedTaxYearFormId,
                tiles: mockTiles?.length ? mockTiles : tiles,
                setTiles,
                getCurrentTaxYear,
                getFormVisibility,
                tempCopy,
                loading,
                setLoading,
                statusesLoading
            }}
        >
            {children}
        </TilesContext.Provider>
    );
};

export default TilesContext;
