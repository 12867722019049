import React from 'react';
import globalStyles from 'styles/globalStyles';
import { ApariButton, ApariDialog } from 'components';
import clsx from 'clsx';
import { Localisation } from 'utils';
import styles from './styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface Props {
    open: boolean;
    buttonText: string;
    cancelText?: string;
    infoText: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const ApariInfoDialog: React.FC<Props> = ({
    open,
    infoText,
    buttonText,
    onConfirm,
    onCancel,
    cancelText = Localisation.localize('general.cancel')
}) => {
    const globalClasses = globalStyles();
    const classes = styles();

    return (
        <ApariDialog open={open}>
            <div className={clsx(classes.dialog)}>
                <div className={clsx(globalClasses.flexRowStart, globalClasses.marginBottomAuto, globalClasses.fullWidth)}>
                    <InfoOutlinedIcon className={clsx(classes.warningIcon)} />
                    <span className={clsx(globalClasses.paddingLeft16, globalClasses.font11weight400Dark)}>{infoText}</span>
                </div>
                <div className={clsx(globalClasses.flexRowCenter)}>
                    <ApariButton
                        color="primary"
                        onClick={onCancel}
                        variant="outlined"
                        className={clsx(globalClasses.marginRight10, classes.dialogButton)}
                    >
                        <span className={clsx(globalClasses.font15weight700Purple)}>{cancelText}</span>
                    </ApariButton>
                    <ApariButton color="primary" onClick={onConfirm} variant="contained" className={clsx(classes.dialogButton)}>
                        <span className={clsx(globalClasses.font15weight600White)}>{buttonText}</span>
                    </ApariButton>
                </div>
            </div>
        </ApariDialog>
    );
};

export default ApariInfoDialog;
