import { makeStyles, Theme } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';

type Props = {
    collapsable?: boolean;
    multiRows?: boolean;
};
export default makeStyles<Theme, Props>(() => ({
    tableCell: ({ multiRows }) => ({
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        padding: 10,
        borderRight: `1px solid ${COLORS.apariBlack20}`,
        borderLeft: `1px solid ${COLORS.apariBlack20}`,
        borderTop: multiRows ? 0 : `1px solid ${COLORS.apariBlack20}`,
        borderBottom: multiRows ? 0 : `1px solid ${COLORS.apariBlack20}`,
        '&:first-child': {
            borderTop: `1px solid ${COLORS.apariBlack20}`
        },
        '&:last-child': {
            borderBottom: `1px solid ${COLORS.apariBlack20}`
        }
    }),
    cellValueName: ({ collapsable }) => ({
        fontSize: collapsable ? 11 : 13,
        fontWeight: collapsable ? 500 : 400,
        fontFamily: FONTS.PRIMARY_FONT,
        color: COLORS.apariBlack,
        whiteSpace: 'nowrap'
    }),
    cellValueAmount: ({ collapsable }) => ({
        fontSize: 11,
        fontWeight: collapsable ? 400 : 700,
        fontFamily: FONTS.PRIMARY_FONT,
        color: COLORS.apariBlack,
        whiteSpace: 'nowrap'
    })
}));
