export type MTDField = {
    name: string;
    label: string;
    value: any;
    HMRCValue: any;
    info?: string;
    type: string;
};

export enum FORM_TYPES {
    ALLOWANCE = 'ALLOWANCE',
    ADJUSTMENTS = 'ADJUSTMENTS'
}

export enum QUARTERLY_PREVIEW_CATEGORY_TYPE {
    INCOME = 'INCOME',
    EXPENSES = 'EXPENSES',
    FHL_INCOME = 'FHL_INCOME',
    FHL_EXPENSES = 'FHL_EXPENSES',
    NON_FHL_INCOME = 'NON_FHL_INCOME',
    NON_FHL_EXPENSES = 'NON_FHL_EXPENSES'
}

export enum ANNUAL_SUMMARY_CATEGORY_TYPE {
    ALLOWANCES = 'allowances',
    ADJUSTMENTS = 'adjustments',
    NON_FINANCIALS = 'nonFinancials',
    OTHER = 'other',

    FHL_ALLOWANCES = 'userValueFHL.allowances',
    FHL_ADJUSTMENTS = 'userValueFHL.adjustments',
    FHL_OTHER = 'userValueFHL.other',

    NON_FHL_ALLOWANCES = 'userValueNonFHL.allowances',
    NON_FHL_ADJUSTMENTS = 'userValueNonFHL.adjustments',
    NON_FHL_OTHER = 'userValueNonFHL.other',

    UK_NON_FHL_PROPERTY_ADJUSTMENTS = 'ukNonFhlProperty.adjustments',
    UK_NON_FHL_PROPERTY_ALLOWANCES = 'ukNonFhlProperty.allowances',
    UK_FHL_PROPERTY_ADJUSTMENTS = 'ukFhlProperty.adjustments',
    UK_FHL_PROPERTY_ALLOWANCES = 'ukFhlProperty.allowances',
    STRUCTURE_BUILDING_ALLOWANCE = 'structuredBuildingAllowance',
    ENHANCED_STRUCTURE_BUILDING_ALLOWANCE = 'enhancedStructuredBuildingAllowance'
}

export type CategoryField = {
    name?: string;
    type?: string;
    options?: any[];
    userValue?: any;
    hmrcValue?: any;
};

export type GroupedCategories = {
    categoryType: QUARTERLY_PREVIEW_CATEGORY_TYPE;
    emptyFields: boolean;
    fields: CategoryField[];
    countryName?: string;
};

export type GroupedCategoriesAnnualSummary = {
    categoryType: ANNUAL_SUMMARY_CATEGORY_TYPE;
    emptyFields: boolean;
    fields: CategoryField[];
    countryName?: string;
    countryCode?: string;
};

export type MTDFormState = {
    title: string;
    type?: FORM_TYPES;
    fields: MTDField[];
};
