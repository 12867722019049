import React, { useContext } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { ApariButton, ApariDialog } from 'components';
import { FinishModalContext } from 'context/FinishModalContext';
import ModalDialogs from 'constants/modalDialogs';
import { GlobalModalDialogs } from 'constants/globalSocketModalDialogs';
import { Localisation } from 'utils';
import { ModalTypes } from 'types';
import globalStyles from 'styles/globalStyles';
import styles from './styles';
import Arrow from 'resources/images/Arrow.png';

type Props = {
    dialog: ModalDialogs | GlobalModalDialogs;
};

const ApariFinishModal: React.FC<Props> = ({ dialog }) => {
    const { modal, toggle, buttons, closeModal } = useContext(FinishModalContext);
    const classes = styles();
    const globalClasses = globalStyles();
    const currentModal = modal[dialog];

    const handleOK = () => {
        currentModal?.action && currentModal.action();
        toggle(dialog);
    };

    const renderRegularContent = () => {
        return (
            <div className={classes.regularContentWrapper}>
                {currentModal?.closeButton && (
                    <IconButton
                        aria-label="close"
                        size="medium"
                        edge="end"
                        onClick={() => closeModal(dialog)}
                        color="default"
                        className={classes.closeButton}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
                <div className={clsx(globalClasses.horizontalPadding16, classes.contentContainer)}>
                    <div className={clsx(globalClasses.font26weight400Dark, globalClasses.marginTop24, globalClasses.marginBottom8)}>
                        {currentModal?.title}
                    </div>
                    <div className={clsx(globalClasses.font13weight400Dark, globalClasses.marginBottom32, globalClasses.preWrap)}>
                        {currentModal?.subtitle}
                    </div>
                    <div className={classes.buttonsContainer}>
                        {!isEmpty(buttons) ? (
                            buttons?.map((button, index) => (
                                <ApariButton key={index} {...button} className={globalClasses.marginBottom8} fullWidth>
                                    {button.text}
                                </ApariButton>
                            ))
                        ) : (
                            <ApariButton variant="contained" color="primary" onClick={handleOK} fullWidth>
                                {currentModal?.buttonText || Localisation.localize('general.ok')}
                            </ApariButton>
                        )}
                    </div>
                </div>
                <div className={clsx(globalClasses.flexRowCenter, classes.logo)}>
                    <img alt="login" src={Arrow} height={248} />
                </div>
            </div>
        );
    };

    return (
        <ApariDialog
            open={currentModal?.open || false}
            maxWidth="lg"
            classes={{ paper: currentModal?.type === ModalTypes.REGULAR ? classes.dialogPaper : undefined }}
            onClose={currentModal?.closeButton ? () => closeModal(dialog) : undefined}
        >
            {currentModal?.type === ModalTypes.REGULAR ? (
                renderRegularContent()
            ) : (
                <div className={classes.contentWrapper}>
                    <div className={globalClasses.flexRowCenter}>
                        <img alt="login" src={Arrow} height={120} />
                    </div>
                    <div className={clsx(classes.title, globalClasses.marginTop24, globalClasses.marginBottom8)}>{currentModal?.title}</div>
                    <div
                        className={clsx(
                            globalClasses.font13weight400Dark,
                            globalClasses.marginBottom32,
                            globalClasses.textAlignCenter,
                            globalClasses.preWrap
                        )}
                    >
                        {currentModal?.subtitle}
                    </div>
                    <ApariButton variant="contained" color="primary" onClick={handleOK}>
                        {currentModal?.buttonText || Localisation.localize('general.ok')}
                    </ApariButton>
                </div>
            )}
        </ApariDialog>
    );
};

export default ApariFinishModal;
