import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    positionAbsolute: {
        position: 'absolute',
        top: 10
    },
    partnerLogo: {
        width: 60,
        height: 'auto',
        marginRight: 5
    },
    logoContainer: {
        borderRight: '1px solid #000',
        marginRight: 5,
        display: 'flex',
        alignItems: 'center',
        height: 40
    },
    rowCard: {
        position: 'relative',
        background: '#FFFFFF',
        boxShadow: '2px 2px 10px rgb(0 0 0 / 5%)',
        borderRadius: '2px',
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'row',
        height: 80
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 0 12px 12px'
    },
    cardContentWithCheckbox: {
        paddingLeft: 0
    },
    menuButton: {
        color: COLORS.apariDarkSilver
    },
    cardBar: {
        height: '100%',
        backgroundColor: COLORS.apariLightSilver,
        width: 2
    },
    cardBarUnsubmitted: {
        backgroundColor: COLORS.apariLightPurple
    },
    rowCardInfo: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    rowCardLeft: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    rowCardInfoDate: {
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '16px',
        color: COLORS.apariBlack
    },
    rowCardInfoSender: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 12
    },
    rowCardInfoSenderTitle: {
        fontWeight: 600,
        fontSize: '13px',
        color: COLORS.apariBlack
    },
    rowCardInfoSenderText: {
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '11px',
        color: COLORS.apariBlack60
    },
    rowCardInfoCategory: {
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '11px',
        padding: '2px 4px 1px 2px',
        marginBottom: 5,
        color: COLORS.apariWhite,
        backgroundColor: COLORS.apariBlack20
    },
    rowAmount: {
        marginRight: 10
    },
    rowDisabled: {
        opacity: 0.5,
        pointerEvents: 'none'
    },
    rowCardInfoStatus: {
        fontWeight: 400,
        marginRight: 16,
        fontSize: '10px',
        lineHeight: '11px',
        color: COLORS.apariBlack60
    },
    transactionLabel: {
        fontSize: 10,
        fontWeight: 400,
        color: COLORS.apariWhite,
        borderRadius: 2,
        padding: '0 4px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'auto',
        maxWidth: '50%'
    },
    labelContainer: {
        width: '85%'
    },
    incomeTypeLabel: {
        background: COLORS.apariPurple,
        marginRight: 8
    },
    bankAccountLabel: {
        background: COLORS.apariLightPurple,
        marginRight: 8
    },
    categoryLabel: {
        background: COLORS.gray3
    },
    labelLegendItem: {
        fontSize: 10,
        fontWeight: 400,
        marginRight: 8,
        opacity: 0.6
    },
    incomeTypeBullet: {
        color: COLORS.apariPurple,
        marginRight: 4
    },
    bankAccountBullet: {
        color: COLORS.apariLightPurple,
        marginRight: 4
    },
    categoryBullet: {
        color: COLORS.gray3,
        marginRight: 4
    },
    additionalInfoIcon: {
        color: COLORS.apariBlack80,
        fontSize: 16,
        transform: 'rotate(45deg)'
    },
    splitIcon: {
        color: COLORS.apariBlack80,
        fontSize: 16,
        transform: 'rotate(90deg)'
    }
}));
