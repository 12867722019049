import React, { useState } from 'react';
import clsx from 'clsx';
import { Portal } from 'react-portal';
import { SwipeableDrawer } from '@material-ui/core';
import { BottomDrawerProps } from './types';
import styles from './styles';

const BottomDrawer: React.FC<BottomDrawerProps> = ({ onDrawerOpen, onDrawerClose, children, ...rest }) => {
    const [open, setOpen] = useState(false);

    const classes = styles();

    const onClose = () => {
        onDrawerClose && onDrawerClose();
        setOpen(false);
    };

    const onOpen = () => {
        onDrawerOpen && onDrawerOpen();
        setOpen(true);
    };

    return (
        <Portal>
            <div className={clsx(classes.root)}>
                <div onClick={() => setOpen(!open)} className={clsx(classes.toggleButtonWrapper)}>
                    <div className={clsx(classes.toggleButton)} />
                </div>
                <SwipeableDrawer
                    className={clsx(classes.drawer)}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open
                        })
                    }}
                    open={open}
                    disableSwipeToOpen={false}
                    disableBackdropTransition={true}
                    anchor="bottom"
                    swipeAreaWidth={35}
                    onClose={onClose}
                    onOpen={onOpen}
                    {...rest}
                >
                    <div onClick={() => setOpen(!open)} className={clsx(classes.toggleButtonWrapper)}>
                        <div className={clsx(classes.toggleButton)} />
                    </div>
                    {children}
                </SwipeableDrawer>
            </div>
        </Portal>
    );
};

export default BottomDrawer;
