import React from 'react';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';
import styles from './styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

type ExpandableItemProps = {
    title: string;
    removeDivider?: boolean;
};

const ExpandableItem: React.FC<ExpandableItemProps> = ({ title, children, removeDivider = false }) => {
    const globalClasses = globalStyles();
    const classes = styles();

    return (
        <React.Fragment>
            {removeDivider ? null : <div className={clsx(classes.divider)} />}
            <Accordion elevation={0} classes={{ expanded: classes.expanded }} className={globalClasses.fullWidth}>
                <AccordionSummary
                    expandIcon={<KeyboardArrowDownIcon />}
                    aria-controls={`panel${title}-content`}
                    id={`panel${title}-header`}
                    classes={{ expanded: classes.expanded }}
                    className={globalClasses.noPadding}
                >
                    <p className={clsx([globalClasses.font18weight400, globalClasses.noMargin])}>{title}</p>
                </AccordionSummary>
                <AccordionDetails className={clsx([classes.details, globalClasses.noPadding, globalClasses.font13weight400Dark])}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    );
};

export default ExpandableItem;
