import { makeStyles, Theme } from '@material-ui/core';
import { FONTS } from 'constants/index';

export default makeStyles<Theme>(theme => ({
    root: {},
    contentWrapperOverride: {
        paddingTop: 88,
        [theme.breakpoints.down('md')]: {
            alignSelf: 'center',
            paddingTop: 10,
            paddingBottom: 40,
            paddingRight: 13
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 50,
            paddingRight: 50
        },
        [theme.breakpoints.up(2100)]: {
            marginTop: '45%',
            marginBottom: 'auto'
        }
    },
    passwordInput: {
        width: '100%',
        maxWidth: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: 310
        }
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 'auto',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignSelf: 'flex-start',
        fontFamily: FONTS.QUATERNARY_FONT,
        [theme.breakpoints.up('md')]: {
            maxWidth: 288
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between'
        }
    },
    iconSize: {
        '& svg': {
            fontSize: 20
        }
    }
}));
