import { COLORS } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: 40,
        right: 40,
        [theme.breakpoints.down('md')]: {
            bottom: 20,
            right: 20
        },
        [theme.breakpoints.down('sm')]: {
            bottom: 15,
            right: 15
        }
    },
    fab: {
        background: COLORS.apariWhite,
        '&:hover': {
            background: COLORS.gray7
        }
    },
    openIcon: {
        transform: 'rotate(60deg)'
    },
    staticTooltipLabel: {
        whiteSpace: 'nowrap'
    }
}));
