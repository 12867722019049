import { Localisation } from 'utils';
import * as yup from 'yup';

const validationSchema = yup.object({
    plainPassword: yup
        .string()
        .max(255, Localisation.localize('formErrors.255charactersMaximum'))
        .required(Localisation.localize('formErrors.fieldIsRequired')),
    confirmPassword: yup.string().test('passwords-match', Localisation.localize('formErrors.passwordsDontMatch'), function (value) {
        return this.parent.plainPassword === value;
    })
});

export default validationSchema;
