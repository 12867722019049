import React from 'react';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import { useMediaQuery, useTheme, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import {
    CurrentUserDto,
    PaymentStatusDto,
    PaymentStatusDtoStatusEnum,
    SubscriptionPackage,
    SubscriptionPackageTypeEnum
} from '@apari/core-api';
import { ApariButton } from 'components/index';
import { PaymentIcon } from 'resources/icons';

import globalStyles from 'styles/globalStyles';
import { EventCategory } from 'types/eventTracking';
import { Localisation, NumberServices } from 'utils';
import styles from './styles';

type SubscriptionCardClientProps = {
    subscriptionPackage: SubscriptionPackage;
    onSubscribe: (subscriptionPackage: SubscriptionPackage, payImmediately?: boolean) => void;
    paymentStatus?: PaymentStatusDto;
    user?: CurrentUserDto;
};

type StandardPackageFeaturesProps = {
    isMobile?: boolean;
};

const FeatureItem: React.FC = ({ children }) => {
    const globalClasses = globalStyles();
    const classes = styles();

    return (
        <Grid item xs={12} md={4} className={clsx(globalClasses.flexRowStart)}>
            <CheckIcon className={clsx(classes.featuresIcon)} />
            <p className={clsx(globalClasses.font11weight400Dark, globalClasses.noVerticalMargin)}>{children}</p>
        </Grid>
    );
};

const StandardPackageFeatures: React.FC<StandardPackageFeaturesProps> = ({ isMobile }) => {
    return (
        <Grid container spacing={isMobile ? 4 : 2}>
            <FeatureItem>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STANDARD_FEATURES.DIGITAL_RECORD_KEEPING')}
            </FeatureItem>
            <FeatureItem>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STANDARD_FEATURES.JOINTLY_OWNED_PROPERTIES')}
            </FeatureItem>
            <FeatureItem>{Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STANDARD_FEATURES.MTD_SOLUTION')}</FeatureItem>
            <FeatureItem>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STANDARD_FEATURES.SNAP_STORE_RECEIPTS')}
            </FeatureItem>
            <FeatureItem>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PREMIUM_FEATURES.CONNECT_BANK_ACCOUNT_SECURELY')}
            </FeatureItem>
            <FeatureItem>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STANDARD_FEATURES.REAL_TIME_UPDATES_FROM_ACCOUNTANT')}
            </FeatureItem>
        </Grid>
    );
};

const SubscriptionCardClient: React.FC<SubscriptionCardClientProps> = ({ subscriptionPackage, paymentStatus, onSubscribe, user }) => {
    const isBooked =
        paymentStatus &&
        paymentStatus.status !== PaymentStatusDtoStatusEnum.INACTIVE &&
        subscriptionPackage.plan === paymentStatus.subscriptionType;

    const discountPercentage =
        subscriptionPackage && subscriptionPackage.defaultAmount
            ? 100 - Math.round((subscriptionPackage.amount / subscriptionPackage.defaultAmount) * 100)
            : null;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const globalClasses = globalStyles();
    const classes = styles();

    const renderPrice = () => {
        const interval =
            subscriptionPackage.type === SubscriptionPackageTypeEnum.MONTHLY
                ? Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PER_MONTH')
                : Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PER_YEAR');

        if (paymentStatus?.staticSubscription) {
            return (
                <p
                    className={clsx(
                        globalClasses.font13weight400Dark,
                        globalClasses.marginTop10,
                        globalClasses.marginBottom8,
                        classes.priceParagraph
                    )}
                >
                    <PaymentIcon fill={theme.palette.primary.main} className={clsx(globalClasses.marginRight8)} />
                    {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.ACCESS_PAID_BY', {
                        accountantBusinessName: user?.agentAgencyName
                    })}
                </p>
            );
        } else {
            return (
                <p
                    className={clsx(
                        globalClasses.font13weight400Dark,
                        globalClasses.marginTop10,
                        globalClasses.marginBottom8,
                        globalClasses.preWrap,
                        classes.priceParagraph
                    )}
                >
                    <Trans
                        i18nKey="DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PERCENT_DISCOUNT"
                        values={{
                            fullPrice: NumberServices.format(subscriptionPackage.defaultAmount!),
                            discountPrice: NumberServices.format(subscriptionPackage.amount!),
                            interval,
                            accountantBusinessName: user?.agentAgencyName,
                            newLine: isMobile ? '\n' : '',
                            discountPercentage: discountPercentage
                        }}
                    >
                        <span className={clsx(globalClasses.textLineThrough)}>£11.99</span>
                        <span className={clsx(globalClasses.font13weight700Dark)}>£5.99</span> per month
                        <span className={clsx(globalClasses.font11weight400Dark)}>(50% discount from name)</span>
                    </Trans>
                </p>
            );
        }
    };

    const getActionText = () => {
        if (!paymentStatus?.staticSubscription) {
            if (paymentStatus?.status === PaymentStatusDtoStatusEnum.INACTIVE) {
                return Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.MAKE_PAYMENT');
            } else {
                return Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.MANAGE_PLANS');
            }
        }
    };

    const getStatus = () => {
        if (isBooked && paymentStatus) {
            switch (paymentStatus.status) {
                case PaymentStatusDtoStatusEnum.ACTIVE:
                    return (
                        <div className={clsx(classes.activeIndicator)}>
                            <span>{Localisation.localize('general.ACTIVE')}</span>
                            <CheckIcon className={clsx(classes.activeIcon)} />
                        </div>
                    );
                case PaymentStatusDtoStatusEnum.INACTIVE:
                    return null;
                default:
                    return null;
            }
        }
    };

    const handleAction = (payImmediately?: boolean) => {
        onSubscribe(subscriptionPackage, payImmediately);
    };

    return (
        <div className={clsx(classes.root)}>
            <div className={clsx(classes.card)}>
                <div className={clsx(classes.cardHeader)}>
                    <div className={clsx(globalClasses.flexRowSpaceBetween, classes.cardTitle)}>
                        <div className={clsx(globalClasses.flexRowStart, globalClasses.fullWidth)}>
                            <span className={clsx(classes.cardTitleFont)}>
                                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.CLIENT')}
                            </span>
                            {getStatus()}
                        </div>
                        {!paymentStatus?.staticSubscription && !isMobile && (
                            <ApariButton
                                fullWidth
                                onClick={() => handleAction()}
                                className={clsx(classes.actionButton)}
                                size="small"
                                color="primary"
                                trackingCategory={EventCategory.PAYMENT}
                                trackingName={getActionText()}
                                variant="contained"
                            >
                                {getActionText()}
                            </ApariButton>
                        )}
                    </div>
                    <div>
                        {renderPrice()}
                        {!paymentStatus?.staticSubscription && (
                            <p className={clsx(globalClasses.font11weight400Dark, globalClasses.noVerticalMargin)}>
                                <Trans i18nKey="DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.ALL_FEES_ARE_DEDUCTIBLE">
                                    All fees are <strong>tax deductible</strong> and include VAT
                                </Trans>
                            </p>
                        )}
                    </div>
                </div>
                <div className={clsx(classes.featuresPart)}>
                    <p className={clsx(globalClasses.font13weight600Dark, classes.featuresFont)}>
                        {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.FEATURES_SUPPORTED')}
                    </p>
                    <StandardPackageFeatures isMobile={isMobile} />
                </div>
                {!paymentStatus?.staticSubscription && isMobile && (
                    <div className={clsx(globalClasses.marginTopAuto, globalClasses.paddingTop20)}>
                        <ApariButton
                            fullWidth
                            onClick={() => handleAction()}
                            className={clsx(classes.actionButton)}
                            size="small"
                            color="primary"
                            trackingCategory={EventCategory.PAYMENT}
                            trackingName={getActionText()}
                            variant="contained"
                        >
                            {getActionText()}
                        </ApariButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SubscriptionCardClient;
