import { useState } from 'react';

const useButtonDisabling = (timeInterval?: number) => {
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const delayButtonActivity = () => {
        setButtonDisabled(true);
        setTimeout(() => {
            setButtonDisabled(false);
        }, timeInterval || 1000);
    };

    return { delayButtonActivity, buttonDisabled };
};

export default useButtonDisabling;
