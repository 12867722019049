import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    container: {
        width: '100%',
        height: '61px',
        backgroundColor: COLORS.apariWhite,
        borderRadius: '2px',
        border: '1px solid #C4C4C4'
    },
    leftBar: {
        height: '100%',
        width: '2px',
        backgroundColor: '#000000'
    },
    dateContainer: {
        marginLeft: '12px',
        marginRight: '12px',
        lineHeight: '16px'
    },
    infoWrapper: {
        flex: 1,
        height: '100%'
    },
    amountContainer: {
        marginRight: '16.5px'
    }
}));
