import React, { useContext, useEffect, useState } from 'react';
import { FormikContextType, getIn } from 'formik';
import clsx from 'clsx';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import { ExpandMore } from '@material-ui/icons';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
    BusinessSourceBasicInfo,
    BusinessSourceBasicInfoTypeEnum,
    PaymentStatusDtoSubscriptionTypeEnum,
    BusinessTypeEnum
} from '@apari/core-api';
import { TransactionCategory, TransactionCategoryApi, TransactionCategoryCategoryTypeEnum, TransactionDto } from '@apari/banking-api';
import { DocumentDto, StorageControllerApi } from '@apari/storage-api';
import { AuthenticationContext } from 'context/AuthenticationContext';
import { AppContext } from 'context/AppContext';
import IncomeTypesContext from 'context/IncomeTypesContext';
import {
    ApAmountField,
    ApariButton,
    ApariDropzone,
    ApariFilePreview,
    ApariTextSwitch,
    ApSelectField,
    ApTextField,
    LoadingPlaceholder
} from 'components';
import { PreviewState } from 'types/files';
import { formatCategoryType, formatOptions, GlobalServices, Localisation, NumberServices } from 'utils';
import { createRedirectableOption } from 'utils/selectOptionsExtender';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

type SplitTransaction = TransactionDto & {
    tempId?: string;
    documents?: DocumentDto[];
    deleted?: boolean;
};

type SplitTransactionSectionProps = {
    parentTransaction?: TransactionDto;
    splitTransaction: SplitTransaction;
    count: number;
    onDelete: (splitTransaction: SplitTransaction) => void;
    onAmountChange: () => void;
    onRestore: (splitTransaction: SplitTransaction) => void;
    formik: FormikContextType<any>;
    submitInProgress: boolean;
};

const storageControllerApi = new StorageControllerApi();
const transactionCategoryApi = new TransactionCategoryApi();

const SplitTransactionSection: React.FC<SplitTransactionSectionProps> = ({
    splitTransaction,
    count,
    onDelete,
    onAmountChange,
    formik,
    submitInProgress,
    onRestore
}) => {
    const [incomeTypesOptions, setIncomeTypeOptions] = useState<BusinessSourceBasicInfo[]>([]);

    const [categories, setCategories] = useState<TransactionCategory[]>([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showAllFields, setShowAllFields] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previousCategoryType, setPreviousCategoryType] = useState<TransactionCategoryCategoryTypeEnum>();
    const [selectedType, setSelectedType] = useState(
        GlobalServices.isDefined(splitTransaction) ? (splitTransaction.isIncome ? 'INCOME' : 'EXPENSE') : 'INCOME'
    );
    const filterTypes = ['INCOME', 'EXPENSE'];

    const { doesUserHaveLowestRequiredPackage } = useContext(AuthenticationContext);
    const { showLoadingBar, hideLoadingBar } = useContext(AppContext);
    const { getIncomeTypes } = useContext(IncomeTypesContext);

    const globalClasses = globalStyles();
    const classes = styles();

    const transactionId: string | undefined = splitTransaction.id ?? splitTransaction.tempId;

    useEffect(() => {
        async function getInitialData() {
            setLoading(true);
            try {
                const options = await getIncomeTypes();
                const business = options.find(a => a.id === getIn(formik.values, transactionId + '.businessId'));
                await getCategories(business);
                setIncomeTypeOptions(options.filter(option => !option.shared));
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        }

        getInitialData();
    }, []);

    useEffect(() => {
        onAmountChange();
    }, [
        getIn(formik.values, transactionId + '.originalAmount'),
        getIn(formik.values, transactionId + '.type'),
        getIn(formik.values, transactionId + '.isRefund')
    ]);

    useEffect(() => {
        async function fetchCategories() {
            if (!GlobalServices.isEmpty(getIn(formik.values, transactionId + '.businessId'))) {
                const business = incomeTypesOptions.find(a => a.id === getIn(formik.values, transactionId + '.businessId'));
                await getCategories(business, true);
            }
        }
        if (!GlobalServices.isEmpty(getIn(formik.values, transactionId + '.businessId'))) {
            fetchCategories();
        }
    }, [getIn(formik.values, transactionId + '.businessId'), getIn(formik.values, transactionId + '.type')]);

    const fetchCategoriesByType = async (categoryType: TransactionCategoryCategoryTypeEnum, resetCategory?: boolean) => {
        try {
            if (previousCategoryType !== categoryType) {
                resetCategory && (await formik.setFieldValue(transactionId + '.category', ''));
                const response = await transactionCategoryApi.getCategoriesByType(categoryType ?? '');
                setCategories(response.data);
                setPreviousCategoryType(categoryType);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getBusinessType = (type: BusinessSourceBasicInfoTypeEnum) => {
        switch (type) {
            case BusinessSourceBasicInfoTypeEnum.SELF_EMPLOYED:
                return BusinessTypeEnum.SELF_EMPLOYED;
            case BusinessSourceBasicInfoTypeEnum.LANDLORD:
            case BusinessSourceBasicInfoTypeEnum.PROPERTY:
                return BusinessTypeEnum.LANDLORD;
            case BusinessSourceBasicInfoTypeEnum.FOREIGN_PROPERTY:
                return BusinessTypeEnum.FOREIGN_LANDLORD;
            default:
                return type;
        }
    };

    const getCategories = async (business?: BusinessSourceBasicInfo, resetCategory?: boolean) => {
        if (GlobalServices.isDefined(business)) {
            const businessType = getBusinessType(business.type as BusinessSourceBasicInfoTypeEnum);
            const transactionType = getIn(formik.values, transactionId + '.type');
            const isIncome =
                (transactionType === 'INCOME' && !formik.values.isRefund) || (transactionType === 'EXPENSE' && formik.values.isRefund)
                    ? true
                    : (transactionType === 'EXPENSE' && !formik.values.isRefund) || (transactionType === 'INCOME' && formik.values.isRefund)
                    ? false
                    : undefined;
            const typeToSend = formatCategoryType(businessType, business.fhl!, isIncome);
            await fetchCategoriesByType(typeToSend as TransactionCategoryCategoryTypeEnum, resetCategory);
        }
    };

    const removeFile = async (documentId: string) => {
        try {
            showLoadingBar();
            await storageControllerApi.deleteDocument(documentId);
            const newFilesArray = getIn(formik.values, transactionId + '.documents').filter((file: DocumentDto) => file.id !== documentId);
            formik.setFieldValue(transactionId + '.documents', newFilesArray);
            hideLoadingBar();
        } catch (e) {
            hideLoadingBar();
            console.log(e);
        }
    };

    const renderImages = () => {
        const images = getIn(formik.values, transactionId + '.documents') || [];
        return images.map((image: DocumentDto, index: number) => {
            return (
                <ApariFilePreview
                    fullWidth
                    key={index}
                    filename={image.filename!}
                    previewUrl={image.previewUrl!}
                    downloadUrl={image.downloadUrl}
                    status={PreviewState.SUCCESS}
                    fileSize={image.size}
                    removeFile={() => removeFile(image.id!)}
                />
            );
        });
    };

    const getSplitRate = (propertyId?: string) => {
        if (incomeTypesOptions.length && propertyId) {
            const property = incomeTypesOptions.find(incomeType => incomeType.propertyId === propertyId.split('_')[1]);
            if (GlobalServices.isDefined(property)) {
                return property.splitRate;
            }
        }
    };

    const calculateSplittedValue = (amount?: number, splitRate?: number) => {
        if (amount && splitRate) {
            return NumberServices.format(amount * splitRate);
        }
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleTypeChange = async (type: string) => {
        setAnchorEl(null);
        await formik.setFieldValue(transactionId + '.type', type);
        setSelectedType(type);
    };

    const onRefundChange = () => {
        formik.setFieldValue(transactionId + '.isRefund', !getIn(formik.values, transactionId + '.isRefund'));
    };

    return loading ? (
        <LoadingPlaceholder className={clsx(globalClasses.marginBottom16)} height={150} />
    ) : (
        <div data-cy={'splitTransaction_' + count} className={clsx(classes.splitTransactionSection)}>
            <div className={clsx(globalClasses.flexRowSpaceBetween)}>
                <div className={clsx(globalClasses.flexRowCenter)}>
                    <p
                        className={clsx(
                            globalClasses.font12weight600Dark,
                            splitTransaction.deleted && classes.deletedSplitTransactionSection
                        )}
                    >
                        {Localisation.localize('SPLIT_TRANSACTION_DIALOG.SECTION_TITLE', { count })}
                    </p>
                    <ApariButton
                        color="primary"
                        data-cy={'transactionType_' + count}
                        variant="outlined"
                        size="small"
                        aria-controls="typeMenu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        className={clsx(classes.typeBtn)}
                    >
                        {Localisation.localize(`RECORD_KEEPING_SCREEN.DIALOGS.${selectedType}`)}
                        <ExpandMore className={clsx(classes.expandIcon)} color="primary" />
                    </ApariButton>

                    <Menu
                        id="typeMenu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        className={clsx(globalClasses.marginBottom10)}
                    >
                        {filterTypes.map(type => (
                            <MenuItem
                                key={type}
                                data-cy={type + '_' + count}
                                selected={selectedType === type}
                                onClick={() => handleTypeChange(type)}
                            >
                                {Localisation.localize(`RECORD_KEEPING_SCREEN.DIALOGS.${type}`)}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
                <div className={clsx(globalClasses.flexRow)}>
                    {splitTransaction.deleted ? (
                        <>
                            <ApariButton
                                data-cy="splitTransactionRestore"
                                disabled={submitInProgress}
                                variant="text"
                                className={clsx(classes.textButtonWithPlus)}
                                onClick={() => onRestore(splitTransaction)}
                            >
                                <RestoreIcon className={clsx(globalClasses.marginRight8)} />
                                <span className={classes.textButtonWithPlusText}>{Localisation.localize('general.RESTORE')}</span>
                            </ApariButton>
                        </>
                    ) : (
                        <>
                            <ApariButton
                                data-cy="splitTransactionDelete"
                                disabled={submitInProgress}
                                variant="text"
                                className={clsx(classes.textButtonWithPlus)}
                                onClick={() => onDelete(splitTransaction)}
                            >
                                <span className={classes.textButtonWithPlusText}>{Localisation.localize('general.delete')}</span>
                            </ApariButton>
                            <ApariButton
                                data-cy="splitTransactionTriggerMoreFields"
                                disabled={submitInProgress}
                                variant="text"
                                className={clsx(classes.textButtonWithPlus)}
                                onClick={() => setShowAllFields(prevState => !prevState)}
                            >
                                <span className={classes.textButtonWithPlusText}>
                                    {showAllFields
                                        ? Localisation.localize('SPLIT_TRANSACTION_DIALOG.HIDE_EXTRA_FIELDS')
                                        : Localisation.localize('SPLIT_TRANSACTION_DIALOG.SHOW_ALL_FIELDS')}
                                </span>
                            </ApariButton>
                        </>
                    )}
                </div>
            </div>
            <div className={clsx(splitTransaction.deleted && classes.deletedSplitTransactionSection)}>
                <div className={clsx(classes.fieldsWrapper)}>
                    <ApSelectField
                        disabled={submitInProgress || splitTransaction.deleted}
                        formControlClassName={clsx(classes.fieldWithMargin)}
                        options={[
                            ...formatOptions(incomeTypesOptions),
                            createRedirectableOption('/settings/income-types', 'general.ADD_BUSINESS_SOURCE')
                        ]}
                        data-cy="businessId"
                        control={transactionId + '.businessId'}
                        label={Localisation.localize('general.INCOME_TYPE')}
                        value={incomeTypesOptions.length ? getIn(formik.values, transactionId + '.businessId') : ''}
                        onChange={e => {
                            formik.handleChange(e);
                        }}
                        error={
                            getIn(formik.touched, transactionId + '.businessId') &&
                            Boolean(getIn(formik.errors, transactionId + '.businessId'))
                        }
                        helperText={
                            getIn(formik.touched, transactionId + '.businessId') && getIn(formik.errors, transactionId + '.businessId')
                        }
                    />

                    <ApSelectField
                        options={formatOptions(categories)}
                        control={transactionId + '.category'}
                        data-cy="category"
                        label={Localisation.localize('general.CATEGORY')}
                        value={categories.length ? getIn(formik.values, transactionId + '.category') : ''}
                        onChange={formik.handleChange}
                        error={
                            categories.length
                                ? getIn(formik.touched, transactionId + '.category') &&
                                  Boolean(getIn(formik.errors, transactionId + '.category'))
                                : false
                        }
                        helperText={
                            categories.length
                                ? getIn(formik.touched, transactionId + '.category') && getIn(formik.errors, transactionId + '.category')
                                : ''
                        }
                        disabled={!categories.length || submitInProgress || splitTransaction.deleted}
                    />
                </div>
                {showAllFields && (
                    <>
                        <div className={clsx(classes.fieldsWrapper)}>
                            <ApTextField
                                disabled={submitInProgress || splitTransaction.deleted}
                                className={clsx(classes.fieldWithMargin)}
                                control={transactionId + '.purpose'}
                                data-cy="purpose"
                                label={Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.DESCRIPTION')}
                                formik={formik}
                            />

                            <ApTextField
                                disabled={submitInProgress || splitTransaction.deleted}
                                control={transactionId + '.counterpartName'}
                                data-cy="counterpartName"
                                label={Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.RECIPIENT')}
                                formik={formik}
                            />
                        </div>
                        <div className={clsx(classes.fieldsWrapper)}>
                            <ApTextField
                                disabled={submitInProgress || splitTransaction.deleted}
                                control={transactionId + '.notes'}
                                data-cy="notes"
                                label={Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.NOTES')}
                                formik={formik}
                            />
                        </div>
                        <div className={classes.fieldsWrapper}>
                            <div className={clsx(globalClasses.flexRowSpaceBetween, classes.positioningRefund)}>
                                <div className={clsx(globalClasses.flexRow)}>
                                    <span className={clsx(globalClasses.font12weight600Black60, classes.topMargin2)}>
                                        {Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.REFUND')}
                                    </span>
                                    <Tooltip
                                        placement="top-start"
                                        title={Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.REFUND_TOOLTIP')}
                                        arrow
                                        classes={{ tooltip: classes.tooltipDesign, arrow: classes.arrowDesign }}
                                    >
                                        <InfoOutlinedIcon className={classes.infoIcon} />
                                    </Tooltip>
                                </div>
                                <ApariTextSwitch onChange={onRefundChange} value={getIn(formik.values, transactionId + '.isRefund')} />
                            </div>
                        </div>
                    </>
                )}
                <div className={clsx(classes.imagesGrid)}>{renderImages()}</div>
                <div className={clsx(classes.fieldsWrapper)}>
                    <div className={clsx(classes.flex1, classes.fieldWithMargin)}>
                        <ApariDropzone
                            disabled={
                                !doesUserHaveLowestRequiredPackage(PaymentStatusDtoSubscriptionTypeEnum.STANDARD) ||
                                submitInProgress ||
                                splitTransaction.deleted
                            }
                            usedInSplitTransaction
                            formik={formik}
                            control={transactionId + '.documents'}
                        />
                    </div>
                    <div className={clsx(globalClasses.flexColumnCenter, classes.flex1, globalClasses.marginTop16)}>
                        <ApAmountField
                            disabled={submitInProgress || splitTransaction.deleted}
                            name={transactionId + '.originalAmount'}
                            formik={formik}
                            data-cy="originalAmount"
                            variant="standard"
                            bolded
                        />
                        {getSplitRate(getIn(formik.values, transactionId + '.businessId')) &&
                        calculateSplittedValue(
                            getIn(formik.values, transactionId + '.originalAmount'),
                            getSplitRate(getIn(formik.values, transactionId + '.businessId'))
                        ) ? (
                            <p className={clsx(globalClasses.font15weight400Light40)}>
                                {Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.YOUR_SPLIT_RATE') +
                                    calculateSplittedValue(
                                        getIn(formik.values, transactionId + '.originalAmount'),
                                        getSplitRate(getIn(formik.values, transactionId + '.businessId'))
                                    )}
                            </p>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SplitTransactionSection;
