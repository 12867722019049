import * as yup from 'yup';
import { AnySchema } from 'yup';
import Lazy from 'yup/lib/Lazy';
import Reference from 'yup/es/Reference';
import { localize } from 'utils/localisation/index';

export const addressObjectValidationSchema = (shape?: any): AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any> =>
    yup
        .object()
        .shape({
            streetName: yup
                .string()
                .trim()
                .max(
                    100,
                    localize('ADDRESS_LOOKUP.CHARACTERS_LIMIT', {
                        fieldName: localize('ADDRESS_LOOKUP.STREET_NAME'),
                        charactersCount: 255
                    })
                )
                .required(
                    localize('ADDRESS_LOOKUP.FIELD_IS_REQUIRED', {
                        fieldName: localize('ADDRESS_LOOKUP.STREET_NAME')
                    })
                )
                .nullable(),
            houseNumber: yup
                .string()
                .trim()
                .max(
                    100,
                    localize('ADDRESS_LOOKUP.CHARACTERS_LIMIT', {
                        fieldName: localize('ADDRESS_LOOKUP.HOUSE_NUMBER'),
                        charactersCount: 100
                    })
                )
                .required(
                    localize('ADDRESS_LOOKUP.FIELD_IS_REQUIRED', {
                        fieldName: localize('ADDRESS_LOOKUP.HOUSE_NUMBER')
                    })
                )
                .nullable(),
            houseNumberExtension: yup
                .string()
                .trim()
                .max(
                    100,
                    localize('ADDRESS_LOOKUP.CHARACTERS_LIMIT', {
                        fieldName: localize('ADDRESS_LOOKUP.BUILDING_NAME'),
                        charactersCount: 100
                    })
                )
                .nullable(),
            postalCode: yup
                .string()
                .trim()
                .max(
                    100,
                    localize('ADDRESS_LOOKUP.CHARACTERS_LIMIT', {
                        fieldName: localize('ADDRESS_LOOKUP.POSTAL_CODE'),
                        charactersCount: 100
                    })
                )
                .required(
                    localize('ADDRESS_LOOKUP.FIELD_IS_REQUIRED', {
                        fieldName: localize('ADDRESS_LOOKUP.POSTAL_CODE')
                    })
                )
                .nullable(),
            city: yup
                .string()
                .trim()
                .max(
                    100,
                    localize('ADDRESS_LOOKUP.CHARACTERS_LIMIT', {
                        fieldName: localize('ADDRESS_LOOKUP.CITY'),
                        charactersCount: 100
                    })
                )
                .required(
                    localize('ADDRESS_LOOKUP.FIELD_IS_REQUIRED', {
                        fieldName: localize('ADDRESS_LOOKUP.CITY')
                    })
                )
                .nullable(),
            state: yup
                .string()
                .trim()
                .max(
                    100,
                    localize('ADDRESS_LOOKUP.CHARACTERS_LIMIT', {
                        fieldName: localize('ADDRESS_LOOKUP.COUNTY'),
                        charactersCount: 100
                    })
                )
                .nullable(),
            country: yup
                .object()
                .nullable()
                .required(
                    localize('ADDRESS_LOOKUP.FIELD_IS_REQUIRED', {
                        fieldName: localize('ADDRESS_LOOKUP.COUNTRY')
                    })
                ),
            ...(shape && shape)
        })
        .nullable()
        .required(localize('formErrors.fieldIsRequired'));
