import { NotificationTypes } from './global';

export type OpenNotificationsPayload = {
    type: NotificationTypes;
    message: string;
    onClose?: () => void;
};

export enum TOUR_TYPES {
    MENU = 'MENU',
    MENU_MOBILE_FULL = 'MENU_MOBILE_FULL',
    MENU_MOBILE = 'MENU_MOBILE'
}

export type ToursStatus = Record<string, boolean | undefined>;
