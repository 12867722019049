import React from 'react';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';
import { DateTimeServices, Localisation } from 'utils';
import styles from './styles';
import breakfastIcon from './assets/breakfast.svg';
import lunchIcon from './assets/lunch.svg';
import dinnerIcon from './assets/dinner.svg';
import ApariMealCard from './ApariMealCard';

type Props = {
    date: string;
    onSelect: (key: string, date: string, checked: boolean) => void;
    values: Record<string, boolean>;
};

type MealCard = {
    text: string;
    type: string;
    icon: string;
};

const ApariMealSection: React.FC<Props> = ({ date, onSelect, values }) => {
    const classes = styles();
    const globalClasses = globalStyles();

    const mealCards: MealCard[] = [
        {
            text: Localisation.localize('general.breakfast'),
            type: 'breakfast',
            icon: breakfastIcon
        },
        {
            text: Localisation.localize('general.lunch'),
            type: 'lunch',
            icon: lunchIcon
        },
        {
            text: Localisation.localize('general.dinner'),
            type: 'dinner',
            icon: dinnerIcon
        }
    ];

    const renderCards = () => {
        return mealCards.map((meal: MealCard, index: number) => {
            return [
                <ApariMealCard
                    key={meal.type}
                    name={meal.type}
                    onChange={(checked: boolean) => onSelect(meal.type, date, checked)}
                    checked={values[meal.type]}
                    text={meal.text}
                    imgSrc={meal.icon}
                />,
                index !== mealCards.length - 1 && <div key={'divider' + meal.type} className={clsx(classes.cardDivider)} />
            ];
        });
    };

    return (
        <div className={clsx(globalClasses.flexColumn, classes.section)}>
            <div className={clsx(classes.date)}>{DateTimeServices.getDisplayFormatDate((date as unknown) as Date)}</div>
            <div className={clsx(globalClasses.flexRow)}>{renderCards()}</div>
        </div>
    );
};

export default ApariMealSection;
