import Cookies from 'universal-cookie/lib/Cookies';
import jwt_decode from 'jwt-decode';
import { GLOBAL } from 'constants/index';

class NetworkService {
    static getToken() {
        const cookie = new Cookies();
        return cookie.get(GLOBAL.JWT_COOKIE_KEY);
    }
    static getDecodedToken(): Record<string, any> | undefined {
        const cookie = new Cookies();
        const token = cookie.get(GLOBAL.JWT_COOKIE_KEY);
        if (token) {
            return jwt_decode(token);
        }
    }
}

export default NetworkService;
