import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
    root: {
        height: 'calc(85vh - 60px)',
        maxHeight: 690,
        borderRadius: 12,
        marginTop: 30,
        boxShadow: '0px 100px 80px rgba(0, 0, 0, 0.04), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.0260636), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.02), 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.0139364)'
    },

    title: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: 16 ,
        textAlign: 'left',
    },
    backButton: {
        marginRight: 16
    },
    content: {
        background: theme.palette.background.paper,
        padding: 16,
        borderRadius: '12px 0px 0px 12px',
        overflow: 'auto'
    },
    fullContent: {
        borderRadius: 12,
    },
    noSidePadding: {
      paddingLeft: 0,
      paddingRight: 0
    },
    noVerticalPadding: {
        paddingTop: 0,
        paddingBottom: 0
    },
    clipart: {
        borderRadius: '0px 12 12 0px',
    },
    children: {

    }
}));
