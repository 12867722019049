import React from 'react';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';
import styles from './styles';
import { GlobalServices, Localisation, NumberServices } from 'utils';

type Props = {
    data: any;
    title?: string;
    ignoreKeys?: string[];
    collapsable?: boolean;
};

const MultiRowTableCell: React.FC<Props> = ({ data, collapsable, title, ignoreKeys }) => {
    const classes = styles({ collapsable });
    const globalClasses = globalStyles();

    const renderValue = (value: string | number | boolean) => {
        switch (typeof value) {
            case 'number':
                return (value < 0 ? '-' : '') + ' £ ' + NumberServices.format(Math.abs(value));
            case 'string':
                return value;
            case 'boolean':
                return value ? 'Yes' : 'No';
        }
    };

    const renderInnerTable = (title: string, value: any[]) => {
        const keys = Object.keys(value[0]);

        return (
            <div className={clsx(globalClasses.flexColumn, globalClasses.fullWidth)}>
                <span className={clsx(classes.cellValueName, globalClasses.marginBottom8)}>
                    {Localisation.localize(`CALCULATION_OVERVIEW.TABLE.${title}`)}
                </span>
                <div className={clsx(globalClasses.flexRowSpaceBetween, globalClasses.fullWidth)}>
                    {keys.map(key => (
                        <span className={clsx(globalClasses.font10weight700Black80)} key={key}>
                            {Localisation.localize(`CALCULATION_OVERVIEW.TABLE.${key}`)}
                        </span>
                    ))}
                </div>
                {value.map((element, index) => (
                    <div key={index} className={clsx(globalClasses.flexRowSpaceBetween, globalClasses.fullWidth)}>
                        {Object.values(element).map((el, index) => (
                            <span className={clsx(globalClasses.font10weight500Black80)} key={index}>
                                {renderValue(el as string | number | boolean)}
                            </span>
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    const renderRows = () =>
        Object.entries(data).map(([key, value], index) =>
            GlobalServices.isDefined(ignoreKeys) && ignoreKeys.includes(key) ? null : (
                <div key={index} className={clsx(globalClasses.flexRow, globalClasses.paddingTop10, globalClasses.paddingBottom10)}>
                    {Array.isArray(value) ? (
                        renderInnerTable(key, value)
                    ) : (
                        <>
                            <span className={clsx(classes.cellValueName, globalClasses.marginRightAuto)}>
                                {Localisation.localize(`CALCULATION_OVERVIEW.TABLE.${key}`)}
                            </span>
                            <span className={clsx(classes.cellValueAmount)}>{renderValue(value as string | number | boolean)}</span>
                        </>
                    )}
                </div>
            )
        );

    return (
        <div className={clsx(classes.tableCell)}>
            {title && <span className={clsx(globalClasses.marginBottom16, globalClasses.font10weight400Dark)}>{title}</span>}
            {renderRows()}
        </div>
    );
};

export default MultiRowTableCell;
