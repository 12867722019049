import React from 'react';
import ContentLoader from 'react-content-loader';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { CalculationSimpleInfoDto } from '@apari/uk-mtd-api';
import { COLORS, DATE_FORMAT } from 'constants/index';
import { InformationCard } from 'components';
import NoDataImage from 'resources/images/Arrow.png';
import globalStyles from 'styles/globalStyles';
import { Income, Rate } from 'types';
import { DateTimeServices, GlobalServices, Localisation } from 'utils';
import styles from './styles';
import { TaxYearOverallDto } from '@apari/uk-jf-api';

type Props = {
    rates?: Rate[];
    calculationInfo?: CalculationSimpleInfoDto;
    sa100CalculationInfo?: TaxYearOverallDto;
    incomes?: Income[];
    profit?: number;
    highestRate?: number;
    loading?: boolean;
    calculationDate?: string;
    source?: string;
    hideDateAndSource?: boolean;
};

type IncomesListProps = {
    incomes: Income[];
};

export const RatesGraphLoading = () => {
    return (
        <ContentLoader speed={2} width="100%" height="214" backgroundColor={COLORS.backgroundLight}>
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="214" />
        </ContentLoader>
    );
};

export const IncomesList: React.FC<IncomesListProps> = ({ incomes }) => {
    const classes = styles();

    const renderIncomes = () => {
        return incomes.map(income => {
            return (
                <InformationCard
                    key={income.type}
                    alwaysInRow
                    name={Localisation.localize('DASHBOARD_SCREEN.taxYearCarousel.' + income.type)}
                    value={income.value}
                />
            );
        });
    };
    return <div className={clsx(classes.incomesListContainer)}>{renderIncomes()}</div>;
};

const RatesGraph: React.FC<Props> = ({
    loading,
    rates,
    profit,
    incomes,
    highestRate,
    calculationInfo,
    sa100CalculationInfo,
    hideDateAndSource,
    calculationDate,
    source = ''
}) => {
    const globalClasses = globalStyles();
    const classes = styles();

    const renderRates = () => {
        return (
            rates &&
            highestRate &&
            rates.map((rate, index: number) => {
                return (
                    <div
                        style={{ right: 'calc(100% - ' + (rate.value / highestRate) * 100 + '%' + ' + 30px)' }}
                        key={index}
                        className={clsx(classes.rateRoot)}
                    >
                        <div>
                            <div className={clsx(classes.rateIndicator)} />
                            <div className={clsx(globalClasses.flexColumnCenter, classes.rateText)}>
                                <p className={clsx(classes.rateLabel)}>{rate.label}</p>
                                <p className={clsx(classes.rateValue)}>{'£ ' + rate.value}</p>
                            </div>
                        </div>
                    </div>
                );
            })
        );
    };

    const renderGraph = () => {
        if (rates && profit && highestRate)
            return (
                <div className={clsx(globalClasses.flexColumnStart, classes.graphContainer)}>
                    <div className={clsx(classes.graph)}>
                        <Tooltip classes={{ tooltip: classes.tooltip }} title={'£ ' + profit} placement="top-end">
                            <div
                                style={{ width: 'calc(' + (profit / highestRate) * 100 + '%' + ' - 30px)' }}
                                className={clsx(classes.profitIndicator)}
                            />
                        </Tooltip>
                        {renderRates()}
                    </div>
                </div>
            );
    };

    const renderIncomesList = () => {
        if (calculationInfo) {
            const preparedIncomes = [
                {
                    type: 'totalIncomeReceivedAmount',
                    value: calculationInfo['totalIncomeReceivedAmount']
                },
                {
                    type: 'totalTaxableIncomeAmount',
                    value: calculationInfo['totalTaxableIncomeAmount']
                },
                {
                    type: 'totalIncomeTaxChargedAmount',
                    value: calculationInfo['totalIncomeTaxChargedAmount']
                },
                {
                    type: 'totalIncomeTaxAndNicDueAmount',
                    value: calculationInfo['totalIncomeTaxAndNicDueAmount']
                }
            ];
            return <IncomesList incomes={preparedIncomes} />;
        } else if (incomes && incomes.length) {
            return <IncomesList incomes={incomes} />;
        }
    };

    const renderSA100Results = () => {
        if (sa100CalculationInfo) {
            const preparedIncomes = [
                {
                    type: 'incomeTaxDue',
                    value: sa100CalculationInfo.totalTaxEtcDue
                },
                {
                    type: 'class4NIC',
                    value: sa100CalculationInfo.class4NICsDue
                },
                {
                    type: 'class2NIC',
                    value: sa100CalculationInfo.class2NICsDue
                },
                {
                    type: 'totalIncomeTax',
                    value: sa100CalculationInfo.totalPaymentDue
                }
            ];
            return <IncomesList incomes={preparedIncomes} />;
        }
    };

    const renderNoDataMessage = () => {
        return (
            <div className={clsx(classes.noDataContainer)}>
                <img width={130} src={NoDataImage} alt="no-data" />
                <p className={clsx(globalClasses.marginTop24)}>{Localisation.localize('DASHBOARD_SCREEN.taxYearCarousel.noDataAdded')}</p>
            </div>
        );
    };

    const renderDateAndSource = () => {
        return (
            <div className={clsx(globalClasses.flexRowSpaceBetween, globalClasses.paddingTop16)}>
                <span className={clsx(globalClasses.font10weight500Black80)}>
                    {Localisation.localize('general.CALCULATION_DATE', {
                        calculationDate: DateTimeServices.getDateInGivenFormat(calculationDate!, DATE_FORMAT.UK_STANDARD_2)
                    })}
                </span>
                <span className={clsx(globalClasses.font10weight500Black80)}>
                    {Localisation.localize('general.SOURCE') + ': ' + source}
                </span>
            </div>
        );
    };

    if (loading) return <RatesGraphLoading />;
    return (
        <>
            {hideDateAndSource ? null : renderDateAndSource()}
            {renderGraph()}
            {renderIncomesList()}
            {renderSA100Results()}
            {GlobalServices.isEmpty(incomes) &&
                GlobalServices.isEmpty(calculationInfo) &&
                GlobalServices.isEmpty(rates) &&
                GlobalServices.isEmpty(sa100CalculationInfo) &&
                renderNoDataMessage()}
        </>
    );
};

export default RatesGraph;
