import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    root: {
        outline: 0,
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        top: 0,
        background: theme.palette.background.paper
    },
    logoWrapper: {
        maxWidth: 330,
        height: 40,
        width: '100%'
    },
    loadingImage: {
        animation: '$pulse-animation 1s infinite'
    },
    '@keyframes pulse-animation': {
        '0%': {
            transform: 'scale(1)'
        },
        '50%': {
            transform: 'scale(1.1)'
        },
        '100%': {
            transform: 'scale(1)'
        }
    }
}));
