import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    passwordStrength: {
        marginBottom: '5.1px'
    },
    strongPasswordColor: {
        color: '#7CBD82'
    },
    mediumPasswordColor: {
        color: '#E8B23C'
    },
    weakPasswordColor: {
        color: '#D9455F'
    }
}));
