import postalCodes from 'postal-codes-js';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import { reduce, upperCase } from 'lodash';
import Cookies from 'universal-cookie/lib/Cookies';
import { RESPONSE_CODES, COMMONS, GLOBAL } from 'constants/index';
import { Empty, DetermineEmptyValueType, TokenErrorTypes } from 'types';
import { Localisation } from '../index';

const NUMBER_PRECISION = 2;

class GlobalServices {
    static isDefined<T>(value: T | undefined | null): value is T {
        return value !== undefined && value !== null;
    }

    static definedValueIsEmpty<T extends number | boolean | string | { [key: string]: any } | any[]>(
        value: T | Empty
    ): value is DetermineEmptyValueType<T> {
        // eslint-disable-line @typescript-eslint/no-explicit-any
        switch (typeof value) {
            case 'object':
                return Object.keys(value).length === 0;
            case 'string':
                return value === '';
            default:
                return false;
        }
    }

    static isEmpty<T extends number | string | { [key: string]: any } | any[]>(value: T | null | boolean | undefined): value is never {
        // eslint-disable-line @typescript-eslint/no-explicit-any
        if (this.isDefined(value)) {
            return this.definedValueIsEmpty(value);
        }
        return true;
    }

    static ifExistSet<T>(value: T | null | undefined): T | string {
        if (this.isDefined(value)) {
            return value;
        } else {
            return '';
        }
    }

    static getArrayDifference<T>(sourceArray: T[], compareToArray: T[]) {
        return sourceArray.filter(element => !compareToArray.includes(element));
    }

    static validatePostalCode(countryCode: string, postalCode: string | number): string | boolean {
        if (this.isEmpty(countryCode)) {
            return false;
        }
        return postalCodes.validate(countryCode, postalCode);
    }

    static generateRandomId(): string {
        return 'id' + Math.random().toString(16).slice(2);
    }

    static setFilter(value: string | { [key: string]: any } | Array<any> | null | undefined): string | undefined {
        // eslint-disable-line @typescript-eslint/no-explicit-any
        if (this.isEmpty(value)) {
            return undefined;
        } else {
            return value as string;
        }
    }

    static getBaseURL(): string {
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;
        if (hostname === 'localhost') {
            const port = window.location.port;
            return protocol + '//' + hostname + ':' + port;
        } else {
            return protocol + '//' + hostname;
        }
    }

    static getHumanReadableFileSize(bytes: number, si = false, decimalPoint = 1): string {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** decimalPoint;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        return bytes.toFixed(decimalPoint) + ' ' + units[u];
    }

    static getQueryParameterIfItExists(searchParams: URLSearchParams, param: string): string | undefined {
        return searchParams.has(param) ? (searchParams.get(param) as string) : undefined;
    }

    static timeout(delay: number): Promise<void> {
        return new Promise(res => setTimeout(res, delay));
    }

    static toFixedNoRounding(num: number, p = NUMBER_PRECISION): string | number {
        let n = Number.isNaN(num) || !isFinite(num) ? 0 : num;
        n = Math.round((n + Number.EPSILON) * 10000) / 10000;
        n = Number.isNaN(n) ? num : n;
        const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + p + '})?', 'g');
        const a = n.toString().match(reg)![0];
        const dot = a.indexOf('.');
        if (dot === -1) {
            // integer, insert decimal dot and pad up zeros
            return a + '.' + '0'.repeat(p);
        }
        const b = p - (a.length - dot) + 1;
        return b > 0 ? a + '0'.repeat(b) : a;
    }

    static camelize(str: string) {
        return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
            if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
            return index === 0 ? match.toLowerCase() : match.toUpperCase();
        });
    }

    static toCamelCase(s: string) {
        return s.replace(/([-_][a-z])/gi, $1 => {
            return $1.toUpperCase().replace('-', '').replace('_', '');
        });
    }

    static keysToCamelCase(obj: Record<string, any>) {
        const n: Record<string, unknown> = {};

        Object.keys(obj).forEach(k => {
            n[GlobalServices.toCamelCase(k)] = obj[k];
        });

        return n;
    }

    static groupArrayElementsIntoSubArrays<T extends Record<string, unknown>>(data: T[], groupProperties: string[]): T[][] {
        const aggregate: Record<string, T[]> = {};
        const groups = data.reduce((aggObj, item) => {
            const stringId = groupProperties.map(key => item[key]).join('_');
            if (aggObj[stringId]) {
                aggObj[stringId].push(item);
            } else {
                aggObj[stringId] = [item];
            }

            return aggObj;
        }, aggregate);
        return [...Object.values(groups)] as T[][];
    }
    static partition<T, U>(array: any[], filter: (e: any, index?: number, arr?: unknown[]) => boolean) {
        const pass: Array<[string, T]> = [],
            fail: Array<[string, U]> = [];
        array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e));
        const passObject = pass.reduce((accumulator, [key, value]) => {
            accumulator[key] = value;
            return accumulator;
        }, {} as Record<string, T>);
        const failObject = fail.reduce((accumulator, [key, value]) => {
            accumulator[key] = value;
            return accumulator;
        }, {} as Record<string, U>);
        return [passObject, failObject];
    }

    static arrayOfRecordsToRecord<T extends Record<string, any>>(array: T[], key: string) {
        return array.reduce((accumulator: Record<string, T>, currentValue) => {
            const accKeyValue: string = currentValue[key];
            delete currentValue[key];
            accumulator[accKeyValue] = currentValue;
            return accumulator;
        }, {});
    }

    static responseCodesErrorHandler(data: any): any {
        let errorHandler;
        if (data.responseCode === RESPONSE_CODES.USER_ALREADY_EXISTS) {
            errorHandler = Localisation.localize('serverErrors.userAlreadyExists');
        } else if (data.responseCode === RESPONSE_CODES.BAD_CREDENTIALS) {
            errorHandler = Localisation.localize('serverErrors.badCredentials');
        } else if (data.responseCode === RESPONSE_CODES.USER_NOT_CONFIRMED) {
            errorHandler = Localisation.localize('serverErrors.userNotConfirmed');
        } else if (data.responseCode === RESPONSE_CODES.INVALID_TOKEN) {
            errorHandler = { tokenErrorType: TokenErrorTypes.INVALID };
        } else if (data.responseCode === RESPONSE_CODES.EXPIRED_TOKEN) {
            errorHandler = { tokenErrorType: TokenErrorTypes.EXPIRED };
        } else if (data.responseCode === RESPONSE_CODES.INVALID_TWO_FACTOR_CODE) {
            errorHandler = Localisation.localize('serverErrors.wrongCode');
        } else if (data.responseCode === RESPONSE_CODES.OAUTH_USER_ALREADY_EXITS) {
            errorHandler = { userAlreadyExists: true };
        } else if (data.responseCode === RESPONSE_CODES.CANNOT_SET_SUBSCRIPTION_TO_STARTER_WHEN_ACTIVE_SHARING) {
            errorHandler = Localisation.localize('serverErrors.CANNOT_SET_SUBSCRIPTION_TO_STARTER_WHEN_ACTIVE_SHARING');
        } else if (data.responseCode === RESPONSE_CODES.STRIPE_ERROR) {
            errorHandler = Localisation.localize('serverErrors.stripeError');
        } else if (data.responseCode === RESPONSE_CODES.CARD_ERROR) {
            errorHandler = Localisation.localize('serverErrors.cardError');
        } else if (data.responseCode === RESPONSE_CODES.RECOVERY_CODE_USED_ON_ACTIVATION) {
            errorHandler = Localisation.localize('serverErrors.youUsedRecoveryKey');
        } else if (data.responseCode === RESPONSE_CODES.CANT_RESET_PASSWORD_FOR_THIS_EMAIL) {
            errorHandler = Localisation.localize('serverErrors.CANT_RESET_PASSWORD_FOR_THIS_EMAIL');
        } else if (data.responseCode === RESPONSE_CODES.ALREADY_REGISTERED_USING_EMAIL) {
            errorHandler = { registeredUsingEmail: true, message: Localisation.localize('serverErrors.ALREADY_REGISTERED_USING_EMAIL') };
        } else if (data.responseCode === RESPONSE_CODES.ACCOUNT_ALREADY_CONFIRMED) {
            errorHandler = {
                accountAlreadyConfirmed: true,
                message: Localisation.localize('serverErrors.accountAlreadyConfirmed')
            };
        } else if (data.responseCode === RESPONSE_CODES.TWO_FACTOR_AUTHENTICATION_REQUIRED) {
            errorHandler = { twoFactorRequired: true, data: JSON.parse(data.responseMessage) };
        } else if (data.message === 'Webform required') {
            errorHandler = { ...data };
        } else if (data.responseCode === RESPONSE_CODES.HMRC_AUTHORIZATION_REQUIRED) {
            errorHandler = { hmrcAuthorizationRequired: true, data: JSON.parse(data.responseMessage) };
        } else if (data.responseCode === RESPONSE_CODES.CANNOT_DOWNGRADE_HAS_MORE_THAN_5_ACCEPTED) {
            errorHandler = Localisation.localize('serverErrors.CANNOT_DOWNGRADE_HAS_MORE_THAN_5_ACCEPTED');
        } else if (RESPONSE_CODES[data.responseCode])
            // TODO: Add the rest of response codes to the list in same way as for this one
            errorHandler = { ...data, responseMessage: Localisation.localize(`serverErrors.${RESPONSE_CODES[data.responseCode]}`) };
        else {
            errorHandler = !GlobalServices.isEmpty(data) ? data : Localisation.localize('serverErrors.problemWithResource');
        }
        return errorHandler;
    }

    static getUUID(): string {
        const cookie = new Cookies();
        const uuid = cookie.get(GLOBAL.UUID_COOKIE_KEY);

        if (uuidValidate(uuid)) {
            return uuid;
        } else {
            const generatedUUID = uuidv4();
            cookie.set(GLOBAL.UUID_COOKIE_KEY, generatedUUID);
            return generatedUUID;
        }
    }

    static isHiddenFeaturesEnabled(): boolean {
        return localStorage.getItem(COMMONS.LOCAL_STORAGE_KEYS.HIDDEN_FEATURES_KEY) === 'true';
    }

    static getAddressValue = (address: Record<string, any>) => {
        return (
            (address?.houseNumberExtension ? address?.houseNumberExtension + ',\n' : '') +
            (address?.streetName ? (address?.houseNumber ? address?.houseNumber + ' ' : '') + address?.streetName + ',\n' : '') +
            (address?.city ? address?.city + ',\n' : '') +
            (address?.state ? address?.state + ',\n' : '') +
            (address?.country && address?.country.name ? address?.country.name + ',\n' : '') +
            (address?.postalCode ? address?.postalCode : '')
        );
    };

    static camelCaseToSnakeUpperCase = (camelCaseString: string) => {
        return upperCase(camelCaseString).replace(/ /g, '_');
    };

    static checkHowManyRegexRulesAreMet = (passwordRegex: RegExp[], value: any) =>
        reduce(passwordRegex, (sum, rule) => (value.match(rule) ? sum + 1 : sum), 0);
}

export default GlobalServices;
