import { COLORS } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    root: {},
    messageWrapper: {
        zIndex: 3,
        height: 'auto',
        padding: '10px 16px 20px 16px',
        background: theme.palette.background.paper,
        borderRadius: '2px',
        transformOrigin: 'top',
        [theme.breakpoints.up('md')]: {
            padding: '10px 24px 30px 24px'
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 780,
            padding: '40px 75px 40px 75px',
            borderRadius: '2px'
        }
        // backfaceVisibility: 'hidden',
        // transform: 'translateZ(0)',
        // transition: 'padding 0.3s, box-shadow 0.3s',
        // WebkitTransition: 'padding 0.3s, box-shadow 0.3s',
        // MozTransition: 'padding 0.3s, box-shadow 0.3s',
        // MsTransition: 'padding 0.3s, box-shadow 0.3s',
        // OTransition: 'padding 0.3s, box-shadow 0.3s',
        // willChange: 'padding, max-height, box-shadow'
    },
    messageWrapperShrink: {
        MozBoxShadow: '11px 10px 37px -5px rgba(0,0,0,0.52)',
        WebkitBoxShadow: '11px 10px 37px -5px rgba(0,0,0,0.52)',
        BoxShadow: '11px 10px 37px -5px rgba(0,0,0,0.52)'
    },
    messageWrapperAdditionalPadding: {
        padding: `${theme.spacing(8) + 'px ' + theme.spacing(4) + 'px ' + theme.spacing(10) + 'px ' + theme.spacing(6) + 'px'}`
    },
    messageWrapperExtended: {
        maxHeight: 800,
        overflow: 'hidden'
    },
    slowAnimations: {
        // transition: 'all 2s',
        // WebkitTransition: 'all 2s',
        // MozTransition: 'all 2s',
        // MsTransition: 'all 2s',
        // OTransition: 'all 2s'
    },
    fastAnimations: {
        transition: 'padding 0.5s, box-shadow 0.5s, max-height 0.5s, filter 0.5s',
        WebkitTransition: 'padding 0.5s, box-shadow 0.5s, max-height 0.5s, filter 0.5s',
        MozTransition: 'padding 0.5s, box-shadow 0.5s, max-height 0.5s, filter 0.5s',
        MsTransition: 'padding 0.5s, box-shadow 0.5s, max-height 0.5s, filter 0.5s',
        OTransition: 'padding 0.5s, box-shadow 0.5s, max-height 0.5s, filter 0.5s'
    },
    subHeader: {
        position: 'relative',
        zIndex: 1,
        backfaceVisibility: 'hidden',
        transform: 'translateZ(0)',
        transformOrigin: 'top',
        transition: 'transform 0.3s, margin-bottom 0s',
        WebkitTransition: 'transform 0.3s, margin-bottom 0s',
        MozTransition: 'transform 0.3s, margin-bottom 0s',
        MsTransition: 'transform 0.3s, margin-bottom 0s',
        OTransition: 'transform 0.3s, margin-bottom 0s',
        willChange: 'transform, margin-bottom'
    },
    subHeaderShrink: {
        transform: 'scaleY(0.65)'
    },
    titleWrapper: {
        marginBottom: 40
    },
    titleText: {
        fontSize: 14,
        fontWeight: 600,
        color: COLORS.apariBlack80
    },
    backIconStyle: {
        fontSize: 32,
        color: theme.palette.text.secondary
    },
    message: {
        color: COLORS.apariBlack80,
        fontSize: 18,
        fontWeight: 400,
        [theme.breakpoints.up('md')]: {
            fontSize: 22
        },
        whiteSpace: 'pre-line',
        maxWidth: 600,
        backfaceVisibility: 'hidden',
        transform: 'translateZ(0)',
        transformOrigin: 'left',
        transition: 'transform 0.3s, font-size 0.3s',
        WebkitTransition: 'transform 0.3s, font-size 0.3s',
        MozTransition: 'transform 0.3s, font-size 0.3s',
        MsTransition: 'transform 0.3s, font-size 0.3s',
        OTransition: 'transform 0.3s, font-size 0.3s',
        willChange: 'transform, font-size'
    },
    messageShrink: {
        transform: 'scaleX(0.65)'
    },
    secondMessage: {
        marginTop: theme.spacing(6),
        opacity: 1,
        animationName: 'fadeInOpacity',
        animationIterationCount: 1,
        animationTimingFunction: 'ease-in',
        animationDuration: '2s'
    },
    loaderWrapper: {
        marginTop: theme.spacing(6)
    },
    triangle: {
        height: '35px',
        [theme.breakpoints.up('lg')]: {
            height: '50px',
            paddingLeft: 2,
            paddingRight: 2
        },
        width: '100%',
        background: theme.palette.background.paper,
        clipPath: 'polygon(50% 100%, 0 0, 100% 0)'
    }
}));
