import React from 'react';
import { useHistory } from 'react-router-dom';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import NewPasswordDialogImg from 'resources/images/new-password-dialog.png';
import globalStyles from 'styles/globalStyles';
import { ApariButton } from 'components';
import { Localisation } from 'utils';
import styles from './styles';
import { DialogContent } from '@material-ui/core';

type Props = DialogProps & {
    onConfirm?: () => void;
    handleClose: () => void;
    message: string;
    success: boolean;
};

const NewPasswordDialog: React.FC<Props> = ({ open, handleClose, message, success }) => {
    const classes = styles();
    const globalClasses = globalStyles();
    const history = useHistory();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            onBackdropClick={handleClose}
            fullScreen={mobile}
            classes={{
                paper: classes.paper,
                paperFullScreen: classes.fullScreenPaper
            }}
            BackdropProps={{ classes: { root: classes.backdrop } }}
        >
            <DialogContent className={clsx(classes.dialogContent)}>
                <img src={NewPasswordDialogImg} width={120} height={120} alt="new-password-dialog" />
                <div className={clsx(classes.textContainer, globalClasses.marginTop20)}>
                    <p className={clsx(classes.titleText)}>{message}</p>
                </div>
            </DialogContent>
            <DialogActions className={clsx(globalClasses.flexColumnCenter, classes.buttonContainer)}>
                <ApariButton
                    variant="contained"
                    onClick={() => history.push(success ? '/sign-in' : '/forgot-password')}
                    color="primary"
                    fullWidth
                >
                    {Localisation.localize(success ? 'general.signIn' : 'signUpWithEmailSuccessScreen.resendEmail')}
                </ApariButton>
            </DialogActions>
        </Dialog>
    );
};
export default NewPasswordDialog;
