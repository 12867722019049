import React, { useState, useEffect } from 'react';
import { TextFieldProps } from '@material-ui/core';
import { Country } from '@apari/core-api';
import { ApariTextField } from 'components';
import { COUNTRIES } from 'constants/countries';
import { Autocomplete } from '@material-ui/lab';
import { FormikContextType } from 'formik';

interface Props {
    control: string;
    label: string;
    formik: FormikContextType<any>;
    customCountries?: Country[];
    onlyUk?: boolean;
    foreign?: boolean;
    dataCy?: string;
    inputDataCy?: string;
}

const ApCountryField: React.FC<Props & TextFieldProps> = ({
    customCountries,
    onlyUk,
    foreign,
    control,
    formik,
    label,
    disabled,
    dataCy,
    inputDataCy
}) => {
    const [countries, setCountries] = useState<Country[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getCountries() {
            setLoading(true);
            try {
                const response = { data: COUNTRIES };
                setCountries(
                    response.data.filter(country => {
                        if (onlyUk) {
                            return country.code2 === 'GB';
                        } else if (foreign) {
                            return country.code2 !== 'GB';
                        } else {
                            return country;
                        }
                    })
                );
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        }
        if (!customCountries) {
            getCountries();
        }
    }, []);

    return (
        <Autocomplete
            data-cy={dataCy}
            fullWidth
            options={customCountries ?? countries}
            renderOption={option => <>{option.name}</>}
            getOptionLabel={option => option.name! || ''}
            getOptionSelected={(country: Country, newValue: Country) => {
                return country.name! === newValue.name!;
            }}
            value={formik.values[control]}
            loading={loading}
            disabled={disabled}
            onChange={(_, data) => formik.setFieldValue(control, data)}
            renderInput={params => (
                <ApariTextField
                    {...params}
                    data-cy={inputDataCy}
                    name={control}
                    label={label}
                    error={formik.touched[control] && Boolean(formik.errors[control])}
                    helperText={formik.touched[control] && formik.errors[control]}
                    variant="outlined"
                    inputProps={{ ...params.inputProps, form: { autocomplete: 'off' } }}
                />
            )}
        />
    );
};

export default ApCountryField;
