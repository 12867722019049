import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    root: {
        marginTop: 4,
        '& input': {
            paddingBottom: '0px !important',
            fontSize: 12,
            '&::placeholder': {
                fontSize: 12,
                color: COLORS.apariBlack80 + ' !important',
                opacity: 1
            }
        },
        '&.MuiInput-underline:before': {
            borderBottom: 'none'
        }
    },
    input: {
        '&::placeholder': {
            fontSize: 12
        }
    }
}));
