import { makeStyles, Theme } from '@material-ui/core';
import { COLORS } from 'constants/index';

type Props = {
    isIOS: boolean;
    hideBorder?: boolean;
};

export default makeStyles<Theme, Props>(theme => ({
    root: {
        color: COLORS.backgroundLight
    },
    hideElement: {
        display: 'none'
    },
    addressLookupInput: {
        marginBottom: 0
    },
    progressWrapper: {
        marginBottom: 2,
        height: 2,
        width: '100%'
    },
    progress: {
        height: 2
    },
    previewInputWrapper: {
        width: '100%'
    },
    previewInput: {
        marginTop: 8,
        marginBottom: 0,
        '& textarea': {
            padding: '2px 0px !important'
        },
        '& .MuiFormHelperText-root.Mui-error': {
            whiteSpace: 'pre-wrap'
        }
    },
    previewInputEnabled: {
        '& .MuiFormLabel-root.Mui-disabled': {
            color: COLORS.apariBlack80 + ' !important'
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: COLORS.apariBlack80 + ' !important'
        }
    },
    previewActionsWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    previewActionButton: {
        color: COLORS.apariLightBlue
    },
    popper: ({ isIOS, hideBorder }) => ({
        width: 'fit-content !important',
        zIndex: 9999,
        ...(!isIOS && {
            [theme.breakpoints.down('sm')]: {
                left: '5px'
            }
        }),
        '& .MuiAutocomplete-paper': {
            border: hideBorder ? 'none' : '1px solid ' + COLORS.apariLightBlue,
            borderRadius: 2,
            margin: 0,
            fontWeight: 400,
            fontSize: 13,
            '& ul': {
                paddingTop: 0,
                paddingBottom: 0,
                '& .MuiAutocomplete-option': {
                    padding: '6px 8px',
                    borderBottom: '1px solid ' + COLORS.backgroundDark,
                    '& div': {
                        '& .MuiGrid-item': {
                            display: 'flex',
                            '& .MuiSvgIcon-root': {
                                color: COLORS.apariGray,
                                fontSize: 22
                            },
                            '& p': {
                                margin: '3px 10px'
                            }
                        }
                    }
                }
            }
        },
        '& .MuiAutocomplete-noOptions': {
            padding: '10px 12px'
        }
    }),
    manualForm: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
        }
    },
    manualFormConfirmButton: {
        marginTop: 10,
        marginBottom: 12,
        [theme.breakpoints.down('sm')]: {
            marginTop: 'auto'
        }
    },
    manualEditDialogContent: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 0px 60px 70px',
        [theme.breakpoints.down('md')]: {
            padding: '0 0px 30px 40px'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 16px 20px 16px',
            height: '100%'
        }
    }
}));
