import { ImageTypes } from 'types/files';

class FilesService {
    static checkIfFileIsImage(type: string): boolean {
        switch (type) {
            case ImageTypes.JPEG:
            case ImageTypes.JPG:
            case ImageTypes.PNG:
                return true;
            default:
                return false;
        }
    }
}

export default FilesService;
