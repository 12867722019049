import React from 'react';
import { IconButton, withWidth, WithWidthProps } from '@material-ui/core';
import clsx from 'clsx';
import { ArrowBackIcon } from 'resources/icons';
import globalStyles from 'styles/globalStyles';
import { UIServices } from 'utils';
import styles from './styles';

type Props = WithWidthProps & {
    messages?: string[];
    customMessages?: JSX.Element[];
    handleBack?: () => void;
    title?: string;
    noSidePadding?: boolean;
    classNameMessages?: string;
    className?: string;
    iconClassName?: string;
    noBottomPadding?: boolean;
    alternativeFont?: boolean;
    noBackButton?: boolean;
    signUpScreen?: boolean;
};

const ScreenTitle: React.FC<Props> = ({
    title,
    width,
    messages,
    customMessages,
    noSidePadding,
    handleBack,
    classNameMessages,
    className,
    iconClassName,
    noBottomPadding,
    alternativeFont,
    noBackButton,
    signUpScreen
}) => {
    const classes = styles({ alternativeFont, signUpScreen });
    const globalClasses = globalStyles();

    const renderBackButton = () => {
        if (handleBack && !UIServices.isMobile(width)) {
            return (
                <IconButton size="small" onClick={handleBack} className={clsx([globalClasses.marginRight8, iconClassName])}>
                    <ArrowBackIcon />
                </IconButton>
            );
        }
    };

    return (
        <div className={clsx(classes.subHeader, globalClasses.flexColumn, className)}>
            <div
                className={clsx(
                    classes.messageWrapper,
                    noSidePadding && globalClasses.noHorizontalPadding,
                    !noBottomPadding && classes.messageWrapperBottomPadding
                )}
            >
                {((title && !UIServices.isMobile(width)) || (handleBack && !UIServices.isMobile(width))) && (
                    <div className={clsx(classes.titleWrapper, globalClasses.flexRow)}>
                        {renderBackButton()}
                        {title && !UIServices.isMobile(width) && (
                            <div className={clsx(classes.titleText, globalClasses.flexColumnCenter)}>{title}</div>
                        )}
                    </div>
                )}
                {noBackButton && title && UIServices.isMobile(width) && (
                    <div className={clsx(classes.titleWrapper, globalClasses.flexRow)}>
                        <div className={clsx(classes.titleText, globalClasses.flexColumn)}>{title}</div>
                    </div>
                )}
                {messages && <div className={clsx(classes.message, classNameMessages)}>{messages[0]}</div>}
                {customMessages && <div className={clsx(classes.message)}>{customMessages[0]}</div>}
            </div>
        </div>
    );
};

export default withWidth()(ScreenTitle);
