import React from 'react';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';
import styles from './styles';
import { Localisation, NumberServices } from 'utils';

type Props = {
    valueName: string;
    value: number | string;
    finalised?: boolean;
    collapsable?: boolean;
    multiRows?: boolean;
};

const CalculationTableCell: React.FC<Props> = ({ valueName, value, collapsable, multiRows, finalised }) => {
    const classes = styles({ collapsable, multiRows });
    const globalClasses = globalStyles();

    const renderValue = () => {
        switch (typeof value) {
            case 'number':
                return (value < 0 ? '-' : '') + ' £ ' + NumberServices.format(Math.abs(value));
            case 'string':
                return value;
            case 'boolean':
                return value ? 'Yes' : 'No';
        }
    };

    return (
        <div className={clsx(classes.tableCell)}>
            <span className={clsx(classes.cellValueName, globalClasses.marginRightAuto)}>{valueName}</span>
            <div className={clsx(globalClasses.flexColumn)}>
                <span className={clsx(classes.cellValueAmount)}>{renderValue()}</span>
                {multiRows && (
                    <span
                        className={clsx(
                            globalClasses.alignSelfFlexEnd,
                            finalised ? globalClasses.font10weight400Purple : globalClasses.font10weight400Dark100
                        )}
                    >
                        {finalised ? Localisation.localize('general.complete') : Localisation.localize('general.running')}
                    </span>
                )}
            </div>
        </div>
    );
};

export default CalculationTableCell;
