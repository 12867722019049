import React from 'react';
import { withWidth, WithWidthProps } from '@material-ui/core';
import { ApexOptions } from 'apexcharts';
import clsx from 'clsx';
import ContentLoader from 'react-content-loader';
import Chart from 'react-apexcharts';
import { IncomeExpenseSummaryGroupByEnum } from '@apari/banking-api';
import { COLORS, FONTS } from 'constants/index';
import { Localisation, UIServices } from 'utils';
import { ChartSeries, ApexChartProps } from 'types';
import styles from './styles';

type ChartContentLoaderProps = WithWidthProps & {
    className?: string;
    innerWrapperClassName?: string;
};

export const ChartContentLoader: React.FC<ChartContentLoaderProps> = ({ width, className, innerWrapperClassName }) => {
    const classes = styles({});

    return (
        <div className={clsx(classes.loader, className)}>
            <div className={clsx(classes.dataContainer, innerWrapperClassName)}>
                {UIServices.isMobile(width) ? (
                    <ContentLoader speed={2} width="100%" height="331" backgroundColor={COLORS.backgroundLight}>
                        <rect x="0" y="130" rx="0" ry="0" width="20" height="40" />
                        <rect x="21" y="30" rx="0" ry="0" width="20" height="140" />
                        <rect x="65" y="115" rx="0" ry="0" width="20" height="55" />
                        <rect x="86" y="75" rx="0" ry="0" width="20" height="95" />
                        <rect x="130" y="95" rx="0" ry="0" width="20" height="75" />
                        <rect x="151" y="135" rx="0" ry="0" width="20" height="35" />
                        <rect x="195" y="50" rx="0" ry="0" width="20" height="120" />
                        <rect x="216" y="90" rx="0" ry="0" width="20" height="80" />
                        <rect x="260" y="145" rx="0" ry="0" width="20" height="25" />
                        <rect x="281" y="20" rx="0" ry="0" width="20" height="150" />
                        <rect x="325" y="40" rx="0" ry="0" width="20" height="130" />
                        <rect x="346" y="60" rx="0" ry="0" width="20" height="110" />
                        <rect x="390" y="40" rx="0" ry="0" width="20" height="130" />
                        <rect x="411" y="60" rx="0" ry="0" width="20" height="110" />
                        <rect x="0" y="190" rx="0" ry="0" width="100%" height="30" />
                        <rect x="0" y="230" rx="0" ry="0" width="100%" height="30" />
                        <rect x="0" y="270" rx="0" ry="0" width="100%" height="30" />
                    </ContentLoader>
                ) : (
                    <ContentLoader speed={2} width="100%" height="323" backgroundColor={COLORS.backgroundLight}>
                        <rect x="0" y="75" rx="0" ry="0" width="20" height="130" />
                        <rect x="21" y="95" rx="0" ry="0" width="20" height="110" />
                        <rect x="65" y="75" rx="0" ry="0" width="20" height="130" />
                        <rect x="86" y="95" rx="0" ry="0" width="20" height="110" />
                        <rect x="130" y="75" rx="0" ry="0" width="20" height="130" />
                        <rect x="151" y="95" rx="0" ry="0" width="20" height="110" />
                        <rect x="195" y="75" rx="0" ry="0" width="20" height="130" />
                        <rect x="216" y="95" rx="0" ry="0" width="20" height="110" />
                        <rect x="260" y="75" rx="0" ry="0" width="20" height="130" />
                        <rect x="281" y="95" rx="0" ry="0" width="20" height="110" />
                        <rect x="325" y="75" rx="0" ry="0" width="20" height="130" />
                        <rect x="346" y="95" rx="0" ry="0" width="20" height="110" />
                        <rect x="390" y="75" rx="0" ry="0" width="20" height="130" />
                        <rect x="411" y="95" rx="0" ry="0" width="20" height="110" />
                        <rect x="0" y="220" rx="0" ry="0" width="141" height="48" />
                        <rect x="153" y="220" rx="0" ry="0" width="141" height="48" />
                        <rect x="306" y="220" rx="0" ry="0" width="141" height="48" />
                        <rect x="0" y="291" rx="0" ry="0" width="188" height="32" />
                        <rect x="348" y="306" rx="0" ry="0" width="100" height="17" />
                    </ContentLoader>
                )}
            </div>
        </div>
    );
};

type Props = ApexChartProps & {
    chartData: ChartSeries[];
    categories?: string[];
    xaxiscategories?: string[][];
    distributed?: boolean;
    columnWidth?: string;
    height?: string | number;
    tickYOffset?: number;
    tickXOffset?: number;
    tickWidth?: number;
    groupBy?: IncomeExpenseSummaryGroupByEnum;
    labelsYVerticalOffset?: number;
    categoriesVerticalOffset?: number;
    xAxisLabelFormatter?: (value: string) => string | string[];
    noDataOffset?: number;
    chartOptionOverride?: ApexOptions;
    onDataPointClick?: (config: any) => void;
    selectedTab: string;
};

const IncomeAndExpensesChart: React.FC<Props> = props => {
    const {
        chartData,
        categories = [],
        // xaxiscategories = [],
        type = 'bar',
        distributed = false,
        columnWidth = '50%',
        height = 205,
        tickWidth = 35,
        tickXOffset = 4,
        tickYOffset = -11.5,
        labelsYVerticalOffset = 12,
        categoriesVerticalOffset = -5,
        noDataOffset = 40,
        chartOptionOverride,
        groupBy,
        // eslint-disable-next-line
        width,
        // xAxisLabelFormatter,
        onDataPointClick,
        selectedTab,
        ...rest
    } = props;

    const classes = styles({ groupBy });

    const getMax = () => {
        const maxForEachSeries: number[] = [];
        for (const series of chartData) {
            maxForEachSeries.push(Math.max(...series.data));
        }
        return Math.max(...maxForEachSeries);
    };

    const chartSettings: ApexOptions = {
        series: chartData,
        chart: {
            height: 350,
            toolbar: {
                show: false
            },
            width: '100%',
            parentHeightOffset: 0,
            events: {
                dataPointSelection: function (event, chartContext, config) {
                    onDataPointClick && onDataPointClick(config);
                }
                // dataPointMouseEnter: function (event) {
                //     event.path[0].style.cursor = 'pointer';
                // }
            }
        },
        grid: {
            borderColor: COLORS.apariLightSilver
        },
        plotOptions: {
            bar: {
                horizontal: false,
                rangeBarGroupRows: true,
                distributed,
                columnWidth
            }
        },
        dataLabels: {
            enabled: false
        },
        labels: categories,
        xaxis: {
            type: 'category',
            categories:
                selectedTab === 'Tax Return Summary'
                    ? ['Self-employment', 'Landlord', 'Employment', 'Pension', 'Dividends', 'Capital Gains']
                    : ['September', 'October', 'November', 'December', 'January', 'February'],
            axisTicks: {
                show: false
            },

            labels: {
                offsetY: categoriesVerticalOffset,
                style: {
                    fontSize: '10px',
                    fontFamily: FONTS.PRIMARY_FONT,
                    fontWeight: 700,
                    cssClass: classes.labels
                }
                // formatter: xAxisLabelFormatter
            }
        },
        yaxis: {
            opposite: true,
            labels: {
                formatter: function (value) {
                    return value >= 1000 ? Math.round((value / 1000) * 100) / 100 + 'k' : value.toString();
                },
                offsetY: labelsYVerticalOffset,
                style: {
                    fontSize: '10px',
                    fontFamily: FONTS.PRIMARY_FONT,
                    fontWeight: 700
                },
                align: 'center'
            },
            max: getMax,
            tickAmount: 5,
            axisTicks: {
                show: true,
                color: COLORS.apariLightSilver,
                width: tickWidth,
                offsetX: tickXOffset,
                offsetY: tickYOffset
            },
            forceNiceScale: true,
            tooltip: {
                enabled: false
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            show: false
        },
        states: {
            hover: {
                filter: {
                    type: 'darken',
                    value: 0.5
                }
            },
            active: {
                filter: {
                    type: 'darken',
                    value: 0.3
                }
            }
        },
        noData: {
            text: Localisation.localize('DASHBOARD_SCREEN.INCOME_TYPE_TABS.NO_DATA'),
            style: {
                fontSize: '14px',
                fontFamily: FONTS.PRIMARY_FONT,
                color: COLORS.apariBlack80
            },
            align: 'center',
            verticalAlign: 'top',
            offsetY: noDataOffset
        },
        ...chartOptionOverride
    };
    return <Chart height={height} type={type} options={chartSettings} series={chartData} {...rest} />;
};

export default withWidth()(IncomeAndExpensesChart);
