import { COLORS, FONTS } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    paper: {
        height: 'auto',
        maxWidth: 418,
        minHeight: 418,
        padding: 16,
        paddingTop: 20,
        paddingBottom: 0,
        [theme.breakpoints.up('md')]: {
            width: '100%'
        }
    },
    fullScreenPaper: {
        height: '100% !important',
        maxWidth: '100% !important'
    },
    button: {
        textTransform: 'none',
        color: COLORS.apariBlack80,
        textDecoration: 'underline'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start'
        },
        padding: '40px 64px 30px 64px'
    },
    questionText: {
        fontFamily: FONTS.SECONDARY_FONT,
        fontStyle: 'normal'
    },
    backdrop: {
        opacity: '0.8 !important',
        background: COLORS.backgroundTransparentWhite,
        backdropFilter: 'blur(4px)'
    },
    textContainer: {
        width: '100%',
        maxWidth: 380
    },
    buttonContainer: {
        marginTop: 'auto',
        padding: '40px 64px'
    }
}));
