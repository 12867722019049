export enum SingleSignOnTypes {
    SIGN_IN,
    CONTINUE
}

export enum TokenErrorTypes {
    INVALID,
    EXPIRED
}

export enum AccountType {
    INDIVIDUAL,
    ACCOUNTANT
}

export enum HMRCAuthorizationType {
    RECONNECT = 'reconnect',
    NOT_CONNECTED = 'notConnected'
}
