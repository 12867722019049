import { Business, Property } from '@apari/core-api';
import { TaxYearDto } from '@apari/uk-mtd-api';

export interface RouteParams {
    initialView: string;
}

export enum RecordStatus {
    UNSUBMITTED = 'UNSUBMITTED',
    ALL = 'ALL'
}

export enum ViewType {
    EOP = 'EOP',
    QUARTER = 'QUARTER'
}

export enum RecordType {
    INCOME = 'INCOME',
    EXPENSES = 'EXPENSES'
}

export enum TransactionsToShow {
    UNSUBMITTED = 'UNSUBMITTED',
    ALL = 'ALL',
    FILTERED = 'FILTERED'
}

export interface RouteParams {
    initialView: string;
}

export interface DataOverviewScope {
    scopeEnum: DataOverviewScopeEnum;
    overviewData?: Business & Property;
    business?: Business;
    shared?: boolean;
}

export type TaxYearDtoExtended = TaxYearDto & {
    isOnSA100: boolean;
};

export enum DataOverviewViewSelection {
    RECORDS = 'records',
    SUMMARY = 'summary',
    SUBMISSION = 'submission',
    CALCULATION = 'calculation'
}

export enum DataOverviewScopeEnum {
    ALL_DATA = 'ALL_DATA',
    BUSINESS = 'BUSINESS',
    BUSINESS_BREAKDOWN = 'BREAKDOWN_BUSINESS',
    PROPERTY = 'PROPERTY',
    UNASSIGNED_DATA = 'UNASSIGNED_DATA',
    OTHER_INCOME = 'OTHER_INCOME'
}

export enum SubmitType {
    SAVE = 'SAVE',
    SUBMIT = 'SUBMIT'
}

export enum PreviewCategory {
    USER_VALUE = 'userValue',
    HMRC_VALUE = 'hmrcValue',
    USER_VALUE_NON_FHL = 'userValueNonFHL',
    HMRC_VALUE_NON_FHL = 'hmrcValueNonFHL',
    USER_VALUE_FHL = 'userValueFHL',
    HMRC_VALUE_FHL = 'hmrcValueFHL',
    UK_FHL_PROPERTY = 'ukFhlProperty',
    UK_NON_FHL_PROPERTY = 'ukNonFhlProperty'
}

export enum CategoryValueType {
    INCOME = 'INCOME',
    EXPENSES = 'EXPENSES',
    FHL_INCOME = 'FHL_INCOME',
    FHL_EXPENSES = 'FHL_EXPENSES',
    NON_FHL_INCOME = 'NON_FHL_INCOME',
    NON_FHL_EXPENSES = 'NON_FHL_EXPENSES',
    ADJUSTMENTS = 'ADJUSTMENTS',
    ALLOWANCES = 'ALLOWANCES',
    ADJUSTMENTS_NON_FHL = 'ADJUSTMENTS_NON_FHL',
    ALLOWANCES_NON_FHL = 'ALLOWANCES_NON_FHL',
    ADJUSTMENTS_FHL = 'ADJUSTMENTS_FHL',
    ALLOWANCES_FHL = 'ALLOWANCES_FHL'
}

export enum FlowTypeEnum {
    INCOMES = 'incomes',
    EXPENSES = 'expenses',
    INCOME = 'income',
    EXPENDITURE = 'expenditure',
    ADJUSTMENTS = 'adjustments',
    ALLOWANCES = 'allowances',
    NON_FINANCIALS = 'nonFinancials',
    OTHER = 'other',
    STRUCTURED_BUILDING_ALLOWANCE = 'structuredBuildingAllowance',
    ENHANCED_STRUCTURED_BUILDING_ALLOWANCE = 'enhancedStructuredBuildingAllowance'
}

export enum SubmissionStatusType {
    EOPS = 'EOPS',
    FINAL = 'FINAL'
}
