import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../constants';

export default makeStyles(theme => ({
    root: {
        padding: '18px 50px 50px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: '16px'
        }
    },
    card: {
        display: 'flex',
        background: '#FFFFFF',
        boxShadow:
            '0px 4px 122px rgba(0, 0, 0, 0.04), 0px 1.28121px 27.2503px rgba(0, 0, 0, 0.0238443), 0px 0.517444px 8.11312px rgba(0, 0, 0, 0.0161557)',
        borderRadius: '2px',
        marginTop: '8px',
        padding: '8px',
        alignItems: 'center',
        '& img': {
            marginRight: '16px',
            width: 48,
            height: 45
        }
    },
    hideImage: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '60px',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    formItems: {
        width: '265px',
        [theme.breakpoints.down('sm')]: {
            width: 'auto'
        }
    },
    typeBtn: {
        borderRadius: 5,
        fontSize: '11px !important'
    },
    switchElementLabel: {
        lineHeight: '32px'
    },
    infoIcon: {
        marginLeft: 10,
        color: COLORS.apariBlack40,
        fontSize: 20
    },
    tooltipDesign: {
        maxWidth: 235,
        height: 60,
        padding: '8px 16px 14px 16px',
        backgroundColor: '#B7B7B7',
        color: COLORS.apariWhite,
        fontSize: 10,
        fontWeight: 600
    },
    arrowDesign: {
        color: '#B7B7B7'
    }
}));
