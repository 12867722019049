const DATE_FORMAT = {
    DD_MM_YYYY_HH_MM: 'DD/MM/yyyy hh:mm a',
    DD_MM_YYYY_HH_MM_REGULAR: 'DD/MM/YYYY HH:mm',
    DD_MM_YYYY: 'dd/MM/yyyy',
    FRONTEND_FORMAT: 'DD/MM/yyyy',
    dd_MM_YY: 'dd.MM.yy',
    DD_MM: 'DD/MM',
    DDMMYY: 'DD.MM.YY',
    YYYY_MM_DD: 'YYYY-MM-DD',
    yyyy_MM_DD: 'yyyy-MM-dd',
    DD: 'DD',
    ddd: 'ddd',
    dddd: 'dddd',
    MMM: 'MMM',
    MMMM: 'MMMM',
    YY: 'YY',
    YYYY: 'YYYY',
    ACCOUNTING_PERIOD_FORMAT: '--MM-DD',
    ACCOUNTING_PERIOD_RANGE: 'dd/MM',
    DATE_OF_BIRTH_FORMAT: 'DD/MM/YYYY',
    UK_STANDARD_2: 'DD.MM.YYYY',
    MM_DD: 'MM-DD',
    DATE_WITH_MONTH_NAME: 'DD MMM YYYY'
};

export default DATE_FORMAT;
