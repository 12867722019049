import { makeStyles, Theme } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';

type Props = {
    noSidePadding?: boolean;
    hideMenu?: boolean;
};

export default makeStyles<Theme, Props>(theme => ({
    navigationRoot: {
        [theme.breakpoints.down('md')]: {
            padding: '0 16px 14px 16px'
        }
    },
    menuItem: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 10,
        [theme.breakpoints.down('md')]: {
            borderRadius: 8,
            width: 'calc(100% - 80px)'
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            textAlign: 'center',
            padding: 20,
            marginBottom: 12,
            height: 72
        },
        '&.MuiListItem-root.Mui-selected': {
            backgroundColor: 'rgba(118, 82, 251, 1)'
        },
        '&.MuiListItem-root.Mui-selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.25)'
        }
    },
    menuItemText: {
        fontFamily: FONTS.PRIMARY_FONT,
        fontStyle: 'normal',
        fontSize: 14,
        fontWeight: 700,
        color: COLORS.apariWhite,
        flex: 'none !important'
    },
    menuIcon: {
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
            alignContent: 'center',
            textAlign: 'center'
        },
        [theme.breakpoints.down('md')]: {
            minWidth: 35
        }
    },
    menuIconColor: {
        color: COLORS.apariWhite
    },
    menuSubItem: {
        padding: '2px 12px'
    },
    customMenu: {
        marginLeft: 10,
        [theme.breakpoints.down('md')]: {
            marginLeft: 56,
            '& .MuiList-root': {
                backgroundColor: COLORS.apariLightBlue + ' !important'
            },
            '& .MuiPaper-root': {
                boxShadow: 'none',
                borderRadius: '0px !important'
            }
        },
        '& .MuiPaper-root': {
            borderRadius: '0 5px 5px 0'
        },
        '& .MuiList-root': {
            padding: 10,
            backgroundColor: '#7652FB',
            '& .MuiListItem-root': {
                fontFamily: FONTS.PRIMARY_FONT,
                fontSize: 16,
                fontWeight: 700,
                color: COLORS.apariWhite,
                borderRadius: 5
            },
            '& .MuiListItem-root:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.25)'
            }
        }
    },
    subMenuItemHighlighted: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)'
    },
    collapse: {
        paddingLeft: 40
    },
    menuDivider: {
        backgroundColor: COLORS.apariWhite,
        width: 144,
        marginLeft: 12
    }
}));
