import { TrackEventParams } from '@datapunt/matomo-tracker-react/es/types';

const useEventTracking = () => {
    const trackingEvent = (event: TrackEventParams) => {
        console.log(event);
    };

    return { trackingEvent };
};

export default useEventTracking;
