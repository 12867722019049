import React, { useContext, useEffect, useRef } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { withWidth, WithWidthProps } from '@material-ui/core';
import { AuthenticationContext } from 'context/AuthenticationContext';
import { AnimatedTransition, ApariButton, ApTextField, SubHeaderClean } from 'components';
import NewLayout from 'containers/layouts/NewLayout';
import { UIServices, Localisation } from 'utils';
import globalStyles from 'styles/globalStyles';
import styles from './styles';
import validationSchema from './validationSchema';

type Props = WithWidthProps & RouteComponentProps;

const ResendEmailVerificationScreen: React.FC<Props> = ({ width }) => {
    const globalClasses = globalStyles();
    const classes = styles();
    const history = useHistory();
    const isInitialMount = useRef(true);

    const { verificationEmailSent, setVerificationEmailSent, resendEmailVerificationToken } = useContext(AuthenticationContext);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (verificationEmailSent) {
                history.replace('/sign-in');
                setVerificationEmailSent(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verificationEmailSent]);

    const handleBack = () => {
        history.goBack();
    };

    const submitForm = async (data: any) => {
        await resendEmailVerificationToken(data.email);
    };

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit: submitForm,
        validationSchema: validationSchema
    });

    const renderMainContent = () => {
        return (
            <>
                <SubHeaderClean
                    title={Localisation.localize('SignInScreen.welcomeToApari')}
                    noSidePadding
                    noBackButton
                    alternativeFont
                    messages={[Localisation.localize('general.resendEmailVerification')]}
                />
                <form
                    className={clsx(globalClasses.fullWidth, globalClasses.flexColumn, globalClasses.fullHeight, classes.formContainer)}
                    onSubmit={formik.handleSubmit}
                >
                    <ApTextField control="email" formik={formik} label={Localisation.localize('general.emailAddress')} />
                    <ApariButton
                        className={clsx(classes.submitButton)}
                        disabled={!formik.isValid}
                        type="submit"
                        fullWidth
                        color="primary"
                        variant="contained"
                        size="small"
                    >
                        {Localisation.localize('general.confirm')}
                    </ApariButton>
                </form>
            </>
        );
    };

    const renderMobileContent = () => {
        return (
            <div className={clsx(globalClasses.contentWrapper, classes.contentWrapperOverride)}>
                <div className={clsx(globalClasses.fullHeight, globalClasses.fullWidth)}>
                    <div className={clsx(globalClasses.fullHeight)}>
                        <div className={clsx(globalClasses.fullHeight, globalClasses.flexColumn, globalClasses.fontRoboto)}>
                            {renderMainContent()}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderDesktopContent = () => {
        return (
            <div className={clsx(globalClasses.contentWrapper, classes.contentWrapperOverride)}>
                <div className={clsx(globalClasses.fullHeight, globalClasses.fullWidth, globalClasses.flexRow)}>
                    <div className={clsx(globalClasses.flexColumnCenter, globalClasses.fullHeight, globalClasses.fontRoboto)}>
                        {renderMainContent()}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <NewLayout
            whiteBackground
            hideHeader={false}
            hideBack={false}
            hideFooter
            hideTitle
            enableBackgroundArrows
            handleBack={handleBack}
            headerText={Localisation.localize('general.registration')}
        >
            <AnimatedTransition addHeight centerVerticallyInDesktop className={classes.marginBottomOverride}>
                {UIServices.isMobile(width) ? renderMobileContent() : renderDesktopContent()}
            </AnimatedTransition>
        </NewLayout>
    );
};

export default withWidth()(ResendEmailVerificationScreen);
