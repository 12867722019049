import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    pickerRoot: {
        marginTop: 4,
        marginBottom: 0,
        '& .MuiButtonBase-root': {
            padding: 0
        },
        '& .MuiSvgIcon-root': {
            fontSize: 18
        }
    },
    root: {
        cursor: 'pointer',
        '& .MuiFormLabel-root.Mui-disabled': {
            color: COLORS.apariBlack40
        },
        '& .MuiInputBase-root.Mui-disabled': {
            borderColor: COLORS.apariBlack40,
            color: COLORS.apariBlack40
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 10
        }
    },
    disableHover: {
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.apariBlack20
        }
    },
    textFieldRoot: {
        '& label': {
            marginLeft: '-3px'
        },
        color: COLORS.apariBlack80,
        borderColor: 'black',
        '& .MuiInputBase-input.Mui-disabled': {
            color: COLORS.apariBlack40 + ' !important'
        },
        '& input': {
            color: COLORS.apariBlack80,
            '&::placeholder': {
                color: theme.palette.grey[400],
                opacity: 1
            }
        }
    }
}));
