import React from 'react';
import { IconButton, Theme, Typography, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialog, { DialogProps } from '@material-ui/core/Dialog';
import { COLORS } from 'constants/index';
import CloseIcon from '@material-ui/icons/Close';
import globalStyles from 'styles/globalStyles';
import clsx from 'clsx';
import { GlobalServices } from 'utils';

interface Props {
    open: boolean;
    title?: string;
    children: React.ReactElement | React.ReactElement[];
    titleClassName?: string;
    onCloseDialog?: () => void;
    titleWrapperClass?: string;
    disableCloseButton?: boolean;
}

const Dialog = withStyles(() => ({
    root: {
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
        }
    },
    paper: {
        overflowY: 'unset'
    },
    paperFullWidth: {
        width: '100%',
        margin: '30px 0',
        boxShadow:
            '0px -5px 15px rgba(0, 0, 0, 0.05), 0px 100px 80px rgba(0, 0, 0, 0.04), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.0260636), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.02), 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.0139364)'
    }
}))(MuiDialog);

const DialogTitle = withStyles((theme: Theme) => ({
    root: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: 0,
        paddingBottom: 0,
        '& h2': {
            fontSize: 18,
            color: COLORS.apariBlack
        }
    }
}))(MuiDialogTitle);

const DialogContent = withStyles(() => ({
    root: {
        padding: 0,
        '&:first-child': {
            paddingTop: 0
        }
    }
}))(MuiDialogContent);

const ApariDialog: React.FC<Props & DialogProps> = ({
    open,
    title,
    titleClassName,
    onCloseDialog,
    children,
    titleWrapperClass = '',
    disableCloseButton = false,
    ...rest
}) => {
    const globalClasses = globalStyles();

    return (
        <Dialog open={open} BackdropProps={{}} maxWidth="lg" {...rest}>
            {GlobalServices.isDefined(title) && (
                <DialogTitle disableTypography className={clsx(globalClasses.flexRowSpaceBetween, titleWrapperClass)}>
                    <Typography variant="h5" className={clsx(titleClassName ? titleClassName : globalClasses.font18weight600Dark)}>
                        {title}
                    </Typography>
                    {onCloseDialog && (
                        <IconButton
                            aria-label="close"
                            data-cy="dialogCloseIcon"
                            size="medium"
                            edge="end"
                            onClick={onCloseDialog}
                            color="default"
                            disabled={disableCloseButton}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </DialogTitle>
            )}
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};

export default ApariDialog;
