import React, { useContext } from 'react';
import { Avatar, AvatarProps } from '@material-ui/core';
import { AuthenticationContext } from 'context/AuthenticationContext';
import styles from './styles';

type Props = AvatarProps & {
    avatarSize?: number;
    paddingTopAdjustment?: number;
};

const ApariAvatar: React.FC<Props> = ({ avatarSize = 32, paddingTopAdjustment, ...rest }) => {
    const classes = styles({ avatarSize, paddingTopAdjustment });

    const { user } = useContext(AuthenticationContext);

    return (
        <Avatar classes={{ colorDefault: classes.defaultAvatar, root: classes.rootAvatar }} {...rest}>
            <span>{user?.firstName ? user?.firstName?.charAt(0).toUpperCase() : undefined}</span>
        </Avatar>
    );
};

export default ApariAvatar;
