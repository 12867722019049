import { GLOBAL } from 'constants/index';
import { GlobalServices } from 'utils';

export default function useHMRCFraudHeaders(): Record<string, any> {
    const { screen, window } = getScreenDetails();
    const fraudHeader = {
        browserPlugins: getPlugins(),
        deviceId: GlobalServices.getUUID(),
        multiFactor: get2FADetails(),
        screens: screen,
        //eslint-disable-next-line
        // @ts-ignore
        licenses: getLicenses(),
        timezone: getTimezone(),
        version: GLOBAL.VERSION,
        windowSize: window
    };

    return {
        fraudHeader
    };
}

function getLicenses() {
    if (localStorage.getItem(GLOBAL.LICENSE_KEY)) {
        return GLOBAL.LICENSE_APP_NAME + '=' + encodeURIComponent(localStorage.getItem(GLOBAL.LICENSE_KEY) as string);
    } else {
        return null;
    }
}

function getTimezone() {
    const date = new Date();

    const tzo = -date.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';

    const pad = function (num: number) {
        return (num < 10 ? '0' : '') + num;
    };

    return 'UTC' + dif + pad(Math.floor(Math.abs(tzo) / 60)) + ':' + pad(Math.abs(tzo) % 60);
}

function getPlugins() {
    let pluginString = '';
    const { plugins } = navigator;
    const { length } = plugins;

    for (let i = 0; i < length; i++) {
        pluginString += plugins[i].name;
        if (i < length - 1) {
            pluginString += ',';
        }
    }

    return encodeURI(pluginString);
}

function get2FADetails() {
    const timestamp = localStorage.getItem(GLOBAL.MFA_TIMESTAMP);
    const reference = localStorage.getItem(GLOBAL.MFA_REFERENCE) as string;
    const type = localStorage.getItem(GLOBAL.MFA_TYPE);

    if (timestamp && reference && type) {
        return `type=${encodeURIComponent(type)}&timestamp=${encodeURIComponent(timestamp)}&unique-reference=${encodeURIComponent(
            reference
        )}`;
    } else {
        return null;
    }
}

function getScreenDetails() {
    const { devicePixelRatio, screen, innerWidth, innerHeight } = window;
    const { height, width, colorDepth } = screen;

    return {
        screen: `width=${width}&height=${height}&scaling-factor=${devicePixelRatio}&colour-depth=${colorDepth}`,
        window: `width=${innerWidth}&height=${innerHeight}`
    };
}
