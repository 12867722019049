import React from 'react';
import clsx from 'clsx';
import { IconButton, withWidth, WithWidthProps } from '@material-ui/core';
import { ArrowBackIcon } from 'resources/icons';
import { Localisation, UIServices } from 'utils';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

const BackToWebsite: React.FC<WithWidthProps> = ({ width }) => {
    const globalClasses = globalStyles();
    const classes = styles();

    const handleBack = () => {
        window.open('https://www.apari-digital.com/', '_self');
    };

    return (
        <div
            className={clsx(
                globalClasses.flexRowStart,
                globalClasses.cursorPointer,
                UIServices.isMobile(width) ? globalClasses.marginBottom8 : globalClasses.marginBottom24
            )}
            onClick={handleBack}
        >
            <IconButton size="small" className={clsx(globalClasses.marginRight12, classes.backButton)}>
                <ArrowBackIcon />
            </IconButton>
            <p
                className={clsx(
                    globalClasses.alignSelfCenter,
                    globalClasses.marginBottom4,
                    globalClasses.marginTop4,
                    classes.backToWebsite
                )}
            >
                {Localisation.localize('general.BACK_TO_WEBSITE')}
            </p>
        </div>
    );
};

export default withWidth()(BackToWebsite);
