import React from 'react';
import clsx from 'clsx';
import { cond, stubTrue } from 'lodash';
import { REGEX } from 'constants/index';
import { PasswordStrengthTypes } from 'types';
import { GlobalServices, Localisation } from 'utils';
import globalStyles from 'styles/globalStyles';
import styles from './styles';
import { DissatisfiedSadIcon, NeutralIcon, SatisfiedHappyIcon } from 'resources/icons';

type Props = {
    plainPassword: string;
};

const icons: Record<PasswordStrengthTypes, any> = {
    [PasswordStrengthTypes.STRONG]: <SatisfiedHappyIcon width={20} height={20} fill="#7CBD82" />,
    [PasswordStrengthTypes.MEDIUM]: <NeutralIcon width={20} height={20} fill="#E8B23C" />,
    [PasswordStrengthTypes.WEAK]: <DissatisfiedSadIcon width={20} height={20} fill="#D9455F" />
};

const PasswordStrengthMeter: React.FC<Props> = ({ plainPassword }) => {
    const classes = styles();
    const globalClasses = globalStyles();

    const renderPasswordStrength = (type: PasswordStrengthTypes) => {
        return (
            <div className={globalClasses.flexRowCenter}>
                {icons[type]}
                <div className={clsx(globalClasses.marginLeft8, (classes as any)[`${type}PasswordColor`])}>
                    {Localisation.localize(`signUpWithEmailScreen.PASSWORD_${type.toUpperCase()}`)}
                </div>
            </div>
        );
    };

    const checkPasswordStrength = cond([
        [(value: number) => value === 4, () => renderPasswordStrength(PasswordStrengthTypes.STRONG)],
        [(value: number) => value >= 2, () => renderPasswordStrength(PasswordStrengthTypes.MEDIUM)],
        [stubTrue, () => renderPasswordStrength(PasswordStrengthTypes.WEAK)]
    ]);

    return (
        <div className={clsx(globalClasses.font12weight400Dark, globalClasses.flexRowStart, classes.passwordStrength)}>
            <div className={globalClasses.marginRight10}>{Localisation.localize('signUpWithEmailScreen.PASSWORD_STRENGTH')}</div>
            <div>{checkPasswordStrength(GlobalServices.checkHowManyRegexRulesAreMet(REGEX.PASSWORD_STRENGTH_ARRAY, plainPassword))}</div>
        </div>
    );
};

export default PasswordStrengthMeter;
