import { makeStyles } from '@material-ui/core';
import { FONTS, COLORS } from 'constants/index';

export default makeStyles(theme => ({
    container: {
        alignItems: 'center'
    },
    dialogContainer: {
        [theme.breakpoints.up('md')]: {
            minWidth: '550px',
            minHeight: '600px'
        },
        position: 'relative'
    },
    imageContainer: {
        marginTop: 'calc(.1 * 100vh)'
    },
    imageRotate: {
        transform: 'rotate(180deg)'
    },
    titleContainer: {
        textAlign: 'center'
    },
    title: {
        fontFamily: [FONTS.SECONDARY_FONT, 'sans-serif'].join(','),
        lineHeight: '23px'
    },
    subtitleContainer: {
        textAlign: 'center'
    },
    subtitle: {
        color: COLORS.apariBlack40
    },
    padding15: {
        paddingLeft: '15%',
        paddingRight: '15%'
    },
    progressBar: {
        height: 12,
        width: '68.75%',
        marginTop: 60,
        borderRadius: 2,
        '& .MuiLinearProgress-bar': {
            borderRadius: 2
        }
    },
    progressBarSecondaryColor: {
        backgroundColor: COLORS.apariBlack10
    },
    bottomContainer: {
        position: 'absolute',
        bottom: 0
    },
    tips: {
        fontSize: 10,
        lineHeight: '10.6px',
        color: COLORS.apariBlack40,
        textAlign: 'center',
        marginBottom: 30,
        paddingLeft: '20%',
        paddingRight: '20%'
    },
    buttonContainer: {},
    negativeButton: {
        backgroundColor: COLORS.backgroundDark
    }
}));
