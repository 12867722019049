import React from 'react';
import {Tabs, TabsProps} from '@material-ui/core';
import ApariCardTab from './ApariCardTab';
import styles from './styles';

type Tab = { label: string, value: string | number }


type Props = TabsProps & {
    onChangeTab: (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => void | ((event: React.ChangeEvent<Record<string, unknown>>, newValue: string) => void),
    initialValue: string | number,
    tabs: Tab[]
}

const ApariCardTabs: React.FC<Props> = ({tabs, onChangeTab, initialValue, classes}) => {
    const cssClasses = styles();

    const renderTabs = () => {
        return tabs.map((tab: Tab) => <ApariCardTab key={tab.label} label={tab.label} value={tab.value}/>);
    };

    return <Tabs
        value={initialValue}
        textColor="primary"
        onChange={onChangeTab}
        classes={{
            indicator: cssClasses.indicator,
            flexContainer: cssClasses.flexContainer,
            ...classes
        }}
    >
        {renderTabs()}
    </Tabs>;
};


export default ApariCardTabs;
