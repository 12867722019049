import React from 'react';
import clsx from 'clsx';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { InputAdornment, TextFieldProps } from '@material-ui/core';
import { FormikContextType, getIn } from 'formik';
import { GlobalServices } from 'utils';
import { ApariTextField } from '../../../common/ui';
import styles from './styles';

type AmountInputProps = Omit<TextFieldProps, 'name'> & {
    name: string;
    valueType?: 'number' | 'string' | 'formatted-string';
    bolded?: boolean;
    formik: FormikContextType<any>;
};

type Target = {
    target: {
        value?: any;
    };
};

type AmountFormatProps = NumberFormatProps & {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (target: Target) => void;
    name: string;
};

const AmountFormat: React.FC<AmountFormatProps> = ({ inputRef, onChange, ...rest }) => {
    return (
        <NumberFormat
            {...rest}
            getInputRef={inputRef}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator=","
            allowNegative={false}
            isNumericString
            onValueChange={values => {
                onChange({
                    target: {
                        value: values
                    }
                });
            }}
        />
    );
};

const MTDFormAmountInputField: React.FC<AmountInputProps> = ({
    formik,
    name,
    valueType = 'number',
    variant = 'standard',
    margin = 'none',
    bolded,
    ...rest
}) => {
    const classes = styles();
    return (
        <ApariTextField
            classes={{
                root: clsx(
                    classes.root,
                    bolded && classes.boldedRoot,
                    !GlobalServices.isEmpty(getIn(formik.values, name)) && classes.filledRoot,
                    !GlobalServices.isEmpty(getIn(formik.values, name)) && bolded && classes.filledBoldedRoot
                )
            }}
            inputProps={{ inputMode: 'decimal' }}
            InputProps={{
                inputComponent: AmountFormat as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                startAdornment: <InputAdornment position="start">£</InputAdornment>
            }}
            fullWidth
            variant={variant}
            margin={margin}
            value={getIn(formik.values, name)}
            placeholder={getIn(formik.values, name) ? '' : '0.00'}
            error={getIn(formik.touched, name) && Boolean(getIn(formik.errors, name))}
            helperText={getIn(formik.touched, name) && getIn(formik.errors, name)}
            onChange={(e: React.ChangeEvent<any>) => {
                const { value } = e.target;
                switch (valueType) {
                    case 'number':
                        formik.setFieldValue(name, value.floatValue || value.floatValue === 0 ? value.floatValue : '');
                        break;
                    case 'string':
                        formik.setFieldValue(name, value.value || '');
                        break;
                    case 'formatted-string':
                        formik.setFieldValue(name, value.formattedValue || '');
                        break;
                    default:
                        formik.setFieldValue(name, value.floatValue || '');
                        break;
                }
            }}
            {...rest}
        />
    );
};

export default MTDFormAmountInputField;
