import React, { ChangeEvent, useContext, useState } from 'react';
import clsx from 'clsx';
import { ClickAwayListener, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TilesContext, { iTiles } from 'context/TilesContext';
import './styles.css';
import styles from './styles';
import globalStyles from 'styles/globalStyles';
import { TILE_NAMES } from 'constants/index';

type TileProps = {
    text: string;
    tooltip: string;
    disabled?: boolean;
    className: string;
    tile: iTiles;
    taxYearAlreadySubmitted?: boolean;
};

const Tile: React.FC<TileProps> = ({ text, tooltip, disabled, className, tile, taxYearAlreadySubmitted }) => {
    const { selectedTiles, setSelectedTiles, tiles, setTiles } = useContext(TilesContext);

    const classes = styles({ taxYearAlreadySubmitted });
    const globalClasses = globalStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState<boolean>(false);

    const handleTileSelection = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;

        tile.active = checked;
        setTiles([...tiles]);

        if (!checked) {
            setSelectedTiles(selectedTiles.filter(elem => elem !== value));
        }
    };

    return (
        <React.Fragment>
            <article className={clsx('tile__checkbox', classes.tile, !tile.active && classes.inactiveTile)}>
                <input
                    type="checkbox"
                    className={`tile ${className}`}
                    id={text.toLowerCase().replaceAll(' ', '-') + '__tile'}
                    value={text}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleTileSelection(e)}
                    defaultChecked={tile.active || tile.name === TILE_NAMES.INCOME_FROM_PROPERTY}
                    disabled={disabled}
                />
                <div className={disabled ? 'tooltip__area' : ''}>
                    <span className={classes.tile__text}>{text}</span>
                    <span className={clsx(classes.tile__info_icon, tile.active ? classes.activeInfoTooltip : classes.inactiveInfoTooltip)}>
                        {isMobile ? (
                            <ClickAwayListener onClickAway={() => setOpen(false)}>
                                <Tooltip
                                    PopperProps={{ style: { marginTop: -12 } }}
                                    onClose={() => setOpen(false)}
                                    open={open}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={tooltip}
                                >
                                    <InfoOutlinedIcon className={clsx(classes.fontSize16)} onClick={() => setOpen(true)} />
                                </Tooltip>
                            </ClickAwayListener>
                        ) : (
                            <Tooltip
                                title={<div className={clsx(globalClasses.preWrap)}>{tooltip}</div>}
                                PopperProps={{ style: { marginTop: -12 } }}
                            >
                                <InfoOutlinedIcon className={clsx(classes.fontSize16)} />
                            </Tooltip>
                        )}
                    </span>
                </div>
            </article>
        </React.Fragment>
    );
};

export default Tile;
