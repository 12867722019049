import React from 'react';
import { TextField, InputAdornment, IconButton, TextFieldProps } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FilterIcon from '@material-ui/icons/Tune';
import styles from './styles';

interface Props {
    onSearchClick?: (event: any) => void;
    onFilterClick?: () => void;
    onEnter?: (event: any) => void;
}

const SearchFilterBar: React.FC<TextFieldProps & Props> = ({ value, onSearchClick, onFilterClick, onEnter, ...rest }) => {
    const classes = styles();

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            onEnter && onEnter(event);
        }
    };

    return (
        <TextField
            fullWidth
            classes={{
                root: classes.textField
            }}
            InputLabelProps={{
                className: classes.inputLabel
            }}
            InputProps={{
                endAdornment: (
                    <>
                        <InputAdornment position="end">
                            <IconButton style={{ padding: 0 }} onClick={onSearchClick} disabled={!value}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                        {onFilterClick && (
                            <InputAdornment position="end">
                                <IconButton style={{ padding: 0 }} onClick={onFilterClick}>
                                    <FilterIcon />
                                </IconButton>
                            </InputAdornment>
                        )}
                    </>
                )
            }}
            onKeyPress={handleKeyPress}
            value={value}
            {...rest}
        />
    );
};

export default SearchFilterBar;
