import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';
import { HMRCMessageContent, HMRCMessageTypes } from 'types';
import { Localisation } from 'utils';
import styles from './styles';

type MessageBlockProps = {
    type: HMRCMessageTypes;
    messages: HMRCMessageContent[];
    title?: string;
    className?: string;
};

const MessageBlock: React.FC<MessageBlockProps> = ({ type, title, messages, className }) => {
    const classes = styles();
    const globalClasses = globalStyles();

    const getTitle = () => {
        if (title) return title;
        switch (type) {
            case HMRCMessageTypes.ERRORS:
                return Localisation.localize('CALCULATION_OVERVIEW.MESSAGES.ERRORS_TITLE');
            case HMRCMessageTypes.WARNINGS:
                return Localisation.localize('CALCULATION_OVERVIEW.MESSAGES.WARNINGS_TITLE');
            case HMRCMessageTypes.INFO:
                return Localisation.localize('CALCULATION_OVERVIEW.MESSAGES.INFO_TITLE');
            default:
                return undefined;
        }
    };

    const getIconClass = () => {
        switch (type) {
            case HMRCMessageTypes.ERRORS:
                return classes.errorIcon;
            case HMRCMessageTypes.WARNINGS:
                return classes.warningIcon;
            case HMRCMessageTypes.INFO:
                return classes.infoIcon;
            default:
                return '';
        }
    };

    const renderMessages = () => {
        return messages.map((message, index) => {
            return (
                <div
                    key={message.id}
                    className={clsx(globalClasses.flexRow, index !== messages.length - 1 && globalClasses.marginBottom20)}
                >
                    <div className={clsx(globalClasses.marginRight16, globalClasses.alignSelfCenter)}>
                        <ErrorOutlineIcon className={clsx(getIconClass())} />
                    </div>
                    <div
                        className={clsx(globalClasses.font13weight400Dark, globalClasses.paddingTop4, classes.textWrapper)}
                        dangerouslySetInnerHTML={{ __html: message.text! }}
                    />
                </div>
            );
        });
    };

    return messages.length > 0 ? (
        <div className={clsx(classes.messageBlockContainer, className)}>
            {getTitle() && <p className={clsx(globalClasses.font15weight700Dark)}>{getTitle()}</p>}
            <div>{renderMessages()}</div>
        </div>
    ) : (
        <div />
    );
};

export default MessageBlock;
