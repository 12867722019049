import { localize } from 'utils/localisation/index';
import * as yup from 'yup';

const validationSchema = yup.object({
    transactionDate: yup.date().required(localize('formErrors.fieldIsRequired')).typeError(localize('formErrors.invalidDate')),
    category: yup.number().required(localize('formErrors.fieldIsRequired')),
    originalAmount: yup.number().required(localize('formErrors.fieldIsRequired')),
    counterpartName: yup.string().max(255, localize('formErrors.255charactersMaximum')),
    purpose: yup.string().max(255, localize('formErrors.255charactersMaximum')),
    notes: yup.string().max(255, localize('formErrors.255charactersMaximum')),
    businessId: yup.string().required(localize('formErrors.fieldIsRequired')),
    bankAccount: yup.string().required(localize('formErrors.fieldIsRequired'))
});

export const importedTransactionValidationSchema = yup.object({
    category: yup.number().required(localize('formErrors.fieldIsRequired')),
    businessId: yup.string().required(localize('formErrors.fieldIsRequired'))
});

export default validationSchema;
