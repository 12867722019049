import React, { useContext } from 'react';
import { Dialog, IconButton, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';
import styles from './styles';
import NavigationContent from '../NavigationContent';
import { ApariAvatar } from 'components';
import { AuthenticationContext } from 'context/AuthenticationContext';

interface Props {
    handleClose: () => void;
    open: boolean;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const HeaderMenu: React.FC<Props> = ({ handleClose, open }) => {
    const globalClasses = globalStyles();
    const classes = styles();
    const { authenticated, user } = useContext(AuthenticationContext);
    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={clsx(classes.navigationDialog)}>
            <div className={clsx(globalClasses.flexRowSpaceBetween, classes.dialogTitle)}>
                {authenticated && (
                    <div className={clsx(globalClasses.fullHeight, globalClasses.flexRowCenter)}>
                        <ApariAvatar className={clsx(globalClasses.marginRight8)} />
                        <div className={clsx(globalClasses.flexColumnStart)}>
                            <span className={clsx(globalClasses.font12weight600White)}>
                                {(user.firstName || '') + ' ' + (user.lastName || '')}
                            </span>
                            <span className={clsx(globalClasses.font10weight400White)}>{user.email || '-'}</span>
                        </div>
                    </div>
                )}
                <IconButton className={clsx(classes.menuIconColor, classes.closeIcon)} color="inherit" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <NavigationContent />
        </Dialog>
    );
};

export default HeaderMenu;
