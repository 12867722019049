import React, { createContext, useState } from 'react';
import { Timeframe, DataOverviewViewSelection, DataOverviewScope, DataOverviewScopeEnum, TaxYearDtoExtended } from 'types';

export interface DataOverviewContextProps {
    currentTimeframe: Timeframe;
    taxYears: TaxYearDtoExtended[];
    currentTaxYear?: TaxYearDtoExtended;
    currentView: DataOverviewViewSelection;
    currentScope: DataOverviewScope;
    overviewScopes: Record<string, DataOverviewScope>;
    loadingScopes: boolean;
    setLoadingScopes: React.Dispatch<React.SetStateAction<boolean>>;
    loadingTaxYears: boolean;
    setLoadingTaxYears: React.Dispatch<React.SetStateAction<boolean>>;
    setOverviewScopes: React.Dispatch<React.SetStateAction<Record<string, DataOverviewScope>>>;
    setCurrentScope: React.Dispatch<React.SetStateAction<DataOverviewScope>>;
    setCurrentView: React.Dispatch<React.SetStateAction<DataOverviewViewSelection>>;
    setCurrentTimeFrame: React.Dispatch<React.SetStateAction<Timeframe>>;
    setCurrentTaxYear: React.Dispatch<React.SetStateAction<TaxYearDtoExtended | undefined>>;
    setTaxYears: React.Dispatch<React.SetStateAction<TaxYearDtoExtended[]>>;
    disabledTaxYears: Record<string, boolean>;
    setDisabledTaxYears: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const DataOverviewContext = createContext({} as DataOverviewContextProps);

export const DataOverviewProvider: React.FC = ({ children }) => {
    const [currentView, setCurrentView] = useState<DataOverviewViewSelection>(DataOverviewViewSelection.RECORDS);
    const [loadingScopes, setLoadingScopes] = useState(false);
    const [loadingTaxYears, setLoadingTaxYears] = useState(false);
    const [currentTimeframe, setCurrentTimeFrame] = useState<Timeframe>(Timeframe.ANNUAL);
    const [overviewScopes, setOverviewScopes] = useState<Record<string, DataOverviewScope>>({});
    const [taxYears, setTaxYears] = useState<TaxYearDtoExtended[]>([]);
    const [currentTaxYear, setCurrentTaxYear] = useState<TaxYearDtoExtended | undefined>();
    const [currentScope, setCurrentScope] = useState<DataOverviewScope>({ scopeEnum: DataOverviewScopeEnum.ALL_DATA });
    const [disabledTaxYears, setDisabledTaxYears] = useState<Record<string, boolean>>({});

    return (
        <DataOverviewContext.Provider
            value={{
                currentTaxYear,
                currentTimeframe,
                currentView,
                currentScope,
                overviewScopes,
                loadingScopes,
                setLoadingScopes,
                loadingTaxYears,
                setLoadingTaxYears,
                setOverviewScopes,
                setCurrentScope,
                setCurrentView,
                setCurrentTaxYear,
                setCurrentTimeFrame,
                taxYears,
                setTaxYears,
                disabledTaxYears,
                setDisabledTaxYears
            }}
        >
            {children}
        </DataOverviewContext.Provider>
    );
};
