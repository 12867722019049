import React from 'react';
import clsx from 'clsx';
import { ListItem } from '@material-ui/core';
import globalStyles from 'styles/globalStyles';
import { ApariCheckbox } from 'components';
import styles from './styles';

type Props = {
    name: string;
    onChange: (checked: boolean) => void;
    checked: boolean;
    disabled?: boolean;
    text: string;
    imgSrc: string;
};

const ApariMealCard: React.FC<Props> = ({ name, onChange, checked, text, imgSrc, disabled = false }) => {
    const classes = styles();
    const globalClasses = globalStyles();

    return (
        <ListItem
            button
            disabled={disabled}
            className={clsx(classes.card, checked && classes.checkedCard, globalClasses.flexColumn)}
            onClick={() => onChange(!checked)}
        >
            <div className={clsx(globalClasses.flexColumnCenter, globalClasses.fullWidth, globalClasses.fullHeight)}>
                <div className={clsx(globalClasses.flexColumnCenter, classes.icon)}>
                    <img src={imgSrc} alt="meal_icon" />
                </div>
                <div className={clsx(classes.checkBoxWrapper)}>
                    <div className={clsx(globalClasses.flexColumnCenter, globalClasses.font12weight600, classes.text)}>{text}</div>
                    <div className={clsx(globalClasses.flexColumnCenter, globalClasses.fullWidth)} style={{ zIndex: 0, padding: 0 }}>
                        <ApariCheckbox disabled={disabled} checked={checked || false} name={name} primaryBorderColor={true} />
                    </div>
                </div>
            </div>
        </ListItem>
    );
};

export default ApariMealCard;
