const RESPONSE_CODES: Record<string, any> = {
    NOT_AUTHORIZED: 401,
    USER_ALREADY_EXISTS: 500,
    BAD_CREDENTIALS: 405,
    USER_DOES_NOT_EXISTS: 409,
    EXPIRED_TOKEN: 410,
    INVALID_TOKEN: 498,
    USER_NOT_CONFIRMED: 407,
    NOT_IMPLEMENTED: 451,
    CARD_ERROR: 600,
    STRIPE_ERROR: 601,
    TWO_FACTOR_AUTHENTICATION_REQUIRED: 706,
    ACCOUNT_ALREADY_CONFIRMED: 705,
    OAUTH_USER_ALREADY_EXITS: 702,
    INVALID_TWO_FACTOR_CODE: 708,
    RECOVERY_CODE_USED_ON_ACTIVATION: 709,
    ALREADY_REGISTERED_USING_EMAIL: 716,
    CANT_RESET_PASSWORD_FOR_THIS_EMAIL: 715,
    HMRC_AUTHORIZATION_REQUIRED: 717,
    DELETE_ALREADY_REQUESTED: 721,
    CANNOT_SET_SUBSCRIPTION_TO_STARTER_WHEN_ACTIVE_SHARING: 732,
    724: 'MISSING_UK_LANDLORD_BUSINESS',
    725: 'MISSING_FOREIGN_LANDLORD_BUSINESS',
    727: 'SHARE_AMOUNT_MUST_BE_LESS_THAN_100',
    728: 'CANNOT_SHARE_PROPERTY_TO_YOURSELF',
    729: 'CANNOT_SHARE_PROPERTY_TO_SAME_PERSON',
    730: 'EXCEEDED_MAX_NUMBER_OF_SHARE_REQUESTS',
    731: 'CANNOT_DELETE_SHARED_PROPERTY',
    733: 'HAS_ASSIGNED_TRANSACTIONS',
    734: 'CANT_SHARE_PROPERTY_ON_STARTER_PLAN',
    741: 'THIS_ACCOUNT_IS_MANAGED_BY_AGENT',
    CANNOT_DOWNGRADE_HAS_MORE_THAN_5_ACCEPTED: 740
};

export default RESPONSE_CODES;
