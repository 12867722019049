export enum PreviewState {
    UPLOADING,
    ERROR,
    SUCCESS
}

export enum ImageTypes {
    JPEG = 'JPEG',
    PNG = 'PNG',
    JPG = 'JPG'
}
