import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTheme } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import { DialogProps, useMediaQuery } from '@material-ui/core';
import { TransactionControllerApi, TransactionDto } from '@apari/banking-api';
import { DocumentDto, StorageControllerApi } from '@apari/storage-api';
import { BusinessSourceBasicInfo, PaymentStatusDtoSubscriptionTypeEnum } from '@apari/core-api/models/index';
import { ApariButton, ApariDialog, LoadingPlaceholder, LoadingTransactionCard, TextButtonWithPlus, TransactionCard } from 'components';
import { AuthenticationContext } from 'context/AuthenticationContext';
import IncomeTypesContext from 'context/IncomeTypesContext';
import { AppContext } from 'context/AppContext';
import { GlobalServices, Localisation, NumberServices, RecordKeepingServices } from 'utils';
import { localize } from 'utils/localisation';
import globalStyles from 'styles/globalStyles';
import { NotificationTypes } from 'types';
import styles from './styles';
import SplitTransactionSection from './SplitTransactionSection';

type Props = DialogProps & {
    transaction: TransactionDto;
    open: boolean;
    onCloseDialog: () => void;
    onSuccess: () => void;
};

type SplitTransaction = TransactionDto & {
    tempId?: string;
    documents?: DocumentDto[];
    deleted?: boolean;
};

const transactionControllerApi = new TransactionControllerApi();
const storageControllerApi = new StorageControllerApi();

const SplitTransactionDialog: React.FC<Props & DialogProps> = ({ transaction, open, onCloseDialog, onSuccess, ...rest }) => {
    const [parentTransaction, setParentTransaction] = useState<TransactionDto>();
    const [tempSplitTransactions, setTempSplitTransactions] = useState<SplitTransaction[]>([]);
    const [splitTransactions, setSplitTransactions] = useState<SplitTransaction[]>([]);
    const [splitTransactionsLoading, setSplitTransactionsLoading] = useState(!!transaction.parentTransactionId);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [haveZeroAmount, setHaveZeroAmount] = useState(false);
    const [validationScheme, setValidationScheme] = useState<any>({});
    const [validationSchemeForTemp, setValidationSchemeForTemp] = useState<any>({});
    const [incomeTypesOptions, setIncomeTypeOptions] = useState<BusinessSourceBasicInfo[]>([]);
    const { getIncomeTypes } = useContext(IncomeTypesContext);

    const { doesUserHaveLowestRequiredPackage } = useContext(AuthenticationContext);
    const { showNotifications, showLoadingBar, hideLoadingBar } = useContext(AppContext);

    const globalClasses = globalStyles();
    const classes = styles();
    const history = useHistory();
    const theme = useTheme();
    const mobileDown = useMediaQuery(theme.breakpoints.down('sm'));

    const formik = useFormik<any>({
        initialValues: {},
        validationSchema: yup.object().shape({
            ...validationScheme,
            ...validationSchemeForTemp
        }),
        onSubmit: async values => {
            if (
                splitTransactions.concat(tempSplitTransactions).filter(transaction => !transaction.deleted).length === 0 ||
                (!haveZeroAmount && totalAmount === parentTransaction?.originalAmount)
            ) {
                const splitTransactionsForPreparing: SplitTransaction[] = Object.values(values);
                const preparedSplittedTransactions = prepareSplittedTransactions(splitTransactionsForPreparing);
                try {
                    showLoadingBar();
                    setSubmitLoading(true);

                    if (preparedSplittedTransactions.delete.length) {
                        await transactionControllerApi.deleteMultipleTransactions(preparedSplittedTransactions.delete);
                    }

                    if (!GlobalServices.isEmpty(preparedSplittedTransactions.createUpdate)) {
                        const response = await transactionControllerApi.createOrUpdateSplitTransactions(parentTransaction?.id as string, {
                            transactions: preparedSplittedTransactions.createUpdate
                        });
                        if (!GlobalServices.isEmpty(response.data.splitTransactionDtoList)) {
                            for (const splitTransactionIndex in response.data.splitTransactionDtoList) {
                                const splitTransaction = response.data.splitTransactionDtoList[splitTransactionIndex as unknown as number];
                                if (splitTransaction.tempId) {
                                    await storageControllerApi.finalizeUpload(
                                        splitTransaction.id!,
                                        values[splitTransaction.tempId!].documents.map((document: DocumentDto) => document.id!)
                                    );
                                } else {
                                    await storageControllerApi.finalizeUpload(
                                        splitTransaction.id!,
                                        values[splitTransaction.id!].documents.map((document: DocumentDto) => document.id!)
                                    );
                                }
                            }
                        }
                    }

                    setSubmitLoading(false);
                    showNotifications({
                        type: NotificationTypes.SUCCESS,
                        message: parentTransaction?.isParent
                            ? Localisation.localize('SPLIT_TRANSACTION_DIALOG.TRANSACTION_SUCCESSFULLY_UPDATED')
                            : Localisation.localize('SPLIT_TRANSACTION_DIALOG.TRANSACTION_SUCCESSFULLY_SPLITTED')
                    });
                    onSuccess();
                    hideLoadingBar();
                } catch (e) {
                    hideLoadingBar();
                    setSubmitLoading(false);
                    console.log('e', e);
                    showNotifications({
                        type: NotificationTypes.ERROR,
                        message: Localisation.localize('SPLIT_TRANSACTION_DIALOG.TRANSACTION_SPLIT_ERROR')
                    });
                }
            }
        }
    });

    useEffect(() => {
        async function getInitialData() {
            try {
                const options = await getIncomeTypes();
                setIncomeTypeOptions(options);
            } catch (e) {
                console.log(e);
            }
        }

        getInitialData();
    }, []);

    useEffect(() => {
        async function getSplittedTransactions() {
            try {
                const response = await transactionControllerApi.getSplitTransactions(transaction.parentTransactionId!);
                setParentTransaction(response.data.parentTransaction);
                setSplitTransactions(response.data.splitTransactionDtoList || []);
            } catch (e) {
                console.log('e', e);
                setSplitTransactionsLoading(false);
            }
        }

        if (transaction.parentTransactionId) {
            getSplittedTransactions();
        } else {
            setParentTransaction(transaction);
            onAddNewSplitTransaction(2);
        }
    }, [transaction]);

    useEffect(() => {
        async function fetchDocuments() {
            try {
                const documents = await getTransactionDocuments(splitTransactions);
                const splitTransactionsWithDocuments: Record<string, any> = {};
                const preparedValidationSchema: any = {};
                splitTransactions.map(splitTransaction => {
                    preparedValidationSchema[splitTransaction.id!] = yup.object().shape({
                        category: yup.number().required(localize('formErrors.fieldIsRequired')),
                        businessId: yup.string().required(localize('formErrors.fieldIsRequired')),
                        originalAmount: yup.number().required(localize('formErrors.fieldIsRequired'))
                    });
                    splitTransactionsWithDocuments[splitTransaction.id!] = {
                        id: splitTransaction.id ? splitTransaction.id : undefined,
                        notes: splitTransaction.notes || '',
                        purpose: splitTransaction.purpose || '',
                        category: splitTransaction.category && splitTransaction.category.id ? splitTransaction.category.id!.toString() : '',
                        businessId:
                            splitTransaction.propertyId !== null
                                ? splitTransaction.businessId + '_' + splitTransaction.propertyId
                                : splitTransaction.businessId,
                        originalAmount: splitTransaction.originalAmount ? splitTransaction.originalAmount!.toString() : '',
                        counterpartName: splitTransaction.counterpartName,
                        documents: documents[splitTransaction.id!] || [],
                        deleted: splitTransaction.deleted || false,
                        type: splitTransaction.isIncome ? 'INCOME' : 'EXPENSE',
                        isRefund: splitTransaction.isRefund
                    };
                });
                await formik.setValues(splitTransactionsWithDocuments);
                setValidationScheme(preparedValidationSchema);
                setSplitTransactionsLoading(false);
            } catch (e) {
                setSplitTransactionsLoading(false);
                console.log('e', e);
            }
        }

        if (transaction.parentTransactionId && splitTransactions.length) {
            fetchDocuments();
        }
    }, [splitTransactions]);

    useEffect(() => {
        trackAmount();
    }, [tempSplitTransactions, splitTransactions]);

    const getTransactionDocuments = async (transactionsForDocuments: SplitTransaction[]) => {
        const documents: Record<string, DocumentDto[]> = {};
        for (const i in transactionsForDocuments) {
            const transactionForDocuments = transactionsForDocuments[i];
            if (transactionForDocuments.id) {
                const storageResponse = await storageControllerApi.getDocuments(transactionForDocuments!.id!, 'TRANSACTION_RECEIPT');
                documents[transactionForDocuments.id] = storageResponse.data;
            }
        }
        return documents;
    };

    const trackAmount = () => {
        const existingTransactionsIds = Object.keys(formik.values);
        let summedAmount = 0;
        for (const transactionIndex in existingTransactionsIds) {
            const existingTransactionId = existingTransactionsIds[transactionIndex];
            if (!formik.values[existingTransactionId].deleted) {
                const areSameTypeAsParent = parentTransaction!.isIncome === (formik.values[existingTransactionId].type === 'INCOME');
                const transactionIsRefund = formik.values[existingTransactionId].isRefund;
                if ((areSameTypeAsParent && !transactionIsRefund) || (!areSameTypeAsParent && transactionIsRefund)) {
                    summedAmount =
                        summedAmount +
                        (formik.values[existingTransactionId].originalAmount !== undefined
                            ? Number(formik.values[existingTransactionId].originalAmount)
                            : 0);
                } else if ((!areSameTypeAsParent && !transactionIsRefund) || (areSameTypeAsParent && transactionIsRefund)) {
                    summedAmount =
                        summedAmount -
                        (formik.values[existingTransactionId].originalAmount !== undefined
                            ? Number(formik.values[existingTransactionId].originalAmount)
                            : 0);
                }
                if (
                    formik.values[existingTransactionId].originalAmount === undefined ||
                    Number(formik.values[existingTransactionId].originalAmount) === 0
                ) {
                    setHaveZeroAmount(true);
                } else {
                    setHaveZeroAmount(false);
                }
            }
        }
        setTotalAmount(NumberServices.round(summedAmount));
    };

    const prepareSplittedTransactions = (
        splittedTransactions: SplitTransaction[]
    ): { createUpdate: TransactionDto[]; delete: string[] } => {
        const transactionsForDelete: string[] = [];
        const transactionsForCreateUpdate: TransactionDto[] = [];

        splittedTransactions.map(splitTransaction => {
            if (splitTransaction.deleted) {
                transactionsForDelete.push(splitTransaction.id!);
            } else {
                const incomeType = incomeTypesOptions.find(inc => inc.id === splitTransaction.businessId);
                transactionsForCreateUpdate.push({
                    ...(splitTransaction.id && { id: splitTransaction.id }),
                    ...(splitTransaction.tempId && { tempId: splitTransaction.tempId }),
                    bankAccount: transaction.bankAccount,
                    businessId: GlobalServices.isDefined(incomeType) ? incomeType.businessId : undefined,
                    propertyId: GlobalServices.isDefined(incomeType) ? incomeType.propertyId : undefined,
                    category: { id: Number(splitTransaction.category) },
                    counterpartName: GlobalServices.isEmpty(splitTransaction.counterpartName)
                        ? parentTransaction
                            ? parentTransaction.counterpartName
                            : ''
                        : splitTransaction?.counterpartName,
                    isIncome: splitTransaction.type === 'INCOME',
                    notes: GlobalServices.isEmpty(splitTransaction.notes)
                        ? parentTransaction
                            ? parentTransaction.notes
                            : ''
                        : splitTransaction?.notes,
                    originalAmount: Number(splitTransaction.originalAmount),
                    purpose: GlobalServices.isEmpty(splitTransaction.purpose)
                        ? parentTransaction
                            ? parentTransaction.purpose
                            : ''
                        : splitTransaction?.purpose,
                    transactionDate: transaction.transactionDate,
                    isRefund: splitTransaction.isRefund
                });
            }
        });

        return { createUpdate: transactionsForCreateUpdate, delete: transactionsForDelete };
    };

    const onAddNewSplitTransaction = (count = 1) => {
        const currentSplitTransactions = [...tempSplitTransactions];
        const preparedValidationSchema: any = { ...validationSchemeForTemp };
        for (let i = 0; i < count; i++) {
            const tempId = uuidv4();
            currentSplitTransactions.push({
                originalAmount: 0,
                transactionDate: transaction.transactionDate!,
                isIncome: transaction?.isIncome,
                tempId
            });
            formik.setFieldValue(tempId, {
                tempId,
                originalAmount: undefined,
                transactionDate: transaction?.transactionDate,
                notes: '',
                purpose: '',
                category: '',
                businessId: '',
                counterpartName: '',
                documents: [],
                deleted: false,
                type: transaction?.isIncome ? 'INCOME' : 'EXPENSE',
                isRefund: false
            });
            preparedValidationSchema[tempId] = yup.object().shape({
                category: yup.number().required(localize('formErrors.fieldIsRequired')),
                businessId: yup.string().required(localize('formErrors.fieldIsRequired')),
                originalAmount: yup.number().required(localize('formErrors.fieldIsRequired'))
            });
        }
        setValidationSchemeForTemp(preparedValidationSchema);
        setTempSplitTransactions(currentSplitTransactions);
    };

    const onDeleteNewSplitTransaction = async (splitTransaction: SplitTransaction) => {
        if (splitTransaction.tempId) {
            const updatedTempSplitTransactions = tempSplitTransactions.filter(
                transaction => transaction.tempId !== splitTransaction.tempId
            );
            const preparedValidationSchema: any = { ...validationSchemeForTemp };
            delete preparedValidationSchema[splitTransaction.tempId];
            delete formik.values[splitTransaction.tempId];
            setValidationSchemeForTemp(preparedValidationSchema);
            setTempSplitTransactions(updatedTempSplitTransactions);
        } else {
            try {
                const preparedValidationSchema: any = { ...validationScheme };
                let updatedSplitTransactions = [...splitTransactions];
                updatedSplitTransactions = splitTransactions.map(transaction => {
                    if (transaction.id === splitTransaction.id) {
                        return { ...transaction, deleted: true };
                    } else {
                        return transaction;
                    }
                });
                delete preparedValidationSchema[splitTransaction.id!];
                setSplitTransactions(updatedSplitTransactions);
                await formik.setFieldValue(splitTransaction.id! + '.deleted', true);
                setValidationScheme(preparedValidationSchema);
            } catch (e) {
                setSubmitLoading(false);
                console.log('e', e);
            }
        }
    };

    const onRestoreSplitTransaction = (splitTransaction: SplitTransaction) => {
        const preparedValidationSchema: any = { ...validationScheme };
        let updatedSplitTransactions = [...splitTransactions];
        updatedSplitTransactions = splitTransactions.map(transaction => {
            if (transaction.id === splitTransaction.id) {
                return { ...transaction, deleted: false };
            } else {
                return transaction;
            }
        });
        setSplitTransactions(updatedSplitTransactions);
        formik.setFieldValue(splitTransaction.id! + '.deleted', false);
        preparedValidationSchema[splitTransaction.id!] = yup.object().shape({
            category: yup.number().required(localize('formErrors.fieldIsRequired')),
            businessId: yup.string().required(localize('formErrors.fieldIsRequired')),
            originalAmount: yup.number().required(localize('formErrors.fieldIsRequired'))
        });
        setValidationScheme(preparedValidationSchema);
    };

    const renderSplitTransactions = () => {
        if (splitTransactionsLoading) {
            return <LoadingPlaceholder height={150} />;
        } else {
            return splitTransactions.concat(tempSplitTransactions).map((splitTransaction, index) => {
                return (
                    <SplitTransactionSection
                        parentTransaction={parentTransaction}
                        splitTransaction={splitTransaction}
                        count={index + 1}
                        key={splitTransaction.tempId ?? splitTransaction.id}
                        onDelete={onDeleteNewSplitTransaction}
                        onAmountChange={trackAmount}
                        onRestore={onRestoreSplitTransaction}
                        formik={formik}
                        submitInProgress={submitLoading}
                    />
                );
            });
        }
    };

    const getAmountDirections = () => {
        const remainingAmount = parentTransaction?.originalAmount
            ? NumberServices.round(parentTransaction?.originalAmount - totalAmount)
            : 0;

        if (haveZeroAmount) {
            return (
                <span
                    data-cy="splitTransactionsNeedValue"
                    className={clsx(classes.amountDirectionText, classes.warningAmountDirectionText)}
                >
                    {Localisation.localize('SPLIT_TRANSACTION_DIALOG.ALL_SPLIT_TRANSACTIONS_NEED_A_VALUE')}
                </span>
            );
        } else {
            if (remainingAmount < 0) {
                return (
                    <span
                        data-cy="exceededOriginalAmount"
                        className={clsx(classes.amountDirectionText, classes.warningAmountDirectionText)}
                    >
                        {Localisation.localize('SPLIT_TRANSACTION_DIALOG.YOU_EXCEEDED_ORIGINAL_AMOUNT')}
                    </span>
                );
            } else {
                return (
                    <span
                        data-cy="remainingAmount"
                        className={clsx(
                            classes.amountDirectionText,
                            remainingAmount !== 0 ? classes.warningAmountDirectionText : classes.positiveAmountDirectionText
                        )}
                    >
                        {Localisation.localize('SPLIT_TRANSACTION_DIALOG.REMAINING_AMOUNT', {
                            amount: parentTransaction?.originalAmount
                                ? NumberServices.format(parentTransaction?.originalAmount - totalAmount)
                                : 0
                        })}
                    </span>
                );
            }
        }
    };

    return (
        <ApariDialog
            title={Localisation.localize('SPLIT_TRANSACTION_DIALOG.TITLE')}
            open={open}
            onCloseDialog={onCloseDialog}
            onClose={() => onCloseDialog()}
            fullWidth
            maxWidth="md"
            {...rest}
        >
            <form className={clsx(classes.root)} onSubmit={formik.handleSubmit}>
                <p className={clsx(globalClasses.font12weight600Dark)}>
                    {Localisation.localize('SPLIT_TRANSACTION_DIALOG.ORIGINAL_TRANSACTION')}
                </p>
                {parentTransaction ? (
                    <TransactionCard
                        data={parentTransaction}
                        title={parentTransaction.counterpartName}
                        subtitle={parentTransaction.purpose}
                        date={parentTransaction.transactionDate}
                        incomeTypeName={RecordKeepingServices.getBusinessName(incomeTypesOptions, parentTransaction)}
                        isRefund={false}
                        showOriginalAmount
                        hideLabels
                    />
                ) : (
                    <LoadingTransactionCard />
                )}
                {renderSplitTransactions()}
                <div className={clsx(classes.amountDirectionTextContainer)}>{!splitTransactionsLoading && getAmountDirections()}</div>
                <div className={clsx(classes.footerActions)}>
                    <TextButtonWithPlus
                        data-cy="splitIntoMoreTransactions"
                        disabled={submitLoading}
                        className={clsx(classes.footerActionButton)}
                        text={Localisation.localize('SPLIT_TRANSACTION_DIALOG.SPLIT_INTO_MORE_TRANSACTIONS')}
                        onClick={() => onAddNewSplitTransaction()}
                    />
                    <div className={clsx(classes.footerActionsSubFolder)}>
                        {!doesUserHaveLowestRequiredPackage(PaymentStatusDtoSubscriptionTypeEnum.STANDARD) && (
                            <ApariButton
                                className={clsx(classes.footerActionButton)}
                                variant="outlined"
                                color="primary"
                                size="small"
                                fullWidth={mobileDown}
                                onClick={() => history.push('/plans')}
                            >
                                {Localisation.localize('SPLIT_TRANSACTION_DIALOG.UPGRADE_TO_ADD_ATTACHMENTS')}
                            </ApariButton>
                        )}
                        <ApariButton
                            data-cy="splitIntoMoreTransactionsSubmit"
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={
                                (splitTransactions.concat(tempSplitTransactions).filter(transaction => !transaction.deleted).length < 2 &&
                                    splitTransactions.concat(tempSplitTransactions).filter(transaction => !transaction.deleted).length !==
                                        0) ||
                                submitLoading
                            }
                            fullWidth={mobileDown}
                        >
                            {Localisation.localize('SPLIT_TRANSACTION_DIALOG.UPDATE_TRANSACTIONS')}
                        </ApariButton>
                    </div>
                </div>
            </form>
        </ApariDialog>
    );
};

export default SplitTransactionDialog;
