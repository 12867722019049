import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './styles';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

type Props = {
    onUpload: (files: File[]) => void;
    onRemoveFile?: (event: any) => void;
    children: React.ReactElement | React.ReactElement[];
    count?: number;
    formats?: string[];
    openDialogOnClick?: boolean;
    className?: string;
    error?: boolean | string;
    successText?: (({ files }: { files: File[] }) => string) | string;
    errorCountText?: (({ count }: { count?: number }) => string) | string;
    errorFormatText?: (({ formats }: { formats?: string[] }) => string) | string;
    disabled?: boolean;
};

interface MessageProps {
    show: boolean;
    text: string | null;
    type: string | null;
}

const ApariFileUpload: React.FC<Props> = ({
    onUpload,
    onRemoveFile,
    disabled,
    children,
    count = 1,
    formats,
    openDialogOnClick = false,
    className,
    successText = 'Successfully uploaded',
    error,
    errorCountText = ({ count }) => `Only ${count} file${count !== 1 ? 's' : ''} can be uploaded at a time`,
    errorFormatText = ({ formats }) => `Only following file formats are acceptable: ${formats && formats.join(', ')}`
}) => {
    const classes = styles();
    const dropRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [dragging, setDragging] = useState(false);
    const [message, setMessage] = React.useState<MessageProps>({
        show: false,
        text: null,
        type: null
    });
    useEffect(() => {
        dropRef.current?.addEventListener('dragover', handleDragOver);
        dropRef.current?.addEventListener('drop', handleDrop);
        dropRef.current?.addEventListener('dragenter', handleDragEnter);
        dropRef.current?.addEventListener('dragleave', handleDragLeave);

        return () => {
            dropRef.current?.removeEventListener('dragover', handleDragOver);
            dropRef.current?.removeEventListener('drop', handleDrop);
            dropRef.current?.removeEventListener('dragenter', handleDragEnter);
            dropRef.current?.removeEventListener('dragleave', handleDragLeave);
        };
    }, []);

    const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setDragging(false);

        const files = e.dataTransfer ? [...e.dataTransfer.files] : [];

        handleUpload(files);
    };

    const handleDragOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleUpload = (files: File[]) => {
        if (count && count < files.length) {
            showMessage(typeof errorCountText === 'function' ? errorCountText({ count }) : errorCountText, 'error');

            return;
        }

        if (formats && files.some((file: File) => !formats.some(format => file.name.toLowerCase().endsWith(format.toLowerCase())))) {
            showMessage(typeof errorFormatText === 'function' ? errorFormatText({ formats }) : errorFormatText, 'error');

            return;
        }

        if (files && files.length) {
            showMessage(typeof successText === 'function' ? successText({ files }) : successText, 'success');

            onUpload(files);
        }
    };

    const handleDragEnter = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setDragging(true);
    };

    const handleDragLeave = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setDragging(false);
    };

    const handleSelectFiles = (e: any) => {
        const files = [...e.target.files];

        handleUpload(files);
    };

    const showMessage = (text: string, type: string) => {
        setMessage({
            show: true,
            text,
            type
        });
    };

    const openFileDialog = () => {
        inputRef && inputRef.current?.click();
    };

    const removeFile = (event: any) => {
        setMessage({
            show: false,
            text: null,
            type: null
        });

        if (inputRef) {
            inputRef.current!.value = '';
            onRemoveFile && onRemoveFile(event);
        }
    };

    return (
        <div className={className}>
            <div
                ref={dropRef}
                style={{ cursor: openDialogOnClick ? 'pointer' : 'default' }}
                className={clsx(classes.container, dragging && classes.dragging, error && classes.error)}
                onClick={openDialogOnClick ? openFileDialog : undefined}
            >
                {openDialogOnClick && (
                    <input
                        ref={inputRef}
                        type="file"
                        disabled={disabled}
                        className={clsx(classes.input)}
                        accept={formats ? formats.map(format => `.${format}`).join(', ') : undefined}
                        multiple={!count || count > 1}
                        onChange={handleSelectFiles}
                    />
                )}
                {message.show ? (
                    <div>
                        {message.text}
                        <IconButton onClick={removeFile}>
                            <Close />
                        </IconButton>
                    </div>
                ) : (
                    children
                )}
            </div>
            {error && <span className={clsx(classes.errorText)}>{error}</span>}
        </div>
    );
};

export default ApariFileUpload;
