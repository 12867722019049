import React from 'react';
import clsx from 'clsx';
import { ListItem, ListItemProps, ListItemText, withWidth } from '@material-ui/core';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

type Props = ListItemProps & {
    label: string;
    onClick: () => void;
};

const ApariListItem: React.FC<Props> = ({ selected, key, onClick, label, disabled }) => {
    const classes = styles();
    const globalClasses = globalStyles();

    return (
        <ListItem
            disabled={disabled}
            classes={{ selected: classes.listItemSelected }}
            className={clsx(classes.listItem)}
            selected={selected}
            key={key}
            onClick={onClick}
            button
        >
            <ListItemText className={clsx(globalClasses.flexColumnCenter, globalClasses.inputsFont)} primary={label} />
        </ListItem>
    );
};

export default withWidth()(ApariListItem);
