import React, { useContext, useEffect, useRef, useState } from 'react';
import { NotificationDto, NotificationsControllerApi } from '@apari/core-api';
import { AuthenticationContext } from './AuthenticationContext';

const notificationsControllerApi = new NotificationsControllerApi();

interface NotificationsContextInterface {
    showPopUp: boolean;
    setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
    popUpNotification?: NotificationDto;
    topBarNotification?: NotificationDto;
    dontShowNotificationPopUpAgain: (notificationId: string) => void;
    dontShowNotificationTopBarAgain: () => void;
    fetchNotifications: () => void;
    handleTopBarAction: () => void;
    resetNotifications: () => void;
}

export const NotificationsContext = React.createContext({} as NotificationsContextInterface);

export const NotificationsProvider: React.FC = ({ children }) => {
    const [popUpNotification, setPopUpNotification] = useState<NotificationDto>();
    const [topBarNotification, setTopBarNotification] = useState<NotificationDto>();
    const [showPopUp, setShowPopUp] = useState(false);

    const isInitialMount = useRef(true);
    const { user, authenticated } = useContext(AuthenticationContext);

    useEffect(() => {
        if (authenticated) {
            fetchNotifications();
        }
    }, []);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (user && authenticated) {
                fetchNotifications();
            } else {
                resetNotifications();
            }
        }
    }, [authenticated]);

    const fetchNotifications = async () => {
        // try {
        //     const response = await notificationsControllerApi.getOnePopupAndTopBar();
        //     if (
        //         response.data.popupNotification &&
        //         response.data.popupNotification.applicationType !== NotificationDtoApplicationTypeEnum.UK_SA100_STANDALONE
        //     ) {
        //         setPopUpNotification(response.data.popupNotification);
        //         setShowPopUp(true);
        //         trackEvent({
        //             category: 'Notification',
        //             name: popUpNotification?.title,
        //             action: 'dialog-opened'
        //         });
        //     }
        //     if (
        //         response.data.topBarNotification &&
        //         response.data.topBarNotification.applicationType !== NotificationDtoApplicationTypeEnum.UK_SA100_STANDALONE
        //     ) {
        //         setTopBarNotification(response.data.topBarNotification);
        //     }
        // } catch (e) {
        //     console.log('e', e);
        // }
    };

    const dontShowNotificationPopUpAgain = async (notificationId: string) => {
        try {
            await notificationsControllerApi.hideNotificationForUser(notificationId);
        } catch (e) {
            console.log('e', e);
        }
    };

    const dontShowNotificationTopBarAgain = () => {
        if (topBarNotification?.id) {
            try {
                notificationsControllerApi.hideNotificationForUser(topBarNotification?.id);
                setTopBarNotification(undefined);
            } catch (e) {
                console.log('e', e);
            }
        }
    };

    const handleTopBarAction = () => {
        if (topBarNotification && topBarNotification.previewInPopup) {
            setPopUpNotification(topBarNotification);
            setShowPopUp(true);
        } else if (topBarNotification?.actionButtonURL) {
            window.open(topBarNotification.actionButtonURL, topBarNotification.actionURLOpenInNewTab ? '_blank' : '_self');
        }
    };

    const resetNotifications = () => {
        setTopBarNotification(undefined);
        setPopUpNotification(undefined);
        setShowPopUp(false);
    };

    return (
        <NotificationsContext.Provider
            value={{
                showPopUp,
                setShowPopUp,
                popUpNotification,
                topBarNotification,
                dontShowNotificationPopUpAgain,
                dontShowNotificationTopBarAgain,
                fetchNotifications,
                handleTopBarAction,
                resetNotifications
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};
