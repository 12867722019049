import React from 'react';
import globalStyles from 'styles/globalStyles';
import { ApariButton, ApariDialog } from 'components';
import clsx from 'clsx';
import { Localisation } from 'utils';
import { Link } from 'react-router-dom';
import styles from './styles';

interface Props {
    open: boolean;
    title: string;
    paragraphs: string[];
    buttonText: string;
    titleClassName?: string;
    onCloseDialog?: () => void;
}

const ApariUpgradeDialog: React.FC<Props> = ({ open, onCloseDialog, title, paragraphs, buttonText }) => {
    const globalClasses = globalStyles();
    const classes = styles();

    return (
        <ApariDialog open={open} onCloseDialog={onCloseDialog} className={clsx(classes.upgradeDialog)} title={title}>
            <div className={clsx(globalClasses.padding24, globalClasses.paddingTop4, classes.upgradeDialogContent)}>
                {paragraphs.map((paragraph, index) => (
                    <p key={index} className={clsx(globalClasses.font13weight400Dark)}>
                        {paragraph}
                    </p>
                ))}
                <div className={clsx(globalClasses.flexColumnCenter)}>
                    <Link to={'/plans'} className={clsx(globalClasses.fullWidth)}>
                        <ApariButton
                            className={clsx(globalClasses.marginBottom10)}
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                        >
                            {buttonText}
                        </ApariButton>
                    </Link>
                    <Link to={'/plans'}>
                        <ApariButton className={clsx(globalClasses.marginBottom10, globalClasses.textUnderline)} variant="text">
                            {Localisation.localize('INCOME_TYPES_SCREEN.ADD_INCOME_TYPES_DIALOG.COMPARE_PLANS')}
                        </ApariButton>
                    </Link>
                    <ApariButton variant="outlined" color="primary" fullWidth size="small" onClick={onCloseDialog}>
                        {Localisation.localize('INCOME_TYPES_SCREEN.ADD_INCOME_TYPES_DIALOG.NOT_NOW')}
                    </ApariButton>
                </div>
            </div>
        </ApariDialog>
    );
};

export default ApariUpgradeDialog;
