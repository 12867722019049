import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    textField: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.gray6
        }
    },
    inputLabel: {
        color: COLORS.gray4
    },
    inputAdornment: {
        '& .MuiInputAdornment-positionStart': {
            '& p': {
                color: theme.palette.primary.main,
                fontSize: 16,
                fontWeight: 500
            }
        }
    },
    darkInputAdornment: {
        '& .MuiInputAdornment-positionStart': {
            '& p': {
                color: COLORS.paperLight
            }
        }
    }
}));
