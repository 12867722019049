declare const process: {
    env: {
        NODE_ENV: string;
        REACT_APP_VERSION: string;
        REACT_APP_BASE_URL: string;
        REACT_APP_JWT_COOKIE_KEY: string;
        REACT_APP_UUID_COOKIE_KEY: string;
        REACT_APP_GOOGLE_CLIENT_ID: string;
        REACT_APP_GOOGLE_REDIRECT_URI: string;
        REACT_APP_APPLE_CLIENT_ID: string;
        REACT_APP_APPLE_REDIRECT_URI: string;
        REACT_APP_FACEBOOK_CLIENT_ID: string;
        REACT_APP_FACEBOOK_REDIRECT_URI: string;
        REACT_APP_STRIPE_KEY: string;
        REACT_APP_HMRC_AUTH_URL: string;
        REACT_APP_MATOMO_URL: string;
        REACT_APP_MATOMO_SITEID: number;
        REACT_APP_AES_KEY: string;
        MFA_TYPE: string;
        MFA_TIMESTAMP: string;
        MFA_REFERENCE: string;
        REACT_APP_COOKIE_DOMAIN: string;
        REACT_APP_IDEAL_POSTCODES_KEY: string;
    };
};

const GLOBAL = {
    VERSION: process.env.REACT_APP_VERSION,
    BASE_URL: process.env.REACT_APP_BASE_URL,
    JWT_COOKIE_KEY: process.env.REACT_APP_JWT_COOKIE_KEY,
    UUID_COOKIE_KEY: process.env.REACT_APP_UUID_COOKIE_KEY,
    GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    GOOGLE_REDIRECT_URI: process.env.REACT_APP_BASE_URL + '/' + process.env.REACT_APP_GOOGLE_REDIRECT_URI,
    APPLE_CLIENT_ID: process.env.REACT_APP_APPLE_CLIENT_ID,
    APPLE_REDIRECT_URI: process.env.REACT_APP_BASE_URL + '/' + process.env.REACT_APP_APPLE_REDIRECT_URI,
    FACEBOOK_CLIENT_ID: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
    FACEBOOK_REDIRECT_URI: process.env.REACT_APP_BASE_URL + '/' + process.env.REACT_APP_FACEBOOK_REDIRECT_URI,
    TOP_MARGIN: 72,
    REACT_APP_STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
    REACT_APP_HMRC_AUTH_URL: process.env.REACT_APP_HMRC_AUTH_URL,
    REACT_APP_MATOMO_URL: process.env.REACT_APP_MATOMO_URL,
    REACT_APP_MATOMO_SITEID: process.env.REACT_APP_MATOMO_SITEID,
    MFA_TYPE: 'APARI_MFA_TYPE',
    MFA_TIMESTAMP: 'APARI_MFA_TIMESTAMP',
    MFA_REFERENCE: 'APARI_MFA_REFERENCE',
    LICENSE_KEY: 'APARI_LICENSE',
    LICENSE_APP_NAME: 'uk-mtd',
    IDEAL_POSTCODES_KEY: process.env.REACT_APP_IDEAL_POSTCODES_KEY,
    AES_KEY: process.env.REACT_APP_AES_KEY,
    NODE_ENV: process.env.NODE_ENV,
    REACT_APP_COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
    TERMS_AND_CONDITIONS: 'https://www.apari-digital.com/terms-and-conditions',
    PRIVACY_POLICY: 'https://www.apari-digital.com/privacy-policy'
};

export default GLOBAL;
