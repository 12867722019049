import React from 'react';
import { Checkbox, CheckboxProps } from '@material-ui/core';
import UncheckedIcon from './UncheckedIcon';
import CheckedIcon from './CheckedIcon';

type Props = CheckboxProps & {
    primaryBorderColor?: boolean;
    invertedColors?: boolean;
};

const ApariCheckbox: React.FC<Props> = ({ checked, size, ...rest }) => {
    return (
        <Checkbox
            color="primary"
            icon={<UncheckedIcon />}
            checkedIcon={<CheckedIcon />}
            checked={checked || false}
            value={checked || false}
            size={size ?? 'medium'}
            {...rest}
        />
    );
};

export default ApariCheckbox;
