import React from 'react';
import clsx from 'clsx';
import {Tab, TabProps} from '@material-ui/core';
import styles from './styles';


const ApariCardTab: React.FC<TabProps> = props => {
    const classes = styles();
    const {selected, label} = props;
    return <Tab selected={selected}
                label={label}
                {...props}
                className={clsx(classes.root, selected && classes.selected)}
    />;
};


export default ApariCardTab;
