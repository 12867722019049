import { makeStyles } from '@material-ui/core';
import globalStyles from 'styles/globalStyles';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    ...globalStyles,
    root: {
        textTransform: 'none'
    },
    marginVertical: {
        marginTop: 5,
        marginBottom: 5
    },
    marginHorizontal: {
        marginLeft: 5,
        marginRight: 5
    },
    outlinedPrimary: {
        borderColor: COLORS.apariLightBlue
    }
}));
