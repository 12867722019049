import React from 'react';
import ContentLoader from 'react-content-loader';
import clsx from 'clsx';
import { IconButton, ListItem, ListItemProps, Tooltip } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { IncomeTypeSummaryBusinessSourceTypeEnum, SingleBusinessAndPropertiesSummary } from '@apari/banking-api';
import { COLORS } from 'constants/index';
import { ApariButton } from 'components/common/ui';
import { GlobalServices, Localisation, NumberServices } from 'utils';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

interface OverviewData {
    amount: number;
    isIncome: boolean;
    hmrcChanges?: boolean;
    unsubmittedFields?: boolean;
    businessSourceType?: IncomeTypeSummaryBusinessSourceTypeEnum;
}
type Props = ListItemProps & {
    label: string;
    subtitle?: string;
    onClick?: () => void;
    leftIcon?: boolean;
    overviewData?: OverviewData;
    singleBusinessOverviewData?: SingleBusinessAndPropertiesSummary;
};

export const LoadingArrowCard: React.FC = () => {
    const classes = styles();
    const globalClasses = globalStyles();

    return (
        <div className={clsx(classes.loaderContainer, globalClasses.marginBottom12)}>
            <ContentLoader speed={2} width="100%" height="72" backgroundColor={COLORS.backgroundLight}>
                <rect x="0" y="0" rx="3" ry="3" width="100%" height="71" />
            </ContentLoader>
        </div>
    );
};

const ArrowCard: React.FC<Props> = ({
    selected,
    onClick,
    subtitle,
    label,
    disabled,
    leftIcon,
    overviewData,
    singleBusinessOverviewData
}) => {
    const classes = styles();
    const globalClasses = globalStyles();

    const renderOverviewData = () => {
        return GlobalServices.isDefined(overviewData) ? (
            <>
                <div className={clsx([globalClasses.flexRowStart, globalClasses.marginTop4])}>
                    <span className={clsx([classes.amount, overviewData.isIncome && classes.amountIncome])}>
                        £ {overviewData.amount > 0 ? (overviewData.isIncome ? '+' : '-') : ''}
                        {NumberServices.format(overviewData.amount)}
                    </span>
                    {overviewData.hmrcChanges && (
                        <Tooltip
                            title={Localisation.localize('DATA_OVERVIEW.SUMMARY_OVERVIEW.FIELDS_CHANGED')}
                            classes={{
                                tooltip: classes.tooltip,
                                arrow: classes.tooltipArrow
                            }}
                            arrow
                        >
                            <ErrorOutlineIcon className={clsx(classes.infoIcon)} />
                        </Tooltip>
                    )}
                </div>
                {overviewData.unsubmittedFields && (
                    <ApariButton variant="text" className={clsx(classes.unsubmittedButton)} endIcon={<ArrowDropDownIcon />}>
                        <div className={clsx(classes.purpleCircle, globalClasses.marginRight4)} />
                        <span className={clsx(classes.unsubittedText)}>
                            {Localisation.localize('DATA_OVERVIEW.SUMMARY_OVERVIEW.UNSUBMITTED_FIELDS')}
                        </span>
                    </ApariButton>
                )}
            </>
        ) : null;
    };

    const renderLandlordBusinessData = () => {
        return (
            !GlobalServices.isEmpty(singleBusinessOverviewData) && (
                <div className={clsx([globalClasses.flexRowStart, globalClasses.marginTop4])}>
                    <span className={clsx([classes.amount, singleBusinessOverviewData!.totalAmount! > 0 && classes.amountIncome])}>
                        £ {singleBusinessOverviewData!.totalAmount! > 0 ? '+' : ''}
                        {NumberServices.format(singleBusinessOverviewData!.totalAmount!)}
                    </span>
                </div>
            )
        );
    };

    const renderBusinessOrPropertyCards = () => {
        return (
            <div className={clsx([globalClasses.flexRowStart])}>
                {leftIcon && <CheckIcon className={clsx(classes.checkIcon)} />}
                <div className={clsx([globalClasses.flexColumn])}>
                    <div className={clsx([globalClasses.flexRowStart])}>
                        <div className={clsx([classes.label, globalClasses.font12weight600Dark, globalClasses.marginRight8])}>{label}</div>
                        {GlobalServices.isDefined(subtitle) && (
                            <div className={clsx([classes.label, globalClasses.font10weight400Dark])}>{subtitle}</div>
                        )}
                        {!GlobalServices.isEmpty(singleBusinessOverviewData) && singleBusinessOverviewData!.isFHL !== null && (
                            <span
                                className={clsx([
                                    globalClasses.borderRadius4,
                                    globalClasses.padding4,
                                    globalClasses.bgApariPurple,
                                    globalClasses.font10weight600White
                                ])}
                            >
                                {singleBusinessOverviewData!.isFHL
                                    ? Localisation.localize('DATA_OVERVIEW.SUMMARY_OVERVIEW.FHL')
                                    : Localisation.localize('DATA_OVERVIEW.SUMMARY_OVERVIEW.NON_FHL')}
                            </span>
                        )}
                    </div>
                    {GlobalServices.isEmpty(overviewData)
                        ? renderLandlordBusinessData()
                        : overviewData?.businessSourceType !== IncomeTypeSummaryBusinessSourceTypeEnum.NON_BUSINESS && renderOverviewData()}
                </div>
            </div>
        );
    };

    return (
        <ListItem disabled={disabled} className={clsx([globalClasses.flexRowStart, classes.listItemOverride])} selected={selected}>
            {renderBusinessOrPropertyCards()}
            {GlobalServices.isDefined(onClick) && (
                <div className={clsx([globalClasses.marginLeftAuto])}>
                    <IconButton onClick={onClick}>
                        <ArrowForwardIcon className={clsx(classes.icon)} />
                    </IconButton>
                </div>
            )}
        </ListItem>
    );
};

export default ArrowCard;
