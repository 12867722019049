import React from "react";
import clsx from 'clsx';
import styles from './styles';

type Props = {
    className?: string
}

const DividerWithText: React.FC<Props> = ({ children, className }) => {
    const classes = styles();
    return (
        <div className={clsx(classes.container, className)}>
            <div className={classes.border} />
            <span className={classes.content}>{children}</span>
            <div className={classes.border} />
        </div>
    );
};
export default DividerWithText;
