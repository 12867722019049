import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
    switchBase: {
        background: theme.palette.grey[500],
        borderRadius: 3,
        width: 88,
        height: 25,
        '& .MuiIconButton-label': {
            height: '100%'
        },
        '& .MuiSwitch-track': {
            visibility: 'hidden'
        },
        '& .MuiSwitch-switchBase': {
            padding: '0px !important',
            height: 25
        },
        '& .MuiSwitch-thumb': {
            height: '100%',
            width: 44,
            borderRadius: 3
        },
        '& .Mui-checked': {
            transform: 'translateX(44px)',
            '& .MuiSwitch-thumb': {
                backgroundColor: theme.palette.primary.main
            }
        },
        '& input': {
            width: 88,
            height: 25
        }
    },
    inputLabel: {
        color: '#BDBDBD'
    }
}));
