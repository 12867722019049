import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { LinearProgress } from '@material-ui/core';
import { ApariButton } from 'components';
import PlayImage from 'resources/images/Play.png';
import PauseImage from 'resources/images/Pause.png';
import globalStyles from 'styles/globalStyles';
import { Localisation } from 'utils';
import styles from './styles';

type WaitWithRetryProps = {
    onRetry: () => void;
    message?: string;
    hmrcMessage?: string;
    buttonText: string;
};

export const WaitWithRetry: React.FC<WaitWithRetryProps> = ({ onRetry, message, hmrcMessage, buttonText }) => {
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
    const [progress, setProgress] = useState(0);
    const [timeoutRunOut, setTimeoutRunOut] = useState(false);

    const globalClasses = globalStyles();
    const classes = styles();
    const waitTimeInMinutes = 1;

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(prevState => prevState + 100 / (waitTimeInMinutes * 60) / 2);
        }, 500);
        setIntervalId(interval);
        return function cleanup() {
            clearTimeout((intervalId as unknown) as number);
        };
    }, []);

    useEffect(() => {
        if (progress >= 100) {
            setTimeoutRunOut(true);
            setProgress(0);
            clearTimeout((intervalId as unknown) as number);
        }
    }, [progress]);

    return (
        <div className={clsx(globalClasses.flexColumnVerticalCenter, classes.errorTableWrapper)}>
            <div className={clsx(classes.imageWrapper, globalClasses.padding32)}>
                <img className={clsx(classes.errorImage, timeoutRunOut && classes.showImage)} src={PlayImage} height={80} alt="play" />
                <img className={clsx(classes.errorImage, !timeoutRunOut && classes.showImage)} src={PauseImage} height={80} alt="pause" />
            </div>
            <p className={clsx(globalClasses.textAlignCenter, classes.errorTableTitle)}>
                {Localisation.localize('EOPS_PREVIEW_SCREEN.HMRC_DID_WRONG')}
            </p>
            <p className={clsx(globalClasses.font13weight400light40, globalClasses.textAlignCenter)}>
                {message ? message : Localisation.localize('EOPS_PREVIEW_SCREEN.HMRC_MESSAGE')}
            </p>
            {hmrcMessage && (
                <p className={clsx(globalClasses.font13weight400light40, globalClasses.marginTop0, globalClasses.textAlignCenter)}>
                    {'(' + hmrcMessage + ')'}
                </p>
            )}
            <p className={clsx(globalClasses.font13weight400light40, globalClasses.textAlignCenter)}>
                {timeoutRunOut
                    ? Localisation.localize('EOPS_PREVIEW_SCREEN.PLEASE_RETRY_NOW')
                    : Localisation.localize('EOPS_PREVIEW_SCREEN.PLEASE_RETRY_INTERVAL', { minute: waitTimeInMinutes })}
            </p>
            <div className={clsx(globalClasses.flexColumn, classes.buttonContainer)}>
                {!timeoutRunOut && <LinearProgress variant="determinate" color="primary" value={progress} />}
                <ApariButton variant="contained" color="primary" disabled={!timeoutRunOut} onClick={onRetry}>
                    {buttonText}
                </ApariButton>
            </div>
        </div>
    );
};

export default WaitWithRetry;
