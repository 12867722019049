import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import clsx from 'clsx';
import styles from './styles';

type Props = TextFieldProps & {
    darkBackground?: boolean;
    noShrinkClass?: boolean;
    alternativeFont?: boolean;
};

const ApariTextField: React.FC<Props> = props => {
    const {
        margin,
        size,
        variant,
        label,
        darkBackground,
        className,
        InputLabelProps,
        noShrinkClass,
        inputProps,
        disabled,
        alternativeFont,
        ...rest
    } = props;
    const classes = styles();

    return (
        <TextField
            fullWidth
            margin={margin || 'normal'}
            size={size || 'small'}
            variant={variant || 'outlined'}
            label={label}
            disabled={disabled}
            InputLabelProps={{
                classes: {
                    root: clsx(classes.inputLabel, alternativeFont && classes.alternativeFont),
                    focused: darkBackground ? classes.inputLabelFocused : '',
                    shrink: noShrinkClass ? '' : classes.inputLabelShrink
                },
                ...InputLabelProps
            }}
            inputProps={{
                className: clsx(
                    darkBackground ? classes.autofillDark : classes.autofill,
                    disabled && classes.disabledInput,
                    alternativeFont && classes.alternativeFont
                ),
                ...inputProps
            }}
            classes={{
                root: clsx(classes.textField, disabled && classes.disableHover)
            }}
            className={clsx(darkBackground && classes.textFieldDarkBackground, className)}
            {...rest}
        />
    );
};

export default ApariTextField;
