import React, { useEffect, useState } from 'react';
import ReactJoyride, { Props } from 'react-joyride';
import { COLORS, FONTS } from 'constants/index';
import { Localisation } from 'utils';

type UserTourProps = Props & {
    startTimeout?: number;
};

const UserTour: React.FC<UserTourProps> = ({ run, startTimeout = 500, ...rest }) => {
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setEnabled(true);
        }, startTimeout);
    }, []);

    return (
        <ReactJoyride
            scrollOffset={200}
            showProgress
            run={run === undefined ? enabled : run}
            continuous={true}
            locale={{
                back: Localisation.localize('TOUR.BACK'),
                close: Localisation.localize('TOUR.CLOSE'),
                last: Localisation.localize('TOUR.LAST'),
                next: Localisation.localize('TOUR.NEXT'),
                skip: Localisation.localize('TOUR.SKIP')
            }}
            styles={{
                tooltip: {
                    borderRadius: 5
                },
                tooltipFooter: {
                    marginTop: 15
                },
                buttonNext: {
                    borderRadius: 2,
                    fontFamily: FONTS.PRIMARY_FONT,
                    fontWeight: 500
                },
                buttonBack: {
                    borderRadius: 2,
                    fontFamily: FONTS.PRIMARY_FONT,
                    fontWeight: 500
                },
                buttonClose: {
                    borderRadius: 2,
                    fontFamily: FONTS.PRIMARY_FONT,
                    fontWeight: 500
                },
                buttonSkip: {
                    borderRadius: 2,
                    fontFamily: FONTS.PRIMARY_FONT,
                    fontWeight: 500
                },
                options: {
                    zIndex: 1301,
                    primaryColor: COLORS.apariLightBlue
                },
                tooltipContent: {
                    padding: '30px 10px 10px 10px'
                }
            }}
            {...rest}
        />
    );
};

export default UserTour;
