import { COLORS } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    section: {
        borderRadius: 12,
        border: '2px solid ' + COLORS.transparentLight,
        marginBottom: 10
    },
    date: {
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.primary.main,
        padding: 14
    },
    cardDivider: {
        marginTop: 10,
        display: 'flex',
        height: 100,
        width: 2,
        background: COLORS.paperLight
    }
}));
