import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.paper,
        borderRadius: 2,
        minHeight: 620,
        boxShadow: '2px 2px 10px rgb(0 0 0 / 5%)',
        [theme.breakpoints.up('lg')]: {
            maxWidth: 376
        }
    },
    headerContainer: {
        padding: 12,
        paddingBottom: 6,
        borderBottom: '1px solid ' + COLORS.apariLightSilver
    },
    graphContainer: {
        position: 'relative',
        background: theme.palette.background.default,
        padding: '25px 12px 16px 12px',
        height: 130,
        justifyContent: 'flex-start'
    },
    businessChartContainer: {
        background: theme.palette.background.default
    },
    noDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '25px 12px 16px 12px'
    },
    comingSoonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '25px 12px 16px 12px',
        flex: 1,
        justifyContent: 'center'
    },
    graph: {
        position: 'relative',
        background: '#CCCCCC',
        height: 22,
        width: '100%',
        zIndex: 1
    },
    profitIndicator: {
        background: COLORS.apariLightBlue,
        height: '100%',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
            background: '#350FC0',
            zIndex: 3
        }
    },
    tooltip: {
        fontSize: 11,
        fontWeight: 500
    },
    ratesContainer: {
        position: 'absolute',
        width: '100%',
        zIndex: 2
    },
    rateRoot: {
        maxWidth: 55,
        zIndex: 2,
        position: 'absolute',
        top: '-9px'
    },
    rateContainer: {
        position: 'relative'
    },
    rateIndicator: {
        height: 40,
        width: 1,
        marginLeft: 'auto',
        borderLeft: '1px dashed ' + COLORS.apariBlack80
    },
    rateText: {
        fontSize: 10,
        fontWeight: 400,
        marginLeft: '100%',
        color: COLORS.apariBlack80
    },
    rateLabel: {
        marginBottom: 0,
        textAlign: 'center'
    },
    rateValue: {
        marginTop: 0,
        whiteSpace: 'nowrap'
    },
    incomesListContainer: {
        paddingTop: 16,
        display: 'flex',
        flexDirection: 'column'
    },
    buttonContainer: {
        marginTop: 'auto',
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16
    }
}));
