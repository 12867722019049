import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    warningIcon: {
        color: COLORS.apariWarning
    },
    dialogButton: {
        maxHeight: 32,
        width: 130,
        padding: '5px 0px'
    },
    dialog: {
        width: 290,
        height: 130,
        padding: 16,
        display: 'flex',
        flexDirection: 'column'
    }
}));
