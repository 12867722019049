import globalAxios from 'axios';
import CryptoJS from 'crypto-js';
import { GLOBAL, RESPONSE_CODES } from 'constants/index';
import useHMRCFraudHeaders from 'hooks/useHMRCFraudHeaders';
import { GlobalServices, Localisation, NetworkService } from 'utils';
import { encrypt } from 'utils/encryption';

const setupAxios = (history: any): void => {
    globalAxios.interceptors.request.use(
        (config: any) => {
            if (GLOBAL.NODE_ENV === 'development') {
                config.url = config.url.replace('http://localhost:8000', GLOBAL.BASE_URL);
            } else {
                config.url = config.url.replace('http://localhost:8000', window.location.origin);
            }
            config.headers['Apari-Current-Application'] = 'UK_MTD';

            const token = NetworkService.getToken();
            const decodedToken = NetworkService.getDecodedToken();
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            if (decodedToken) {
                const { taxEntityId, id } = decodedToken;
                if (config.url.includes('uk-mtd') && taxEntityId && id) {
                    const { fraudHeader } = useHMRCFraudHeaders();
                    config.headers['APARI-USER-INFO'] = encrypt(JSON.stringify(fraudHeader), createEncryptionKey(taxEntityId, id), [
                        { padding: CryptoJS.pad.Pkcs7 }
                    ]);
                }
            }
            return config;
        },
        (error: any) => {
            Promise.reject(error);
        }
    );

    globalAxios.interceptors.response.use(
        (request: any) => {
            return request;
        },
        async (error: any) => {
            const { response } = error;
            if (response) {
                const { data } = response;
                if (
                    response.config.url.includes('/logout/check') &&
                    response.status === RESPONSE_CODES.NOT_AUTHORIZED &&
                    window.location.pathname !== '/logout-session'
                ) {
                    history.replace('/logout-session');
                }
                return Promise.reject(GlobalServices.responseCodesErrorHandler(data));
            } else {
                return Promise.reject(Localisation.localize('serverErrors.problemWithResource'));
            }
        }
    );
};

const createEncryptionKey = (taxEntityId: string, id: string): string => {
    return taxEntityId!.substr(0, 16).concat(id!.substr(0, 16));
};

export default setupAxios;
