import { makeStyles } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';
import { Theme } from '@material-ui/core/styles';
import { PreviewState } from 'types/files';

type Props = {
    status: PreviewState;
    uploadStatus?: number;
    fullWidth?: boolean;
};
export default makeStyles<Theme, Props>(theme => ({
    input: {
        display: 'none'
    },
    root: {
        textTransform: 'none'
    },
    marginVertical: {
        marginTop: 5,
        marginBottom: 5
    },
    marginHorizontal: {
        marginLeft: 5,
        marginRight: 5
    },
    warningIcon: {
        color: COLORS.apariWarning
    },
    uploadButton: {
        textTransform: 'none',
        display: 'flex'
    },
    deleteDialogButton: {
        maxHeight: 32,
        width: 130
    },
    deleteDialog: {
        width: 290,
        height: 130,
        padding: 12,
        display: 'flex',
        flexDirection: 'column'
    },
    icon: {
        fontSize: 24,
        color: COLORS.apariDarkSilver
    },
    iconButton: {
        padding: 5
    },
    previewCard: ({ status, fullWidth }) => ({
        padding: '0',
        backgroundColor: '#fff',
        borderRadius: 2,
        marginBottom: 10,
        minHeight: 72,
        paddingRight: fullWidth ? 0 : 16,
        [theme.breakpoints.up('lg')]: {
            width: fullWidth ? '100%' : 315
        },
        width: '100%',

        opacity: status === PreviewState.UPLOADING || status === PreviewState.ERROR ? 0.5 : 1,
        boxShadow:
            '0px 4px 122px rgba(0, 0, 0, 0.04), 0px 1.28121px 27.2503px rgba(0, 0, 0, 0.0238443), 0px 0.517444px 8.11312px rgba(0, 0, 0, 0.0161557)'
    }),
    previewCardContainer: ({ fullWidth }) => ({
        width: fullWidth ? '100%' : 315,
        [theme.breakpoints.down('lg')]: {
            padding: '4px 16px',
            width: '100%'
        }
    }),
    label: {
        fontFamily: FONTS.PRIMARY_FONT,
        width: 200,
        [theme.breakpoints.down('lg')]: {
            width: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            width: 230
        },
        [theme.breakpoints.down('xs')]: {
            width: 100
        }
    },
    fileLabel: {
        fontFamily: FONTS.PRIMARY_FONT,
        width: 200,
        [theme.breakpoints.down('lg')]: {
            width: '100%'
        }
    },
    progressBarContainer: {
        width: '100%',
        height: 2,
        backgroundColor: COLORS.apariDarkGrey
    },
    progressBar: ({ uploadStatus }) => ({
        height: '100%',
        width: `${uploadStatus}%`,
        backgroundColor: COLORS.apariPurple
    }),
    camera: {
        display: 'none'
    },
    previewImage: {
        padding: 8,
        width: 72,
        height: 72
    },
    mobilePreviewImage: {
        width: 35,
        height: 35,
        marginRight: 2
    }
}));
