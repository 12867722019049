import { Localisation } from 'utils';

const generateErrorMessage = (error: any, customMessage?: string) => {
    let message = error;
    if (error?.responseMessage) {
        message = error?.responseMessage;
    } else if (error?.message) {
        message = error?.message;
    } else if (error?.code >= 500) {
        message = Localisation.localize('serverErrors.SOMETHING_WENT_WRONG');
    } else if (customMessage) {
        message = customMessage;
    }
    return message;
};

export default generateErrorMessage;
