import React from 'react';
import { Tooltip, TooltipProps, withStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

const MyTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: COLORS.apariWhite,
        color: COLORS.apariBlack80,
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        borderRadius: 2,
        border: '1px solid ' + COLORS.apariBlack20
    }
}))(Tooltip);

const CustomTooltip: React.FC<TooltipProps> = ({ children, ...rest }) => {
    return (
        <MyTooltip disableFocusListener disableHoverListener disableTouchListener {...rest}>
            {children}
        </MyTooltip>
    );
};

export default CustomTooltip;
