import React from 'react';
import { TextFieldProps } from '@material-ui/core';
import { FormikContextType, getIn } from 'formik';
import { ApariChipsInput } from 'components/common/ui';
import styles from './styles';

type Props = TextFieldProps & {
    control: string;
    formik: FormikContextType<any>;
    withModal?: boolean;
};

const MTDFormChipsInputField: React.FC<Props> = ({ formik, control, withModal, ...rest }) => {
    const classes = styles();
    return (
        <ApariChipsInput
            classes={{
                root: classes.root
            }}
            fullWidth
            variant="standard"
            margin="none"
            name={control}
            formik={formik}
            error={getIn(formik.touched, control) && Boolean(getIn(formik.errors, control))}
            helperText={getIn(formik.touched, control) && getIn(formik.errors, control)}
            withModal={withModal}
            {...rest}
        />
    );
};

export default MTDFormChipsInputField;
