import React, { useContext, useEffect } from 'react';
import { Router } from 'react-router-dom';
import Cookies from 'universal-cookie/lib/Cookies';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { LinearProgress } from '@material-ui/core';
import { Notifications, ApariOfflineStatus } from 'components';
import { AuthenticationContext, AuthenticationProvider } from 'context/AuthenticationContext';
import IncomeTypesContext, { IncomeTypesProvider } from 'context/IncomeTypesContext';
import { AppContext, AppProvider } from 'context/AppContext';
import { NotificationsProvider } from 'context/NotificationsContext';
import { GLOBAL } from 'constants/index';
import { createBrowserHistory } from 'history';
import { GlobalServices } from 'utils';
import { AppRouter } from 'routers';
import setupAxios from 'services/axios';
import './App.css';
import { FinishModalProvider } from './context/FinishModalContext';
import { MockDataContextProvider } from './context/MockDataContext';
import { TilesProvider } from './context/TilesContext';

const history = createBrowserHistory();

function App() {
    const { authenticated, setAuthToken, setCurrentUser, signOut, fetchBookedSubscriptionData } = useContext(AuthenticationContext);
    const { loadingBar } = useContext(AppContext);
    const { resetIncomeTypes } = useContext(IncomeTypesContext);

    const cookie = new Cookies();
    const jwtToken = cookie.get(GLOBAL.JWT_COOKIE_KEY);

    const { pushInstruction } = useMatomo();

    pushInstruction('enableJSErrorTracking');

    useEffect(() => {
        setupAxios(history);
        GlobalServices.getUUID();
    }, []);

    useEffect(() => {
        async function checkUserTokenValid() {
            if (jwtToken) {
                try {
                    await setAuthToken(jwtToken);
                } catch (error) {
                    resetIncomeTypes();
                    signOut();
                }
            }
        }

        checkUserTokenValid();
    }, [jwtToken]);

    useEffect(() => {
        async function checkSubscriptionStatus() {
            if (authenticated) {
                await setCurrentUser();
                await fetchBookedSubscriptionData();
            }
        }
        checkSubscriptionStatus();
    }, [authenticated]);

    return (
        <Router history={history}>
            <NotificationsProvider>
                <FinishModalProvider>
                    <div className="app-loader">{loadingBar && <LinearProgress variant="query" color="primary" />}</div>
                    <ApariOfflineStatus />
                    <AppRouter authenticated={true} acceptedTermsAndConditions={true} mtdWizardDone={true} />
                    <Notifications />
                </FinishModalProvider>
            </NotificationsProvider>
        </Router>
    );
}

const Root = (): React.ReactElement => {
    return (
        <AppProvider>
            <AuthenticationProvider>
                <MockDataContextProvider>
                    <IncomeTypesProvider>
                        <TilesProvider>
                            <App />
                        </TilesProvider>
                    </IncomeTypesProvider>
                </MockDataContextProvider>
            </AuthenticationProvider>
        </AppProvider>
    );
};

export default Root;
