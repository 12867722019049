import { makeStyles } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';

export default makeStyles(() => ({
    tableCell: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        padding: 10,
        border: `1px solid ${COLORS.apariBlack20}`
    },
    cellValueName: {
        fontSize: 11,
        fontWeight: 700,
        fontFamily: FONTS.PRIMARY_FONT,
        color: COLORS.apariBlack
    },
    cellValueAmount: {
        fontSize: 11,
        fontWeight: 400,
        fontFamily: FONTS.PRIMARY_FONT,
        color: COLORS.apariBlack
    }
}));
