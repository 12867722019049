import React, { useContext, useEffect, useState } from 'react';
import { DialogProps, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { BusinessSourceBasicInfo } from '@apari/core-api';
import { TransactionDto } from '@apari/banking-api';
import { DocumentDto, StorageControllerApi } from '@apari/storage-api';
import {
    ApAmountField,
    ApariButton,
    ApariDialog,
    ApariFilePreview,
    ApariTextSwitch,
    ApDatePickerField,
    ApSelectField,
    ApTextField,
    LoadingPlaceholder
} from 'components';
import IncomeTypesContext from 'context/IncomeTypesContext';
import { formatOptions, GlobalServices, Localisation, NumberServices } from 'utils';
import { PreviewState } from 'types/files';
import styles from './styles';
import globalStyles from 'styles/globalStyles';
import Arrow from 'resources/images/Arrow.png';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

type Props = DialogProps & {
    transaction: TransactionDto & { address?: Record<string, any> | undefined };
    open: boolean;
    onCloseDialog: () => void;
};

interface FormikValuesTypes {
    type: string;
    purpose: string;
    counterpartName: string;
    businessId: string;
    category: number;
    bankAccount: number;
    transactionDate: string;
    notes: string;
    originalAmount: number;
    isRefund: boolean | undefined;
}

const storageControllerApi = new StorageControllerApi();

const PreviewSharedTransactionDialog: React.FC<Props & DialogProps> = ({ transaction, open, onCloseDialog, ...rest }) => {
    const [loadingDocuments, setLoadingDocuments] = useState(false);
    const [documents, setDocuments] = useState<DocumentDto[]>([]);
    const [transactionProperty, setTransactionProperty] = useState<BusinessSourceBasicInfo | undefined>({});
    const { getIncomeTypes } = useContext(IncomeTypesContext);
    const globalClasses = globalStyles();
    const classes = styles();

    const formik = useFormik<FormikValuesTypes>({
        initialValues: {
            type: transaction.isIncome ? 'INCOME' : 'EXPENSE',
            purpose: transaction.purpose!,
            counterpartName: transaction.counterpartName!,
            businessId: transaction.propertyId!,
            category: transaction.category!.id!,
            bankAccount: transaction.bankAccount!.id!,
            transactionDate: transaction.transactionDate,
            notes: transaction.notes!,
            originalAmount: transaction.originalAmount,
            isRefund: transaction.isRefund
        },
        validationSchema: {},
        onSubmit: () => {
            // no submit
        }
    });

    useEffect(() => {
        async function fetchInitialData() {
            if (GlobalServices.isDefined(transaction)) {
                setLoadingDocuments(true);
                const storageResponse = await storageControllerApi.getDocuments(transaction!.id!, 'TRANSACTION_RECEIPT');
                setDocuments(storageResponse.data);
                setLoadingDocuments(false);
                const incomeTypes = await getIncomeTypes();
                setTransactionProperty(incomeTypes.find(item => item.propertyId === transaction.propertyId));
            }
        }
        fetchInitialData();
    }, []);

    return (
        <ApariDialog
            title={Localisation.localize('PREVIEW_SHARED_TRANSACTION_DIALOG.TITLE')}
            open={open}
            onCloseDialog={onCloseDialog}
            onClose={() => onCloseDialog()}
            fullWidth
            maxWidth="md"
            {...rest}
        >
            {loadingDocuments ? (
                <LoadingPlaceholder height={600} width={365} />
            ) : (
                <div className={classes.root}>
                    <div className={clsx(globalClasses.flexColumnAlignStart, classes.formItems)}>
                        <ApariButton
                            color="primary"
                            variant="outlined"
                            disabled
                            size="small"
                            aria-controls="typeMenu"
                            aria-haspopup="true"
                            className={clsx(globalClasses.marginBottom10, classes.typeBtn)}
                        >
                            {Localisation.localize(`RECORD_KEEPING_SCREEN.DIALOGS.${formik.values.type}`)}
                        </ApariButton>
                        <ApTextField
                            disabled
                            control="purpose"
                            label={Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.DESCRIPTION')}
                            formik={formik}
                        />
                        <ApTextField
                            disabled
                            control="counterpartName"
                            label={Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.RECIPIENT')}
                            formik={formik}
                        />
                        <ApSelectField
                            options={[{ value: transactionProperty?.propertyId || 0, label: transactionProperty?.name || '' }]}
                            control="businessId"
                            disabled
                            label={Localisation.localize('general.INCOME_TYPE')}
                            value={formik.values.businessId}
                            error={formik.touched.businessId && Boolean(formik.errors.businessId)}
                            helperText={formik.touched.businessId && formik.errors.businessId}
                        />
                        <ApSelectField
                            options={formatOptions([transaction.category])}
                            control="category"
                            label={Localisation.localize('general.CATEGORY')}
                            value={formik.values.category}
                            onChange={formik.handleChange}
                            disabled
                        />
                        <ApSelectField
                            disabled
                            options={[...formatOptions([transaction.bankAccount])]}
                            control="bankAccount"
                            label={Localisation.localize('general.ACCOUNT')}
                            value={formik.values.bankAccount}
                        />
                        <ApDatePickerField
                            disabled
                            label={Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.DATE')}
                            name="transactionDate"
                            value={formik.values.transactionDate}
                            onChange={() => {
                                // no action
                            }}
                        />
                        <ApTextField
                            disabled
                            control="notes"
                            label={Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.NOTES')}
                            multiline
                            rows={4}
                            formik={formik}
                        />

                        <div
                            className={clsx(
                                globalClasses.flexRowSpaceBetween,
                                globalClasses.paddingBottom10,
                                globalClasses.paddingTop10,
                                globalClasses.fullWidth
                            )}
                        >
                            <div className={clsx(globalClasses.flexRowStart)}>
                                <span className={clsx(globalClasses.font12weight600Black60, classes.switchElementLabel)}>
                                    {Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.REFUND')}
                                </span>
                                <Tooltip
                                    placement="top-start"
                                    title={Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.REFUND_TOOLTIP')}
                                    arrow
                                    classes={{ tooltip: classes.tooltipDesign, arrow: classes.arrowDesign }}
                                >
                                    <InfoOutlinedIcon className={classes.infoIcon} />
                                </Tooltip>
                            </div>
                            <ApariTextSwitch disabled value={formik.values.isRefund ?? false} />
                        </div>

                        <ApAmountField
                            disabled
                            name="originalAmount"
                            formik={formik}
                            variant="standard"
                            bolded
                            className={clsx(globalClasses.marginTop20, globalClasses.marginBottom10)}
                        />
                        <p className={clsx(globalClasses.font15weight400Light40)}>
                            {Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.YOUR_SPLIT_RATE')}
                            <span data-cy="splitValue">{NumberServices.format(transaction.originalAmount * transaction.splitRate!)}</span>
                        </p>
                        <ApariButton
                            color="primary"
                            variant="contained"
                            type="submit"
                            fullWidth
                            className={clsx(globalClasses.marginTop10)}
                            onClick={onCloseDialog}
                        >
                            {Localisation.localize('general.CLOSE')}
                        </ApariButton>
                    </div>
                    {!isEmpty(documents) ? (
                        <div className={globalClasses.flexRowEnd}>
                            <div
                                className={clsx(
                                    globalClasses.font12weight600Dark,
                                    globalClasses.alignSelfFlexEnd,
                                    globalClasses.fullWidth,
                                    globalClasses.marginTop32,
                                    globalClasses.marginLeft12
                                )}
                            >
                                {Localisation.localize('PREVIEW_SHARED_TRANSACTION_DIALOG.UPLOADED_FILES', { count: documents.length })}
                                {Object.entries(documents).map(([key, value]) => (
                                    <ApariFilePreview
                                        key={key}
                                        status={PreviewState.SUCCESS}
                                        filename={value.filename!}
                                        previewUrl={value.previewUrl!}
                                        downloadUrl={value.downloadUrl}
                                        fileSize={value.size}
                                        noDelete
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className={clsx(globalClasses.marginLeftAuto, globalClasses.marginRight52, classes.hideImage)}>
                            <img alt="login" src={Arrow} height={308} />
                        </div>
                    )}
                </div>
            )}
        </ApariDialog>
    );
};

export default PreviewSharedTransactionDialog;
