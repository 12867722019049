import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    root: {},
    imageContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        marginTop: '157px',
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'flex-start',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '81.5px'
        }
    },
    subheader: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        paddingBottom: 0,
        marginTop: '47px'
    },
    iconContainer: {
        //used to offset 12px padding that icon component adds. If I remove the left padding from the icon, the highlight circle gets messed up
    },
    formContainer: {
        [theme.breakpoints.up('md')]: {
            paddingRight: '81.5px'
        }
    },
    buttonContainer: {
        maxWidth: 300,
        [theme.breakpoints.down('md')]: {
            padding: '0px 16px'
        }
    },
    cancelButton: {},
    dialog: {
        height: '100%',
        padding: 12,
        paddingTop: 0,
        [theme.breakpoints.up('lg')]: {
            padding: '24px 0px 60px 80px',
            paddingTop: 0,
            width: 780
        }
    },
    title: {
        padding: '16px 0',
        [theme.breakpoints.down('md')]: {
            padding: 16
        }
    },
    dialogTitle: {
        padding: '20px 0 20px 20px',
        [theme.breakpoints.down('md')]: {
            padding: '20px 4px'
        }
    },
    icon: {
        color: COLORS.apariLightBlue
    },
    noDataContainer: {
        minHeight: 200,
        height: '100%'
    }
}));
