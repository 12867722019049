import { makeStyles, Theme } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';
interface Props {
    width?: number;
    hasBorder?: boolean;
}
export default makeStyles<Theme, Props>(theme => ({
    root: ({ width, hasBorder }) => ({
        padding: '24px 16px 24px 16px',
        background: COLORS.apariWhite,
        marginTop: 40,
        WebkitFlex: 'none',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginBottom: 20
        },
        [theme.breakpoints.up('lg')]: {
            background: 'none',
            width: width,
            height: '200px !important',
            padding: `77.5px 0 38.4px 0`
        },
        borderTop: hasBorder ? `1px solid ${COLORS.apariBlack80}` : '',
        backfaceVisibility: 'hidden',
        alignItems: 'flex-start',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column'
    }),
    footerText: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'row',
        whiteSpace: 'pre',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            alignItems: 'flex-start',

            flexDirection: 'column'
        }
    },
    transparentHeader: {
        background: theme.palette.background.default
    },
    rootShadow: {
        [theme.breakpoints.down('md')]: {
            MozBoxShadow: '11px 10px 37px -5px rgba(0,0,0,0.52)',
            WebkitBoxShadow: '11px 10px 37px -5px rgba(0,0,0,0.52)',
            BoxShadow: '11px 10px 37px -5px rgba(0,0,0,0.52)'
        }
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        height: 30,
        fontSize: 14,
        fontWeight: 600,
        color: COLORS.apariBlack80
    },
    signOutIcon: {
        marginLeft: 8
    },
    monumentFont: {
        fontFamily: FONTS.SECONDARY_FONT,
        fontStyle: 'normal'
    },
    menuIconColor: {
        color: COLORS.apariBlack80
    },
    closeIcon: {
        fontSize: 20,
        padding: 0
    },
    dialogTitle: {
        paddingRight: 20
    },
    moneyhubBankFeed: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start'
        }
    },
    infoIcon: {
        color: COLORS.apariBlack40
    },
    tooltipDesign: {
        maxWidth: 230
    },
    arrowDesign: {
        color: '#B7B7B7'
    }
}));
