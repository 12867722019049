import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles<Theme>(() => ({
    root: {},
    menuIconRoot: {
        padding: 4
    },
    menuPaper: {
        marginTop: 20,
        padding: 25,
        boxShadow:
            '0px 4px 122px rgba(0, 0, 0, 0.04), 0px 1.28121px 27.2503px rgba(0, 0, 0, 0.0238443), 0px 0.517444px 8.11312px rgba(0, 0, 0, 0.0161557)',
        width: 272
    },
    menuList: {
        padding: 0
    },
    rootMenuItem: {
        marginTop: 4,
        padding: 0,
        paddingTop: 2,
        paddingBottom: 2,
        minHeight: 'unset'
    },
    externalLinkIcon: {
        fontSize: 12
    },
    mtdImage: {
        transform: 'translate(0px, -2px)'
    },
    fullWidth: {
        width: '100%'
    }
}));
