export default [
    '/start-trial',
    '/authentication-method',
    '/link-your-phone-number',
    '/sms-authentication-code',
    '/bind-google-authenticator',
    '/google-authentication-code',
    '/safe-recovery-key',
    '/hmrc/sign-up',
    '/hmrc/national-insurance-number',
    '/income-type',
    '/tax-year',
    '/self-employed',
    '/landlord',
    '/landlord-property',
    '/foreign-landlord',
    '/foreign-landlord-property',
    '/dashboard',
    '/settings',
    '/settings/account-settings',
    '/cancel-deletion',
    '/settings/account-settings/confirm-2fa',
    '/settings/account-settings/bind-google-authenticator',
    '/settings/account-settings/safe-recovery-key',
    '/settings/account-settings/google-authentication-code',
    '/checkout/success',
    '/checkout/cancel',
    '/plans'
];
