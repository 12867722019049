import React from 'react';
import clsx from 'clsx';
import { InputAdornment, TextFieldProps } from '@material-ui/core';
import { ApariTextField } from 'components';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { NUMBERS } from 'constants/index';
import styles from './styles';

type Props = TextFieldProps & Record<string, unknown>;

type Target = {
    target: {
        value?: number;
        name?: string;
    };
};

type DistanceFormatProps = NumberFormatProps & {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (target: Target) => void;
    name: string;
};

const DistanceFormat: React.FC<DistanceFormatProps> = props => {
    const { onChange, name, inputRef, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            thousandSeparator={NUMBERS.default.thousandSeparator}
            decimalSeparator={NUMBERS.default.decimalSeparator}
            decimalScale={2}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.floatValue,
                        name: name
                    }
                });
            }}
            allowNegative={false}
            allowLeadingZeros={false}
            isNumericString
        />
    );
};

const ApariDistanceField: React.FC<Props> = props => {
    const { className, ...rest } = props;
    const classes = styles();

    return (
        <ApariTextField
            className={clsx(classes.root, className)}
            InputProps={{
                endAdornment: <InputAdornment position="end">km</InputAdornment>,
                inputComponent: DistanceFormat as any // eslint-disable-line @typescript-eslint/no-explicit-any
            }}
            {...rest}
        />
    );
};

export default ApariDistanceField;
