import { COLORS } from 'constants/index';

export default {
    root: {
        '& label': {
            color: COLORS.apariBlack80,
            fontSize: 13
        },
        '& .MuiInput-underline.MuiInput-marginDense': {
            '& input': {
                paddingBottom: 4
            }
        },
        '& .MuiInputBase-root div p': {
            fontSize: 13
        },
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: 10.5,
            paddingBottom: 10.5,
            color: COLORS.apariBlack80
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '2px !important'
        },
        '& .MuiInput-underline:before': {
            borderBottomStyle: 'solid'
        }
    }
};
