import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import moment from 'moment';
import clsx from 'clsx';
import { TransactionDto } from '@apari/banking-api';
import { ApariCheckbox } from 'components';
import { COLORS, DATE_FORMAT } from 'constants/index';
import globalStyles from 'styles/globalStyles';
import { GlobalServices, Localisation, NumberServices } from 'utils';
import styles from './styles';
import CardBar from './CardBar';
import { HomeShareIcon, RefundIcon, ShareIcon } from 'resources/icons';

import payPropLogo from './../../../resources/images/payprop-logo.svg';
import airbnbLogo from './../../../resources/images/airbnb-logo.svg';

interface Props {
    data: any;
    className?: string;
    type?: 'transaction' | 'overview';
    onCheckboxChange?: (data: any) => void;
    onRowClick?: (data: any) => void;
    title?: string;
    subtitle?: string;
    date?: string;
    checked?: boolean | undefined;
    disabled?: boolean | undefined;
    hasMenu?: boolean;
    category?: string;
    hasBar?: boolean;
    barActive?: boolean;
    incomeTypeName?: string;
    hasAttachments?: boolean;
    onEdit?: (data: any) => void;
    onSplitTransaction?: (data: any) => void;
    onPreview?: (data: any) => void;
    hideLabels?: boolean;
    showOriginalAmount?: boolean;
    isRefund?: boolean;
    isShared?: boolean;
    hasLogo?: boolean;
    partner?: string;
}

export const TransactionLabelsLegend: React.FC = () => {
    const classes = styles();
    const globalClasses = globalStyles();

    return (
        <div className={clsx(globalClasses.flexRowStart, globalClasses.fullWidth, globalClasses.marginBottom16)}>
            <div className={clsx(classes.labelLegendItem)}>
                <span className={clsx(classes.incomeTypeBullet)}> &#9679;</span>
                <span>{Localisation.localize('general.INCOME_TYPE')}</span>
            </div>
            <div className={clsx(classes.labelLegendItem)}>
                <span className={clsx(classes.categoryBullet)}> &#9679;</span>
                <span>{Localisation.localize('general.CATEGORY')}</span>
            </div>
        </div>
    );
};

const TransactionLabels: React.FC<{ transaction: TransactionDto; businessName?: string }> = ({ transaction, businessName }) => {
    const classes = styles();
    const globalClasses = globalStyles();

    return (
        <div
            className={clsx(
                globalClasses.flexRowStart,
                classes.positionAbsolute,
                globalClasses.marginLeft12,
                globalClasses.marginBottom4,
                classes.labelContainer
            )}
        >
            {businessName && (
                <div data-cy="cardBusinessName" className={clsx(classes.transactionLabel, classes.incomeTypeLabel)}>
                    {businessName}
                </div>
            )}
            {transaction.category && (
                <div data-cy="cardCategoryLabel" className={clsx(classes.transactionLabel, classes.categoryLabel)}>
                    {transaction.category.name}
                </div>
            )}
        </div>
    );
};

export const LoadingTransactionCard: React.FC = () => {
    const classes = styles();

    return (
        <div className={clsx(classes.rowCard)}>
            <ContentLoader speed={2} width="100%" height="71" backgroundColor={COLORS.backgroundLight}>
                <rect x="0" y="0" rx="3" ry="3" width="100%" height="70" />
            </ContentLoader>
        </div>
    );
};

const TransactionCard: React.FC<Props> = ({
    data,
    hasMenu,
    date,
    title,
    subtitle,
    type = 'transaction',
    onCheckboxChange,
    onRowClick,
    checked,
    disabled,
    className,
    hasBar,
    barActive,
    category,
    incomeTypeName,
    hasAttachments,
    isRefund,
    onEdit,
    onSplitTransaction,
    onPreview,
    hideLabels,
    showOriginalAmount,
    isShared,
    hasLogo,
    partner
}) => {
    const classes = styles();
    const globalClasses = globalStyles();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const rowClick = (transaction: TransactionDto) => {
        onRowClick && onRowClick(transaction);
    };

    const renderTooltipIcon = (title: string, icon: React.SVGAttributes<any>) => {
        return (
            <Tooltip title={Localisation.localize(`DATA_OVERVIEW.SUBMISSION_OVERVIEW.${title}`)} arrow>
                <div>{icon}</div>
            </Tooltip>
        );
    };

    const getSrc = () => {
        switch (partner) {
            case 'PayProp':
                return payPropLogo;
            case 'AirBnb':
                return airbnbLogo;
            default:
                return;
        }
    };

    const renderRightInfo = () => {
        switch (type) {
            case 'transaction':
                return (
                    <>
                        {hasLogo && (
                            <div className={clsx(classes.logoContainer)}>
                                <img src={getSrc()} alt="Logo" className={clsx(classes.partnerLogo)} />
                            </div>
                        )}
                        <div>
                            <strong
                                data-cy="cardAmount"
                                className={clsx(classes.rowAmount)}
                                style={{ color: data.isIncome !== isRefund ? COLORS.apariLightBlue : '#666666' }}
                            >
                                £ {data.isIncome !== isRefund ? '+' : '-'}
                                {showOriginalAmount
                                    ? NumberServices.format(GlobalServices.isEmpty(data.originalAmount) ? 0 : data.originalAmount)
                                    : NumberServices.format(GlobalServices.isEmpty(data.amount) ? 0 : data.amount)}
                            </strong>
                            <div className={clsx(globalClasses.flexRowEnd, globalClasses.marginRight6)}>
                                {hasAttachments
                                    ? renderTooltipIcon(
                                          'ATTACH_FILE_ICON_TOOLTIP',
                                          <AttachFileIcon className={clsx(classes.additionalInfoIcon)} />
                                      )
                                    : null}
                                {data.parentTransactionId
                                    ? renderTooltipIcon(
                                          'CALL_SPLIT_ICON_TOOLTIP',
                                          <CallSplitIcon data-cy="splitIcon" className={clsx(classes.splitIcon)} />
                                      )
                                    : null}
                                {isRefund ? renderTooltipIcon('REFUND_ICON_TOOLTIP', <RefundIcon />) : null}
                                {isShared ? renderTooltipIcon('SHARE_ICON_TOOLTIP', <ShareIcon />) : null}
                                {data.sharedWithSomeone ? renderTooltipIcon('HOME_SHARE_ICON_TOOLTIP', <HomeShareIcon />) : null}
                            </div>
                        </div>
                    </>
                );
            case 'overview':
                return (
                    <span className={clsx(classes.rowCardInfoStatus)}>
                        {Localisation.localize(`DATA_OVERVIEW.SUBMISSION_OVERVIEW.${data.status}`) || '-'}
                    </span>
                );
        }
    };

    const checkboxChange = (event: any, data: any) => {
        event.stopPropagation();
        onCheckboxChange && onCheckboxChange(data);
    };

    return (
        <div data-cy={data.id} className={clsx(classes.rowCard, disabled ? clsx(classes.rowDisabled) : undefined, className)}>
            {hasBar && <CardBar isUnsubmitted={barActive} />}
            <div
                className={clsx(
                    classes.cardContent,
                    onCheckboxChange && classes.cardContentWithCheckbox,
                    onRowClick && globalClasses.cursorPointer
                )}
            >
                <div className={clsx(classes.rowCardLeft)} onClick={() => rowClick(data)}>
                    {onCheckboxChange && (
                        <ApariCheckbox
                            data-cy="transactionCheckbox"
                            icon={<CircleUnchecked style={{ borderColor: 'black' }} />}
                            checkedIcon={<CircleCheckedFilled style={{ borderColor: 'black' }} />}
                            value={data.isChecked || false}
                            checked={checked}
                            onClick={event => checkboxChange(event, data)}
                        />
                    )}

                    <div className={clsx(classes.rowCardInfo)}>
                        <div className={clsx(globalClasses.flexColumnCenter)}>
                            {date && (
                                <>
                                    <span data-cy="cardDate" className={clsx(classes.rowCardInfoDate)}>
                                        {moment(date).format(DATE_FORMAT.DD)}
                                    </span>
                                    <span data-cy="cardDate" className={clsx(classes.rowCardInfoDate)}>
                                        {moment(date).format(DATE_FORMAT.MMM)}
                                    </span>
                                    <span data-cy="cardDate" className={clsx(globalClasses.font10weight400Dark60)}>
                                        {moment(date).format(DATE_FORMAT.YYYY)}
                                    </span>
                                </>
                            )}
                        </div>
                        <div className={clsx(globalClasses.flexColumnStart, globalClasses.fullWidth)}>
                            <div className={clsx(globalClasses.flexRowStart, globalClasses.fullWidth)}>
                                {!hideLabels && <TransactionLabels transaction={data} businessName={incomeTypeName} />}
                            </div>
                            <div className={clsx(globalClasses.flexRowCenter, globalClasses.fullWidth)}>
                                <div className={clsx(classes.rowCardInfoSender)}>
                                    {!GlobalServices.isEmpty(category) && (
                                        <div className={clsx(classes.rowCardInfoCategory)}>{category || '-'}</div>
                                    )}
                                    <strong data-cy="cardCounterpart" className={clsx(classes.rowCardInfoSenderTitle)}>
                                        {title || '-'}
                                    </strong>
                                    <span data-cy="cardPurpose" className={clsx(classes.rowCardInfoSenderText)}>
                                        {subtitle || '-'}
                                    </span>
                                </div>
                                <div className={clsx(globalClasses.flexRowCenter, globalClasses.marginLeftAuto)}>{renderRightInfo()}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {hasMenu && (
                    <IconButton data-cy="transactionMenu" className={clsx(globalClasses.noPadding)} onClick={handleClick}>
                        <MoreVertIcon className={clsx(classes.menuButton)} />
                    </IconButton>
                )}
                <Menu
                    anchorEl={anchorEl}
                    PaperProps={{
                        square: true
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {onEdit && (
                        <MenuItem
                            onClick={() => {
                                onEdit(data);
                                handleClose();
                            }}
                        >
                            {Localisation.localize('general.edit')}
                        </MenuItem>
                    )}
                    {onSplitTransaction && (
                        <MenuItem
                            data-cy="splitTransactionButton"
                            disabled={!!data.parentTransactionId}
                            onClick={() => {
                                onSplitTransaction(data);
                                handleClose();
                            }}
                        >
                            {Localisation.localize('general.SPLIT_TRANSACTION')}
                        </MenuItem>
                    )}
                    {onPreview && (
                        <MenuItem
                            onClick={() => {
                                onPreview(data);
                                handleClose();
                            }}
                        >
                            {Localisation.localize('general.VIEW')}
                        </MenuItem>
                    )}
                </Menu>
            </div>
        </div>
    );
};

export default TransactionCard;
