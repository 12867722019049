import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    upgradeDialogContent: {
        maxWidth: 355
    },
    upgradeDialog: {
        '& .MuiDialogTitle-root': {
            padding: '16px 28px'
        }
    }
}));
