import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
    root: {
        width: 120,

        '& input': {
            fontSize: 12
        },
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: 10,
            paddingBottom: 10
        },
        '& .MuiInputAdornment-positionEnd': {
            '& p': {
                fontSize: '12px !important',
                color: theme.palette.primary.main
            }
        }
    }
}));

