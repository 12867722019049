//list of currently accepted search parameters used in useQuery hook

enum QUERY_PARAMS {
    UTM_SOURCE = 'utm_source',
    UTM_MEDIUM = 'utm_medium',
    UTM_CAMPAIGN = 'utm_campaign',
    UTM_TERM = 'utm_term',
    UTM_CONTENT = 'utm_content',
    GCLID = 'gclid',
    FCLID = 'fclid',
    REFERRAL_CODE = 'referral_code'
}

export default QUERY_PARAMS;
