import moment from 'moment';
import dateFormat from './dateFormat';
const DATES = {
    TAXYEAR_UK_START: '04-06',
    TAXYEAR_UK_START_SERVER_FORMAT: '--04-06',
    YEAR_START: moment('01-01', dateFormat.MM_DD).format(),
    YEAR_END: moment('12-31', dateFormat.MM_DD).format()
};

export default DATES;
