import React from 'react';
import { useHistory } from 'react-router';
import { withWidth, WithWidthProps } from '@material-ui/core';
import { UIServices, Localisation } from 'utils';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';
import { AnimatedTransition, ApariButton, SubHeaderClean } from 'components';
import NewLayout from 'containers/layouts/NewLayout';
import styles from './styles';

const AccountConfirmationSuccess: React.FC<WithWidthProps> = ({ width }) => {
    const history = useHistory();
    const globalClasses = globalStyles();
    const classes = styles();

    const renderMainContent = () => {
        return (
            <>
                <div>
                    <SubHeaderClean
                        title={Localisation.localize('SignInScreen.welcomeToApari')}
                        noSidePadding
                        alternativeFont
                        noBackButton
                        messages={[Localisation.localize('AccountConfirmationScreen.registrationCompleted')]}
                    />
                </div>
                <div className={clsx(globalClasses.fullWidth, globalClasses.flexColumn, globalClasses.fullHeight, classes.formContainer)}>
                    <div className={clsx(classes.confirmButtonContainer)}>
                        <ApariButton
                            data-cy="accountConfirm"
                            color={'primary'}
                            variant={'contained'}
                            fullWidth
                            size="small"
                            onClick={() => history.push('/sign-in')}
                        >
                            {Localisation.localize('general.CONTINUE')}
                        </ApariButton>
                    </div>
                </div>
            </>
        );
    };

    const renderMobileContent = () => {
        return (
            <div className={clsx(globalClasses.contentWrapper, classes.contentWrapperOverride)}>
                <div className={clsx(globalClasses.fullHeight, globalClasses.fullWidth)}>
                    <div className={clsx(globalClasses.fullHeight)}>
                        <div className={clsx(globalClasses.fullHeight, globalClasses.flexColumn)}>{renderMainContent()}</div>
                    </div>
                </div>
            </div>
        );
    };

    const renderDesktopContent = () => {
        return (
            <div className={clsx(globalClasses.contentWrapper, classes.contentWrapperOverride)}>
                <div className={clsx(globalClasses.fullHeight, globalClasses.fullWidth, globalClasses.flexRow)}>
                    <div className={clsx(globalClasses.flexColumnCenter, globalClasses.fullHeight)}>{renderMainContent()}</div>
                </div>
            </div>
        );
    };

    return (
        <NewLayout
            whiteBackground
            hideBack
            hideFooter
            hideTitle
            headerText={Localisation.localize('general.registration')}
            enableBackgroundArrows
        >
            <AnimatedTransition addHeight centerVerticallyInDesktop className={classes.marginBottomOverride}>
                {UIServices.isMobile(width) ? renderMobileContent() : renderDesktopContent()}
            </AnimatedTransition>
        </NewLayout>
    );
};

export default withWidth()(AccountConfirmationSuccess);
