import { makeStyles } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';
import theme from 'styles/theme';

export default makeStyles(() => ({
    root: {},
    label: {
        fontFamily: FONTS.PRIMARY_FONT,
        [theme.breakpoints.down('md')]: {
            fontSize: 10,
            maxWidth: 120
        }
    },
    listItemOverride: {
        padding: '8px 8px 8px 16px',
        backgroundColor: '#fff',
        borderRadius: 2,
        marginBottom: 16,
        minHeight: 64,
        boxShadow:
            '0px 4px 122px rgba(0, 0, 0, 0.04), 0px 1.28121px 27.2503px rgba(0, 0, 0, 0.0238443), 0px 0.517444px 8.11312px rgba(0, 0, 0, 0.0161557)'
    },
    loaderContainer: {
        height: 72,
        borderRadius: 2,
        marginBottom: 20,
        boxShadow:
            '0px 4px 122px rgba(0, 0, 0, 0.04), 0px 1.28121px 27.2503px rgba(0, 0, 0, 0.0238443), 0px 0.517444px 8.11312px rgba(0, 0, 0, 0.0161557)'
    },
    icon: {
        fontSize: 24,
        color: COLORS.apariDarkSilver
    },
    checkIcon: {
        fontSize: 24,
        marginLeft: 8,
        color: COLORS.apariBlack80
    },
    infoIcon: {
        fontSize: 20,
        marginLeft: 8,
        color: COLORS.apariBlack40
    },
    purpleCircle: {
        background: COLORS.apariOverviewPurple,
        borderRadius: '50%',
        height: 6,
        width: 6
    },
    unsubittedText: {
        fontSize: 10,
        fontWeight: 400,
        color: COLORS.apariBlack60,
        textDecoration: 'underline'
    },
    unsubmittedButton: {
        padding: '4px 0'
    },
    amount: {
        fontSize: 10,
        fontWeight: 700,
        color: COLORS.apariBlack40
    },
    amountIncome: {
        color: COLORS.apariLightBlue
    },
    tooltip: {
        backgroundColor: COLORS.apariLightSilver,
        color: COLORS.apariBlack80
    },
    tooltipArrow: {
        color: COLORS.apariLightSilver
    }
}));
