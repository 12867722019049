import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    selectComponentPaper: {
        border: `${'2px solid ' + theme.palette.primary.main}`,
        '& ul': {
            padding: 0
        },
        '& li': {
            fontSize: 14,
            height: 50
        }
    },
    customSelect: {
        '& .MuiOutlinedInput-input': {
            paddingTop: 10.5,
            paddingBottom: 10.5,
            fontSize: 12
        }
    },
    customSelectNative: {
        width: '100%',
        '& .MuiOutlinedInput-input': {
            paddingTop: 8.5,
            paddingBottom: 12.5,
            fontSize: 12
        }
    }
}));
