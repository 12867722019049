import { makeStyles, Theme } from '@material-ui/core';
import { COLORS } from 'constants/index';

type Props = {
    avatarSize: number;
    paddingTopAdjustment?: number;
};

export default makeStyles<Theme, Props>(() => ({
    root: {},
    defaultAvatar: {
        color: COLORS.apariPurple2,
        backgroundColor: COLORS.apariLightSilver
    },
    rootAvatar: ({ avatarSize, paddingTopAdjustment }) => ({
        width: avatarSize,
        height: avatarSize,
        ...(paddingTopAdjustment && { paddingTop: paddingTopAdjustment }),
        fontWeight: 600,
        '& span': {
            transform: 'scale(1.1)'
        }
    })
}));
