import React from 'react';
import clsx from 'clsx';
import { SubmissionSubmitter } from '@apari/uk-mtd-api/models';
import { DATE_FORMAT } from 'constants/index';
import globalStyles from 'styles/globalStyles';
import { DateTimeServices, Localisation } from 'utils';

type SubmitterInfoProps = {
    className?: string;
    submitter?: SubmissionSubmitter;
    submittedAtDate?: string;
};

const SubmitterInfo: React.FC<SubmitterInfoProps> = ({ className, submitter, submittedAtDate }) => {
    const globalClasses = globalStyles();

    const getSubmitter = () => {
        if (submitter) {
            if (submitter.agencyName) {
                return submitter.agencyName;
            } else {
                return submitter.firstName + ' ' + submitter.lastName;
            }
        }
    };

    const getSubmissionInfo = () => {
        if (submittedAtDate) {
            return `${DateTimeServices.getDateInGivenFormat(submittedAtDate, DATE_FORMAT.UK_STANDARD_2)} ${getSubmitter() ? 'by' : ''} ${
                getSubmitter() || ''
            }`;
        } else {
            return Localisation.localize(`DATA_OVERVIEW.SUBMISSION_OVERVIEW.NO_SUBMISSION_MADE`);
        }
    };

    return (
        <div className={clsx(globalClasses.flexRowStart, className)}>
            <span className={clsx(globalClasses.font10weight400Dark, globalClasses.marginRight40)}>
                {Localisation.localize(`DATA_OVERVIEW.SUBMISSION_OVERVIEW.LAST_SUBMISSION`)}
            </span>
            <span className={clsx(globalClasses.font10weight600Dark)}>{getSubmissionInfo()}</span>
        </div>
    );
};

export default SubmitterInfo;
