import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    root: {},
    divider: {
        width: '100%',
        border: '1px solid #E0E0E0'
    },
    expanded: {
        '&$expanded': {
            margin: 0,
            minHeight: 0,
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    details: {
        display: 'block',
        whiteSpace: 'pre-wrap',
        marginBottom: 40
    }
}));
