import React, { useState } from 'react';
import clsx from 'clsx';
import { IconButton, Menu, MenuItem, MenuItemProps, MenuProps } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import globalStyles from 'styles/globalStyles';
import { Localisation } from 'utils';
import styles from './styles';
import apariArrow from 'resources/images/apari-arrow.svg';
import sa100Icon from 'resources/images/sa100-icon.svg';
import { UserApi } from '@apari/core-api';

type Props = Partial<MenuProps> & {
    isMobile?: boolean;
};

type CustomMenuItemProps = Partial<Omit<MenuItemProps, 'button'>> & {
    external?: boolean;
    src?: any;
    imageStyleClassName?: any;
};

const CustomMenuItem: React.FC<CustomMenuItemProps> = ({ external, src, imageStyleClassName, className, children, ...rest }) => {
    const classes = styles();
    const globalClasses = globalStyles();

    return (
        <MenuItem classes={{ root: classes.rootMenuItem }} className={clsx(globalClasses.font12weight600Dark, className)} {...rest}>
            {external && (
                <img
                    alt="..."
                    style={{ height: 17, paddingRight: src ? 8 : 16 }}
                    className={clsx(imageStyleClassName)}
                    src={src || apariArrow}
                />
            )}
            {children}
        </MenuItem>
    );
};

const ApariAppSwitchMenu: React.FC<Props> = ({ isMobile, ...rest }) => {
    const userApi = new UserApi();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const classes = styles();
    const globalClasses = globalStyles();

    const open = Boolean(anchorEl);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const redirectToSa100 = async () => {
        const response = await userApi.switchApplication('UK_SA100_STANDALONE');
        if (response.data.url) {
            window.location.replace(response.data.url);
        }
    };

    return (
        <div>
            <IconButton
                data-cy="expandMenuItem"
                classes={{ root: classes.menuIconRoot }}
                className={clsx(isMobile && globalClasses.paddingTop0Forced)}
                onClick={handleOpen}
            >
                {anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Menu
                classes={{
                    paper: clsx(
                        classes.menuPaper,
                        isMobile && classes.fullWidth,
                        isMobile ? globalClasses.marginLeft0 : globalClasses.marginLeft48
                    ),
                    list: classes.menuList
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                {...rest}
            >
                <p className={clsx(globalClasses.font12weight600HeaderMenuGray, globalClasses.marginTop0)}>
                    {Localisation.localize('HEADER.MENU.SWITCH_TO')}
                </p>
                <a onClick={redirectToSa100}>
                    <CustomMenuItem external onClick={handleClose} src={sa100Icon} imageStyleClassName={classes.mtdImage}>
                        {Localisation.localize('HEADER.MAKING_TAX_DIGITAL')}
                    </CustomMenuItem>
                </a>
                <p className={clsx(globalClasses.font12weight600HeaderMenuGray)}>{Localisation.localize('HEADER.MENU.DISCOVER')}</p>
                <a target="_blank" rel="noreferrer" href="https://apari-digital.typeform.com/to/wWkI58U1">
                    <CustomMenuItem external onClick={handleClose}>
                        {Localisation.localize('HEADER.MENU.FIND_THE_RIGHT_PRODUCT')}
                    </CustomMenuItem>
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.apari-digital.com/">
                    <CustomMenuItem external onClick={handleClose}>
                        {Localisation.localize('HEADER.MENU.APARI_HOME')}
                    </CustomMenuItem>
                </a>
            </Menu>
        </div>
    );
};

export default ApariAppSwitchMenu;
