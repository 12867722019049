import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    tilesGridColumns: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
        placeItems: 'center',
        gap: '15px',
        [theme.breakpoints.down('md')]: {
            maxWidth: 395,
            margin: '0 auto'
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto !important',
            width: 'auto !important',
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'
        }
    },
    tilesWrapper: {
        [theme.breakpoints.up('md')]: {
            padding: 16
        },
        [theme.breakpoints.up('lg')]: {
            width: 427
        }
    },
    alreadySubmitted: {
        '&:checked': {
            '& ~ div': {
                backgroundColor: COLORS.apariGray
            }
        },
        '& ~ div': {
            backgroundColor: COLORS.gray5,
            color: COLORS.apariWhite,
            '& > span': {
                color: COLORS.apariWhite
            }
        }
    }
}));
