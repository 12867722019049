import { CURRENCY } from 'constants/index';

const NUMBERS = {
    default: {
        thousandSeparator: '.',
        decimalSeparator: ',',
        currency: CURRENCY.EURO
    }
};

export default NUMBERS;
