import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    root: {},
    text: {
        wordBreak: 'break-word',
        marginTop: theme.spacing(2)
    },
    disabledText: {
        color: theme.palette.grey[500]
    },
    divider: {
        borderColor: COLORS.inputDarkMode
    },
    labelMargin: {
        marginLeft: theme.spacing(2)
    }
}));
