export enum ButtonType {
    SAVE_AND_CONTINUE,
    SAVE_AND_RETURN_LATER
}

export type HMRCDataField = {
    label: string;
    value: any;
};

export type NavigationOptions = {
    label: string;
    value: number;
    path: string;
    disabled?: boolean;
};

export interface RouteParamsJellyfish {
    currentYear: string;
}
