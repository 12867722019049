import React from 'react';
import clsx from 'clsx';
import { Modal } from '@material-ui/core';
import { motion } from 'framer-motion';
import globalStyles from 'styles/globalStyles';
import ApariLogo from 'resources/images/apari-logo.svg';
import styles from './styles';

const pageVariants = {
    initial: {
        opacity: 0.5
    },
    in: {
        opacity: 1,
        scale: 1
    },
    out: {
        opacity: 0.5
    }
};

const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
};

const FullScreenLoading: React.FC<{ message?: string }> = ({ message }) => {
    const classes = styles();
    const globalClasses = globalStyles();
    return (
        <Modal open={true} hideBackdrop={true}>
            <motion.div
                className={clsx(classes.root, globalClasses.flexColumnCenter)}
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
            >
                <div className={clsx(globalClasses.flexColumnCenter)}>
                    <img className={clsx(classes.loadingImage)} src={ApariLogo} alt="logo_small" />
                    <div className={clsx(globalClasses.font12weight400Grey, globalClasses.marginTop8)}>{message}</div>
                </div>
            </motion.div>
        </Modal>
    );
};
export default FullScreenLoading;
