import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { DATE_FORMAT } from 'constants/index';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

interface Props {
    data: any;
    title?: string;
    subtitle?: string;
    date?: string;
}

const TransactionCard: React.FC<Props> = ({ data, date, title, subtitle }) => {
    const classes = styles();
    const globalClasses = globalStyles();

    const { isIncome, amount } = data;

    return (
        <div className={clsx(classes.container, globalClasses.flexRowStart, globalClasses.marginBottom12)}>
            <div className={clsx(classes.leftBar)} />
            <div className={clsx(classes.dateContainer, globalClasses.font13weight500Dark)}>
                <div className={clsx(globalClasses.flexColumnCenter)}>
                    {date && (
                        <>
                            <span data-cy="cardDate">{moment(date).format(DATE_FORMAT.DD)}</span>
                            <span data-cy="cardDate">{moment(date).format(DATE_FORMAT.MMM)}</span>
                            <span data-cy="cardDate" className={clsx(globalClasses.font10weight400Dark60)}>
                                {moment(date).format(DATE_FORMAT.YYYY)}
                            </span>
                        </>
                    )}
                </div>
            </div>
            <div className={clsx(classes.infoWrapper, globalClasses.flexRowSpaceBetween)}>
                <div className={clsx(globalClasses.flexColumn)}>
                    <div className={clsx(globalClasses.font13weight600)}>{title ?? '-'}</div>
                    <div className={clsx(globalClasses.font10weight500Darker)}>{subtitle ?? '-'}</div>
                </div>
                <div className={clsx(globalClasses.font10Weight700Black40, classes.amountContainer)}>
                    £ {isIncome ? '+' : '-'}
                    {amount}
                </div>
            </div>
        </div>
    );
};

export default TransactionCard;
