import React from 'react';
import clsx from 'clsx';
import { ListItem, ListItemProps, withWidth } from '@material-ui/core';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

type Props = ListItemProps & {
    label: string;
    onClick: () => void;
    Icon: React.ElementType;
};

const ApariListItemWithIcon: React.FC<Props> = ({ selected, key, onClick, label, disabled, Icon }) => {
    const classes = styles();
    const globalClasses = globalStyles();

    return (
        <ListItem
            disabled={disabled}
            className={clsx(globalClasses.flexRowSpaceBetween, classes.listItem)}
            selected={selected}
            key={key}
            onClick={onClick}
            button
        >
            <div className={clsx(classes.label)}>{label}</div>
            <div>
                <Icon />
            </div>
        </ListItem>
    );
};

export default withWidth()(ApariListItemWithIcon);
