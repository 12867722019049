import { Localisation } from 'utils';
import * as yup from 'yup';

const validationSchema = yup.object({
    email: yup
        .string()
        .email()
        .max(255, Localisation.localize('formErrors.255charactersMaximum'))
        .required(Localisation.localize('formErrors.fieldIsRequired')),
    password: yup
        .string()
        .max(255, Localisation.localize('formErrors.255charactersMaximum'))
        .required(Localisation.localize('formErrors.fieldIsRequired'))
});

export default validationSchema;
