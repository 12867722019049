import { TransactionCategoryCategoryTypeEnum } from '@apari/banking-api';

export const SE_EXPENSE = [
    { subcategories: undefined, id: 29, countryId: 1, parentCategory: undefined, name: 'Advertising and PR', categoryType: 'SE_EXPENSE' },
    { subcategories: undefined, id: 30, countryId: 1, parentCategory: undefined, name: 'Bad debts', categoryType: 'SE_EXPENSE' },
    {
        subcategories: undefined,
        id: 31,
        countryId: 1,
        parentCategory: undefined,
        name: 'Business Entertaining',
        categoryType: 'SE_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 32,
        countryId: 1,
        parentCategory: undefined,
        name: 'CIS Payments To Subcontractors',
        categoryType: 'SE_EXPENSE'
    },
    { subcategories: undefined, id: 33, countryId: 1, parentCategory: undefined, name: 'Cost of Goods', categoryType: 'SE_EXPENSE' },
    { subcategories: undefined, id: 34, countryId: 1, parentCategory: undefined, name: 'Depreciation', categoryType: 'SE_EXPENSE' },
    {
        subcategories: undefined,
        id: 35,
        countryId: 1,
        parentCategory: undefined,
        name: 'Finance costs & Leasing',
        categoryType: 'SE_EXPENSE'
    },
    { subcategories: undefined, id: 36, countryId: 1, parentCategory: undefined, name: 'Insurance', categoryType: 'SE_EXPENSE' },
    { subcategories: undefined, id: 37, countryId: 1, parentCategory: undefined, name: 'Interest', categoryType: 'SE_EXPENSE' },
    {
        subcategories: undefined,
        id: 38,
        countryId: 1,
        parentCategory: undefined,
        name: 'Miscellaneous expenses',
        categoryType: 'SE_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 39,
        countryId: 1,
        parentCategory: undefined,
        name: 'Office Costs, Light & Heat, Phone',
        categoryType: 'SE_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 40,
        countryId: 1,
        parentCategory: undefined,
        name: 'Professional Fees Legal, Accountancy, Surveys etc',
        categoryType: 'SE_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 41,
        countryId: 1,
        parentCategory: undefined,
        name: 'Repairs and maintenance',
        categoryType: 'SE_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 42,
        countryId: 1,
        parentCategory: undefined,
        name: 'Travel, Mileage & Subsistence',
        categoryType: 'SE_EXPENSE'
    },
    { subcategories: undefined, id: 43, countryId: 1, parentCategory: undefined, name: 'Wages & Salary', categoryType: 'SE_EXPENSE' }
];

export const SE_INCOME = [
    { subcategories: undefined, id: 54, countryId: 1, parentCategory: undefined, name: 'Income', categoryType: 'SE_INCOME' },
    { subcategories: undefined, id: 55, countryId: 1, parentCategory: undefined, name: 'Interest', categoryType: 'SE_INCOME' }
];

export const NON_FHL_LL_EXPENSE = [
    {
        subcategories: undefined,
        id: 1,
        countryId: 1,
        parentCategory: undefined,
        name: 'Brought Fwd Residential Financial Cost',
        categoryType: 'NON_FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 2,
        countryId: 1,
        parentCategory: undefined,
        name: 'Council Tax and Licences',
        categoryType: 'NON_FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 3,
        countryId: 1,
        parentCategory: undefined,
        name: 'Furniture, Furnishings & White goods',
        categoryType: 'NON_FHL_LL_EXPENSE'
    },
    { subcategories: undefined, id: 4, countryId: 1, parentCategory: undefined, name: 'Insurance', categoryType: 'NON_FHL_LL_EXPENSE' },
    {
        subcategories: undefined,
        id: 5,
        countryId: 1,
        parentCategory: undefined,
        name: 'Miscellaneous expenses',
        categoryType: 'NON_FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 6,
        countryId: 1,
        parentCategory: undefined,
        name: 'Mortgage interest - Residential properties',
        categoryType: 'NON_FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 7,
        countryId: 1,
        parentCategory: undefined,
        name: 'Mortgage interest and other financial costs - Commercial properties',
        categoryType: 'NON_FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 8,
        countryId: 1,
        parentCategory: undefined,
        name: 'Office Costs, Light & Heat, Phone',
        categoryType: 'NON_FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 9,
        countryId: 1,
        parentCategory: undefined,
        name: 'Professional Fees Legal, Accountancy, Surveys etc',
        categoryType: 'NON_FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 10,
        countryId: 1,
        parentCategory: undefined,
        name: 'Property management costs',
        categoryType: 'NON_FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 11,
        countryId: 1,
        parentCategory: undefined,
        name: 'Property Repairs and Maintenance',
        categoryType: 'NON_FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 12,
        countryId: 1,
        parentCategory: undefined,
        name: 'Rent a Room Relief Claimed',
        categoryType: 'NON_FHL_LL_EXPENSE'
    },
    { subcategories: undefined, id: 13, countryId: 1, parentCategory: undefined, name: 'Stamp Duty', categoryType: 'NON_FHL_LL_EXPENSE' },
    {
        subcategories: undefined,
        id: 14,
        countryId: 1,
        parentCategory: undefined,
        name: 'Travel, Mileage & Subsistence',
        categoryType: 'NON_FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 15,
        countryId: 1,
        parentCategory: undefined,
        name: 'Utilities and Service charges',
        categoryType: 'NON_FHL_LL_EXPENSE'
    }
];

export const NON_FHL_LL_INCOME = [
    { subcategories: undefined, id: 44, countryId: 1, parentCategory: undefined, name: 'Lease Premium', categoryType: 'NON_FHL_LL_INCOME' },
    {
        subcategories: undefined,
        id: 45,
        countryId: 1,
        parentCategory: undefined,
        name: 'Other Property Income + Interest Received',
        categoryType: 'NON_FHL_LL_INCOME'
    },
    { subcategories: undefined, id: 46, countryId: 1, parentCategory: undefined, name: 'Rent a Room', categoryType: 'NON_FHL_LL_INCOME' },
    { subcategories: undefined, id: 47, countryId: 1, parentCategory: undefined, name: 'Rental Income', categoryType: 'NON_FHL_LL_INCOME' },
    {
        subcategories: undefined,
        id: 48,
        countryId: 1,
        parentCategory: undefined,
        name: 'Reverse Premiums',
        categoryType: 'NON_FHL_LL_INCOME'
    }
];

export const FHL_LL_EXPENSE = [
    {
        subcategories: undefined,
        id: 16,
        countryId: 1,
        parentCategory: undefined,
        name: 'Council Tax and Licences',
        categoryType: 'FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 17,
        countryId: 1,
        parentCategory: undefined,
        name: 'Furniture, Furnishings & White goods',
        categoryType: 'FHL_LL_EXPENSE'
    },
    { subcategories: undefined, id: 18, countryId: 1, parentCategory: undefined, name: 'Insurance', categoryType: 'FHL_LL_EXPENSE' },
    {
        subcategories: undefined,
        id: 19,
        countryId: 1,
        parentCategory: undefined,
        name: 'Miscellaneous expenses',
        categoryType: 'FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 20,
        countryId: 1,
        parentCategory: undefined,
        name: 'Mortgage interest and other financial costs - Commercial properties',
        categoryType: 'FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 21,
        countryId: 1,
        parentCategory: undefined,
        name: 'Office Costs, Light & Heat, Phone',
        categoryType: 'FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 22,
        countryId: 1,
        parentCategory: undefined,
        name: 'Professional Fees Legal, Accountancy, Surveys etc',
        categoryType: 'FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 23,
        countryId: 1,
        parentCategory: undefined,
        name: 'Property management costs',
        categoryType: 'FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 24,
        countryId: 1,
        parentCategory: undefined,
        name: 'Property Repairs and Maintenance',
        categoryType: 'FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 25,
        countryId: 1,
        parentCategory: undefined,
        name: 'Rent a Room Relief Claimed',
        categoryType: 'FHL_LL_EXPENSE'
    },
    { subcategories: undefined, id: 26, countryId: 1, parentCategory: undefined, name: 'Stamp Duty', categoryType: 'FHL_LL_EXPENSE' },
    {
        subcategories: undefined,
        id: 27,
        countryId: 1,
        parentCategory: undefined,
        name: 'Travel, Mileage & Subsistence',
        categoryType: 'FHL_LL_EXPENSE'
    },
    {
        subcategories: undefined,
        id: 28,
        countryId: 1,
        parentCategory: undefined,
        name: 'Utilities and Service charges',
        categoryType: 'FHL_LL_EXPENSE'
    }
];

export const FHL_LL_INCOME = [
    { subcategories: undefined, id: 49, countryId: 1, parentCategory: undefined, name: 'Lease Premium', categoryType: 'FHL_LL_INCOME' },
    {
        subcategories: undefined,
        id: 50,
        countryId: 1,
        parentCategory: undefined,
        name: 'Other Property Income + Interest Received',
        categoryType: 'FHL_LL_INCOME'
    },
    { subcategories: undefined, id: 51, countryId: 1, parentCategory: undefined, name: 'Rent a Room', categoryType: 'FHL_LL_INCOME' },
    { subcategories: undefined, id: 52, countryId: 1, parentCategory: undefined, name: 'Rental Income', categoryType: 'FHL_LL_INCOME' },
    { subcategories: undefined, id: 53, countryId: 1, parentCategory: undefined, name: 'Reverse Premiums', categoryType: 'FHL_LL_INCOME' }
];

export const getCategoriesByType = (type: TransactionCategoryCategoryTypeEnum) => {
    switch (type) {
        case TransactionCategoryCategoryTypeEnum.SE_EXPENSE:
            return SE_EXPENSE;
        case TransactionCategoryCategoryTypeEnum.SE_INCOME:
            return SE_INCOME;
        case TransactionCategoryCategoryTypeEnum.NON_FHL_LL_EXPENSE:
            return NON_FHL_LL_EXPENSE;
        case TransactionCategoryCategoryTypeEnum.NON_FHL_LL_INCOME:
            return NON_FHL_LL_INCOME;
        case TransactionCategoryCategoryTypeEnum.FHL_LL_EXPENSE:
            return FHL_LL_EXPENSE;
        case TransactionCategoryCategoryTypeEnum.FHL_LL_INCOME:
            return FHL_LL_INCOME;
        default:
            return [];
    }
};
