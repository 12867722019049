import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Dropzone from 'react-dropzone';
import fileSize from 'filesize';
import { Localisation } from 'utils';

type Props = DialogProps & {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type FileDetails = {
    name: string;
    size: number;
};

const UploadFileDialog: React.FC<Props> = ({ open, setOpen }) => {
    const [fileDetails, setFileDetails] = useState<FileDetails>({ name: '', size: 0 });
    const [selectedFile, setSelectedFile] = useState<File>(null!);
    const [fileSelected, setFileSelected] = useState<boolean>(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemoveFile = () => {
        setFileDetails({ name: '', size: 0 });
        setFileSelected(false);
        setSelectedFile(null!);
    };

    const handleNext = async () => {
        await uploadFile(selectedFile);
    };

    const convertFileToBase64 = (file: File) =>
        new Promise((resolve, reject) => {
            if (!(file instanceof File)) {
                resolve(undefined);
            }
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });

    const uploadFile = (file: File) => async () => {
        await convertFileToBase64(file);
        return true;
    };

    const onDrop = <T extends File>(acceptedFiles: T[]) => {
        setFileDetails({
            name: acceptedFiles[0].name,
            size: acceptedFiles[0].size
        });
        setSelectedFile(acceptedFiles[0]);
        setFileSelected(true);
    };

    return (
        <Dialog open={open} fullWidth={true} maxWidth="sm" onExited={handleRemoveFile} onClose={handleClose}>
            <DialogTitle disableTypography className="text-center p-6 divide bg-secondary-main" id="responsive-dialog-title">
                <Typography variant="h3" className="text-white">
                    {Localisation.localize('UploadPdfDialog.title')}
                </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent className="flex flex-col justify-center items-center" style={{ minHeight: 300 }}>
                {!fileSelected ? (
                    <Dropzone multiple={false} onDrop={onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <div
                                className="transition outline-none duration-500 ease-in-out p-16 bg-gray-100 rounded-lg opacity-75 border-2 hover:opacity-100 cursor-pointer"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} accept=".pdf, .jpg, .png" />
                                <div>
                                    <div className="text-gray-700 text-xl">{Localisation.localize('UploadPdfDialog.chooseOrDrag')}</div>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                ) : (
                    <div className="flex flex-col justify-center items-center">
                        <div className="flex flex-row justify-center items-center space-x-2">
                            <Typography variant="h5">{Localisation.localize('UploadPdfDialog.selectedFile') + ': '}</Typography>
                            <Typography variant="h4">{fileDetails.name + ' ' + fileSize(fileDetails.size)}</Typography>
                        </div>
                        <Button
                            variant="contained"
                            className="bg-red-500 mt-8"
                            color="secondary"
                            startIcon={<CloseIcon />}
                            onClick={handleRemoveFile}
                        >
                            {Localisation.localize('general.remove')}
                        </Button>
                    </div>
                )}
            </DialogContent>
            <Divider />
            <DialogActions className="p-4">
                <Button onClick={handleClose} variant="outlined" color="primary">
                    {Localisation.localize('general.cancel')}
                </Button>
                <Button disabled={!fileSelected} onClick={handleNext} variant="contained" color="primary">
                    {Localisation.localize('general.next')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default UploadFileDialog;
