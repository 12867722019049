import React from 'react';
import clsx from 'clsx';
import {Switch, SwitchProps} from '@material-ui/core';
import styles from './styles';

const ApariSwitch: React.FC<SwitchProps> = props => {
    const {className, ...rest} = props;
    const classes = styles();

    return <Switch className={clsx(classes.switchBase, className || '')} {...rest}/>;
};

export default ApariSwitch;
