import React, { useEffect, useState } from 'react';
import { NotificationTypes, OpenNotificationsPayload } from 'types';
import { MaintenanceAnnouncementDto } from '@apari/core-api';

type NotificationState = {
    showNotification: boolean;
    notificationType?: NotificationTypes;
    notificationMessage: string;
    onNotificationClose?: () => void;
};

interface AppContextInterface {
    loadingBar: boolean;
    showLoadingBar: () => void;
    hideLoadingBar: () => void;
    notificationState: NotificationState;
    showNotifications: (payload: OpenNotificationsPayload) => void;
    hideNotifications: () => void;
    showInfoBar: boolean;
    setShowInfoBar: React.Dispatch<React.SetStateAction<boolean>>;
    maintenanceAnnouncement?: MaintenanceAnnouncementDto;
    setMaintenanceAnnouncement: React.Dispatch<React.SetStateAction<MaintenanceAnnouncementDto | undefined>>;
    countdown: string;
    isOnline: boolean;
}

export const AppContext = React.createContext({} as AppContextInterface);

export const AppProvider: React.FC = ({ children }) => {
    const [loadingBar, setLoadingBar] = useState<boolean>(false);
    const [showInfoBar, setShowInfoBar] = useState(false);
    const [maintenanceAnnouncement, setMaintenanceAnnouncement] = useState<MaintenanceAnnouncementDto>();
    const [notificationState, setNotificationState] = useState<NotificationState>({
        showNotification: false,
        notificationType: undefined,
        notificationMessage: '',
        onNotificationClose: undefined
    });

    let refreshInterval: NodeJS.Timeout;

    useEffect(() => {
        handleConnectionChange();
        window.addEventListener('online', handleConnectionChange);
        window.addEventListener('offline', handleConnectionChange);

        return function cleanup() {
            window.removeEventListener('online', handleConnectionChange);
            window.removeEventListener('offline', handleConnectionChange);
        };
    }, []);

    useEffect(() => {
        checkForMaintenance();
        refreshInterval = setInterval(() => {
            checkForMaintenance();
        }, 600000);
        return function cleanup() {
            clearInterval(refreshInterval);
        };
    }, []);

    const handleConnectionChange = () => {
        // const isOnline = window.navigator.onLine;
        // if (isOnline) {
        //     pingGoogle();
        //     pingInterval = setInterval(() => {
        //         pingGoogle();
        //     }, 2000);
        // } else {
        //     setIsOnline(false);
        // }
    };

    const checkForMaintenance = async () => {
        try {
            // const response = await configurationPublicControllerApi.getMaintenanceAnnouncement();
            // setMaintenanceAnnouncement(response.data);
            // if (response.data.enable) {
            //     startTimer(response.data);
            // } else {
            //     setShowInfoBar(false);
            // }
        } catch (error) {
            console.log('error', error);
        }
    };

    const showLoadingBar = () => {
        setLoadingBar(true);
    };

    const hideLoadingBar = () => {
        setLoadingBar(false);
    };

    const showNotifications = (payload: OpenNotificationsPayload) => {
        setNotificationState({
            showNotification: true,
            notificationType: payload.type,
            notificationMessage: payload.message,
            onNotificationClose: payload.onClose
        });
    };

    const hideNotifications = () => {
        setNotificationState({
            showNotification: false,
            notificationType: undefined,
            notificationMessage: '',
            onNotificationClose: () => {
                //comment to prevent unexpected empty function
            }
        });
    };

    return (
        <AppContext.Provider
            value={{
                isOnline: true,
                countdown: '',
                maintenanceAnnouncement,
                setMaintenanceAnnouncement,
                showInfoBar,
                setShowInfoBar,
                loadingBar,
                showLoadingBar,
                hideLoadingBar,
                notificationState,
                showNotifications,
                hideNotifications
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
