import React from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { GlobalServices, Localisation } from 'utils';
import NoDataContent from 'containers/pages/settings/IncomeTypesScreen/NoDataContent';

type Props = {
    data: Record<string, number | string | boolean | null>[];
};

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            fontSize: 12
        },
        body: {
            fontSize: 12
        }
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover
            }
        }
    })
)(TableRow);

const useStyles = makeStyles({
    table: {
        width: '100%'
    }
});

const ApariTable: React.FC<Props> = ({ data }) => {
    const classes = useStyles();

    const renderValue = (value: any) => {
        if(typeof value === "boolean"){
            if(value === true){
                return "yes"
            } else {
                return "no";
            }
        }
        return value;
    }

    const renderTableRow = (row: Record<string, number | string | boolean | null>) =>
        Object.values(row).map(value => (
            <StyledTableCell key={String(value)} align="center">
                {renderValue(value)}
            </StyledTableCell>
        ));
    const renderTableBody = () => data.map((row, index) => <StyledTableRow key={index}>{renderTableRow(row)}</StyledTableRow>);

    return GlobalServices.isEmpty(data) ? (
        <NoDataContent />
    ) : (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {Object.keys(data[0]).map((name, index) => (
                            <StyledTableCell key={index} align="center">
                                {Localisation.localize(`table.${name}`)}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>{renderTableBody()}</TableBody>
            </Table>
        </TableContainer>
    );
};

export default ApariTable;
