import { GlobalServices } from 'utils';

const decimal = '.';
const group = ',';

class NumberServices {
    static format(value: number | string, integer = null): number | string {
        if (GlobalServices.isDefined(value)) {
            const tmpValue = GlobalServices.toFixedNoRounding(Number(value));
            const strValue = tmpValue.toString();
            let intValue = null;
            let decimalValue = null;

            if (strValue.indexOf('.') !== -1) {
                intValue = strValue.split('.')[0];
                decimalValue = strValue.split('.')[1];
            } else {
                intValue = strValue;
                decimalValue = '00';
            }
            const intValueGroup = intValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + group);
            if (integer) {
                return intValueGroup;
            } else {
                return intValueGroup + decimal + decimalValue;
            }
        }
        return value;
    }

    static round(value: number): number {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }
}

export default NumberServices;
