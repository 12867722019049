import React from 'react';
import { Divider, MenuItem, Select, SelectProps, withWidth, WithWidthProps } from '@material-ui/core';
import { ObjectWithKeysRegular } from 'types';
import { UIServices } from 'utils';
import styles from './styles';
import clsx from 'clsx';

type Props = SelectProps &
    WithWidthProps & {
        onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
        options: ObjectWithKeysRegular[];
        valueKey: string;
        labelKey: string;
        value: string | number;
    };

const ApariSelect: React.FC<Props> = ({ value, valueKey, labelKey, onChange, options, width, ...rest }) => {
    const classes = styles();
    //might be bugged when option values are more complicated
    console.log(value);
    const renderOptions = () => {
        return options.map((option: ObjectWithKeysRegular, index: number) => {
            console.log(option);

            return [
                <MenuItem selected={value === option[valueKey]} key={option[labelKey]} value={option[valueKey]}>
                    {option[labelKey]}
                </MenuItem>,
                <Divider key={index} variant="middle" />
            ];
        });
    };
    const renderNativeOptions = () => {
        return options.map((option: ObjectWithKeysRegular) => {
            return (
                <option key={option[labelKey]} value={option[valueKey]}>
                    {option[labelKey]}
                </option>
            );
        });
    };
    return UIServices.isMobile(width) ? (
        <Select native value={value} className={clsx(classes.customSelectNative)} variant="outlined" onChange={onChange}>
            {renderNativeOptions()}
        </Select>
    ) : (
        <Select
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                },
                getContentAnchorEl: null
            }}
            fullWidth
            value={value}
            onChange={onChange}
            {...rest}
        >
            {renderOptions()}
        </Select>
    );
};

export default withWidth()(ApariSelect);
