import {Localisation} from "../index";

export const PATH_OPTION = "path";

export const createRedirectableOption = (path: string, label: string) => {
    return {
        label: Localisation.localize(label),
        value: PATH_OPTION,
        path
    }
}
