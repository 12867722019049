import React from 'react';
import clsx from 'clsx';
import styles from './styles';
import { Localisation } from 'utils';
import { COLORS } from 'constants/index';
import PasswordStrengthBar from 'react-password-strength-bar';

type Props = {
    plainPassword: string;
    onChangeScore: (score: number) => void;
};

const ApariPasswordStrengthBar: React.FC<Props> = props => {
    const { plainPassword, onChangeScore } = props;
    const { transparentWhite } = COLORS;
    const classes = styles({ passwordLength: (plainPassword || '').length });

    return (
        <PasswordStrengthBar
            password={plainPassword}
            minLength={8}
            shortScoreWord={Localisation.localize('signUpWithEmailScreen.8charactersMinimum')}
            className={clsx(classes.passwordBar)}
            onChangeScore={onChangeScore}
            barColors={[transparentWhite, transparentWhite, transparentWhite, transparentWhite, transparentWhite]}
            scoreWords={[
                Localisation.localize('signUpWithEmailScreen.weak'),
                Localisation.localize('signUpWithEmailScreen.weak'),
                Localisation.localize('signUpWithEmailScreen.fair'),
                Localisation.localize('signUpWithEmailScreen.good'),
                Localisation.localize('signUpWithEmailScreen.strong')
            ]}
        />
    );
};

export default ApariPasswordStrengthBar;
