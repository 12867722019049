import React from 'react';
import clsx from 'clsx';
import { Localisation } from 'utils';
import './styles.css';

interface Props {
    onChange?: (value: boolean) => void;
    className?: string;
    trueLabel?: string;
    falseLabel?: string;
    value: boolean;
    disabled?: boolean;
    dataCy?: string;
}

const ApariTextSwitch: React.FC<Props> = ({ className = '', trueLabel, falseLabel, disabled = false, value, onChange, dataCy }) => {
    const handleChange = () => {
        !disabled && onChange && onChange(!value);
    };

    return (
        <div className={clsx('container', className)} data-cy={dataCy}>
            <label className="switch">
                <input type="checkbox" checked={value} onChange={handleChange} disabled={disabled} />
                <div className={clsx(`slider round ${disabled && 'switchDisabled'}`)}>
                    <span className="onSwitch">{trueLabel || Localisation.localize('general.YES')}</span>
                    <span className="offSwitch">{falseLabel || Localisation.localize('general.NO')}</span>
                </div>
            </label>
        </div>
    );
};

export default ApariTextSwitch;
