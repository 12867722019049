import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';
export default makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    border: {
        borderWidth: 1,
        borderColor: COLORS.apariBlack20,
        width: '100%'
    },
    content: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        fontWeight: 400,
        fontSize: 12,
        color: COLORS.apariBlack20
    }
}));
