import { makeStyles, Theme } from '@material-ui/core';
import { COLORS } from 'constants/index';

type Props = {
    passwordLength: number;
};

export default makeStyles<Theme, Props>(() => ({
    passwordBar: ({ passwordLength }) => {
        return {
            visibility: passwordLength === 0 ? 'hidden' : 'visible',
            width: '100%',
            height: 5,
            borderRadius: 2,
            background: passwordLength < 8 ? COLORS.apariOrange : COLORS.apariGreen,
            '& p': {
                fontSize: '12px !important'
            }
        };
    }
}));
