import { makeStyles, Theme } from '@material-ui/core';
import { COLORS } from 'constants/index';
import PurpleBackground from 'resources/images/Purple_background.png';
import Arrow from 'resources/images/Arrow.png';
import LaptopAndMobile from 'resources/images/laptop_and_mobile.png';
import LaptopAndMobileAgent from 'resources/images/laptop_and_mobile_agent.png';

type Props = {
    noSidePadding?: boolean;
    hideMenu?: boolean;
    enableBackgroundArrows?: boolean;
    enableLaptopAndMobileImage?: boolean;
    isAgent?: boolean;
};

export default makeStyles<Theme, Props>(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flex: 1
    },
    whiteBackground: {
        backgroundColor: theme.palette.background.paper + '!important'
    },
    contentRoot: ({ hideMenu, enableBackgroundArrows, enableLaptopAndMobileImage, isAgent }) => {
        return {
            flex: 1,
            backgroundColor: theme.palette.background.paper,
            [theme.breakpoints.up('lg')]: {
                alignItems: 'center',
                marginLeft: hideMenu ? 'none' : 135
            },
            ...(enableBackgroundArrows && {
                [theme.breakpoints.up('md')]: {
                    '& header': {
                        background: 'transparent'
                    }
                },
                backgroundImage: `url(${PurpleBackground}), url(${Arrow})`,
                backgroundRepeat: 'no-repeat, no-repeat',
                backgroundSize: 'auto 100%, auto 340px',
                backgroundPosition: 'right top, left center',
                [theme.breakpoints.down('md')]: {
                    backgroundImage: 'none'
                }
            }),
            ...(enableLaptopAndMobileImage && {
                backgroundImage: `url(${isAgent ? LaptopAndMobileAgent : LaptopAndMobile}), url(${PurpleBackground}), url(${Arrow})`,
                backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
                backgroundSize: ' auto 432px, auto 100%, auto 340px',
                backgroundPosition: 'top 63% right 88px, right top, left center',
                [theme.breakpoints.between('md', 'lg')]: {
                    backgroundSize: ' auto 324px, 50% 100%, auto 340px',
                    backgroundPosition: 'top 50% right 88px, right top, left center'
                },
                [theme.breakpoints.down('md')]: {
                    backgroundImage: 'none'
                },
                [theme.breakpoints.up(2100)]: {
                    backgroundSize: ' auto 600px, auto 100%, auto 340px',
                    backgroundPosition: 'top 63% right 15%, right top, left center'
                }
            })
        };
    },
    desktopNavigation: {
        width: 135,
        height: '100%',
        overflowY: 'auto',
        position: 'fixed',
        zIndex: 2,
        padding: '20px 18px 18px 18px',
        backgroundColor: COLORS.apariLightBlue,
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)'
        }
    },
    noTopMargin: {
        marginTop: 0
    },
    marginTop50: {
        marginTop: 50
    }
}));
