import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { ListItem, ListItemIcon, ListItemText, useMediaQuery, useTheme, withWidth, WithWidthProps } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { PaymentStatusDtoSubscriptionTypeEnum } from '@apari/core-api';
import { AuthenticationContext } from 'context/AuthenticationContext';
import IncomeTypesContext from 'context/IncomeTypesContext';
import { AppContext } from 'context/AppContext';
import useEventTracking from 'hooks/useEventTracking';
import globalStyles from 'styles/globalStyles';
import { Localisation, UIServices } from 'utils';
import { EventActions, EventCategory } from 'types/eventTracking';
import { NotificationTypes, TOUR_TYPES } from 'types';
import { HelpIcon, ProfileAndSettingsIcon, TaxSubmissionIcon } from 'resources/icons';
import NavigationOption, { NavigationOptionProps } from './NavigatioOption';
import styles from './styles';
import logoWhite from 'resources/images/apari-logo-white.svg';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Cookies from 'js-cookie';

type NavigationContentProps = WithWidthProps & {
    disableLogoAction?: boolean;
};

const NavigationContent: React.FC<NavigationContentProps> = ({ width, disableLogoAction = false }) => {
    const { signOut, doesUserHaveLowestRequiredPackage, startTour, authenticated } = useContext(AuthenticationContext);
    const { resetIncomeTypes } = useContext(IncomeTypesContext);
    const { showLoadingBar, hideLoadingBar, showNotifications } = useContext(AppContext);
    const { trackingEvent } = useEventTracking();

    const theme = useTheme();
    const history = useHistory();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const globalClasses = globalStyles();
    const classes = styles({});

    const isSupportDisabled = !doesUserHaveLowestRequiredPackage(PaymentStatusDtoSubscriptionTypeEnum.STANDARD);

    const handleSignOut = async () => {
        showLoadingBar();

        if (Cookies.get('connectedGoodLord') !== undefined) {
            Cookies.remove('connectedGoodLord');
        }

        if (Cookies.get('connectedPayProp') !== undefined) {
            Cookies.remove('connectedPayProp');
        }

        if (Cookies.get('connectedAirbnb') !== undefined) {
            Cookies.remove('connectedAirbnb');
        }

        setTimeout(() => {
            showNotifications({
                type: NotificationTypes.INFO,
                message: 'Cookies have been cleared'
            });

            history.push('/sign-in');
            hideLoadingBar();
            resetIncomeTypes();
            signOut();
        }, 1000);
    };

    const getNavigationOptions = (mobileSize: boolean): NavigationOptionProps[] => [
        {
            link: '/dashboard',
            icon: <DashboardIcon fontSize="small" className={clsx(classes.menuIconColor)} />,
            label: { text: Localisation.localize('MENU.DASHBOARD'), className: clsx(classes.menuItemText) },
            trackingCategory: EventCategory.MENU,
            tourId: 'tour-id-menu-record-keeping'
        },
        {
            link: '/record-keeping/categorize',
            icon: <LibraryBooksIcon fontSize="small" className={clsx(classes.menuIconColor)} />,
            label: { text: Localisation.localize('MENU.RECORD_KEEPING'), className: clsx(classes.menuItemText) },
            trackingCategory: EventCategory.MENU,
            tourId: 'tour-id-menu-record-keeping'
        },
        {
            link: '/jellyfish/2021-22',
            icon: <TaxSubmissionIcon style={{ fontSize: 16 }} className={clsx(classes.menuIconColor)} />,
            label: { text: Localisation.localize('MENU.DATA_OVERVIEW'), className: clsx(classes.menuItemText) },
            trackingCategory: EventCategory.MENU,
            tourId: 'tour-id-menu-tax-submission'
        },
        {
            link: '/settings',
            icon: <ProfileAndSettingsIcon style={{ fontSize: 25 }} className={clsx(classes.menuIconColor)} />,
            label: { text: Localisation.localize('MENU.PROFILE_AND_SETTINGS'), className: clsx(classes.menuItemText) },
            trackingCategory: EventCategory.MENU,
            tourId: 'tour-id-menu-profile'
        },
        {
            link: '/plans',
            icon: <HelpIcon style={{ fontSize: 25 }} className={clsx(classes.menuIconColor)} />,
            label: { text: Localisation.localize('MENU.HELP'), className: clsx(classes.menuItemText) },
            subMenu: true,
            tourId: 'tour-id-menu-help',
            subMenuOptions: [
                {
                    label: Localisation.localize('MENU.USING_THE_MENU'),
                    onClick: () => startTour(mobileSize ? TOUR_TYPES.MENU_MOBILE : TOUR_TYPES.MENU),
                    tourId: mobileSize ? 'tour-id-menu-mobile-tips' : 'tour-id-menu-desktop-tips'
                },
                {
                    label: Localisation.localize('MENU.ONLINE_HELP'),
                    onClick: () => {
                        trackingEvent({
                            category: EventCategory.MENU,
                            name: Localisation.localize('MENU.ONLINE_HELP'),
                            action: EventActions.CLICK
                        });
                        window.open('https://apari.zendesk.com/hc/en-us', '_blank');
                    },
                    tourId: mobileSize ? 'tour-id-menu-mobile-online-help' : 'tour-id-menu-desktop-online-help'
                },
                {
                    label: Localisation.localize('MENU.SUPPORT'),
                    highlighted: window.location.pathname.includes('/plans'),
                    onClick: () => {
                        trackingEvent({
                            category: EventCategory.MENU,
                            name: Localisation.localize('MENU.SUPPORT'),
                            action: EventActions.CLICK
                        });
                        if (!isSupportDisabled) {
                            window.location.href = 'mailto:support@apari-digital.com';
                        } else if (!mobileSize && history.location.pathname === '/dashboard') {
                            const element = document.getElementById('subscription-section');
                            if (element) element.scrollIntoView({ behavior: 'smooth' });
                        } else {
                            history.push('/plans');
                        }
                    },
                    tourId: mobileSize ? 'tour-id-menu-mobile-support' : 'tour-id-menu-desktop-support'
                }
            ],
            trackingCategory: EventCategory.MENU
        }
    ];

    const renderNavigationOptions = () => {
        return getNavigationOptions(UIServices.isMobile(width)).map((option, index) => <NavigationOption key={index} {...option} />);
    };

    const renderDesktopHeader = () => {
        return (
            <div className={clsx(globalClasses.flexColumnCenter, globalClasses.paddingBottom32)}>
                {disableLogoAction ? (
                    <img alt="..." style={{ height: 32 }} className=" rounded-lg" src={logoWhite} />
                ) : (
                    <Link to={authenticated ? '/dashboard' : '/sign-in'}>
                        <img alt="..." style={{ height: 32 }} className=" rounded-lg" src={logoWhite} />
                    </Link>
                )}
            </div>
        );
    };

    return (
        <div className={clsx(globalClasses.flexColumn, globalClasses.fullHeight, classes.navigationRoot, 'tour-id-menu-desktop-menu')}>
            {!UIServices.isMobile(width) && renderDesktopHeader()}
            {renderNavigationOptions()}
            <ListItem
                data-cy="signOutButton"
                button
                onClick={handleSignOut}
                className={clsx(
                    classes.menuItem,
                    globalClasses.marginTopAuto,
                    isMobile && globalClasses.widthFitContent,
                    'tour-id-menu-logout'
                )}
            >
                <ListItemIcon className={clsx(classes.menuIcon)}>
                    <PowerSettingsNewIcon className={clsx(classes.menuIconColor)} />
                </ListItemIcon>
                <ListItemText disableTypography className={clsx(classes.menuItemText)} primary={Localisation.localize('MENU.LOGOUT')} />
            </ListItem>
        </div>
    );
};

export default withWidth()(NavigationContent);
