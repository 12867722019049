const TABS = {
    PROFILE_AND_RESIDENCY: 1,
    INCOME_FROM_EMPLOYMENT: 2,
    INCOME_FROM_PROPERTY: 3,
    INCOME_FROM_SELF_EMPLOYMENT: 4,
    PENSION_INCOME: 5,
    CAPITAL_GAINS: 6,
    OTHER_INCOME: 7,
    CHILD_BENEFIT: 8,
    OTHER_DEDUCTIONS: 9,
    TAX_RELIEF_ON_INVESTMENTS: 10,
    STUDENT_LOAN: 11,
    SA100_SUBMISSION: 12
};

export default TABS;
