const formatOptions = (values: any) => {
    if (!values) return [];

    return values.map((value: any) => ({
        label: value.name,
        value: value.id
    }));
};

export default formatOptions;
