import { COLORS } from 'constants/index';
import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    card: {
        borderRadius: 2,
        background: theme.palette.background.paper,
        padding: '15px 12px',
        width: '100%',
        boxShadow:
            '0px 4px 122px rgba(0, 0, 0, 0.04), 0px 1.28121px 27.2503px rgba(0, 0, 0, 0.0238443), 0px 0.517444px 8.11312px rgba(0, 0, 0, 0.0161557)',
        height: 54,
        '&:hover': {
            borderColor: COLORS.transparentLight
        }
    },
    cardChecked: {
        border: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main
        }
    },
    checkboxIcon: {
        width: 32,
        height: 32,
        borderRadius: 4,
        border: 2,
        fill: 'none',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main
    },
    disabled: {
        borderColor: theme.palette.grey[500]
    },
    text: {
        paddingTop: 3
        //    wordBreak: 'break-all',
    },
    textDisabled: {
        color: theme.palette.grey[500]
    }
}));
