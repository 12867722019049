export const COUNTRIES = [
    { name: 'Andorra', code2: 'AD', code3: 'AND' },
    { name: 'United Arab Emirates', code2: 'AE', code3: 'ARE' },
    { name: 'Afghanistan', code2: 'AF', code3: 'AFG' },
    { name: 'Antigua and Barbuda', code2: 'AG', code3: 'ATG' },
    { name: 'Anguilla', code2: 'AI', code3: 'AIA' },
    { name: 'Albania', code2: 'AL', code3: 'ALB' },
    { name: 'Armenia', code2: 'AM', code3: 'ARM' },
    { name: 'Angola', code2: 'AO', code3: 'AGO' },
    { name: 'Antarctica', code2: 'AQ', code3: 'ATA' },
    { name: 'Argentina', code2: 'AR', code3: 'ARG' },
    { name: 'American Samoa', code2: 'AS', code3: 'ASM' },
    { name: 'Austria', code2: 'AT', code3: 'AUT' },
    { name: 'Australia', code2: 'AU', code3: 'AUS' },
    { name: 'Aruba', code2: 'AW', code3: 'ABW' },
    { name: 'Åland Islands', code2: 'AX', code3: 'ALA' },
    { name: 'Azerbaijan', code2: 'AZ', code3: 'AZE' },
    { name: 'Bosnia and Herzegovina', code2: 'BA', code3: 'BIH' },
    { name: 'Barbados', code2: 'BB', code3: 'BRB' },
    { name: 'Bangladesh', code2: 'BD', code3: 'BGD' },
    { name: 'Belgium', code2: 'BE', code3: 'BEL' },
    { name: 'Burkina Faso', code2: 'BF', code3: 'BFA' },
    { name: 'Bulgaria', code2: 'BG', code3: 'BGR' },
    { name: 'Bahrain', code2: 'BH', code3: 'BHR' },
    { name: 'Burundi', code2: 'BI', code3: 'BDI' },
    { name: 'Benin', code2: 'BJ', code3: 'BEN' },
    { name: 'Saint Barthélemy', code2: 'BL', code3: 'BLM' },
    { name: 'Bermuda', code2: 'BM', code3: 'BMU' },
    { name: 'Brunei Darussalam', code2: 'BN', code3: 'BRN' },
    { name: 'Bolivia, Plurinational State of', code2: 'BO', code3: 'BOL' },
    { name: 'Bonaire, Sint Eustatius and Saba', code2: 'BQ', code3: 'BES' },
    { name: 'Brazil', code2: 'BR', code3: 'BRA' },
    { name: 'Bahamas', code2: 'BS', code3: 'BHS' },
    { name: 'Bhutan', code2: 'BT', code3: 'BTN' },
    { name: 'Bouvet Island', code2: 'BV', code3: 'BVT' },
    { name: 'Botswana', code2: 'BW', code3: 'BWA' },
    { name: 'Belarus', code2: 'BY', code3: 'BLR' },
    { name: 'Belize', code2: 'BZ', code3: 'BLZ' },
    { name: 'Canada', code2: 'CA', code3: 'CAN' },
    { name: 'Cocos (Keeling) Islands', code2: 'CC', code3: 'CCK' },
    { name: 'Congo, the Democratic Republic of the', code2: 'CD', code3: 'COD' },
    { name: 'Central African Republic', code2: 'CF', code3: 'CAF' },
    { name: 'Congo', code2: 'CG', code3: 'COG' },
    { name: 'Switzerland', code2: 'CH', code3: 'CHE' },
    { name: "Côte d'Ivoire", code2: 'CI', code3: 'CIV' },
    { name: 'Cook Islands', code2: 'CK', code3: 'COK' },
    { name: 'Chile', code2: 'CL', code3: 'CHL' },
    { name: 'Cameroon', code2: 'CM', code3: 'CMR' },
    { name: 'China', code2: 'CN', code3: 'CHN' },
    { name: 'Colombia', code2: 'CO', code3: 'COL' },
    { name: 'Costa Rica', code2: 'CR', code3: 'CRI' },
    { name: 'Cuba', code2: 'CU', code3: 'CUB' },
    { name: 'Cape Verde', code2: 'CV', code3: 'CPV' },
    { name: 'Curaçao', code2: 'CW', code3: 'CUW' },
    { name: 'Christmas Island', code2: 'CX', code3: 'CXR' },
    { name: 'Cyprus', code2: 'CY', code3: 'CYP' },
    { name: 'Czech Republic', code2: 'CZ', code3: 'CZE' },
    { name: 'Germany', code2: 'DE', code3: 'DEU' },
    { name: 'Djibouti', code2: 'DJ', code3: 'DJI' },
    { name: 'Denmark', code2: 'DK', code3: 'DNK' },
    { name: 'Dominica', code2: 'DM', code3: 'DMA' },
    { name: 'Dominican Republic', code2: 'DO', code3: 'DOM' },
    { name: 'Algeria', code2: 'DZ', code3: 'DZA' },
    { name: 'Ecuador', code2: 'EC', code3: 'ECU' },
    { name: 'Estonia', code2: 'EE', code3: 'EST' },
    { name: 'Egypt', code2: 'EG', code3: 'EGY' },
    { name: 'Western Sahara', code2: 'EH', code3: 'ESH' },
    { name: 'Eritrea', code2: 'ER', code3: 'ERI' },
    { name: 'Spain', code2: 'ES', code3: 'ESP' },
    { name: 'Ethiopia', code2: 'ET', code3: 'ETH' },
    { name: 'Finland', code2: 'FI', code3: 'FIN' },
    { name: 'Fiji', code2: 'FJ', code3: 'FJI' },
    { name: 'Falkland Islands (Malvinas)', code2: 'FK', code3: 'FLK' },
    { name: 'Micronesia, Federated States of', code2: 'FM', code3: 'FSM' },
    { name: 'Faroe Islands', code2: 'FO', code3: 'FRO' },
    { name: 'France', code2: 'FR', code3: 'FRA' },
    { name: 'Gabon', code2: 'GA', code3: 'GAB' },
    { name: 'United Kingdom', code2: 'GB', code3: 'GBR' },
    { name: 'Grenada', code2: 'GD', code3: 'GRD' },
    { name: 'Georgia', code2: 'GE', code3: 'GEO' },
    { name: 'French Guiana', code2: 'GF', code3: 'GUF' },
    { name: 'Guernsey', code2: 'GG', code3: 'GGY' },
    { name: 'Ghana', code2: 'GH', code3: 'GHA' },
    { name: 'Gibraltar', code2: 'GI', code3: 'GIB' },
    { name: 'Greenland', code2: 'GL', code3: 'GRL' },
    { name: 'Gambia', code2: 'GM', code3: 'GMB' },
    { name: 'Guinea', code2: 'GN', code3: 'GIN' },
    { name: 'Guadeloupe', code2: 'GP', code3: 'GLP' },
    { name: 'Equatorial Guinea', code2: 'GQ', code3: 'GNQ' },
    { name: 'Greece', code2: 'GR', code3: 'GRC' },
    { name: 'South Georgia and the South Sandwich Islands', code2: 'GS', code3: 'SGS' },
    { name: 'Guatemala', code2: 'GT', code3: 'GTM' },
    { name: 'Guam', code2: 'GU', code3: 'GUM' },
    { name: 'Guinea-Bissau', code2: 'GW', code3: 'GNB' },
    { name: 'Guyana', code2: 'GY', code3: 'GUY' },
    { name: 'Hong Kong', code2: 'HK', code3: 'HKG' },
    { name: 'Heard Island and McDonald Islands', code2: 'HM', code3: 'HMD' },
    { name: 'Honduras', code2: 'HN', code3: 'HND' },
    { name: 'Croatia', code2: 'HR', code3: 'HRV' },
    { name: 'Haiti', code2: 'HT', code3: 'HTI' },
    { name: 'Hungary', code2: 'HU', code3: 'HUN' },
    { name: 'Indonesia', code2: 'ID', code3: 'IDN' },
    { name: 'Ireland', code2: 'IE', code3: 'IRL' },
    { name: 'Israel', code2: 'IL', code3: 'ISR' },
    { name: 'Isle of Man', code2: 'IM', code3: 'IMN' },
    { name: 'India', code2: 'IN', code3: 'IND' },
    { name: 'British Indian Ocean Territory', code2: 'IO', code3: 'IOT' },
    { name: 'Iraq', code2: 'IQ', code3: 'IRQ' },
    { name: 'Iran, Islamic Republic of', code2: 'IR', code3: 'IRN' },
    { name: 'Iceland', code2: 'IS', code3: 'ISL' },
    { name: 'Italy', code2: 'IT', code3: 'ITA' },
    { name: 'Jersey', code2: 'JE', code3: 'JEY' },
    { name: 'Jamaica', code2: 'JM', code3: 'JAM' },
    { name: 'Jordan', code2: 'JO', code3: 'JOR' },
    { name: 'Japan', code2: 'JP', code3: 'JPN' },
    { name: 'Kenya', code2: 'KE', code3: 'KEN' },
    { name: 'Kyrgyzstan', code2: 'KG', code3: 'KGZ' },
    { name: 'Cambodia', code2: 'KH', code3: 'KHM' },
    { name: 'Kiribati', code2: 'KI', code3: 'KIR' },
    { name: 'Comoros', code2: 'KM', code3: 'COM' },
    { name: 'Saint Kitts and Nevis', code2: 'KN', code3: 'KNA' },
    { name: "Korea, Democratic People's Republic of", code2: 'KP', code3: 'PRK' },
    { name: 'Korea, Republic of', code2: 'KR', code3: 'KOR' },
    { name: 'Kuwait', code2: 'KW', code3: 'KWT' },
    { name: 'Cayman Islands', code2: 'KY', code3: 'CYM' },
    { name: 'Kazakhstan', code2: 'KZ', code3: 'KAZ' },
    { name: "Lao People's Democratic Republic", code2: 'LA', code3: 'LAO' },
    { name: 'Lebanon', code2: 'LB', code3: 'LBN' },
    { name: 'Saint Lucia', code2: 'LC', code3: 'LCA' },
    { name: 'Liechtenstein', code2: 'LI', code3: 'LIE' },
    { name: 'Sri Lanka', code2: 'LK', code3: 'LKA' },
    { name: 'Liberia', code2: 'LR', code3: 'LBR' },
    { name: 'Lesotho', code2: 'LS', code3: 'LSO' },
    { name: 'Lithuania', code2: 'LT', code3: 'LTU' },
    { name: 'Luxembourg', code2: 'LU', code3: 'LUX' },
    { name: 'Latvia', code2: 'LV', code3: 'LVA' },
    { name: 'Libya', code2: 'LY', code3: 'LBY' },
    { name: 'Morocco', code2: 'MA', code3: 'MAR' },
    { name: 'Monaco', code2: 'MC', code3: 'MCO' },
    { name: 'Moldova, Republic of', code2: 'MD', code3: 'MDA' },
    { name: 'Montenegro', code2: 'ME', code3: 'MNE' },
    { name: 'Saint Martin (French part)', code2: 'MF', code3: 'MAF' },
    { name: 'Madagascar', code2: 'MG', code3: 'MDG' },
    { name: 'Marshall Islands', code2: 'MH', code3: 'MHL' },
    { name: 'North Macedonia, Republic of', code2: 'MK', code3: 'MKD' },
    { name: 'Mali', code2: 'ML', code3: 'MLI' },
    { name: 'Myanmar', code2: 'MM', code3: 'MMR' },
    { name: 'Mongolia', code2: 'MN', code3: 'MNG' },
    { name: 'Macao', code2: 'MO', code3: 'MAC' },
    { name: 'Northern Mariana Islands', code2: 'MP', code3: 'MNP' },
    { name: 'Martinique', code2: 'MQ', code3: 'MTQ' },
    { name: 'Mauritania', code2: 'MR', code3: 'MRT' },
    { name: 'Montserrat', code2: 'MS', code3: 'MSR' },
    { name: 'Malta', code2: 'MT', code3: 'MLT' },
    { name: 'Mauritius', code2: 'MU', code3: 'MUS' },
    { name: 'Maldives', code2: 'MV', code3: 'MDV' },
    { name: 'Malawi', code2: 'MW', code3: 'MWI' },
    { name: 'Mexico', code2: 'MX', code3: 'MEX' },
    { name: 'Malaysia', code2: 'MY', code3: 'MYS' },
    { name: 'Mozambique', code2: 'MZ', code3: 'MOZ' },
    { name: 'Namibia', code2: 'NA', code3: 'NAM' },
    { name: 'New Caledonia', code2: 'NC', code3: 'NCL' },
    { name: 'Niger', code2: 'NE', code3: 'NER' },
    { name: 'Norfolk Island', code2: 'NF', code3: 'NFK' },
    { name: 'Nigeria', code2: 'NG', code3: 'NGA' },
    { name: 'Nicaragua', code2: 'NI', code3: 'NIC' },
    { name: 'Netherlands', code2: 'NL', code3: 'NLD' },
    { name: 'Norway', code2: 'NO', code3: 'NOR' },
    { name: 'Nepal', code2: 'NP', code3: 'NPL' },
    { name: 'Nauru', code2: 'NR', code3: 'NRU' },
    { name: 'Niue', code2: 'NU', code3: 'NIU' },
    { name: 'New Zealand', code2: 'NZ', code3: 'NZL' },
    { name: 'Oman', code2: 'OM', code3: 'OMN' },
    { name: 'Panama', code2: 'PA', code3: 'PAN' },
    { name: 'Peru', code2: 'PE', code3: 'PER' },
    { name: 'French Polynesia', code2: 'PF', code3: 'PYF' },
    { name: 'Papua New Guinea', code2: 'PG', code3: 'PNG' },
    { name: 'Philippines', code2: 'PH', code3: 'PHL' },
    { name: 'Pakistan', code2: 'PK', code3: 'PAK' },
    { name: 'Poland', code2: 'PL', code3: 'POL' },
    { name: 'Saint Pierre and Miquelon', code2: 'PM', code3: 'SPM' },
    { name: 'Pitcairn', code2: 'PN', code3: 'PCN' },
    { name: 'Puerto Rico', code2: 'PR', code3: 'PRI' },
    { name: 'Palestine, State of', code2: 'PS', code3: 'PSE' },
    { name: 'Portugal', code2: 'PT', code3: 'PRT' },
    { name: 'Palau', code2: 'PW', code3: 'PLW' },
    { name: 'Paraguay', code2: 'PY', code3: 'PRY' },
    { name: 'Qatar', code2: 'QA', code3: 'QAT' },
    { name: 'Réunion', code2: 'RE', code3: 'REU' },
    { name: 'Romania', code2: 'RO', code3: 'ROU' },
    { name: 'Serbia', code2: 'RS', code3: 'SRB' },
    { name: 'Russian Federation', code2: 'RU', code3: 'RUS' },
    { name: 'Rwanda', code2: 'RW', code3: 'RWA' },
    { name: 'Saudi Arabia', code2: 'SA', code3: 'SAU' },
    { name: 'Solomon Islands', code2: 'SB', code3: 'SLB' },
    { name: 'Seychelles', code2: 'SC', code3: 'SYC' },
    { name: 'Sudan', code2: 'SD', code3: 'SDN' },
    { name: 'Sweden', code2: 'SE', code3: 'SWE' },
    { name: 'Singapore', code2: 'SG', code3: 'SGP' },
    { name: 'Saint Helena, Ascension and Tristan da Cunha', code2: 'SH', code3: 'SHN' },
    { name: 'Slovenia', code2: 'SI', code3: 'SVN' },
    { name: 'Svalbard and Jan Mayen', code2: 'SJ', code3: 'SJM' },
    { name: 'Slovakia', code2: 'SK', code3: 'SVK' },
    { name: 'Sierra Leone', code2: 'SL', code3: 'SLE' },
    { name: 'San Marino', code2: 'SM', code3: 'SMR' },
    { name: 'Senegal', code2: 'SN', code3: 'SEN' },
    { name: 'Somalia', code2: 'SO', code3: 'SOM' },
    { name: 'Suriname', code2: 'SR', code3: 'SUR' },
    { name: 'South Sudan', code2: 'SS', code3: 'SSD' },
    { name: 'Sao Tome and Principe', code2: 'ST', code3: 'STP' },
    { name: 'El Salvador', code2: 'SV', code3: 'SLV' },
    { name: 'Sint Maarten (Dutch part)', code2: 'SX', code3: 'SXM' },
    { name: 'Syrian Arab Republic', code2: 'SY', code3: 'SYR' },
    { name: 'Eswatini', code2: 'SZ', code3: 'SWZ' },
    { name: 'Turks and Caicos Islands', code2: 'TC', code3: 'TCA' },
    { name: 'Chad', code2: 'TD', code3: 'TCD' },
    { name: 'French Southern Territories', code2: 'TF', code3: 'ATF' },
    { name: 'Togo', code2: 'TG', code3: 'TGO' },
    { name: 'Thailand', code2: 'TH', code3: 'THA' },
    { name: 'Tajikistan', code2: 'TJ', code3: 'TJK' },
    { name: 'Tokelau', code2: 'TK', code3: 'TKL' },
    { name: 'Timor-Leste', code2: 'TL', code3: 'TLS' },
    { name: 'Turkmenistan', code2: 'TM', code3: 'TKM' },
    { name: 'Tunisia', code2: 'TN', code3: 'TUN' },
    { name: 'Tonga', code2: 'TO', code3: 'TON' },
    { name: 'Turkey', code2: 'TR', code3: 'TUR' },
    { name: 'Trinidad and Tobago', code2: 'TT', code3: 'TTO' },
    { name: 'Tuvalu', code2: 'TV', code3: 'TUV' },
    { name: 'Taiwan, Province of China', code2: 'TW', code3: 'TWN' },
    { name: 'Tanzania, United Republic of', code2: 'TZ', code3: 'TZA' },
    { name: 'Ukraine', code2: 'UA', code3: 'UKR' },
    { name: 'Uganda', code2: 'UG', code3: 'UGA' },
    { name: 'United States Minor Outlying Islands', code2: 'UM', code3: 'UMI' },
    { name: 'United States', code2: 'US', code3: 'USA' },
    { name: 'Uruguay', code2: 'UY', code3: 'URY' },
    { name: 'Uzbekistan', code2: 'UZ', code3: 'UZB' },
    { name: 'Holy See (Vatican City State)', code2: 'VA', code3: 'VAT' },
    { name: 'Saint Vincent and the Grenadines', code2: 'VC', code3: 'VCT' },
    { name: 'Venezuela, Bolivarian Republic of', code2: 'VE', code3: 'VEN' },
    { name: 'Virgin Islands, British', code2: 'VG', code3: 'VGB' },
    { name: 'Virgin Islands, U.S.', code2: 'VI', code3: 'VIR' },
    { name: 'Viet Nam', code2: 'VN', code3: 'VNM' },
    { name: 'Vanuatu', code2: 'VU', code3: 'VUT' },
    { name: 'Wallis and Futuna', code2: 'WF', code3: 'WLF' },
    { name: 'Samoa', code2: 'WS', code3: 'WSM' },
    { name: 'Yemen', code2: 'YE', code3: 'YEM' },
    { name: 'Mayotte', code2: 'YT', code3: 'MYT' },
    { name: 'South Africa', code2: 'ZA', code3: 'ZAF' },
    { name: 'Zambia', code2: 'ZM', code3: 'ZMB' },
    { name: 'Zimbabwe', code2: 'ZW', code3: 'ZWE' }
];
