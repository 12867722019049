import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { NUMBERS } from 'constants/index';

type Props = NumberFormatProps & {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (value: number | undefined) => void;
    name: string;
};

const ApariNumberFormat: React.FC<Props> = props => {
    const { inputRef, name, onChange, value, ...other } = props;
    return (
        <NumberFormat
            {...other}
            name={name}
            value={value || 0}
            getInputRef={inputRef}
            thousandSeparator={NUMBERS.default.thousandSeparator}
            decimalSeparator={NUMBERS.default.decimalSeparator}
            onValueChange={values => {
                onChange(values.floatValue);
            }}
            allowNegative={false}
            suffix={' EUR'}
            allowLeadingZeros={false}
            decimalScale={2}
            fixedDecimalScale
            isNumericString
        />
    );
};

export default ApariNumberFormat;
