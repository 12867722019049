export interface OverviewInfo {
    personalDetails: PersonalDetail[];
}

export interface PersonalDetail {
    name: string;
    address: string;
    postcode: string;
    email: string;
    verifiedId: boolean;
    dateOfBirth: string;
    utr: string;
    nino: string;
    taxResidency: string;
    bankAccounts: BankAccount[];
    graph?: Graph;
    mtd?: MTD[];
    apariRecords?: ApariRecords[];
    sa302?: SA302[];
    taxReturnValidation?: TaxReturnValidation[];
}

export interface BusinessDetails {
    taxRegime: string;
    totalIncomeTaxAndNicsDue: string;
    totalTaxDeducted: string;
    totalIncomeTaxNicsCharged: string;
}

export interface BankAccount {
    name: string;
    creditCard: boolean;
    creditLimit: string;
    creditAvailable: string;
    transactions: Transaction[];
}

export interface Graph {
    transactions: Transaction[][];
    income: number[];
    f_expenses: number[];
    v_expenses: number[];
}

export interface Transaction {
    [key: string]: string | number | null;
    date: string;
    description: string;
    recipient: string;
    amount: string;
    type: null | string;
}

export interface TableRouteParams {
    tableView: string;
}

export enum DataCollectorTableView {
    SELF_EMPLOYMENTS = 'self-employments',
    EMPLOYMENTS = 'employments',
    PROPERTIES = 'properties',
    TAXATION_PAYMENTS = 'taxation-payments',
    OTHER_INCOMES = 'other-incomes'
}

export interface BehaviourAnalysis {
    name: string;
    score: string;
}

export interface Contract {
    [key: string]: string | number;
    name: string;
    endDate: string;
}

export interface OtherIncome {
    [key: string]: string | number;
    year: string;
    dividends: string;
    interest: string;
    pension: string;
}

export interface Property {
    [key: string]: string | number;
    name: string;
    address: string;
    postcode: string;
    ownership: string;
    monthlyRentalPaymentsExpected: string;
    monthlyRentalPaymentsReceived: string;
    purchasePrice: string;
    avgMarketValue: string;
    outstandingMortgage: string;
    projectedGrowth: string;
    type: string;
}

export interface SelfEmploymentDataCollector {
    [key: string]: string | number;
    year: string;
    name: string;
    incomeReceived: string;
    profitOrLoss: string;
    businessDescription: string;
    nic2: string;
    nic4: string;
    lossesBroughtForward: string;
    lossesCarriedForward: string;
}

export interface EmploymentDataCollector {
    [key: string]: string | number;
    name: string;
    PAYEReference: string;
    taxPaid: string;
    bonusPayments: string;
    totalEmploymentIncome: string;
}

export interface TaxationPayment {
    [key: string]: string | number | boolean;
    year: string;
    endOfYearEstimate: string;
    totalIncome: string;
    totalTaxPaid: string;
    totalNIC: string;
    taxCode: string;
    certified: boolean;
    payed: boolean;
}

export interface MTD {
    [key: string]: string | number | BusinessDetails;
    name: string;
    businessDetails: BusinessDetails;
}

export interface ApariRecords {
    [key: string]: string | number | boolean | Transaction[];
    name: string;
    transactions: Transaction[];
}

export interface SA302 {
    [key: string]: string | number | boolean;
    name: string;
    dueOn: string;
    receivedOn: string;
    issuedOn: string;
    surcharges: string;
    interest: string;
    tax: string;
    lessPaymentsForThisYear: string;
    lessOtherAdjustments: string;
    penalties: string;
    irMark: string;
    taxResidency: string;
}

export interface TaxReturnValidation {
    [key: string]: string | number | boolean;
    name: string;
    income: number;
    expenses: number;
    gross: number;
    paye: string;
    taxDeducted: number;
    taxableIncome: number;
    incomeTax: number;
    nic2: number;
    nic4: number;
    totalLiability: number;
    taxDue: number;
}
