import React from 'react';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';
import { Localisation } from 'utils';
import { GLOBAL } from 'constants/index';
import logo from 'resources/images/apari-logo.svg';
import styles from './styles';

type Props = {
    hasBorder?: boolean;
    width?: number;
};

const Footer: React.FC<Props> = ({ hasBorder, width = 780 }) => {
    const classes = styles({ hasBorder, width });
    const globalClasses = globalStyles();

    return (
        <div className={clsx(classes.root)}>
            <img alt="..." style={{ height: 50 }} className=" rounded-lg" src={logo} />
            <div className={clsx(classes.footerText)}>
                <div className={clsx(globalClasses.marginRightAuto)}>{Localisation.localize('LAYOUT_FOOTER.COPYRIGHT')}</div>
                <div className={clsx(globalClasses.flexColumn)}>
                    <div className={clsx(classes.moneyhubBankFeed)}>
                        <span className={clsx(globalClasses.marginRight4, globalClasses.font13weight400light, globalClasses.paddingTop2)}>
                            {Localisation.localize('LAYOUT_FOOTER.BANK_FEEDS_MONEYHUB')}
                        </span>
                        <Tooltip
                            placement="top-start"
                            title={Localisation.localize('BankAccountsScreen.REGULATORY_INFORMATION')}
                            arrow
                            classes={{ tooltip: classes.tooltipDesign, arrow: classes.arrowDesign }}
                        >
                            <InfoOutlinedIcon className={classes.infoIcon} fontSize="small" />
                        </Tooltip>
                    </div>
                    <div>
                        <a
                            target={'_blank'}
                            rel={'noreferrer'}
                            href={'https://www.apari-digital.com/contact'}
                            className={globalClasses.marginRight8}
                        >
                            <u>{Localisation.localize('LAYOUT_FOOTER.CONTACT')}</u>
                        </a>
                        <a target={'_blank'} rel={'noreferrer'} href={GLOBAL.TERMS_AND_CONDITIONS} className={globalClasses.marginRight8}>
                            <u>{Localisation.localize('LAYOUT_FOOTER.TERMS_AND_CONDITIONS')}</u>
                        </a>
                        <a target={'_blank'} rel={'noreferrer'} href={GLOBAL.PRIVACY_POLICY}>
                            <u>{Localisation.localize('LAYOUT_FOOTER.PRIVACY_POLICY')}</u>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
