import { makeStyles } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';

export default makeStyles(theme => ({
    textField: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F2F2F2'
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline':
            {
                borderColor: theme.palette.error.main
            },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main
        },
        '& .MuiInput-underline:before': {
            borderColor: '#F2F2F2'
        }
    },
    autofillDark: {
        '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px ' + COLORS.darkPurple + ' inset',
            WebkitTextFillColor: COLORS.paperLight + ' !important'
        }
    },
    autofill: {
        '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px ' + COLORS.paperLight + ' inset',
            WebkitTextFillColor: COLORS.apariBlack80 + ' !important'
        }
    },
    textFieldDarkBackground: {},
    inputLabel: {
        color: COLORS.apariBlack60,
        fontSize: 12
    },
    inputLabelFocused: {
        color: COLORS.apariBlack60 + ' !important'
    },
    inputLabelShrink: {
        transform: 'translate(14px, -6px) scale(0.95) !important'
    },
    showPasswordIcon: {
        padding: 4,
        color: COLORS.gray7,
        mixBlendMode: 'normal'
    },
    alternativeFont: {
        fontFamily: FONTS.QUATERNARY_FONT
    }
}));
