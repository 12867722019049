import React from 'react';
import { ApexOptions } from 'apexcharts';

export type Rate = {
    label: string;
    value: number;
};

export enum Timeframe {
    LAST_7_DAYS,
    LAST_MONTH,
    LAST_6_MONTHS,
    LAST_12_MONTHS,
    LAST_YEAR,
    ANNUAL,
    CURRENT_TAX_YEAR_Q1,
    CURRENT_TAX_YEAR_Q2,
    CURRENT_TAX_YEAR_Q3,
    CURRENT_TAX_YEAR_Q4,
    LAST_TAX_YEAR_Q1,
    LAST_TAX_YEAR_Q2,
    LAST_TAX_YEAR_Q3,
    LAST_TAX_YEAR_Q4,
    LAST_TAX_YEAR,
    TAX_YEAR,
    YEAR
}

export type Period = {
    from: string;
    to: string;
};

export type Income = {
    type: string;
    value?: number;
};

export type TaxYear = {
    title: string;
    rates: Rate[];
    incomes: Income[];
    profit: number;
    status: string;
};

export enum TaxSubmissionStatus {
    RUNNING = 'RUNNING',
    FINISHED = 'FINISHED'
}

export type Business = {
    id: string;
    label: string;
};

export type ChartSeries = {
    name?: string;
    data: number[];
    color?: string;
};

export type ApexChartProps = {
    type?:
        | 'line'
        | 'area'
        | 'bar'
        | 'histogram'
        | 'pie'
        | 'donut'
        | 'rangeBar'
        | 'radialBar'
        | 'scatter'
        | 'bubble'
        | 'heatmap'
        | 'candlestick'
        | 'radar'
        | 'polarArea';
    series?: Array<ChartSeries>;
    width?: string | number;
    height?: string | number;
    options?: ApexOptions;
};

export type FloatingButtonActions = {
    name: string;
    action: () => void;
    icon: React.ReactElement;
};

export enum SubscriptionTypeValues {
    STARTER = 0,
    STANDARD = 1,
    PREMIUM = 2
}
