import { makeStyles, Theme } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles<Theme>(theme => ({
    root: {
        paddingTop: 40,
        width: '100%',
        height: '100%'
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        minHeight: 240,
        padding: '36px 16px 20px 16px',
        borderRadius: 2,
        background: COLORS.apariWhite,
        boxShadow:
            '0px 4px 122px rgba(0, 0, 0, 0.04), 0px 1.28121px 27.2503px rgba(0, 0, 0, 0.0238443), 0px 0.517444px 8.11312px rgba(0, 0, 0, 0.0161557)',
        [theme.breakpoints.up('md')]: {
            padding: '36px 24px 30px 24px'
        }
    },

    cardHeader: {
        [theme.breakpoints.down('sm')]: {
            padding: '0px 20px'
        }
    },
    cardTitle: {},
    cardTitleFont: {
        fontSize: 24,
        fontWeight: 700,
        color: theme.palette.primary.main
    },
    activeIndicator: {
        display: 'flex',
        fontSize: 13,
        fontWeight: 800,
        marginLeft: 'auto',
        [theme.breakpoints.up('md')]: {
            marginLeft: 50
        },
        color: theme.palette.primary.main
    },
    activeIcon: {
        marginLeft: 4,
        fontSize: 15
    },
    featuresPart: {
        marginTop: 24,
        marginBottom: 20,
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
            marginTop: 16
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0px 20px'
        }
    },
    featuresFont: {
        marginBottom: 16,
        [theme.breakpoints.up('md')]: {
            marginBottom: 23
        }
    },
    featuresIcon: {
        fontSize: 16,
        marginRight: 10,
        color: theme.palette.primary.main
    },
    accentColor: {
        color: COLORS.apariLightPurple
    },
    actionButton: {
        fontSize: '14px !important',
        height: 32,
        [theme.breakpoints.up('md')]: {
            maxWidth: 196
        }
    },
    priceParagraph: {
        whiteSpace: 'pre-line'
    }
}));
