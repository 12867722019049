import React from 'react';
import { Portal } from 'react-portal';
import clsx from 'clsx';
import styles from './styles';

const FloatingMenu: React.FC = ({ children }) => {
    const classes = styles();

    return (
        <Portal>
            <div className={clsx(classes.menuContainer)}>
                <div className={clsx(classes.desktopMenu)}>{children}</div>
            </div>
        </Portal>
    );
};

export default FloatingMenu;
