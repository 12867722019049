import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    tabs: {
        zIndex: 1,
        width: '100%',
        textTransform: 'none',
        minHeight: 'auto',
        borderBottom: `1px solid ${COLORS.apariLightSilver}`,
        marginTop: 15,
        overflow: 'hidden',
        position: 'relative',
        boxSizing: 'border-box'
    },
    dropDown: {
        borderBottom: `2px solid ${COLORS.apariPurple}`
    },
    dropDownMenu: {
        border: `1px solid ${COLORS.apariPurple}`,
        borderRadius: 'unset',
        '& ul': {
            padding: 0
        }
    },
    menuItem: {
        textTransform: 'none',
        color: 'black',
        fontSize: '13px',
        fontWeight: 400,
        padding: '5px 20px',
        minHeight: 'auto',
        minWidth: 'auto',
        borderBottom: '1px solid #F9F9F9'
    },
    tabText: {
        textTransform: 'none',
        color: 'black',
        fontSize: '13px',
        fontWeight: 400,
        padding: '0 20px',
        minHeight: 'auto',
        minWidth: 'auto'
    },
    orangeColor: {
        color: COLORS.apariOrange
    },
    alignItemsEnd: {
        alignItems: 'end'
    },
    padding18: {
        padding: '0 18px'
    },
    errorIcon: {
        color: 'red',
        fontSize: '20px',
        marginLeft: '8px'
    }
}));
