import React, { useState, useEffect, useContext, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import { FormikValues, useFormik } from 'formik';
import { ExpandMore } from '@material-ui/icons';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import { BankAccount, TransactionCategory, TransactionCategoryCategoryTypeEnum, TransactionDto } from '@apari/banking-api';
import { DocumentDto } from '@apari/storage-api';
import { BusinessTypeEnum, BusinessSourceBasicInfo, BusinessSourceBasicInfoTypeEnum } from '@apari/core-api/models';
import { DATE_FORMAT } from 'constants/index';
import IncomeTypesContext from 'context/IncomeTypesContext';
import { ApariButton, ApTextField, ApSelectField, ApDatePickerField, ApAmountField, ApariDropzone, ApariTextSwitch } from 'components';
import useEventTracking from 'hooks/useEventTracking';
import moment from 'moment';
import { EventActions, EventCategory } from 'types/eventTracking';
import { formatCategoryType, formatOptions, GlobalServices, Localisation, NumberServices } from 'utils';
import { createRedirectableOption } from 'utils/selectOptionsExtender';
import validationSchema, { importedTransactionValidationSchema } from './validationSchema';
import LoadingData from './LoadingData';
import styles from './styles';
import globalStyles from 'styles/globalStyles';
import Arrow from 'resources/images/Arrow.png';
import { BANK_ACCOUNTS } from '../../../constants/mockData';
import { getCategoriesByType } from '../../../constants/categories';

interface Props {
    onCloseDialog?: () => void;
    onAddTransaction?: (data: TransactionDto, documents?: DocumentDto[]) => void;
    onUpdateTransaction?: (data: TransactionDto, documents?: DocumentDto[]) => void;
    onDeleteTransaction?: (id: string) => void;
    values?: any | null;
    loadingDocuments?: boolean;
    importedPreview?: boolean;
    refetchTransactions?: () => void;
}

interface FormikValuesTypes {
    id: string;
    notes: string;
    transactionDate: Date;
    purpose: string;
    bankAccount: string;
    category: string;
    type: string;
    businessId: string;
    originalAmount?: number;
    counterpartName: string;
    documents?: DocumentDto[];
    isRefund: boolean;
}

const TransactionForm: React.FC<Props> = ({
    values,
    onAddTransaction,
    onUpdateTransaction,
    loadingDocuments,
    onDeleteTransaction,
    importedPreview,
    refetchTransactions
}) => {
    const classes = styles();
    const globalClasses = globalStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [previousCategoryType, setPreviousCategoryType] = useState<TransactionCategoryCategoryTypeEnum>();
    const [selectedType, setSelectedType] = useState(
        GlobalServices.isDefined(values) ? (values.isIncome ? 'INCOME' : 'EXPENSE') : 'INCOME'
    );
    const [categories, setCategories] = useState<TransactionCategory[]>([]);
    const [incomeTypesOptions, setIncomeTypeOptions] = useState<BusinessSourceBasicInfo[]>([]);
    const [accounts, setAccounts] = useState<BankAccount[]>([]);
    const [loading, setLoading] = useState(false);
    const filterTypes = ['INCOME', 'EXPENSE'];
    const isImportedByCsv = GlobalServices.isDefined(values) && values.isImportedByCsv;

    const { getIncomeTypes } = useContext(IncomeTypesContext);
    const { trackingEvent } = useEventTracking();
    const initialRender = useRef(true);

    const formik = useFormik<FormikValuesTypes>({
        initialValues: GlobalServices.isDefined(values)
            ? {
                  id: values.id ? values.id : undefined,
                  notes: values.notes || '',
                  purpose: values.purpose || '',
                  category: values.category ? values.category.id : '',
                  businessId: values.propertyId !== null ? values.businessId + '_' + values.propertyId : values.businessId,
                  transactionDate: values.transactionDate,
                  originalAmount: values.originalAmount,
                  bankAccount: values.bankAccount.id || '',
                  type: values.isIncome ? 'INCOME' : 'EXPENSE',
                  counterpartName: values.counterpartName,
                  documents: values.documents || [],
                  isRefund: values.isRefund || false
              }
            : {
                  id: '',
                  notes: '',
                  transactionDate: new Date(),
                  purpose: '',
                  bankAccount: '',
                  category: '',
                  type: 'INCOME',
                  businessId: '',
                  originalAmount: undefined,
                  counterpartName: '',
                  documents: [],
                  isRefund: false
              },
        validationSchema: values && values.externalId ? importedTransactionValidationSchema : validationSchema,
        onSubmit: async (values: FormikValues) => {
            const obj = incomeTypesOptions.find(inc => inc.id === values.businessId);
            const isIncome = selectedType === 'INCOME';
            const bankAccount = accounts.find(account => account.id === values.bankAccount);
            const category = categories.find(category => category.id === values.category);
            const transactionDate = moment(values.transactionDate).format(DATE_FORMAT.YYYY_MM_DD);

            const finalData = {
                id: values.id ? values.id : undefined,
                isIncome,
                bankAccount,
                category,
                transactionDate,
                counterpartName: values.counterpartName,
                notes: values.notes,
                purpose: values.purpose,
                originalAmount: values.originalAmount,
                businessId: GlobalServices.isDefined(obj) ? obj.businessId : undefined,
                propertyId: GlobalServices.isDefined(obj) ? obj.propertyId : undefined,
                isRefund: values.isRefund
            };

            if (formik.values.id) {
                onUpdateTransaction &&
                    (await onUpdateTransaction(
                        finalData,
                        GlobalServices.getArrayDifference(formik.values.documents!, formik.initialValues.documents!)
                    ));
            } else {
                trackingEvent({ category: EventCategory.RECORD_KEEPING, action: EventActions.DONE, name: 'Created transaction' });
                onAddTransaction && (await onAddTransaction(finalData, formik.values.documents));
            }
        }
    });

    useEffect(() => {
        async function fetchCategories() {
            if (!GlobalServices.isEmpty(formik.values.businessId)) {
                const business = incomeTypesOptions.find(a => a.id === formik.values.businessId);
                await getCategories(business, true);
            }
        }
        fetchCategories();
    }, [formik.values.businessId, selectedType]);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            if (refetchTransactions && GlobalServices.isEmpty(formik.values.documents)) {
                refetchTransactions();
            }
        }
    }, [formik.values.documents]);

    useEffect(() => {
        async function getInitialData() {
            setLoading(true);
            try {
                const options = await getIncomeTypes();

                const business = options.find(a => a.id === formik.values.businessId);
                await getCategories(business);
                setAccounts(BANK_ACCOUNTS);
                setIncomeTypeOptions(options.filter(option => !option.shared));
                setLoading(false);
            } catch (e) {
                console.log(e);
            }
        }

        getInitialData();
    }, []);

    const fetchCategoriesByType = async (categoryType: TransactionCategoryCategoryTypeEnum, resetCategory?: boolean) => {
        try {
            if (previousCategoryType !== categoryType) {
                resetCategory && (await formik.setFieldValue('category', ''));
                const categories: any = getCategoriesByType(categoryType);
                const response = { data: categories };
                setCategories(response.data!);
                setPreviousCategoryType(categoryType);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getBusinessType = (type: BusinessSourceBasicInfoTypeEnum) => {
        switch (type) {
            case BusinessSourceBasicInfoTypeEnum.SELF_EMPLOYED:
                return BusinessTypeEnum.SELF_EMPLOYED;
            case BusinessSourceBasicInfoTypeEnum.LANDLORD:
            case BusinessSourceBasicInfoTypeEnum.PROPERTY:
                return BusinessTypeEnum.LANDLORD;
            case BusinessSourceBasicInfoTypeEnum.FOREIGN_PROPERTY:
                return BusinessTypeEnum.FOREIGN_LANDLORD;
            default:
                return type;
        }
    };

    const getCategories = async (business?: BusinessSourceBasicInfo, resetCategory?: boolean) => {
        if (business) {
            const businessType = getBusinessType(business.type as BusinessSourceBasicInfoTypeEnum);
            const isIncome = selectedType === 'INCOME';
            const typeToSend = formatCategoryType(businessType, business.fhl!, isIncome);
            await fetchCategoriesByType(typeToSend as TransactionCategoryCategoryTypeEnum, resetCategory);
        }
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (type: string) => {
        setAnchorEl(null);
        formik.setFieldValue('type', type);
        formik.setFieldValue('category', '');
        setSelectedType(type);
    };

    const handleDateChange = (date: any) => {
        formik.setFieldValue('transactionDate', date);
    };

    const deleteTransaction = async (id: string) => {
        onDeleteTransaction && (await onDeleteTransaction(id));
    };

    const getSplitRate = (propertyId?: string) => {
        if (incomeTypesOptions.length && propertyId) {
            const property = incomeTypesOptions.find(incomeType => incomeType.propertyId === propertyId.split('_')[1]);
            if (GlobalServices.isDefined(property)) {
                return property.splitRate;
            }
        }
    };

    const calculateSplittedValue = (amount?: number, splitRate?: number) => {
        if (amount && splitRate) {
            return NumberServices.format(amount * splitRate);
        }
    };

    const handleBusinessChange = (e: React.ChangeEvent<any>) => {
        formik.handleChange(e);
    };

    const onRefundChange = () => {
        formik.setFieldValue('isRefund', !formik.values.isRefund);
        if (importedPreview || isImportedByCsv) {
            formik.setFieldValue('category', undefined);
            setSelectedType(selectedType === 'INCOME' ? 'EXPENSE' : 'INCOME');
        }
    };

    return (
        <div className={clsx(classes.formContainer)}>
            {loading && loadingDocuments ? (
                <LoadingData />
            ) : (
                <>
                    <form onSubmit={formik.handleSubmit} className={clsx(classes.form)}>
                        <ApariButton
                            color="primary"
                            variant="outlined"
                            disabled={importedPreview || isImportedByCsv}
                            size="small"
                            aria-controls="typeMenu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            className={clsx(globalClasses.marginBottom10, classes.typeBtn, importedPreview && classes.previewTypeBtn)}
                        >
                            {Localisation.localize(`RECORD_KEEPING_SCREEN.DIALOGS.${selectedType}`)}
                            {!importedPreview && !isImportedByCsv && <ExpandMore color="primary" />}
                        </ApariButton>

                        <Menu
                            id="typeMenu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            className={clsx(globalClasses.marginBottom10)}
                        >
                            {filterTypes.map(type => (
                                <MenuItem data-cy={type} key={type} selected={selectedType === type} onClick={() => handleClose(type)}>
                                    {Localisation.localize(`RECORD_KEEPING_SCREEN.DIALOGS.${type}`)}
                                </MenuItem>
                            ))}
                        </Menu>

                        <ApTextField
                            disabled={importedPreview}
                            className={clsx(importedPreview && classes.previewInput)}
                            control="purpose"
                            data-cy="purpose"
                            label={Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.DESCRIPTION')}
                            formik={formik}
                        />

                        <ApTextField
                            disabled={importedPreview}
                            className={clsx(importedPreview && classes.previewInput)}
                            control="counterpartName"
                            data-cy="counterpartName"
                            label={Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.RECIPIENT')}
                            formik={formik}
                        />

                        <ApSelectField
                            options={[
                                ...formatOptions(incomeTypesOptions),
                                createRedirectableOption('/settings/income-types', 'general.ADD_BUSINESS_SOURCE')
                            ]}
                            data-cy="businessId"
                            control="businessId"
                            label={Localisation.localize('general.INCOME_TYPE')}
                            value={formik.values.businessId}
                            onChange={e => handleBusinessChange(e)}
                            error={formik.touched.businessId && Boolean(formik.errors.businessId)}
                            helperText={formik.touched.businessId && formik.errors.businessId}
                        />
                        <ApSelectField
                            options={formatOptions(categories)}
                            control="category"
                            data-cy="category"
                            label={Localisation.localize('general.CATEGORY')}
                            value={formik.values.category}
                            onChange={formik.handleChange}
                            error={formik.touched.category && Boolean(formik.errors.category)}
                            helperText={formik.touched.category && formik.errors.category}
                            disabled={!categories.length}
                        />

                        <ApSelectField
                            disabled={importedPreview}
                            formControlClassName={clsx(importedPreview && classes.previewSelect)}
                            options={[
                                ...formatOptions(accounts),
                                createRedirectableOption('/settings/bank-accounts', 'general.ADD_TRANSACTION_SOURCE')
                            ]}
                            control="bankAccount"
                            data-cy="bankAccount"
                            label={Localisation.localize('general.ACCOUNT')}
                            value={formik.values.bankAccount}
                            onChange={formik.handleChange}
                            error={formik.touched.bankAccount && Boolean(formik.errors.bankAccount)}
                            helperText={formik.touched.bankAccount && formik.errors.bankAccount}
                        />

                        <ApDatePickerField
                            className={clsx(importedPreview && classes.previewDatePicker)}
                            disabled={importedPreview}
                            label={Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.DATE')}
                            name="transactionDate"
                            data-cy="transactionDate"
                            value={formik.values.transactionDate}
                            onChange={handleDateChange}
                            error={formik.touched.transactionDate && Boolean(formik.errors.transactionDate)}
                            helperText={formik.touched.transactionDate && formik.errors.transactionDate}
                        />
                        <ApTextField
                            className={clsx(importedPreview && classes.previewInput)}
                            control="notes"
                            data-cy="notes"
                            label={Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.NOTES')}
                            multiline
                            rows={4}
                            formik={formik}
                        />

                        <div className={clsx(globalClasses.flexRowSpaceBetween, globalClasses.paddingBottom10, globalClasses.paddingTop10)}>
                            <div className={clsx(globalClasses.flexRowStart)}>
                                <span className={clsx(globalClasses.font12weight600Black60, classes.switchElementLabel)}>
                                    {Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.REFUND')}
                                </span>
                                <Tooltip
                                    placement="top-start"
                                    title={Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.REFUND_TOOLTIP')}
                                    arrow
                                    classes={{ tooltip: classes.tooltipDesign, arrow: classes.arrowDesign }}
                                >
                                    <InfoOutlinedIcon className={classes.infoIcon} />
                                </Tooltip>
                            </div>
                            <ApariTextSwitch onChange={onRefundChange} value={formik.values.isRefund} />
                        </div>

                        {isMobile && <ApariDropzone formik={formik} control="documents" />}

                        <ApAmountField
                            disabled={importedPreview}
                            name="originalAmount"
                            data-cy="originalAmount"
                            formik={formik}
                            variant="standard"
                            bolded
                            className={clsx(
                                globalClasses.marginTop20,
                                globalClasses.marginBottom10,
                                importedPreview && classes.previewInput
                            )}
                        />

                        {getSplitRate(formik.values.businessId) &&
                        calculateSplittedValue(formik.values.originalAmount, getSplitRate(formik.values.businessId)) ? (
                            <p className={clsx(globalClasses.font15weight400Light40)}>
                                {Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.YOUR_SPLIT_RATE')}
                                <span data-cy="splitValue">
                                    {calculateSplittedValue(formik.values.originalAmount, getSplitRate(formik.values.businessId))}
                                </span>
                            </p>
                        ) : null}

                        <ApariButton
                            data-cy="submitButton"
                            color="primary"
                            variant="contained"
                            type="submit"
                            fullWidth
                            disabled={!formik.isValid || formik.isSubmitting}
                            className={clsx(globalClasses.marginTop10)}
                        >
                            {formik.values.id
                                ? Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.UPDATE_TRANSACTION')
                                : Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.ADD_TRANSACTION')}
                        </ApariButton>
                        {formik.values.id && !importedPreview && (
                            <ApariButton
                                data-cy="deleteButton"
                                color="default"
                                variant="text"
                                type="button"
                                fullWidth
                                className={clsx(globalClasses.marginTop10)}
                                onClick={() => deleteTransaction(formik.values.id)}
                            >
                                {Localisation.localize('RECORD_KEEPING_SCREEN.DIALOGS.DELETE')}
                            </ApariButton>
                        )}
                    </form>
                    {isMobile ? (
                        <div className={clsx(classes.hideImage)}>
                            <img alt="login" src={Arrow} height={308} />
                        </div>
                    ) : (
                        <ApariDropzone formik={formik} control="documents" />
                    )}
                </>
            )}
        </div>
    );
};

export default TransactionForm;
