import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    title: {
        fontFamily: 'Monument Extended',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '19px',
        letterSpacing: '0em',
        textAlign: 'center'
    },
    contentWrapper: {
        width: 300,
        padding: '40px 26px',
        display: 'flex',
        flexDirection: 'column'
    },
    regularContentWrapper: {
        height: '80vh',
        padding: '40px 26px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            height: '100%'
        }
    },
    logo: {
        width: '50%',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    buttonsContainer: {
        marginTop: '80px',
        [theme.breakpoints.down('md')]: {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end'
        }
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 20
    },
    dialogPaper: {
        width: 768,
        minHeight: '80vh',
        overflowY: 'unset',
        paddingTop: 0,
        paddingBottom: 0,
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            margin: 0,
            height: '100%',
            maxHeight: '100%',
            paddingTop: 0,
            paddingBottom: 40
        }
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            alignItems: 'start'
        }
    }
}));
