import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    finalisedTag: {
        fontSize: 10,
        fontWeight: 500,
        color: COLORS.apariFinalisedGreen,
        display: 'flex',
        flexDirection: 'row'
    },
    finalisedTagIcon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 6,
        '& svg': {
            fontSize: 15
        }
    },
    finalisedTagText: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '140%'
    }
}));
