import queryString from 'query-string';
import { QUERY_PARAMS } from 'constants/index';
import { LocalStorageServices } from '../utils';

export default function useURLSearch(location: any = {}, queryParams?: Array<QUERY_PARAMS>) {
    const { search } = location;
    const searchValues = queryString.parse(search);

    const queryValues = extractUsefulQueryParams(searchValues);

    function extractUsefulQueryParams(searchValues: any) {
        const tempObj = {} as Record<QUERY_PARAMS, any>;
        const queryParameters = LocalStorageServices.getJSON('QUERY_PARAMETERS');
        if (queryParameters && queryParameters[QUERY_PARAMS.REFERRAL_CODE]) {
            tempObj[QUERY_PARAMS.REFERRAL_CODE] = queryParameters[QUERY_PARAMS.REFERRAL_CODE];
        }

        if (queryParams) {
            queryParams.forEach(item => {
                if (Object.prototype.hasOwnProperty.call(searchValues, item)) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    tempObj[item] = searchValues[item];
                }
            });

            return tempObj;
        } else {
            return searchValues;
        }
    }

    return {
        queryValues
    };
}
