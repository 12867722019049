import { makeStyles, Theme } from '@material-ui/core';
import { COLORS } from 'constants/index';

type Props = {
    authenticated?: boolean;
};

export default makeStyles<Theme, Props>(theme => ({
    root: ({ authenticated }) => {
        return {
            height: 'auto',
            padding: '2.5px 16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            background: COLORS.apariWarning,
            [theme.breakpoints.down('md')]: {
                position: 'sticky',
                top: 72,
                zIndex: 1199
            },
            ...(!authenticated
                ? {
                      position: 'relative'
                  }
                : {
                      [theme.breakpoints.up('md')]: {
                          position: 'sticky',
                          top: 72,
                          zIndex: 1199
                      }
                  })
        };
    },
    content: {
        minHeight: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: 780,
        textAlign: 'center'
    }
}));
