import React from 'react';
import { HelperMenuProps } from './types';
import FloatingMenu from './FloatingMenu';
import BottomDrawer from './BottomDrawer';

const HelperMenu: React.FC<HelperMenuProps> = ({ drawerProps, children }) => {
    return children?.props.children ? (
        <>
            <BottomDrawer {...drawerProps}>{children}</BottomDrawer>
            <FloatingMenu>{children}</FloatingMenu>
        </>
    ) : (
        <div />
    );
};

export default HelperMenu;
