import React from 'react';
import clsx from 'clsx';
import { ButtonProps } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ApariButton } from 'components';
import styles from './styles';

type Props = ButtonProps & {
    text: string;
    hidePlus?: boolean;
};

const TextButtonWithPlus: React.FC<Props> = ({ text, hidePlus, className, ...rest }) => {
    const classes = styles();

    return (
        <ApariButton variant="text" className={clsx(classes.textButtonWithPlus, className)} {...rest}>
            {!hidePlus && <AddIcon className={classes.plusSign} />}
            <span className={classes.textButtonWithPlusText}>{text}</span>
        </ApariButton>
    );
};

export default TextButtonWithPlus;
