import React, { useState, useContext } from 'react';
import { AppBar, IconButton, useTheme, withWidth, WithWidthProps } from '@material-ui/core';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie/lib/Cookies';
import { ApariButton } from 'components';
import { AuthenticationContext } from 'context/AuthenticationContext';
import { AppContext } from 'context/AppContext';
import globalStyles from 'styles/globalStyles';
import logo from 'resources/images/apari-logo.svg';
import { ArrowBackIcon, ExitAndLogoutIcon, NavigationIcon, PaymentIconCircle } from 'resources/icons';
import { GLOBAL } from 'constants/index';
import mtdIcon from 'resources/images/mtd-icon.svg';
import { Localisation, UIServices } from 'utils';
import { RoleTypes } from 'types';
import HeaderMenu from './HeaderMenu';
import ApariAppSwitchMenu from './ApariAppSwitchMenu';
import { ApariAvatar } from 'components';
import styles from './styles';
import { ImpersonationControllerApi } from '@apari/core-api';
import { NotificationTypes } from 'types';

type Props = WithWidthProps & {
    scrollPosition?: number;
    handleBack?: () => void;
    text?: string;
    hideBack?: boolean;
    hideMenu?: boolean;
    transparentHeader?: boolean;
    showLogo?: boolean;
    hideTitle?: boolean;
    hideAppSwitch?: boolean;
    disableLogoAction?: boolean;
    hideUserInfo?: boolean;
    hideBottomLine?: boolean;
    enableInfoBar?: boolean;
};

const impersonationController = new ImpersonationControllerApi();

const Header: React.FC<Props> = ({
    handleBack,
    transparentHeader,
    hideMenu,
    width,
    scrollPosition = 0,
    text = '',
    hideBack = false,
    showLogo = true,
    hideTitle = false,
    hideAppSwitch = false,
    disableLogoAction = false,
    hideUserInfo = false,
    hideBottomLine = false,
    enableInfoBar = false
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { authenticated, user, isImpersonating, paymentStatus } = useContext(AuthenticationContext);
    const { showLoadingBar, hideLoadingBar, showNotifications, showInfoBar } = useContext(AppContext);
    const history = useHistory();
    const cookie = new Cookies();
    const theme = useTheme();
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 30);
    const classes = styles();
    const globalClasses = globalStyles();

    const handleClick = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const renderMobileHeader = () => {
        return (
            <div>
                {disableLogoAction ? (
                    <div style={{ height: 24, marginBottom: 5 }}>
                        {showLogo && <img alt="..." style={{ paddingTop: 5 }} className="rounded-lg" src={logo} />}
                    </div>
                ) : (
                    <Link to={authenticated ? '/dashboard' : '/sign-in'}>
                        <div style={{ height: 24, marginBottom: 5 }}>
                            {showLogo && <img alt="..." style={{ paddingTop: 5 }} className="rounded-lg" src={logo} />}
                        </div>
                    </Link>
                )}
                {!hideTitle && (
                    <div className={clsx(globalClasses.flexRow)}>
                        {!hideBack && handleBack && (
                            <IconButton size="small" onClick={handleBack} className={clsx(globalClasses.marginRight8)}>
                                <ArrowBackIcon />
                            </IconButton>
                        )}
                        <div className={clsx(classes.text)}>{text}</div>
                    </div>
                )}
            </div>
        );
    };

    const renderDesktopHeader = () => {
        return (
            <div className={clsx(globalClasses.flexColumnCenter, globalClasses.horizontalPadding12)}>
                {disableLogoAction ? (
                    <img alt="..." style={{ height: 24 }} className=" rounded-lg" src={logo} />
                ) : (
                    <Link to={authenticated ? '/dashboard' : '/sign-in'} className={globalClasses.flexRowCenter}>
                        <img alt="..." style={{ height: 24 }} className=" rounded-lg" src={logo} />
                    </Link>
                )}
            </div>
        );
    };
    const handleEndImpersonation = async () => {
        try {
            showLoadingBar();
            const response = await impersonationController.endImpersonation(user.id!);
            if (response.data) {
                cookie.set(GLOBAL.JWT_COOKIE_KEY, response.data.token, {
                    path: '/',
                    expires: expireDate,
                    domain: GLOBAL.REACT_APP_COOKIE_DOMAIN
                });
                history.replace('/agent-redirect');
            }
            hideLoadingBar();
        } catch (e) {
            hideLoadingBar();
            console.log('e', e);
            showNotifications({ type: NotificationTypes.ERROR, message: Localisation.localize('serverErrors.SOMETHING_WENT_WRONG') });
        }
    };

    const renderImpersonate = () => {
        return (
            <div
                className={clsx(
                    classes.impersonate,
                    UIServices.isMobile(width) ? globalClasses.font12weight700Light : globalClasses.font14weight700Light
                )}
            >
                <div className={UIServices.isMobile(width) ? clsx(globalClasses.lineHeight140) : undefined}>
                    {Localisation.localize('DASHBOARD_SCREEN.GENERAL.YOU_ARE_IMPERSONATING_PERSON', {
                        fullName: `${user.firstName} ${user.lastName}`
                    })}
                </div>
                <ApariButton
                    variant="text"
                    className={clsx(
                        globalClasses.textUnderline,
                        UIServices.isMobile(width) ? globalClasses.font12weight700Light : globalClasses.font14weight700Light,
                        globalClasses.noWrap
                    )}
                    onClick={handleEndImpersonation}
                >
                    {Localisation.localize('DASHBOARD_SCREEN.GENERAL.EXIT_VIEW')}
                    <ExitAndLogoutIcon className={globalClasses.marginLeft16} />
                </ApariButton>
            </div>
        );
    };

    const userCanViewSwitch = () => {
        switch (user.role?.roleName) {
            case RoleTypes.AGENT:
            case RoleTypes.CLIENT:
                return false;
            case RoleTypes.USER:
                return true;
            default:
                return true;
        }
    };

    return (
        <AppBar
            elevation={0}
            position="fixed"
            className={clsx(
                classes.root,
                transparentHeader && classes.transparentHeader,
                globalClasses.fullHeight,
                globalClasses.flexRow,
                scrollPosition > 0 && classes.rootShadow,
                !hideBottomLine && authenticated && classes.bottomLine,
                hideMenu && classes.overrideRoot,
                enableInfoBar && showInfoBar && !UIServices.isMobile(width) && globalClasses.marginTop32
            )}
        >
            {isImpersonating && renderImpersonate()}
            <div className={clsx(globalClasses.flexRowCenter, globalClasses.fullHeight)}>
                {UIServices.isMobile(width) ? renderMobileHeader() : hideMenu ? renderDesktopHeader() : null}
                {authenticated && (
                    <div
                        className={clsx(
                            !hideBottomLine && classes.rightBorderPartialHeight,
                            UIServices.isMobile(width) ? globalClasses.marginLeft24 : null
                        )}
                    />
                )}
                {authenticated && userCanViewSwitch() && !hideAppSwitch && (
                    <div className={clsx(globalClasses.fullHeight, globalClasses.flexRowCenter)}>
                        {!UIServices.isMobile(width) && (
                            <img
                                alt="..."
                                style={{ height: 24, marginLeft: UIServices.isMobile(width) ? 0 : 34 }}
                                className={clsx(globalClasses.marginRight8)}
                                src={mtdIcon}
                            />
                        )}
                        <p
                            className={clsx(
                                globalClasses.font16weight400Dark,
                                !UIServices.isMobile(width) && globalClasses.paddingTop4,
                                UIServices.isMobile(width) ? globalClasses.marginLeft20 : globalClasses.marginLeft12
                            )}
                        >
                            {UIServices.isMobile(width)
                                ? Localisation.localize('HEADER.MENU.SIMPLE_SELF_ASSESSMENT')
                                : Localisation.localize('HEADER.MENU.SIMPLE_SELF_ASSESSMENT')}
                        </p>
                        <ApariAppSwitchMenu isMobile={UIServices.isMobile(width)} />
                    </div>
                )}
            </div>
            {!hideUserInfo && authenticated && !UIServices.isMobile(width) && (
                <div
                    className={clsx(
                        globalClasses.fullHeight,
                        globalClasses.flexRowCenter,
                        globalClasses.marginRight40,
                        globalClasses.positionRelative
                    )}
                >
                    {paymentStatus?.staticSubscription && (
                        <PaymentIconCircle className={clsx(classes.staticPaymentIcon)} fill={theme.palette.primary.main} />
                    )}
                    <ApariAvatar avatarSize={48} className={clsx(globalClasses.marginRight8)} />
                    <div className={clsx(globalClasses.flexColumnStart)}>
                        <span className={clsx(globalClasses.font14weight600Dark)}>
                            {(user.firstName || '') + ' ' + (user.lastName || '')}
                        </span>
                        <span className={clsx(globalClasses.font10weight400Dark)}>{user.email || '-'}</span>
                    </div>
                </div>
            )}
            {UIServices.isMobile(width) && authenticated && !hideMenu && (
                <div className={clsx(globalClasses.flexRow, globalClasses.fullHeight, 'tour-id-menu-mobile-menu')}>
                    {paymentStatus?.staticSubscription && (
                        <div className={clsx(globalClasses.flexColumnCenter, globalClasses.marginRight8)}>
                            <PaymentIconCircle fill={theme.palette.primary.main} />
                        </div>
                    )}
                    <IconButton size="small" onClick={handleClick}>
                        <NavigationIcon />
                    </IconButton>
                    {<HeaderMenu open={isOpen} handleClose={handleClose} />}
                </div>
            )}
        </AppBar>
    );
};

export default withWidth()(Header);
