import { makeStyles, Theme } from '@material-ui/core';
import { COLORS } from 'constants/index';

type Props = {
    color: string;
    alwaysInRow?: boolean;
};

export default makeStyles<Theme, Props>(theme => ({
    card: ({ alwaysInRow }) => {
        return {
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                height: 30
            },
            width: '100%',
            height: alwaysInRow ? 30 : 48,
            background: COLORS.backgroundDark,
            marginBottom: 12,
            marginRight: 12,
            '&:last-child': {
                marginRight: 0
            },
            padding: '8px 14px 8px 12px'
        };
    },
    square: props => {
        return {
            background: props.color,
            width: 8,
            height: 8
        };
    },
    informationIndicator: {
        [theme.breakpoints.up('md')]: {
            alignSelf: 'flex-start',
            marginTop: 4
        }
    },
    cardText: ({ alwaysInRow }) => {
        return {
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'row'
            },
            flexDirection: alwaysInRow ? 'row' : 'column'
        };
    },
    cardLabel: {
        marginTop: 0,
        marginBottom: 0,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    cardValue: ({ alwaysInRow }) => {
        return {
            [theme.breakpoints.down('md')]: {
                marginLeft: 8
            },
            width: 'auto',
            marginLeft: alwaysInRow ? 8 : 0,
            marginTop: 0,
            marginBottom: 0,
            whiteSpace: 'nowrap'
        };
    }
}));
