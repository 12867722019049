import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { FormikContextType, getIn } from 'formik';
import { isMobile } from 'react-device-detect';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';
import { CircularProgress, IconButton, ListItem } from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { DocumentDto, StorageControllerApi } from '@apari/storage-api';
import { PaymentStatusDtoSubscriptionTypeEnum } from '@apari/core-api';
import { COLORS, REGEX } from 'constants/index';
import { ApariButton, ApariDialog, ApariFilePreview, ApariInfoDialog, ApariUpgradeDialog } from 'components';
import { AppContext } from 'context/AppContext';
import { AuthenticationContext } from 'context/AuthenticationContext';
import FileIcon from 'resources/images/file_icon.svg';
import { UploadCloudIcon } from 'resources/icons';
import globalStyles from 'styles/globalStyles';
import { NotificationTypes } from 'types';
import { PreviewState } from 'types/files';
import { EventCategory } from 'types/eventTracking';
import { FilesService, GlobalServices, Localisation, NetworkService } from 'utils';
import styles from './styles';

type Props = {
    darkBackground?: boolean;
    formik: FormikContextType<any>;
    control: string;
    attachedTo?: string;
    disabled?: boolean;
    usedInSplitTransaction?: boolean;
};

type UploadProgress = DocumentDto & {
    status: PreviewState;
    uploadStatus?: number;
    errors?: FileError[];
};

type FailedUpload = Partial<FileRejection> & {
    previewUrl?: string;
};

const storageControllerApi = new StorageControllerApi();

const ApariDropzone: React.FC<Props> = ({ attachedTo, formik, control, usedInSplitTransaction, disabled }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [currentlyUploading, setCurrentlyUploading] = useState<Record<string, UploadProgress>>({});
    const [failedUploads, setFailedUploads] = useState<Record<string, FailedUpload>>({});
    const [compressionInProgress, setCompressionInProgress] = useState(false);
    const { doesUserHaveLowestRequiredPackage } = useContext(AuthenticationContext);
    const { showLoadingBar, hideLoadingBar, showNotifications } = useContext(AppContext);
    const camera = useRef<HTMLInputElement>(null);
    const history = useHistory();

    const allowedFiles = '.pdf,.jpg,.jpeg,.png';

    const takePhoto = async (event: ChangeEvent<HTMLInputElement>) => {
        if (GlobalServices.isDefined(event)) {
            await onDrop([...event.target.files!]);
        }
    };

    useEffect(() => {
        const existingImagesId = Object.keys(currentlyUploading);
        const updatedCurrentlyUploading = { ...currentlyUploading };

        for (const existingImageId in existingImagesId) {
            if (
                getIn(formik.values, control) &&
                GlobalServices.isEmpty(
                    getIn(formik.values, control).find(
                        (image: Record<string, UploadProgress>) => image.id === existingImagesId[existingImageId]
                    )
                )
            ) {
                delete updatedCurrentlyUploading[existingImagesId[existingImageId]];
            }
        }

        setCurrentlyUploading(updatedCurrentlyUploading);
    }, [getIn(formik.values, control)]);

    useEffect(() => {
        const firstObjects = getIn(formik.values, control).reduce(
            (accumulator: Record<string, UploadProgress>, currentFile: DocumentDto) => {
                accumulator[currentFile.id!] = { ...currentFile, status: PreviewState.SUCCESS };
                return accumulator;
            },
            {}
        );
        setCurrentlyUploading({ ...firstObjects });
    }, []);

    const hasActivePlan = doesUserHaveLowestRequiredPackage(PaymentStatusDtoSubscriptionTypeEnum.STANDARD);
    const classes = styles({ hasActivePlan, usedInSplitTransaction });
    const globalClasses = globalStyles();

    const onDrop = async <T extends File>(acceptedFiles: T[]) => {
        try {
            setCompressionInProgress(true);
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1200,
                useWebWorker: true
            };
            const compressedFiles: File[] = await Promise.all(
                acceptedFiles.map(file => {
                    if (file.type.includes('image') && file.size > 1e6) {
                        return imageCompression(file, options);
                    }
                    return file;
                })
            );
            const preUploadResponse = await storageControllerApi.preUpload(
                'TRANSACTION_RECEIPT',
                compressedFiles.map(file => ({ filename: file.name, size: file.size }))
            );
            const uploadedFiles: DocumentDto[] = [];
            const promises: PromiseLike<any>[] = [];
            setCompressionInProgress(false);
            for (const file of compressedFiles) {
                const preSignedPutObject = preUploadResponse.data.find(preUploadRes => preUploadRes.filename === file.name);

                const dataUrl = await imageCompression.getDataUrlFromFile(file);
                setCurrentlyUploading(prevState => ({
                    ...prevState,
                    [preSignedPutObject!.documentId!]: {
                        ...prevState[preSignedPutObject!.documentId!],
                        previewUrl: dataUrl,
                        size: file.size,
                        downloadUrl: dataUrl,
                        filename: file.name,
                        id: preSignedPutObject!.documentId!,
                        attachmentType: 'TRANSACTION_RECEIPT',
                        attachedTo: attachedTo,
                        uploadStatus: 0,
                        status: PreviewState.UPLOADING
                    }
                }));
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();
                promises.push(
                    axios
                        .put(preSignedPutObject!.preSignedPutURL!, file, {
                            headers: {
                                'Content-Type': file.type
                            },
                            cancelToken: source.token,
                            onUploadProgress: progressEvent => {
                                const { loaded, total } = progressEvent;
                                const uploadStatus = Math.round((loaded * 100) / total);
                                setCurrentlyUploading(prevState => ({
                                    ...prevState,
                                    [preSignedPutObject!.documentId!]: {
                                        ...prevState[preSignedPutObject!.documentId!],
                                        uploadStatus: uploadStatus
                                    }
                                }));
                            }
                        })
                        .then(() => {
                            setCurrentlyUploading(prevState => ({
                                ...prevState,
                                [preSignedPutObject!.documentId!]: {
                                    ...prevState[preSignedPutObject!.documentId!],
                                    status: PreviewState.SUCCESS
                                }
                            }));
                            return {
                                id: preSignedPutObject!.documentId!,
                                filename: file.name,
                                size: file.size,
                                attachmentType: 'TRANSACTION_RECEIPT',
                                attachedTo: attachedTo,
                                downloadUrl: dataUrl,
                                previewUrl: dataUrl
                            };
                        })
                        .catch(error => {
                            if (axios.isCancel(error)) {
                                source.cancel();
                            } else {
                                const parser = new DOMParser();
                                const xmlResponse = parser.parseFromString(error, 'text/xml');
                                const code = xmlResponse.getElementsByTagName('Code')[0]!.innerHTML;
                                setCurrentlyUploading(prevState => ({
                                    ...prevState,
                                    [preSignedPutObject!.documentId!]: {
                                        ...prevState[preSignedPutObject!.documentId!],
                                        status: PreviewState.ERROR,
                                        errors: [{ message: Localisation.localize(`UploadPdfDialog.${code}`), code: 'request-failed' }]
                                    }
                                }));
                            }
                        })
                );
            }
            Promise.allSettled(promises)
                .then(responses => {
                    responses.forEach(response => response.status === 'fulfilled' && uploadedFiles.push(response.value));
                })
                .finally(() => {
                    formik.setFieldValue(control, [...getIn(formik.values, control), ...Object.values(uploadedFiles)]);
                    showNotifications({
                        type: NotificationTypes.SUCCESS,
                        message: Localisation.localize('UploadPdfDialog.uploadSuccessful', { count: uploadedFiles.length })
                    });
                });
        } catch (e) {
            console.log(e);
        }
    };

    const odDropRejected = (fileRejections: FileRejection[]) => {
        const files = fileRejections.reduce((accumulator: Record<string, FailedUpload>, currentFile) => {
            accumulator[currentFile.file.name] = { errors: currentFile.errors };
            return accumulator;
        }, {});
        setFailedUploads(prevState => ({ ...prevState, ...files }));
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noDrag: isMobile,
        multiple: true,
        disabled: !hasActivePlan,
        maxSize: 5e7,
        accept: allowedFiles,
        onDropAccepted: onDrop,
        onDropRejected: odDropRejected
    });

    const removeFile = async (documentId: string) => {
        try {
            showLoadingBar();
            await storageControllerApi.deleteDocument(documentId);
            const newFilesArray = getIn(formik.values, control).filter((file: DocumentDto) => file.id !== documentId);
            const tempCurrentlyUploading = { ...currentlyUploading };
            delete tempCurrentlyUploading[documentId];
            setCurrentlyUploading({
                ...tempCurrentlyUploading
            });
            formik.setFieldValue(control, newFilesArray);
            hideLoadingBar();
        } catch (e) {
            hideLoadingBar();
            console.log(e);
        }
    };

    const renderPreview = () => {
        const previews: JSX.Element[] = [];

        if (!GlobalServices.isEmpty(failedUploads)) {
            previews.push(
                ...Object.entries(failedUploads).map(([key, value], index) => (
                    <ApariFilePreview
                        key={index}
                        filename={key}
                        previewUrl={value.previewUrl}
                        status={PreviewState.ERROR}
                        uploadErrors={value.errors}
                    />
                ))
            );
        }

        if (!GlobalServices.isEmpty(currentlyUploading)) {
            previews.push(
                ...Object.entries(currentlyUploading).map(([key, value]) => (
                    <ApariFilePreview
                        key={key}
                        filename={value.filename!}
                        status={value.status}
                        previewUrl={value.previewUrl!}
                        downloadUrl={value.downloadUrl}
                        uploadErrors={value.errors}
                        uploadStatus={value.uploadStatus}
                        fileSize={value.size}
                        removeFile={() => removeFile(value.id!)}
                    />
                ))
            );
        }
        return (
            <div className={clsx(globalClasses.marginTop10, globalClasses.fullWidth, globalClasses.flexColumnCenter)}>
                {!GlobalServices.isEmpty(previews) && (
                    <div
                        className={clsx(
                            globalClasses.font12weight600Dark,
                            globalClasses.marginTop32,
                            globalClasses.marginBottom10,
                            globalClasses.paddingLeft24,
                            globalClasses.alignSelfFlexStart
                        )}
                    >
                        {Localisation.localize('UploadPdfDialog.uploadedFiles', {
                            numberOfFiles: getIn(formik.values, control).length
                        })}
                    </div>
                )}
                {previews}
                {compressionInProgress && <CircularProgress className={clsx(globalClasses.marginTop32)} />}
            </div>
        );
    };

    const renderDesktop = () => (
        <div className={clsx(globalClasses.flexColumnVerticalCenter)}>
            {(GlobalServices.isEmpty(getIn(formik.values, control)) || hasActivePlan) && (
                <>
                    <div className={clsx(globalClasses.font15weight600Dark, globalClasses.marginBottom32)}>
                        {Localisation.localize('UploadPdfDialog.title')}
                    </div>
                    <div {...getRootProps({ className: classes.dropzone })}>
                        <input {...getInputProps({ accept: allowedFiles })} />
                        <div className={clsx(globalClasses.flexColumnVerticalCenter)}>
                            <UploadCloudIcon
                                style={{
                                    fontSize: 74
                                }}
                                stroke={hasActivePlan ? COLORS.apariPurple2 : COLORS.apariGraphGray}
                            />
                            <div
                                className={clsx(
                                    classes.uploadText,
                                    globalClasses.padding20,
                                    globalClasses.textAlignCenter,
                                    globalClasses.marginTop32
                                )}
                            >
                                {Localisation.localize(`UploadPdfDialog.chooseOrDrag`)}
                            </div>
                        </div>
                    </div>
                    <div
                        className={clsx(
                            globalClasses.marginTop20,
                            hasActivePlan ? globalClasses.font12weight400Dark : globalClasses.font12weight400Grey
                        )}
                    >
                        {Localisation.localize(`general.or`)}
                    </div>
                    <ApariButton
                        disabled={!hasActivePlan}
                        color="primary"
                        type="button"
                        variant="outlined"
                        className={clsx(classes.button)}
                        onClick={open}
                    >
                        {Localisation.localize(`UploadPdfDialog.browseFiles`)}
                    </ApariButton>
                    <div
                        className={clsx(
                            globalClasses.marginTop20,
                            hasActivePlan ? globalClasses.font12weight400Dark : globalClasses.font12weight400Grey
                        )}
                    >
                        {Localisation.localize('UploadPdfDialog.supportedDocuments')}
                    </div>
                </>
            )}
            {!hasActivePlan && !usedInSplitTransaction && (
                <>
                    <div className={clsx(globalClasses.font15weight600Dark, globalClasses.marginTop32)}>
                        {Localisation.localize('UploadPdfDialog.upgradeTitle')}
                    </div>
                    <div className={clsx(globalClasses.font12weight400Dark, globalClasses.padding20, globalClasses.textAlignCenter)}>
                        {Localisation.localize('UploadPdfDialog.upgradePlan')}
                    </div>
                    <ApariButton
                        variant="outlined"
                        color="primary"
                        trackingCategory={EventCategory.PAYMENT}
                        trackingName={Localisation.localize('UploadPdfDialog.upgradeToAddAttachments')}
                        onClick={() => history.push('/plans')}
                    >
                        {Localisation.localize('UploadPdfDialog.upgradeToAddAttachments')}
                    </ApariButton>
                </>
            )}
            {renderPreview()}
        </div>
    );

    const renderMobilePreview = () => {
        const mobilePreviews: JSX.Element[] = [];
        const token = NetworkService.getToken();
        for (let i = 0; i < getIn(formik.values, control).length; ++i) {
            if (i === 4) {
                mobilePreviews.push(
                    <span className={clsx(globalClasses.marginLeftAuto, globalClasses.font15weight600Grey)}>{`(+ ${
                        getIn(formik.values, control).length - i
                    })`}</span>
                );
                break;
            }
            const { previewUrl, filename } = getIn(formik.values, control)[i]!;
            const filenameParts = filename.split('.');
            const type = filenameParts[filenameParts.length - 1].toUpperCase();
            const sourceUrl =
                GlobalServices.isDefined(previewUrl) && REGEX.HTTP_URL.test(previewUrl) ? `${previewUrl}&apari_jwt=${token}` : previewUrl;
            mobilePreviews.push(
                <img className={clsx(classes.mobilePreviewImage)} src={FilesService.checkIfFileIsImage(type) ? sourceUrl : FileIcon} />
            );
        }
        return <div className={clsx(globalClasses.flexRowStart, globalClasses.fullWidth)}>{mobilePreviews}</div>;
    };

    const renderMobile = () => (
        <div className={clsx(classes.mobileAttachments)}>
            <span className={clsx(globalClasses.font10weight400Dark40)}>{Localisation.localize('UploadPdfDialog.attachments')}</span>

            <div>
                <div className={clsx(globalClasses.flexRowStart)}>
                    {GlobalServices.isEmpty(getIn(formik.values, control)) ? (
                        <span className={clsx(globalClasses.font12weight400Dark)}>
                            {Localisation.localize('UploadPdfDialog.addAttachments')}
                        </span>
                    ) : (
                        renderMobilePreview()
                    )}
                    <IconButton className={clsx(globalClasses.marginLeftAuto)} onClick={() => setOpenDialog(true)}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </div>
            </div>

            {hasActivePlan ? (
                <ApariDialog
                    title={Localisation.localize('UploadPdfDialog.attachments')}
                    open={openDialog}
                    fullScreen
                    onCloseDialog={() => {
                        if (Object.values(currentlyUploading).some(value => value.status === PreviewState.UPLOADING)) {
                            setOpenInfoDialog(true);
                        } else {
                            setOpenDialog(false);
                        }
                    }}
                >
                    <div className={clsx(globalClasses.flexColumnVerticalCenter)}>
                        {isMobile && (
                            <ApariButton
                                onClick={() => {
                                    camera!.current!.click();
                                }}
                                className={clsx(globalClasses.flexColumnCenter, classes.photoButton)}
                            >
                                <div className={clsx(globalClasses.flexColumnCenter)}>
                                    <AddAPhotoIcon color="primary" fontSize="large" className={clsx(globalClasses.marginBottom20)} />
                                    <div className={clsx(globalClasses.font15weight600Dark)}>
                                        {Localisation.localize('UploadPdfDialog.takeAPhoto')}
                                    </div>
                                </div>
                                <input ref={camera} onChange={takePhoto} hidden type="file" accept="image/*" capture="environment" />
                            </ApariButton>
                        )}
                        <div {...getRootProps({ className: classes.dropzone })}>
                            <input {...getInputProps({ accept: allowedFiles })} />
                            <ApariButton onClick={open} variant="text" className={clsx(globalClasses.flexRow)}>
                                <DescriptionOutlinedIcon
                                    color="primary"
                                    fontSize="large"
                                    className={clsx(globalClasses.marginRight10, globalClasses.alignSelfFlexStart)}
                                />
                                <div className={clsx(globalClasses.flexColumnStart)}>
                                    <div className={clsx(globalClasses.font15weight600Dark)}>
                                        {Localisation.localize('UploadPdfDialog.selectFromDevice')}
                                    </div>
                                    <div className={clsx(globalClasses.font10weight400Dark)}>
                                        {Localisation.localize('UploadPdfDialog.usedDocuments')}
                                    </div>
                                    <div className={clsx(globalClasses.font10weight400Dark)}>
                                        {Localisation.localize('UploadPdfDialog.maxFileSize')}
                                    </div>
                                </div>
                            </ApariButton>
                        </div>
                        {renderPreview()}
                    </div>
                    <ApariInfoDialog
                        open={openInfoDialog}
                        buttonText={Localisation.localize('general.stay')}
                        cancelText={Localisation.localize('UploadPdfDialog.cancelUploads')}
                        infoText={Localisation.localize('UploadPdfDialog.uploadingFilesWarning')}
                        onCancel={() => {
                            setOpenDialog(false);
                        }}
                        onConfirm={() => setOpenInfoDialog(false)}
                    />
                </ApariDialog>
            ) : (
                <ApariUpgradeDialog
                    open={openDialog}
                    onCloseDialog={() => setOpenDialog(false)}
                    title={Localisation.localize('UploadPdfDialog.upgradeTitle')}
                    paragraphs={[Localisation.localize('UploadPdfDialog.upgradePlan')]}
                    buttonText={Localisation.localize('UploadPdfDialog.upgradeToAddAttachments')}
                />
            )}
        </div>
    );

    const renderSplitTransactionPreview = () => {
        return (
            <div className={clsx(classes.mobileAttachments)}>
                <div className={clsx(globalClasses.flexRowStart)}>
                    <ListItem disabled={disabled} button onClick={() => setOpenDialog(true)}>
                        <div className={clsx(globalClasses.flexColumn, globalClasses.paddingRight10)}>
                            <AttachFileIcon className={clsx(classes.attachmentIcon)} />
                        </div>
                        <div className={clsx(globalClasses.flexColumnStart)}>
                            <span className={clsx(globalClasses.font12weight600Dark)}>
                                {Localisation.localize('UploadPdfDialog.upgradeTitle')}
                            </span>
                            <span className={clsx(globalClasses.font10weight600Light40)}>
                                {Localisation.localize('UploadPdfDialog.supportedDocuments')}
                            </span>
                        </div>
                    </ListItem>
                </div>

                {hasActivePlan ? (
                    openDialog && (
                        <ApariDialog
                            title={Localisation.localize('UploadPdfDialog.attachments')}
                            open={openDialog}
                            fullScreen={isMobile}
                            onCloseDialog={() => {
                                if (Object.values(currentlyUploading).some(value => value.status === PreviewState.UPLOADING)) {
                                    setOpenInfoDialog(true);
                                } else {
                                    setOpenDialog(false);
                                }
                            }}
                        >
                            <div
                                className={clsx(
                                    globalClasses.flexColumnVerticalCenter,
                                    globalClasses.paddingRight32,
                                    globalClasses.paddingLeft32,
                                    globalClasses.paddingBottom32
                                )}
                            >
                                {isMobile ? (
                                    <>
                                        <ApariButton
                                            onClick={() => {
                                                camera!.current!.click();
                                            }}
                                            className={clsx(globalClasses.flexColumnCenter, classes.photoButton)}
                                        >
                                            <div className={clsx(globalClasses.flexColumnCenter)}>
                                                <AddAPhotoIcon
                                                    color="primary"
                                                    fontSize="large"
                                                    className={clsx(globalClasses.marginBottom20)}
                                                />
                                                <div className={clsx(globalClasses.font15weight600Dark)}>
                                                    {Localisation.localize('UploadPdfDialog.takeAPhoto')}
                                                </div>
                                            </div>
                                            <input
                                                ref={camera}
                                                onChange={takePhoto}
                                                hidden
                                                type="file"
                                                accept="image/*"
                                                capture="environment"
                                            />
                                        </ApariButton>

                                        <div {...getRootProps({ className: classes.dropzone })}>
                                            <input {...getInputProps({ accept: allowedFiles })} />
                                            <ApariButton onClick={open} variant="text" className={clsx(globalClasses.flexRow)}>
                                                <DescriptionOutlinedIcon
                                                    color="primary"
                                                    fontSize="large"
                                                    className={clsx(globalClasses.marginRight10, globalClasses.alignSelfFlexStart)}
                                                />
                                                <div className={clsx(globalClasses.flexColumnStart)}>
                                                    <div className={clsx(globalClasses.font15weight600Dark)}>
                                                        {Localisation.localize('UploadPdfDialog.selectFromDevice')}
                                                    </div>
                                                    <div className={clsx(globalClasses.font10weight400Dark)}>
                                                        {Localisation.localize('UploadPdfDialog.usedDocuments')}
                                                    </div>
                                                    <div className={clsx(globalClasses.font10weight400Dark)}>
                                                        {Localisation.localize('UploadPdfDialog.maxFileSize')}
                                                    </div>
                                                </div>
                                            </ApariButton>
                                        </div>
                                        {renderPreview()}
                                    </>
                                ) : (
                                    renderDesktop()
                                )}
                            </div>
                            <ApariInfoDialog
                                open={openInfoDialog}
                                buttonText={Localisation.localize('general.stay')}
                                cancelText={Localisation.localize('UploadPdfDialog.cancelUploads')}
                                infoText={Localisation.localize('UploadPdfDialog.uploadingFilesWarning')}
                                onCancel={() => {
                                    setOpenDialog(false);
                                }}
                                onConfirm={() => setOpenInfoDialog(false)}
                            />
                        </ApariDialog>
                    )
                ) : (
                    <ApariUpgradeDialog
                        open={openDialog}
                        onCloseDialog={() => setOpenDialog(false)}
                        title={Localisation.localize('UploadPdfDialog.upgradeTitle')}
                        paragraphs={[Localisation.localize('UploadPdfDialog.upgradePlan')]}
                        buttonText={Localisation.localize('UploadPdfDialog.upgradeToAddAttachments')}
                    />
                )}
            </div>
        );
    };

    if (usedInSplitTransaction) return renderSplitTransactionPreview();

    return isMobile ? renderMobile() : renderDesktop();
};

export default ApariDropzone;
