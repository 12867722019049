import React from 'react';
import clsx from 'clsx';
import {List, ListProps, withWidth} from '@material-ui/core';
import styles from './styles';

type Props = ListProps & Record<string, unknown>

const ApariList: React.FC<Props> = ({children, ...rest}) => {
    const classes = styles();

    return <List component="ul" className={clsx(classes.list)} {...rest}>
        {children}
    </List>;

};

export default withWidth()(ApariList);
