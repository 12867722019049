import React from 'react';
import clsx from 'clsx';
import { ListItem, ListItemText } from '@material-ui/core';
import globalStyles from 'styles/globalStyles';
import { ApariCheckbox } from 'components';
import styles from './styles';

type Props = {
    name: string;
    onChange: (checked: boolean) => void;
    checked: boolean;
    disabled?: boolean;
    text: string;
};

const ApariCheckBoxCard: React.FC<Props> = ({ name, onChange, checked, text, disabled = false }) => {
    const classes = styles();
    const globalClasses = globalStyles();

    return (
        <ListItem
            button
            disabled={disabled}
            className={clsx(classes.card, checked && classes.checkedCard, globalClasses.flexColumn)}
            onClick={() => onChange(!checked)}
        >
            <div className={clsx(globalClasses.flexRowStart)}>
                <div style={{ display: 'inline-block', zIndex: 0, padding: 0 }}>
                    <ApariCheckbox disabled={disabled} checked={checked || false} name={name} primaryBorderColor={true} />
                </div>
            </div>
            <div>
                <ListItemText className={clsx(globalClasses.flexColumnCenter, globalClasses.inputsFont, classes.text)} primary={text} />
            </div>
        </ListItem>
    );
};

export default ApariCheckBoxCard;
