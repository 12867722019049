import moment from 'moment';
import { GlobalServices, Localisation } from 'utils';

const formatFilterLabels = (state: any, type: string, value?: any): string => {
    let response: any = '';
    switch (type) {
        case 'incomeTypes':
        case 'categories':
        case 'accounts':
        case 'sortTypes':
            response = formatTypes(value, state[type]);
            break;
        case 'showPrivate':
        case 'showShared':
            response = Localisation.localize(`TRANSACTIONS_FILTER.${GlobalServices.camelCaseToSnakeUpperCase(type)}`);
    }

    return response;
};

export const formatTimeRange = (timeRangeFrom: string, timeRangeTo: string): string => {
    if (timeRangeFrom && timeRangeTo) {
        return moment(timeRangeFrom).format('DD.MM.YYYY') + ' - ' + moment(timeRangeTo).format('DD.MM.YYYY');
    } else if (timeRangeFrom) {
        return 'From ' + moment(timeRangeFrom).format('DD.MM.YYYY') + ' onwards';
    } else if (timeRangeTo) {
        return 'Until ' + moment(timeRangeTo).format('DD.MM.YYYY');
    } else {
        return '';
    }
};

const formatTypes = (value: string, types: []) => {
    return types.map((type: any) => {
        if (type.id === value) {
            return type.name;
        } else if (type.value === value) {
            return type.label;
        }
    });
};

export default formatFilterLabels;
