import React, { useContext } from 'react';
import clsx from 'clsx';
import { TILE_NAMES } from 'constants/index';
import TilesContext from 'context/TilesContext';
import styles from './styles';
import Tile from './Tile';
import { TaxYearDtoStatusEnum } from '@apari/uk-jf-api';

type FormVisibilityTilesProps = {
    minWidth?: number;
    disabled?: boolean;
    taxYearStatus?: TaxYearDtoStatusEnum;
};

const FormVisibilityTiles: React.FC<FormVisibilityTilesProps> = ({ minWidth, disabled, taxYearStatus }) => {
    const classes = styles();

    const { tiles } = useContext(TilesContext);

    const taxYearAlreadySubmitted = [TaxYearDtoStatusEnum.SUBMITTED].includes(taxYearStatus!);

    return (
        <div className={clsx(classes.tilesWrapper)}>
            <div className={clsx(classes.tilesGridColumns)} {...(minWidth && { style: { minWidth } })}>
                {tiles.map(tile => {
                    return (
                        <Tile
                            className={clsx(tile.active.toString(), taxYearAlreadySubmitted ? classes.alreadySubmitted : '')}
                            key={tile.name}
                            text={tile.name}
                            tile={tile}
                            tooltip={tile.tooltip}
                            disabled={
                                tile.name === TILE_NAMES.PROFILE_AND_RESIDENCY ||
                                tile.name === TILE_NAMES.SA100_SUBMISSION ||
                                taxYearAlreadySubmitted ||
                                disabled
                            }
                            taxYearAlreadySubmitted={taxYearAlreadySubmitted}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default FormVisibilityTiles;
