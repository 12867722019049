import React from 'react';
import ContentLoader from 'react-content-loader';
import { COLORS } from 'constants/index';

const LoadingData: React.FC = () => {
    return (
        <div>
            <ContentLoader speed={2} width="100%" height="600" backgroundColor={COLORS.backgroundLight}>
                <rect x="0" y="0" rx="0" ry="0" width="365" height="600" />
            </ContentLoader>
        </div>
    );
};

export default LoadingData;
