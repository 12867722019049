import React, { useContext, useEffect, useState } from 'react';
import { TransactionControllerApi, TransactionDto } from '@apari/banking-api';
import { DocumentDto, StorageControllerApi } from '@apari/storage-api';
import { ApariDialog, LoadingPlaceholder, TransactionForm } from 'components';
import { AppContext } from 'context/AppContext';
import { NotificationTypes } from 'types';
import { GlobalServices, Localisation } from 'utils';

const transactionApi = new TransactionControllerApi();
const storageControllerApi = new StorageControllerApi();

interface Props {
    open: boolean;
    transaction: TransactionDto | null;
    onClose?: () => void;
    onRefresh?: () => void;
}

const TransactionDetailsDialog: React.FC<Props> = ({ open, transaction, onClose, onRefresh, ...rest }) => {
    const { showNotifications, showLoadingBar, hideLoadingBar } = useContext(AppContext);
    const [documents, setDocuments] = useState<DocumentDto[]>([]);
    const [loadingDocuments, setLoadingDocuments] = useState(false);

    const importedPreview = transaction ? !GlobalServices.isEmpty(transaction.externalId) : false;

    useEffect(() => {
        async function fetchDocs() {
            if (GlobalServices.isDefined(transaction)) {
                setLoadingDocuments(true);
                const storageResponse = await storageControllerApi.getDocuments(transaction!.id!, 'TRANSACTION_RECEIPT');
                setDocuments(storageResponse.data);
                setLoadingDocuments(false);
            }
        }
        fetchDocs();
    }, []);

    const deleteTransaction = async (id: string) => {
        try {
            showLoadingBar();
            await transactionApi.deleteTransaction(id);
            await storageControllerApi.deleteDocuments('TRANSACTION_RECEIPT', id);
            onRefresh && onRefresh();
            showNotifications({
                type: NotificationTypes.SUCCESS,
                message: Localisation.localize('RECORD_KEEPING_SCREEN.INTRODUCTION.TRANSACTION_DELETED')
            });
            onClose && onClose();
            hideLoadingBar();
        } catch (error) {
            hideLoadingBar();
            console.log(error);
        }
    };

    const updateTransaction = async (data: TransactionDto, documents?: DocumentDto[]) => {
        try {
            showLoadingBar();
            await transactionApi.updateTransaction(data);
            if (GlobalServices.isDefined(documents)) {
                await storageControllerApi.finalizeUpload(
                    data.id!,
                    documents.map(document => document.id!)
                );
            }
            onRefresh && onRefresh();
            showNotifications({
                type: NotificationTypes.SUCCESS,
                message: Localisation.localize('RECORD_KEEPING_SCREEN.INTRODUCTION.TRANSACTION_UPDATED')
            });
            onClose && onClose();
            hideLoadingBar();
        } catch (error) {
            hideLoadingBar();
            console.log(error);
        }
    };

    return (
        <ApariDialog
            open={open}
            fullWidth
            maxWidth="md"
            title={Localisation.localize('general.edit')}
            onCloseDialog={onClose}
            onClose={() => onClose && onClose()}
            {...rest}
        >
            {loadingDocuments ? (
                <LoadingPlaceholder height={600} width={365} />
            ) : (
                <TransactionForm
                    importedPreview={importedPreview}
                    loadingDocuments={loadingDocuments}
                    values={GlobalServices.isDefined(transaction) ? { ...transaction, documents } : undefined}
                    onDeleteTransaction={deleteTransaction}
                    onUpdateTransaction={updateTransaction}
                    refetchTransactions={onRefresh}
                    onCloseDialog={onClose}
                />
            )}
        </ApariDialog>
    );
};

export default TransactionDetailsDialog;
