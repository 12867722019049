import React from 'react';
import clsx from 'clsx';
import styles from './styles';

interface Props {
    isUnsubmitted?: boolean;
}

const TransactionCard: React.FC<Props> = ({ isUnsubmitted }) => {
    const classes = styles();
    return <div className={clsx(classes.cardBar, isUnsubmitted && classes.cardBarUnsubmitted)} />;
};

export default TransactionCard;
