import { makeStyles } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';

export default makeStyles(() => ({
    errorTableWrapper: {
        border: '1px solid ' + COLORS.apariBlack20,
        borderRadius: 2,
        padding: 16,
        minHeight: 316,
        height: '100%'
    },
    errorTableTitle: {
        marginTop: 0,
        marginBottom: 0,
        fontSize: 21,
        fontFamily: FONTS.SECONDARY_FONT,
        color: COLORS.apariBlack80,
        fontWeight: 400
    },
    imageWrapper: {
        height: 80,
        position: 'relative',
        boxSizing: 'content-box',
        width: 60
    },
    errorImage: {
        position: 'absolute',
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 0s, opacity 0.5s linear'
    },
    showImage: {
        opacity: 1,
        visibility: 'visible'
    },
    buttonContainer: {
        width: '100%',
        maxWidth: 'max-content'
    }
}));
