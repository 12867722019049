import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    root: {},
    contentWrapperOverride: {
        paddingTop: 88,
        [theme.breakpoints.down('md')]: {
            alignSelf: 'center',
            paddingTop: 10,
            paddingBottom: 40
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 50,
            paddingRight: 50
        },
        [theme.breakpoints.up(2100)]: {
            marginTop: '45%',
            marginBottom: 'auto',
            paddingTop: 42
        }
    },
    textCenter: {
        textAlign: 'center'
    },
    singUpButton: {
        maxWidth: '100%'
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        alignSelf: 'flex-start',
        [theme.breakpoints.up('lg')]: {
            maxWidth: 288
        }
    },
    submitButton: {
        marginTop: 'auto',
        [theme.breakpoints.up('lg')]: {
            marginTop: 40
        }
    },
    marginBottomOverride: {
        marginBottom: 144,
        [theme.breakpoints.down('md')]: {
            marginBottom: 0
        }
    }
}));
