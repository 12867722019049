import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import { AuthenticationApi, PasswordReset } from '@apari/core-api';
import globalStyles from 'styles/globalStyles';
import { SubHeaderClean, AnimatedTransition, ApariButton, ApPasswordField, PasswordStrengthMeter } from 'components';
import { AppContext } from 'context/AppContext';
import NewLayout from 'containers/layouts/NewLayout';
import { GlobalServices, Localisation } from 'utils';
import { TokenErrorTypes } from 'types';
import styles from './styles';
import NewPasswordDialog from './NewPasswordDialog';
import validationSchema from './validationSchema';

const authenticationApi = new AuthenticationApi();

const NewPasswordScreen: React.FC<RouteComponentProps> = ({ location }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [newPasswordDialogOpen, setNewPasswordDialogOpen] = useState<boolean>(false);
    const [dialogMessage, setDialogMessage] = useState<string>('');
    const [resetSuccess, setResetSuccess] = useState<boolean>(false);

    const params = new URLSearchParams(location.search);
    const token = GlobalServices.getQueryParameterIfItExists(params, 'token');
    const resetInfo = GlobalServices.getQueryParameterIfItExists(params, 'info');
    const invalidToken = params.get('invalidToken');

    const { showLoadingBar, hideLoadingBar } = useContext(AppContext);

    const submitForm = async (data: any) => {
        setLoading(true);
        await changePassword(resetInfo!, token!, { password: data.plainPassword });
        setLoading(false);
    };

    const formik = useFormik({
        initialValues: {
            plainPassword: '',
            confirmPassword: ''
        },
        onSubmit: submitForm,
        validationSchema: validationSchema
    });

    const globalClasses = globalStyles();
    const classes = styles();
    const history = useHistory();

    useEffect(() => {
        setDialogMessageHelper(Number(invalidToken));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invalidToken]);

    const setDialogMessageHelper = (errorType: TokenErrorTypes) => {
        switch (errorType) {
            case TokenErrorTypes.EXPIRED:
                setDialogMessage(Localisation.localize('NEW_PASSWORD_SCREEN.TOKEN_EXPIRE'));
                break;
            case TokenErrorTypes.INVALID:
                setDialogMessage(Localisation.localize('NEW_PASSWORD_SCREEN.TOKEN_INVALID'));
                break;
            default:
                break;
        }
        setResetSuccess(false);
    };

    const onDialogClose = () => {
        history.push('/sign-in');
    };

    const changePassword = async (info: string, token: string, newPassword: PasswordReset) => {
        showLoadingBar();
        try {
            await authenticationApi.resetPassword(info, token, newPassword);
            setDialogMessage(Localisation.localize('NEW_PASSWORD_SCREEN.SUCCESS_RESET'));
            setResetSuccess(true);
            setNewPasswordDialogOpen(true);
        } catch (e) {
            setDialogMessageHelper(e.tokenErrorType);
            setNewPasswordDialogOpen(true);
        }
        hideLoadingBar();
    };

    return (
        <NewLayout
            transparentHeader
            hideHeader={false}
            hideFooter
            hideTitle
            headerText={Localisation.localize('NEW_PASSWORD_SCREEN.TITLE')}
            enableBackgroundArrows
        >
            <AnimatedTransition addHeight className={globalClasses.fontRoboto}>
                <div className={clsx(globalClasses.contentWrapper, classes.contentWrapperOverride)}>
                    <div className={clsx(globalClasses.fullHeight, globalClasses.fullWidth)}>
                        <div className={clsx(globalClasses.fullHeight)}>
                            <div className={clsx(globalClasses.fullWidth, globalClasses.flexColumn, globalClasses.fullHeight)}>
                                <div>
                                    <SubHeaderClean
                                        title={Localisation.localize('NEW_PASSWORD_SCREEN.MESSAGE')}
                                        messages={[Localisation.localize('NEW_PASSWORD_SCREEN.DESCRIPTION')]}
                                        alternativeFont
                                        noBackButton
                                        noSidePadding
                                    />
                                </div>
                                <form onSubmit={formik.handleSubmit} className={clsx(classes.formContainer)}>
                                    <div>
                                        <ApPasswordField
                                            className={clsx(classes.passwordInput, classes.iconSize)}
                                            autoComplete="new-password"
                                            control="plainPassword"
                                            label={Localisation.localize('general.password')}
                                            formik={formik}
                                        />
                                        <ApPasswordField
                                            className={clsx(classes.passwordInput, classes.iconSize)}
                                            autoComplete="new-password"
                                            control="confirmPassword"
                                            label={Localisation.localize('general.confirmPassword')}
                                            formik={formik}
                                            margin="normal"
                                        />
                                        {formik.values.plainPassword.length >= 8 &&
                                            isEqual(formik.values.plainPassword, formik.values.confirmPassword) && (
                                                <PasswordStrengthMeter plainPassword={formik.values.plainPassword} />
                                            )}
                                    </div>
                                    <div className={clsx(globalClasses.CTAWrapperTabletMode, globalClasses.noHorizontalPadding)}>
                                        <ApariButton
                                            className={clsx(globalClasses.marginTop16)}
                                            fullWidth
                                            type="submit"
                                            disabled={loading}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                        >
                                            {Localisation.localize('general.confirm')}
                                        </ApariButton>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </AnimatedTransition>
            <NewPasswordDialog open={newPasswordDialogOpen} handleClose={onDialogClose} message={dialogMessage} success={resetSuccess} />
        </NewLayout>
    );
};

export default NewPasswordScreen;
