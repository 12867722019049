import React from 'react';
import clsx from 'clsx';
import {Fab, FabProps} from '@material-ui/core';

type Props = FabProps

const ApariFabButton: React.FC<Props> = props => {
    const {children, className, ...rest} = props;
    return <Fab className={clsx(className)} {...rest} >{children}</Fab>;
};

export default ApariFabButton;
