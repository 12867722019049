import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import CheckIcon from '@material-ui/icons/Check';
import {
    DiscountDto,
    PaymentStatusDto,
    PaymentStatusDtoStatusEnum,
    SubscriptionPackage,
    SubscriptionPackagePlanEnum,
    SubscriptionPackageTypeEnum
} from '@apari/core-api';
import { ApariButton } from 'components/index';
import SupportPackagesErrorImage from 'resources/images/Arrow.png';
import globalStyles from 'styles/globalStyles';
import { SubscriptionTypeValues } from 'types';
import { EventCategory } from 'types/eventTracking';
import { DateTimeServices, Localisation, NumberServices } from 'utils';
import styles from './styles';

type SubscriptionCardProps = {
    subscriptionPackage: SubscriptionPackage;
    onSubscribe: (subscriptionPackage: SubscriptionPackage, payImmediately?: boolean) => void;
    paymentStatus?: PaymentStatusDto;
    recommended?: boolean;
};

type LoadingSubscriptionCardProps = {
    tall?: boolean;
};

type PackageFeaturesProps = {
    recommended?: boolean;
    isPayNowButtonVisible?: boolean;
};

type FeatureItemProps = {
    recommended?: boolean;
};

const FeatureItem: React.FC<FeatureItemProps> = ({ children, recommended }) => {
    const globalClasses = globalStyles();
    const classes = styles({ recommended });

    return (
        <div className={clsx(globalClasses.flexRowStart, globalClasses.marginBottom8)}>
            <CheckIcon className={clsx(classes.featuresIcon)} />
            <p className={clsx(globalClasses.font11weight400Dark, globalClasses.noVerticalMargin)}>{children}</p>
        </div>
    );
};

const StarterPackageFeatures: React.FC<PackageFeaturesProps> = ({ recommended, isPayNowButtonVisible }) => {
    const classes = styles({ recommended });
    return (
        <div>
            <FeatureItem recommended={recommended}>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STARTER_FEATURES.DIGITAL_RECORD_KEEPING')}
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STARTER_FEATURES.SUPPORT_THROUGH_EXTENSIVE_FAQS')}
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STARTER_FEATURES.MTD_SOLUTION')}
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STANDARD_FEATURES.SELF_ASSESSMENT')}
                {isPayNowButtonVisible && (
                    <span className={classes.exclTrial}>
                        {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.SELF_ASSESSMENT_EXCL_TRIAL')}
                    </span>
                )}
            </FeatureItem>
        </div>
    );
};

const StandardPackageFeatures: React.FC<PackageFeaturesProps> = ({ recommended, isPayNowButtonVisible }) => {
    const classes = styles({ recommended });
    return (
        <div>
            <FeatureItem recommended={recommended}>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STANDARD_FEATURES.DIGITAL_RECORD_KEEPING')}
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                <strong>{Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STANDARD_FEATURES.EMAIL_SUPPORT')}</strong>
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STANDARD_FEATURES.SUPPORT_THROUGH_FAQS')}
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STANDARD_FEATURES.MTD_SOLUTION')}
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STANDARD_FEATURES.SELF_ASSESSMENT')}
                {isPayNowButtonVisible && (
                    <span className={classes.exclTrial}>
                        {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.SELF_ASSESSMENT_EXCL_TRIAL')}
                    </span>
                )}
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                <strong>
                    {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STANDARD_FEATURES.JOINTLY_OWNED_PROPERTIES')}
                </strong>
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                <strong>{Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STANDARD_FEATURES.SNAP_STORE_RECEIPTS')}</strong>
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                <strong>
                    {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PREMIUM_FEATURES.CONNECT_BANK_ACCOUNT_SECURELY')}
                </strong>
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                <strong>
                    {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.STANDARD_FEATURES.NEW_FEATURES_AS_RELEASED')}
                </strong>
            </FeatureItem>
        </div>
    );
};

const PremiumPackageFeatures: React.FC<PackageFeaturesProps> = ({ recommended, isPayNowButtonVisible }) => {
    const classes = styles({ recommended });

    return (
        <div>
            <FeatureItem recommended={recommended}>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PREMIUM_FEATURES.DIGITAL_RECORD_KEEPING')}
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                <strong>
                    <Trans i18nKey="DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PREMIUM_FEATURES.EMAIL_SUPPORT">
                        S<span className={clsx(classes.accentColor)}>Phone </span>and Email support from our accounting team{' '}
                    </Trans>
                </strong>
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PREMIUM_FEATURES.SUPPORT_THROUGH_FAQS')}
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PREMIUM_FEATURES.MTD_SOLUTION')}
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PREMIUM_FEATURES.SELF_ASSESSMENT')}
                {isPayNowButtonVisible && (
                    <span className={classes.exclTrial}>
                        {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.SELF_ASSESSMENT_EXCL_TRIAL')}
                    </span>
                )}
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                <strong>{Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PREMIUM_FEATURES.JOINTLY_OWNED_PROPERTIES')}</strong>
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                <strong>{Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PREMIUM_FEATURES.SNAP_STORE_RECEIPTS')}</strong>
            </FeatureItem>
            <FeatureItem recommended={recommended}>
                <strong>
                    {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PREMIUM_FEATURES.CONNECT_BANK_ACCOUNT_SECURELY')}
                </strong>
            </FeatureItem>

            <FeatureItem recommended={recommended}>
                <strong>{Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PREMIUM_FEATURES.NEW_FEATURES_AS_RELEASED')}</strong>
            </FeatureItem>
        </div>
    );
};

export const LoadingSubscriptionCard: React.FC<LoadingSubscriptionCardProps> = ({ tall }) => {
    const classes = styles({});

    return (
        <div className={clsx(classes.root)}>
            <ContentLoader speed={2} width="100%" height={tall ? '490' : '320'}>
                <rect x="0" y="0" rx="3" ry="3" width="100%" height={tall ? '490' : '320'} />
            </ContentLoader>
        </div>
    );
};

export const SubscriptionsNotLoadedCard: React.FC = () => {
    const globalClasses = globalStyles();
    const classes = styles({});

    return (
        <div className={clsx(globalClasses.flexColumnCenter, classes.root)}>
            <img src={SupportPackagesErrorImage} alt="subscriptions-package-error" width={42} />
            <p className={clsx(globalClasses.font16weight400Dark)}>
                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.ERROR_LOADING_SUBSCRIPTIONS')}
            </p>
        </div>
    );
};

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ recommended, subscriptionPackage, paymentStatus, onSubscribe }) => {
    const indicateRecommended = recommended;
    const isBooked =
        paymentStatus &&
        paymentStatus.status !== PaymentStatusDtoStatusEnum.INACTIVE &&
        subscriptionPackage.plan === paymentStatus.subscriptionType;

    const globalClasses = globalStyles();
    const classes = styles({ recommended: indicateRecommended });

    const renderDiscount = useCallback(
        (discount: DiscountDto) => () => {
            if (discount && (discount.discountAmountOff || discount.discountPercentOff)) {
                const date = new Date(discount.durationOfDiscount!);
                const formattedPrice = discount.discountAmountOff ? NumberServices.format(discount.discountAmountOff) : undefined;
                return (
                    <span className={clsx(globalClasses.font11weight400Dark)}>
                        {discount.durationOfDiscount
                            ? Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.DISCOUNT_WITH_DATE', {
                                  percentOrAmount: formattedPrice || discount.discountPercentOff,
                                  percentSign: discount.discountPercentOff ? '%' : '',
                                  poundSign: formattedPrice ? '£' : '',
                                  date: date.getDate() + ' ' + DateTimeServices.getMonthName(date) + ' ' + date.getFullYear()
                              })
                            : Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.DISCOUNT_WITHOUT_DATE', {
                                  percentOrAmount: formattedPrice || discount.discountPercentOff,
                                  percentSign: discount.discountPercentOff ? '%' : '',
                                  poundSign: formattedPrice ? '£' : ''
                              })}
                    </span>
                );
            } else {
                return '';
            }
        },
        []
    );

    const renderPrice = () => {
        const interval =
            subscriptionPackage.type === SubscriptionPackageTypeEnum.MONTHLY
                ? Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PER_MONTH')
                : Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PER_YEAR');

        if (isBooked && paymentStatus) {
            const date = new Date(paymentStatus.trialEndDate!);

            return (
                <p
                    className={clsx(
                        globalClasses.font13weight400Dark,
                        globalClasses.marginTop10,
                        globalClasses.marginBottom8,
                        classes.priceParagraph
                    )}
                >
                    {paymentStatus.status === PaymentStatusDtoStatusEnum.TRIALING ? (
                        <Trans
                            i18nKey="DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.TRIAL_ENDS"
                            values={{
                                date: date.getDate() + ' ' + DateTimeServices.getMonthName(date) + ' ' + date.getFullYear(),
                                price: NumberServices.format(subscriptionPackage.amount)
                            }}
                        >
                            Trial ends date then
                            <span className={clsx(Boolean(paymentStatus.priceWithDiscount) && globalClasses.textLineThrough)}>£price</span>
                        </Trans>
                    ) : (
                        <span className={clsx(Boolean(paymentStatus.priceWithDiscount) && globalClasses.textLineThrough)}>
                            {'£' + NumberServices.format(subscriptionPackage.amount)}
                        </span>
                    )}
                    {Boolean(paymentStatus.priceWithDiscount) && (
                        <strong>{' £' + NumberServices.format(paymentStatus.priceWithDiscount!)}</strong>
                    )}
                    {' ' + interval + '\n'}
                    {renderDiscount({
                        durationOfDiscount: paymentStatus.durationOfDiscount,
                        discountPercentOff: paymentStatus.discountPercentOff,
                        discountAmountOff: paymentStatus.discountAmountOff
                    })()}
                </p>
            );
        } else {
            return (
                <p
                    className={clsx(
                        globalClasses.font13weight400Dark,
                        globalClasses.marginTop10,
                        globalClasses.marginBottom8,
                        classes.priceParagraph
                    )}
                >
                    {subscriptionPackage.discount ? (
                        <>
                            <span className={clsx(globalClasses.textLineThrough)}>{`£${NumberServices.format(
                                subscriptionPackage.amount!
                            )} `}</span>
                            <strong> {'£' + NumberServices.format(subscriptionPackage.discount.priceWithDiscount!)}</strong>
                        </>
                    ) : (
                        '£' + NumberServices.format(subscriptionPackage.amount)
                    )}
                    {' ' + interval + '\n'}
                    {subscriptionPackage.discount &&
                        renderDiscount({
                            durationOfDiscount: subscriptionPackage.discount?.durationOfDiscount,
                            discountPercentOff: subscriptionPackage.discount?.discountPercentOff,
                            discountAmountOff: subscriptionPackage.discount?.discountAmountOff
                        })()}
                </p>
            );
        }
    };

    const getFeaturesTitle = () => {
        return Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.FEATURES_SUPPORTED');
    };

    const getFeaturesList = () => {
        switch (subscriptionPackage.plan) {
            case SubscriptionPackagePlanEnum.STARTER:
                return <StarterPackageFeatures recommended={indicateRecommended} isPayNowButtonVisible={isPayNowButtonVisible()} />;
            case SubscriptionPackagePlanEnum.STANDARD:
                return <StandardPackageFeatures recommended={indicateRecommended} isPayNowButtonVisible={isPayNowButtonVisible()} />;
            case SubscriptionPackagePlanEnum.PREMIUM:
                return <PremiumPackageFeatures recommended={indicateRecommended} isPayNowButtonVisible={isPayNowButtonVisible()} />;
        }
    };

    const getActionText = () => {
        if (isBooked) {
            return Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.MANAGE_PLANS');
        }
        if (paymentStatus?.status === PaymentStatusDtoStatusEnum.INACTIVE) {
            return (
                Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.SUBSCRIBE_TO') + ' ' + subscriptionPackage.title.split(' ')[0]
            );
        } else {
            if (paymentStatus) {
                if (SubscriptionTypeValues[paymentStatus.subscriptionType!] > SubscriptionTypeValues[subscriptionPackage.plan!]) {
                    return (
                        Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.SWITCH_TO') +
                        ' ' +
                        subscriptionPackage.title.split(' ')[0]
                    );
                } else {
                    return (
                        Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.UPGRADE_TO') +
                        ' ' +
                        subscriptionPackage.title.split(' ')[0]
                    );
                }
            }
        }
    };

    const getStatus = () => {
        if (isBooked && paymentStatus) {
            switch (paymentStatus.status) {
                case PaymentStatusDtoStatusEnum.TRIALING:
                    return (
                        <div className={clsx(classes.activeIndicator)}>
                            <span>{Localisation.localize('general.TRIAL')}</span>
                            <CheckIcon className={clsx(classes.activeIcon)} />
                        </div>
                    );
                case PaymentStatusDtoStatusEnum.ACTIVE:
                    return (
                        <div className={clsx(classes.activeIndicator)}>
                            <span>{Localisation.localize('general.ACTIVE')}</span>
                            <CheckIcon className={clsx(classes.activeIcon)} />
                        </div>
                    );
                case PaymentStatusDtoStatusEnum.INACTIVE:
                    return null;
                default:
                    return null;
            }
        }
    };

    const handleAction = (payImmediately?: boolean) => {
        onSubscribe(subscriptionPackage, payImmediately);
    };

    const isPayNowButtonVisibleForTrialEndLessThenMonth = () => {
        const today = new Date();
        const trialEndDate = new Date(paymentStatus!.trialEndDate!);
        trialEndDate.setMonth(trialEndDate.getMonth() - 1);
        return trialEndDate < today;
    };

    const isPayNowButtonVisible = () => {
        return (
            (isBooked && paymentStatus?.payNowButtonEnabled) ||
            (paymentStatus?.status === PaymentStatusDtoStatusEnum.TRIALING && isPayNowButtonVisibleForTrialEndLessThenMonth())
        );
    };

    return (
        <div className={clsx(classes.root)}>
            {indicateRecommended && (
                <div className={clsx(globalClasses.flexColumnCenter, classes.recommendedIndicator)}>
                    <p>{Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.RECOMMENDED_FOR_YOU')}</p>
                </div>
            )}
            <div className={clsx(classes.card)}>
                <div className={clsx(classes.cardHeader)}>
                    <div className={clsx(globalClasses.flexRowSpaceBetween, classes.cardTitle)}>
                        <span className={clsx(classes.cardTitleFont)}>{subscriptionPackage.title.split(' ')[0]}</span>
                        {getStatus()}
                    </div>
                    <div>
                        {renderPrice()}
                        <p className={clsx(globalClasses.font11weight400Dark, globalClasses.noVerticalMargin)}>
                            <Trans i18nKey="DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.ALL_FEES_ARE_DEDUCTIBLE">
                                All fees are <strong>tax deductible</strong> and include VAT
                            </Trans>
                        </p>
                    </div>
                </div>
                <div className={clsx(classes.featuresPart)}>
                    <p className={clsx(globalClasses.font13weight600Dark)}>{getFeaturesTitle()}</p>
                    {getFeaturesList()}
                </div>
                <div className={clsx(globalClasses.marginTopAuto, globalClasses.paddingTop20)}>
                    <ApariButton
                        fullWidth
                        onClick={() => handleAction()}
                        className={clsx(classes.actionButton)}
                        size="small"
                        color="primary"
                        trackingCategory={EventCategory.PAYMENT}
                        trackingName={getActionText()}
                        variant="contained"
                    >
                        {getActionText()}
                    </ApariButton>
                    {isPayNowButtonVisible() && (
                        <>
                            <p
                                className={clsx(
                                    globalClasses.font10weight400Dark40,
                                    globalClasses.marginTop16,
                                    globalClasses.marginBottom0
                                )}
                            >
                                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.TRIAL_EXCLUDE_ANNUAL_SUBMISSION')}
                            </p>
                            <ApariButton
                                fullWidth
                                onClick={() => handleAction(true)}
                                className={clsx(classes.payNowEndTrialButton, globalClasses.marginTop16)}
                                size="small"
                                color="primary"
                                trackingCategory={EventCategory.PAYMENT}
                                trackingName={getActionText()}
                                variant={'outlined'}
                            >
                                {Localisation.localize('DASHBOARD_SCREEN.SUBSCRIPTIONS_SECTION.PAY_NOW_END_TRIAL')}
                            </ApariButton>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SubscriptionCard;
