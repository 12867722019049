export type SelectOption = { value: string | number; label: string };

export type MonthOptions = {
    name: string;
    monthNumber: number;
    action: (month: number, checked: boolean) => void;
};
// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace EmptyTypes {
    export type String = '';
    export type Object = Record<string, never>;
    export type Array = never[];
}

export type Empty = EmptyTypes.Array | EmptyTypes.Object | EmptyTypes.String;

export type DetermineEmptyValueType<T> = T extends string
    ? EmptyTypes.String
    : T extends unknown[]
    ? EmptyTypes.Array
    : T extends Record<string, unknown>
    ? EmptyTypes.Object
    : never;

export enum NotificationTypes {
    ERROR,
    WARNING,
    INFO,
    SUCCESS
}

export interface UploadedFile {
    file: File;
    documentId: string;
}

export enum FieldType {
    BOOLEAN = 'boolean',
    SELECT = 'select',
    STRING = 'string'
}

export enum ApiVersion {
    V1 = 'v1',
    V2 = 'v2'
}

export enum ModalTypes {
    INFO = 'INFO',
    REGULAR = 'REGULAR'
}

export enum RoleTypes {
    USER = 'USER',
    CLIENT = 'CLIENT',
    AGENT = 'AGENT'
}

export enum PasswordStrengthTypes {
    STRONG = 'strong',
    MEDIUM = 'medium',
    WEAK = 'weak'
}
