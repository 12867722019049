import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { NotificationsContext } from 'context/NotificationsContext';
import { AuthenticationContext } from 'context/AuthenticationContext';
import { ApariButton, ApariDialog, ApCheckbox } from 'components';
import globalStyles from 'styles/globalStyles';
import { Localisation } from 'utils';
import styles from './styles';

const NotificationBarAndPopUp: React.FC = () => {
    const globalClasses = globalStyles();
    const classes = styles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { trackEvent } = useMatomo();

    const {
        showPopUp,
        setShowPopUp,
        topBarNotification,
        popUpNotification,
        dontShowNotificationTopBarAgain,
        dontShowNotificationPopUpAgain,
        handleTopBarAction
    } = useContext(NotificationsContext);

    const { signInCalled } = useContext(AuthenticationContext);

    const formik = useFormik({
        initialValues: {
            dontShowAgain: false
        },
        onSubmit: async values => {
            if (values.dontShowAgain) {
                await dontShowNotificationPopUpAgain(popUpNotification?.id as string);
                setShowPopUp(false);
                trackEvent({
                    category: 'Notification',
                    name: popUpNotification?.title,
                    action: 'hide'
                });
            } else {
                setShowPopUp(false);
                trackEvent({
                    category: 'Notification',
                    name: popUpNotification?.title,
                    action: 'dismiss'
                });
            }
        }
    });

    return (
        <>
            {topBarNotification && (
                <div className={clsx(classes.root)}>
                    <div className={clsx(classes.content)}>
                        <div className={clsx(globalClasses.flexRowStart)}>
                            <div className={clsx(classes.iconContainer)}>
                                <NotificationImportantIcon color="primary" />
                            </div>

                            <div className={clsx(classes.informationContainer)}>
                                <span className={clsx(classes.title)} dangerouslySetInnerHTML={{ __html: topBarNotification.title! }} />
                                <span>
                                    <span
                                        className={clsx(classes.message)}
                                        dangerouslySetInnerHTML={{ __html: topBarNotification.shortDescription! }}
                                    />
                                    {topBarNotification.actionButtonTitle ? (
                                        <span className={clsx(classes.link)} onClick={handleTopBarAction}>
                                            {topBarNotification.actionButtonTitle}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </span>
                            </div>
                        </div>
                        {topBarNotification.possibleToHide && (
                            <div className={clsx(classes.closeIconContainer)}>
                                <IconButton className={clsx(classes.closeIconButton)} onClick={dontShowNotificationTopBarAgain}>
                                    <CloseIcon className={clsx(classes.closeIcon)} />
                                </IconButton>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {popUpNotification && signInCalled && (
                <ApariDialog
                    maxWidth="md"
                    open={showPopUp}
                    fullWidth
                    title={isMobile ? undefined : ''}
                    onClose={() => setShowPopUp(false)}
                    onCloseDialog={() => setShowPopUp(false)}
                    className={clsx(classes.dialog)}
                >
                    <form onSubmit={formik.handleSubmit} className={clsx(classes.dialogContent)}>
                        {popUpNotification.newFeature && (
                            <p className={clsx(classes.newFeatureText)}>{Localisation.localize('NOTIFICATION_POP_UP.NEW_FEATURE')}</p>
                        )}
                        <p className={clsx(classes.dialogTitle)} dangerouslySetInnerHTML={{ __html: popUpNotification.title! }} />
                        <p
                            className={clsx(classes.dialogDescription)}
                            dangerouslySetInnerHTML={{ __html: popUpNotification.description! }}
                        />
                        {popUpNotification.videoURL && (
                            <div className={clsx(globalClasses.flexColumnCenter)}>
                                <iframe
                                    src={popUpNotification.videoURL}
                                    className={clsx(classes.video)}
                                    width={isMobile ? '100%' : '500'}
                                    height={isMobile ? '140' : '280'}
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                        )}
                        <p
                            className={clsx(classes.dialogDescription)}
                            dangerouslySetInnerHTML={{ __html: popUpNotification.extraDescription! }}
                        />
                        {popUpNotification.possibleToHide && (
                            <ApCheckbox
                                control="dontShowAgain"
                                formik={formik}
                                className={clsx(globalClasses.flexColumnCenter)}
                                text={Localisation.localize('NOTIFICATION_POP_UP.DONT_SHOW_AGAIN')}
                            />
                        )}
                        <div className={clsx(classes.buttonsContainer)}>
                            {popUpNotification.actionButtonTitle && (
                                <ApariButton
                                    fullWidth
                                    className={clsx(classes.button)}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={async () => {
                                        await dontShowNotificationPopUpAgain(popUpNotification?.id as string);
                                        window.open(
                                            popUpNotification.actionButtonURL,
                                            popUpNotification.actionURLOpenInNewTab ? '_blank' : '_self'
                                        );
                                    }}
                                >
                                    {popUpNotification.actionButtonTitle}
                                </ApariButton>
                            )}
                            <ApariButton
                                className={clsx(classes.button)}
                                fullWidth
                                variant="outlined"
                                color="primary"
                                size="small"
                                type="submit"
                            >
                                {Localisation.localize('NOTIFICATION_POP_UP.DISMISS')}
                            </ApariButton>
                        </div>
                    </form>
                </ApariDialog>
            )}
        </>
    );
};

export default NotificationBarAndPopUp;
