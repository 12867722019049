import { makeStyles } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';

export default makeStyles(theme => ({
    root: {
        padding: '18px 30px 50px 30px'
    },
    splitTransactionSection: {
        marginBottom: 26,
        padding: 20,
        borderRadius: 2,
        boxShadow: '0px 4px 122px rgb(0 0 0 / 4%), 0px 1.28121px 27.2503px rgb(0 0 0 / 2%), 0px 0.517444px 8.11312px rgb(0 0 0 / 2%)'
    },
    typeBtn: {
        borderRadius: 5,
        fontSize: '11px !important',
        minHeight: 17,
        paddingTop: 0,
        paddingBottom: 0,
        marginLeft: 16
    },
    expandIcon: {
        fontSize: 19
    },
    deletedSplitTransactionSection: {
        opacity: 0.3
    },
    footerActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row'
        }
    },
    footerActionsSubFolder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 'auto',
            alignItems: 'center',
            flexDirection: 'row'
        }
    },
    footerActionButton: {
        marginBottom: 18,
        marginRight: 0,
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
            marginRight: 18
        }
    },
    textButtonWithPlus: {
        padding: 0,
        fontFamily: FONTS.PRIMARY_FONT,
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        marginTop: 16,
        color: COLORS.apariBlack80
    },
    textButtonWithPlusText: {
        paddingTop: 2,
        fontSize: 12,
        fontWeight: 600,
        textDecoration: 'underline'
    },
    fieldsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        }
    },
    fieldWithMargin: {
        [theme.breakpoints.up('md')]: {
            marginRight: 24
        }
    },
    imagesGrid: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: 24,
        gridRowGap: 16,
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'auto'
        }
    },
    flex1: {
        flex: 1
    },
    amountDirectionTextContainer: {
        margin: '8px 0px',
        height: 9,
        marginBottom: 16
    },
    positiveAmountDirectionText: {
        color: COLORS.apariFinalisedGreen
    },
    warningAmountDirectionText: {
        color: COLORS.apariWarning
    },
    amountDirectionText: {
        display: 'block',
        fontSize: 9,
        fontWeight: 400,
        textAlign: 'right'
    },
    infoIcon: {
        marginLeft: 10,
        color: COLORS.apariBlack40,
        fontSize: 20
    },
    tooltipDesign: {
        maxWidth: 235,
        height: 60,
        padding: '8px 16px 14px 16px',
        backgroundColor: '#B7B7B7',
        color: COLORS.apariWhite,
        fontSize: 10,
        fontWeight: 600
    },
    arrowDesign: {
        color: '#B7B7B7'
    },
    positioningRefund: {
        marginTop: 16,
        marginBottom: 16,
        [theme.breakpoints.up('md')]: {
            width: '50%',
            paddingRight: 16
        }
    },
    topMargin2: {
        marginTop: 2
    }
}));
