import { ForeignNonFHLPreviewDtoV2, ForeignValues } from '@apari/uk-mtd-api';
import { filter, get } from 'lodash';
import { Localisation } from 'utils';
import { GroupedCategories, GroupedCategoriesAnnualSummary } from 'types';

type GroupedCategoriesByType = Record<string, GroupedCategories[] | GroupedCategoriesAnnualSummary[]>;
type CountriesList = {
    countriesList: string[];
    countryCodes: string[];
};

const collectTabItems = (
    categories: GroupedCategoriesByType | Record<string, any>,
    singleCategoryDepthPath: string,
    translationBase: string
) =>
    Object.keys(categories).map((category, index) => ({
        value: index,
        label: get(categories[category], singleCategoryDepthPath) || Localisation.localize(`${translationBase}.${category}`)
    }));

const collectExternalCountries = (foreignLandlordPreview: ForeignNonFHLPreviewDtoV2[] | ForeignValues[]): CountriesList => {
    const externalCountries = filter(foreignLandlordPreview, { external: true });
    return {
        countriesList: externalCountries.map(item => (item as ForeignNonFHLPreviewDtoV2).countryName) as string[],
        countryCodes: externalCountries.map(item => (item as ForeignValues).userValue?.countryCode) as string[]
    };
};

export default { collectTabItems, collectExternalCountries };
