import React from 'react';
import clsx from 'clsx';
import styles from './styles';

type Props = {
    className?: string
}

const ApariCheckboxCardGrid: React.FC<Props> = ({className, children}) => {
    const classes = styles();
    return <div className={clsx(classes.checkBoxOptionsGrid, className)}>
        {children}
    </div>;
};

export default ApariCheckboxCardGrid;

