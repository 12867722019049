import React, { useEffect, useState } from 'react';
import { ApariButton } from 'components';
import Arrow from 'resources/images/Arrow.png';
import clsx from 'clsx';
import { Localisation } from 'utils';
import globalStyles from 'styles/globalStyles';
import styles from './styles';
import { Dialog, LinearProgress, useMediaQuery, useTheme } from '@material-ui/core';

type Props = {
    title?: string;
    subtitle?: string;
    error: boolean;
    progressBarValue?: number;
    showLoadingBar: boolean;
    positiveButtonText?: string;
    negativeButtonText?: string;
    open: boolean;
    image?: string;
    onPositiveClick?: () => void;
    onNegativeClick?: () => void;
    tips?: string;
    successSubtitle?: string;
    errorSubtitle?: string;
    hideSecondButton?: boolean;
    customSuccessContent?: React.ReactNode;
    loadingText?: string;
    hideTips?: boolean;
} & typeof defaultProps;

const defaultProps = {
    showLoadingBar: true,
    open: true,
    error: false,
    onPositiveClick: () => console.log('Positive button click'),
    onNegativeClick: () => console.log('Negative button click')
};

const WaitingScreen: React.FC<Props> = (props: Props) => {
    const classes = styles();
    const globalClasses = globalStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        error,
        progressBarValue,
        showLoadingBar,
        positiveButtonText,
        negativeButtonText,
        onPositiveClick,
        onNegativeClick,
        open,
        image,
        tips,
        successSubtitle,
        errorSubtitle,
        hideSecondButton,
        customSuccessContent,
        loadingText,
        hideTips
    } = props;
    let title = props.title;

    if (!title) {
        title = error ? Localisation.localize('waitingScreen.errorTitle') : Localisation.localize('waitingScreen.submissionSuccessful');
    }

    const [progress, setProgress] = useState(0);
    const [tipIndex, setTipIndex] = useState(0);

    const tipsForShow = hideTips ? '' : tips ? tips : Localisation.localize('waitingScreen.tips', { returnObjects: true });

    useEffect(() => {
        if (!progressBarValue) {
            const timer = setInterval(() => {
                setProgress(oldProgress => {
                    if (oldProgress === 100) {
                        clearInterval(timer);
                    }
                    const diff = Math.random() * Math.min(Math.max(100 - oldProgress, 1), 10);
                    return Math.min(oldProgress + diff, 100);
                });
            }, 500);

            return () => {
                clearInterval(timer);
            };
        }
    }, []);

    useEffect(() => {
        if (showLoadingBar && open) {
            const tipTimer = setInterval(() => {
                setTipIndex(oldIndex => {
                    return oldIndex === tipsForShow.length - 1 ? 0 : oldIndex + 1;
                });
            }, 2000);

            return () => {
                clearInterval(tipTimer);
            };
        }
    }, []);

    return (
        <Dialog open={open} fullScreen={fullScreen} className={clsx(classes.dialogContainer)} classes={{ paper: classes.dialogContainer }}>
            <div
                className={clsx([
                    globalClasses.fullHeight,
                    globalClasses.fullWidth,
                    globalClasses.flexColumn,
                    globalClasses.horizontalPadding16,
                    classes.container
                ])}
            >
                <div className={clsx([classes.imageContainer, error && classes.imageRotate])}>
                    <img alt="apariRoundedImage" src={image || Arrow} height={181} />
                </div>
                {!showLoadingBar ? (
                    <>
                        <div className={clsx([classes.titleContainer, globalClasses.marginTop40])}>
                            <div className={clsx([classes.title, globalClasses.font18weight400])}>{title}</div>
                        </div>
                        <div className={clsx([classes.subtitleContainer, globalClasses.marginTop20])}>
                            {error ? (
                                <div
                                    className={clsx([classes.subtitle, globalClasses.font13weight400, showLoadingBar && classes.padding15])}
                                >
                                    {errorSubtitle}
                                </div>
                            ) : customSuccessContent ? (
                                customSuccessContent
                            ) : (
                                <div
                                    className={clsx([classes.subtitle, globalClasses.font13weight400, showLoadingBar && classes.padding15])}
                                >
                                    {successSubtitle}
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <LinearProgress
                            className={clsx(classes.progressBar)}
                            {...(showLoadingBar && progress < 100 ? { value: progressBarValue || progress, variant: 'determinate' } : {})}
                            classes={{ colorPrimary: classes.progressBarSecondaryColor }}
                        />
                        <p
                            className={clsx(
                                globalClasses.font13weight400light40,
                                globalClasses.preWrap,
                                globalClasses.marginTop20,
                                loadingText && globalClasses.textAlignCenter
                            )}
                        >
                            {loadingText ? loadingText : Localisation.localize('waitingScreen.loadingText')}
                        </p>
                    </>
                )}

                <div
                    className={clsx([
                        globalClasses.marginTopAuto,
                        globalClasses.fullWidth,
                        classes.bottomContainer,
                        globalClasses.horizontalPadding16
                    ])}
                >
                    {showLoadingBar ? (
                        <div className={clsx(classes.tips)}>{tipsForShow[tipIndex]}</div>
                    ) : (
                        <div className={clsx([classes.buttonContainer, globalClasses.marginBottom20])}>
                            <ApariButton fullWidth variant="contained" color="primary" onClick={() => onPositiveClick()}>
                                {positiveButtonText
                                    ? positiveButtonText
                                    : error
                                    ? Localisation.localize('waitingScreen.backSubmission')
                                    : Localisation.localize('waitingScreen.viewCalculation')}
                            </ApariButton>
                            {!error && !hideSecondButton && (
                                <ApariButton fullWidth variant="text" color="secondary" onClick={() => onNegativeClick()}>
                                    <div className={clsx(globalClasses.font12weight600Dark)}>
                                        {negativeButtonText || Localisation.localize('waitingScreen.backOverview')}
                                    </div>
                                </ApariButton>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Dialog>
    );
};

WaitingScreen.defaultProps = defaultProps;

export default WaitingScreen;
