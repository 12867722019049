import React from 'react';
import { List, ListItem, Typography, withWidth, WithWidthProps } from '@material-ui/core';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

export type NavigationItem = {
    label: string;
    step: number;
};

type Props = WithWidthProps & {
    navigationItems: NavigationItem[];
    step: number;
};

const Navigation: React.FC<Props> = ({ navigationItems, step}) => {
    const classes = styles();
    const globalClasses = globalStyles();

    const renderNavigationItems = () => {
        return (
            <List>
                {navigationItems.map((item: NavigationItem) => {
                    return (
                        <ListItem key={item.label} className={clsx(globalClasses.flexRow)}>
                            <Typography className={clsx(classes.dot, item.step > step && classes.notCompleted)} variant="body1">
                                {'\u25CF'}
                            </Typography>
                            <Typography className={clsx(item.step > step && classes.notCompleted)} variant="body1">
                                {item.label}
                            </Typography>
                        </ListItem>
                    );
                })}
            </List>
        );
    };

    return <div className={clsx(classes.root, globalClasses.fullHeight, globalClasses.flexColumn)}>{renderNavigationItems()}</div>;
};

export default withWidth()(Navigation);
