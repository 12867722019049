import { makeStyles } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';

export default makeStyles(() => ({
    textButtonWithPlus: {
        padding: 0,
        fontFamily: FONTS.PRIMARY_FONT,
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: COLORS.apariBlack80
    },
    plusSign: {
        fontSize: 32,
        marginRight: 6
    },
    textButtonWithPlusText: {
        paddingTop: 2,
        fontSize: 12,
        fontWeight: 600,
        textDecoration: 'underline'
    }
}));
