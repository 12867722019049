import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { AuthenticationApi } from '@apari/core-api';
import DialogActions from '@material-ui/core/DialogActions';
import { useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import ForgotPasswordDialogImg from 'resources/images/Pause.png';
import globalStyles from 'styles/globalStyles';
import { ApariButton } from 'components';
import { Localisation } from 'utils';
import styles from './styles';
import { DialogContent } from '@material-ui/core';
import { AppContext } from 'context/AppContext';
import { NotificationTypes } from 'types';

const authenticationApi = new AuthenticationApi();

type Props = DialogProps & {
    onConfirm?: () => void;
    handleClose: () => void;
    email: string;
};

const ForgotPasswordDialog: React.FC<Props> = ({ open, email, handleClose }) => {
    const [loading, setLoading] = useState<boolean>(false);

    const { showLoadingBar, hideLoadingBar, showNotifications } = useContext(AppContext);

    const classes = styles();
    const globalClasses = globalStyles();
    const history = useHistory();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleResendEmail = async () => {
        setLoading(true);
        showLoadingBar();
        try {
            await authenticationApi.forgotPassword(email);
            showNotifications({
                type: NotificationTypes.SUCCESS,
                message: Localisation.localize('FORGOT_PASSWORD_SCREEN.EMAIL_SUCCESSFULLY_SENT')
            });
            history.push('/sign-in');
        } catch (e) {
            setLoading(false);
            showNotifications({ type: NotificationTypes.ERROR, message: e });
        }
        hideLoadingBar();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            onBackdropClick={handleClose}
            fullScreen={mobile}
            classes={{
                paper: classes.paper,
                paperFullScreen: classes.fullScreenPaper
            }}
            BackdropProps={{ classes: { root: classes.backdrop } }}
        >
            <DialogContent className={clsx(classes.dialogContent)}>
                <img src={ForgotPasswordDialogImg} height={120} alt="forgot-password-dialog" />
                <div className={clsx(classes.textContainer, globalClasses.marginTop40)}>
                    <p className={clsx(globalClasses.font16weight400Dark, globalClasses.marginBottom20)}>
                        {Localisation.localize('signUpWithEmailSuccessScreen.pleaseConfirmYourEmailAddress')}
                    </p>
                    <p className={clsx(globalClasses.font11weight400Dark)}>
                        {Localisation.localize('signUpWithEmailSuccessScreen.emailSentTo')}
                    </p>
                    <p className={clsx(globalClasses.font11weight600Dark)}>{email}</p>
                    <p className={clsx(globalClasses.font11weight400Dark, globalClasses.marginTop10)}>
                        {Localisation.localize('signUpWithEmailSuccessScreen.clickConfirmationLink')}
                    </p>
                </div>
            </DialogContent>
            <DialogActions className={clsx(globalClasses.flexColumnCenter, classes.buttonContainer)}>
                <p className={clsx(globalClasses.font11weight400Dark, globalClasses.noMargin)}>
                    {Localisation.localize('signUpWithEmailSuccessScreen.CLICK_LINK_BELOW')}
                </p>
                <ApariButton
                    variant="text"
                    disabled={loading}
                    onClick={handleResendEmail}
                    color="primary"
                    fullWidth
                    className={clsx(classes.button, globalClasses.font12weight600Dark, globalClasses.noPadding, globalClasses.marginTop16)}
                >
                    {Localisation.localize('signUpWithEmailSuccessScreen.resendEmail')}
                </ApariButton>
            </DialogActions>
        </Dialog>
    );
};
export default ForgotPasswordDialog;
