import React from 'react';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { MdArrowBack } from 'react-icons/md';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

type Props = {
    onBack?: () => void;
    title?: string;
    contentClass?: string;
    noSidePadding?: boolean;
    noVerticalPadding?: boolean;
    fullContent?: boolean;
};

const ApariMobileView: React.FC<Props> = ({ onBack, title, children, contentClass, noSidePadding, noVerticalPadding, fullContent }) => {
    const classes = styles();
    const globalClasses = globalStyles();

    return (
        <div className={clsx(globalClasses.fullWidth, globalClasses.flexRow, classes.root)}>
            <div
                className={clsx(
                    fullContent ? globalClasses.fullWidth : globalClasses.halfWidth,
                    globalClasses.flexColumn,
                    classes.content,
                    fullContent && classes.fullContent,
                    noSidePadding && classes.noSidePadding,
                    noVerticalPadding && classes.noVerticalPadding,
                    contentClass
                )}
            >
                <div className={clsx(globalClasses.fullWidth, globalClasses.flexRowStart)}>
                    {onBack && (
                        <IconButton className={clsx(classes.backButton)} onClick={onBack}>
                            <MdArrowBack />
                        </IconButton>
                    )}
                    <div className={clsx(classes.title)}>{title}</div>
                </div>
                <div className={clsx(globalClasses.fullHeight, globalClasses.flexColumn, globalClasses.overflowHidden)}>{children}</div>
            </div>
            {!fullContent && <div className={clsx(globalClasses.halfWidth)}></div>}
        </div>
    );
};

export default ApariMobileView;
