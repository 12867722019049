import { TransactionCategory, TransactionDto } from '@apari/banking-api';

export enum RECORD_STATUS {
    INCOME = 'INCOME',
    EXPENSE = 'EXPENSE'
}

export type UnassignedTransactionsInitialState = {
    transactions: TransactionDto[];
    totalElements?: number;
    filteredTransactions?: any[] | undefined;
    incomeTypeVisible?: boolean;
    filterTypes?: string[];
    existingTransactionTypes?: Record<'INCOME' | 'EXPENSE', { exists: boolean; count: number }>;
    selectedRecordStatus?: RECORD_STATUS;
    disabledType?: boolean | undefined;
    selectedTransactions?: string[] | undefined;
    categories?: TransactionCategory[] | undefined;
    incomeTypes?: any[] | undefined;
    accounts?: any[] | undefined;
    loaded?: boolean;
    type?: string | undefined;
    category?: number | undefined;
    isConfirmationOpened?: boolean;
    isTransactionDetailsOpen?: boolean | undefined;
    btnType?: string;
    transactionDetails?: any;
    searchText?: string;
    infiniteScrollPosition?: 0;
};

export type UnassignedFiltersFormik = {
    searchText: string;
    recordStatus?: RECORD_STATUS;
    dateFrom: string | null;
    dateTo: string | null;
    accountId: string;
    sortBy: string;
};

export enum CheckboxType {
    EMPTY = 'EMPTY',
    LIGHT = 'LIGHT',
    FULL = 'FULL'
}
