import React from 'react';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';
import Arrow from 'resources/images/Arrow.png';
import { Localisation } from 'utils';
import styles from './styles';

const NoDataContent: React.FC = () => {
    const globalClasses = globalStyles();
    const classes = styles();

    return (
        <div className={clsx(globalClasses.flexColumnCenter, classes.noDataContainer)}>
            <img src={Arrow} height={152} alt="no-records" />
            <div className={clsx(globalClasses.font14weight400Dark, globalClasses.marginTop10, globalClasses.marginBottom24)}>
                {Localisation.localize('DATA_OVERVIEW.NO_BUSINESSES')}
            </div>
        </div>
    );
};

export default NoDataContent;
