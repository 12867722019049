import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, useMediaQuery, useTheme, Divider } from '@material-ui/core';
import useEventTracking from 'hooks/useEventTracking';
import { EventActions } from 'types/eventTracking';
import { GlobalServices } from 'utils';
import CustomTooltip from './CustomTooltip';
import styles from './styles';

export type SubMenuOption = {
    label: string;
    highlighted?: boolean;
    tourId?: string;
    onClick: () => void;
};

export type NavigationOptionProps = {
    link: string;
    icon: JSX.Element;
    label: { text: string; className: string };
    disabled?: boolean;
    newTab?: boolean;
    trackingCategory: string;
    customAction?: () => void;
    tooltipText?: string;
    subMenu?: boolean;
    subMenuOptions?: SubMenuOption[];
    subMenuOpen?: boolean;
    tourId?: string;
};

const NavigationOption: React.FC<NavigationOptionProps> = ({
    link,
    icon,
    label,
    tooltipText,
    disabled,
    newTab,
    customAction,
    trackingCategory,
    subMenu,
    subMenuOptions,
    subMenuOpen,
    tourId
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [openCollapse, setOpenCollapse] = useState(
        subMenuOpen ? true : subMenuOptions ? subMenuOptions.some(subMenuOption => subMenuOption.highlighted) : false
    );
    const itemRef = useRef<HTMLDivElement>(null);

    const { trackingEvent } = useEventTracking();

    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const classes = styles({});

    useEffect(() => {
        if (subMenuOpen) {
            setAnchorEl(itemRef.current);
        } else {
            setAnchorEl(null);
        }
    }, [subMenuOpen]);

    const handleNewTab = (link: string) => {
        const win = window.open(link, '_blank');
        win!.focus();
    };

    const handleCollapse = () => {
        setOpenCollapse(prevState => !prevState);
    };

    const handleSubMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSubMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <CustomTooltip title={tooltipText || ''} placement={isMobile ? 'left' : 'right'}>
            <>
                <ListItem
                    ref={itemRef}
                    className={clsx(classes.menuItem, tourId)}
                    onClick={event => {
                        if (GlobalServices.isDefined(trackingCategory)) {
                            trackingEvent({ category: trackingCategory, name: label.text, action: EventActions.CLICK });
                        }
                        if (subMenu) {
                            isMobile ? handleCollapse() : handleSubMenuOpen(event);
                        } else if (customAction) {
                            customAction();
                        } else {
                            newTab ? handleNewTab(link) : history.push(link);
                        }
                    }}
                    button
                    disabled={disabled}
                    selected={window.location.pathname.includes(link)}
                >
                    <ListItemIcon className={clsx(classes.menuIcon)}>{icon}</ListItemIcon>
                    <ListItemText disableTypography className={label.className} primary={label.text} />
                </ListItem>
                {subMenu &&
                    subMenuOptions &&
                    (isMobile ? (
                        <Collapse in={openCollapse} timeout="auto" unmountOnExit className={clsx(classes.collapse)}>
                            <List component="div" disablePadding>
                                <Divider className={classes.menuDivider} />
                                {subMenuOptions.map(subMenuOption => (
                                    <ListItem
                                        key={subMenuOption.label}
                                        button
                                        onClick={subMenuOption.onClick}
                                        className={clsx(classes.menuItem, classes.menuSubItem, subMenuOption.tourId)}
                                        selected={subMenuOption.highlighted}
                                    >
                                        <ListItemText disableTypography className={classes.menuItemText} primary={subMenuOption.label} />
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    ) : (
                        <Menu
                            className={clsx(classes.customMenu)}
                            anchorEl={anchorEl}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: isMobile ? 'bottom' : 'top',
                                horizontal: isMobile ? 'left' : 'right'
                            }}
                            transformOrigin={{
                                vertical: isMobile ? 'top' : 'top',
                                horizontal: isMobile ? 'left' : 'left'
                            }}
                            PaperProps={{
                                square: true
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleSubMenuClose}
                        >
                            {subMenuOptions.map(subMenuOption => (
                                <MenuItem
                                    key={subMenuOption.label}
                                    onClick={subMenuOption.onClick}
                                    className={clsx(subMenuOption.highlighted && classes.subMenuItemHighlighted, subMenuOption.tourId)}
                                >
                                    {subMenuOption.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    ))}
            </>
        </CustomTooltip>
    );
};

export default NavigationOption;
