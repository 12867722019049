import React, { CSSProperties } from 'react';
import ContentLoader from 'react-content-loader';
import { COLORS } from 'constants/index';

type LoadingPlaceholderProps = {
    className?: string;
    style?: CSSProperties;
    height: number;
    width?: number | string;
};

const LoadingPlaceholder: React.FC<LoadingPlaceholderProps> = ({ height, width, className, style }) => {
    return (
        <ContentLoader
            className={className}
            style={style}
            speed={2}
            width={width ?? '100%'}
            height={height}
            display="block"
            backgroundColor={COLORS.backgroundLight}
        >
            <rect x="0" y="0" rx="3" ry="3" width={width ?? '100%'} height={height} />
        </ContentLoader>
    );
};

export default LoadingPlaceholder;
