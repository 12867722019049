import React, { useImperativeHandle, useRef, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRight';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';

type Props = {
    children: React.ReactElement | React.ReactElement[];
    label: string | React.ReactElement;
    value?: number | string;
    rightIcon?: React.ReactNode;
    ContainerProps?: React.HTMLAttributes<HTMLElement> & React.RefAttributes<HTMLElement | null>;
    unClickableText?: string | React.ReactElement;
    parentMenuClasses?: string;
};

const NestedMenuItems: React.FC<Props> = ({
    children,
    label,
    value,
    rightIcon,
    ContainerProps: ContainerPropsProp = {},
    unClickableText,
    parentMenuClasses
}) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const menuItemRef = useRef<HTMLLIElement>(null);
    const menuContainerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;
    useImperativeHandle(containerRefProp, () => containerRef.current);
    const globalClasses = globalStyles();

    const handleMouseClick = (event: React.MouseEvent<HTMLElement>) => {
        setIsSubMenuOpen(true);
        if (ContainerProps?.onMouseEnter) ContainerProps.onMouseEnter(event);
    };

    const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
        setIsSubMenuOpen(false);
        if (ContainerProps?.onMouseLeave) ContainerProps.onMouseLeave(event);
    };

    return (
        <div className={clsx(globalClasses.flexRowStart)}>
            {unClickableText}
            <div
                ref={containerRef}
                onMouseMove={handleMouseClick}
                onClick={handleMouseClick}
                onMouseLeave={handleMouseLeave}
                {...ContainerProps}
            >
                <MenuItem ref={menuItemRef} value={value} className={parentMenuClasses}>
                    {label}
                    {rightIcon || <ArrowRight />}
                </MenuItem>
                <Menu
                    style={{ pointerEvents: 'none' }}
                    anchorEl={menuItemRef.current}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    open={isSubMenuOpen}
                    autoFocus={false}
                    disableAutoFocus
                    disableEnforceFocus
                    onClose={() => {
                        setIsSubMenuOpen(false);
                    }}
                >
                    <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
                        {children}
                    </div>
                </Menu>
            </div>
        </div>
    );
};

export default NestedMenuItems;
