import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { isIOS } from 'react-device-detect';
import { FormikContextType, FormikErrors, FormikTouched, getIn } from 'formik';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Box, Grid, LinearProgress, Popper, PopperProps, PropTypes, useMediaQuery, useTheme } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { Client, lookupAddress } from '@ideal-postcodes/core-browser';
import { Address } from '@ideal-postcodes/api-typings';
import { debounce, isEmpty } from 'lodash';
import { Country } from '@apari/core-api';
import { ApariDialog, ApariTextField, TextButtonWithPlus } from 'components';
import { GLOBAL } from 'constants/index';
import { COUNTRIES } from 'constants/countries';
import NoDataImage from 'resources/images/Arrow.png';
import globalStyles from 'styles/globalStyles';
import { GlobalServices, Localisation } from 'utils';
import styles from './styles';
import ManualAddressForm from './ManualAddressForm';

const client = new Client({ api_key: GLOBAL.IDEAL_POSTCODES_KEY });

type AddressLookupProps = Partial<AutocompleteProps<any, any, any, any>> & {
    formik?: FormikContextType<any>;
    control?: string;
    label: string;
    margin?: PropTypes.Margin;
    onAddressSelect?: (addressObject: AddressInternal | null) => void;
    onlyUk?: boolean;
    foreign?: boolean;
    validationSchemaExtension?: any;
    openEditManualDialog?: boolean;
};

export type AddressInternal = {
    streetName?: string;
    houseNumber?: string;
    houseNumberExtension?: string;
    postalCode?: string;
    city?: string;
    state?: string;
    country?: Country | null;
};

const AddressLookup: React.FC<AddressLookupProps> = ({
    onAddressSelect,
    disabled,
    formik,
    control,
    label,
    value = null,
    onlyUk,
    foreign,
    margin = 'dense',
    validationSchemaExtension,
    openEditManualDialog,
    ...rest
}) => {
    const [addresses, setAddresses] = useState<Address[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [selectedAddress, setSelectedAddress] = useState<AddressInternal | null>(value);
    const [previewAddressText, setPreviewAddressText] = useState('');
    const [countries, setCountries] = useState<Country[]>([]);
    const [manualEditDialog, setManualEditDialog] = useState<boolean>(false);
    const autoCompleteRef: React.Ref<any> = useRef();
    const hideBorder = isEmpty(searchValue) || searchValue.length < 3;
    const classes = styles({ isIOS, hideBorder });
    const globalClasses = globalStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const activeAddress: AddressInternal | null = formik && control ? formik.values[control] || null : selectedAddress;

    const debouncedSearch = React.useRef(
        debounce(async (query: string) => {
            if (query) {
                try {
                    if (query.length > 2 && !openEditManualDialog) {
                        setAddresses(await lookupAddress({ client: client, query: query }));
                    }
                } catch (e) {
                    console.log(e);
                }
            } else {
                setAddresses([]);
            }
        }, 300)
    ).current;

    useEffect(() => {
        async function getCountries() {
            try {
                const response = { data: COUNTRIES };
                setCountries(
                    response.data.filter(country => {
                        if (onlyUk) {
                            return country.code2 === 'GB';
                        } else if (foreign) {
                            return country.code2 !== 'GB';
                        } else {
                            return country;
                        }
                    })
                );
            } catch (e) {
                console.log('e', e);
            }
        }
        getCountries();
    }, []);

    useEffect(() => {
        if (activeAddress) {
            setPreviewAddressText(
                (activeAddress.houseNumberExtension ? activeAddress.houseNumberExtension + ',\n' : '') +
                    (activeAddress.streetName
                        ? (activeAddress.houseNumber ? activeAddress.houseNumber + ' ' : '') + activeAddress.streetName + ',\n'
                        : '') +
                    (activeAddress.city ? activeAddress.city + ',\n' : '') +
                    (activeAddress.state ? activeAddress.state + ',\n' : '') +
                    (activeAddress.country && activeAddress.country.name ? activeAddress.country.name + ',\n' : '') +
                    (activeAddress.postalCode ? activeAddress.postalCode : '')
            );
        } else {
            setPreviewAddressText('');
        }
    }, [activeAddress]);

    const generateInternalAddressObject = (apiAddress: Address | null): AddressInternal => {
        if (apiAddress) {
            return {
                streetName: apiAddress.thoroughfare,
                houseNumber: apiAddress.building_number || apiAddress.building_name,
                houseNumberExtension: apiAddress.building_number && apiAddress.building_name,
                postalCode: apiAddress.postcode,
                city: apiAddress.post_town,
                state: apiAddress.county,
                country: countries.find(country => country.code2 === 'GB')
            };
        } else {
            return {};
        }
    };

    const handleClear = () => {
        if (formik && control) {
            formik.setFieldValue(control, null);
        } else {
            setSelectedAddress(null);
            onAddressSelect && onAddressSelect(null);
        }
        setPreviewAddressText('');
        setSearchValue('');
        setAddresses([]);
    };

    const renderValidationErrors = () => {
        let preparedError = '';
        if (formik && control && getIn(formik.touched, control) && getIn(formik.errors, control)) {
            const errors = getIn(formik.errors, control);
            const errorsKeys = Object.keys(errors);
            if (typeof errors !== 'string' && errorsKeys) {
                for (const key of errorsKeys) {
                    preparedError = preparedError + errors[key] + '\n';
                }
            } else {
                preparedError = errors;
            }
        }
        return preparedError;
    };

    const getInitialErrors = (): {
        errors: FormikErrors<AddressInternal> | undefined;
        touched: FormikTouched<AddressInternal> | undefined;
    } => {
        if (formik && control) {
            const errors = getIn(formik.errors, control);
            const errorsKeys = errors ? Object.keys(errors) : undefined;
            if (typeof errors !== 'string' && errorsKeys) {
                return {
                    errors: getIn(formik.errors, control) as FormikErrors<AddressInternal>,
                    touched: getIn(formik.touched, control) as FormikTouched<AddressInternal>
                };
            } else {
                return { errors: undefined, touched: undefined };
            }
        } else {
            return { errors: undefined, touched: undefined };
        }
    };

    const handleInputClick = () => {
        openEditManualDialog && setManualEditDialog(true);
    };

    const CustomPopper: React.FC<PopperProps> = props => {
        const classes = styles({ isIOS, hideBorder });
        return <Popper {...props} className={clsx(classes.popper)} placement="bottom-start" />;
    };

    return (
        <>
            <div className={clsx(classes.previewInputWrapper, !activeAddress && classes.hideElement)}>
                <ApariTextField
                    className={clsx(classes.previewInput, !disabled && classes.previewInputEnabled)}
                    fullWidth
                    label={label}
                    multiline
                    value={previewAddressText}
                    disabled
                    {...(formik &&
                        control && {
                            error: getIn(formik.touched, control) && Boolean(getIn(formik.errors, control)),
                            helperText: renderValidationErrors()
                        })}
                />
                <div className={clsx(classes.progressWrapper)}>
                    {GlobalServices.isEmpty(countries) && (
                        <LinearProgress className={clsx(classes.progress)} variant="query" color="primary" />
                    )}
                </div>
            </div>
            <Autocomplete
                className={clsx(activeAddress && classes.hideElement)}
                getOptionLabel={option => (typeof option === 'string' ? option || '' : option.thoroughfare || '')}
                getOptionSelected={(option: Address, value: AddressInternal) => {
                    if (option && value) {
                        return option.thoroughfare === value.streetName && option.postcode === value.postalCode;
                    } else {
                        return false;
                    }
                }}
                filterOptions={x => x}
                options={addresses}
                autoComplete
                freeSolo={hideBorder}
                innerRef={autoCompleteRef}
                disabled={disabled || GlobalServices.isEmpty(countries)}
                includeInputInList
                filterSelectedOptions
                value={activeAddress}
                PopperComponent={CustomPopper}
                onChange={(event, newValue) => {
                    setAddresses(newValue ? [newValue, ...addresses] : addresses);
                    if (formik && control) {
                        formik.setFieldValue(control, generateInternalAddressObject(newValue));
                    }
                    if (onAddressSelect) {
                        setSelectedAddress(generateInternalAddressObject(newValue));
                        onAddressSelect(generateInternalAddressObject(newValue));
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    debouncedSearch(newInputValue);
                    setSearchValue(newInputValue);
                }}
                renderInput={params => (
                    <>
                        <ApariTextField
                            {...params}
                            className={clsx(classes.addressLookupInput)}
                            label={label}
                            fullWidth
                            margin={margin}
                            onClick={handleInputClick}
                            type={openEditManualDialog ? 'button' : 'text'}
                            InputProps={{ ...params.InputProps, endAdornment: <div /> }}
                            {...(formik &&
                                control && {
                                    error: getIn(formik.touched, control) && Boolean(getIn(formik.errors, control)),
                                    helperText: renderValidationErrors()
                                })}
                        />
                        <div className={clsx(classes.progressWrapper)}>
                            {GlobalServices.isEmpty(countries) && (
                                <LinearProgress className={clsx(classes.progress)} variant="query" color="primary" />
                            )}
                        </div>
                    </>
                )}
                renderOption={(props: Address | null) => {
                    if (props) {
                        const preparedText =
                            (props.building_name ? props.building_name + ', ' : '') +
                            (props.building_number ? props.building_number + ' ' : '') +
                            (props.thoroughfare ? props.thoroughfare + ', ' : '') +
                            (props.post_town ? props.post_town + ', ' : '') +
                            (props.county ? props.county + ', ' : '') +
                            (props.country ? props.country + ', ' : '') +
                            (props.postcode ? props.postcode : '');

                        const matches = match(preparedText, searchValue);
                        const parts = parse(preparedText, matches);

                        return (
                            <div {...props} style={{ width: autoCompleteRef?.current.offsetWidth - 18 }}>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Box component={LocationOnIcon} />
                                    </Grid>
                                    <Grid item xs>
                                        <p>
                                            {parts.map((part, index) => {
                                                return (
                                                    <span key={index} style={{ fontWeight: part.highlight ? 600 : 400 }}>
                                                        {part.text}
                                                    </span>
                                                );
                                            })}
                                        </p>
                                    </Grid>
                                </Grid>
                            </div>
                        );
                    }
                }}
                {...rest}
            />
            <div className={clsx(classes.previewActionsWrapper)}>
                {activeAddress && (
                    <TextButtonWithPlus
                        data-cy="clear"
                        className={clsx(classes.previewActionButton)}
                        text={Localisation.localize('ADDRESS_LOOKUP.CLEAR')}
                        hidePlus
                        disabled={disabled || GlobalServices.isEmpty(countries)}
                        onClick={handleClear}
                    />
                )}
                <TextButtonWithPlus
                    data-cy="editManually"
                    className={clsx(classes.previewActionButton)}
                    text={Localisation.localize('ADDRESS_LOOKUP.EDIT_MANUALLY')}
                    hidePlus
                    disabled={disabled || GlobalServices.isEmpty(countries)}
                    onClick={() => setManualEditDialog(true)}
                />
            </div>
            <ApariDialog
                maxWidth="md"
                fullScreen={isMobile}
                title=""
                open={manualEditDialog}
                onCloseDialog={() => setManualEditDialog(false)}
            >
                <div className={clsx(classes.manualEditDialogContent)}>
                    <div className={clsx(isMobile ? globalClasses.fullWidth : globalClasses.halfWidth)}>
                        <ManualAddressForm
                            address={activeAddress}
                            onCancel={() => setManualEditDialog(false)}
                            onConfirm={data => {
                                if (formik && control) {
                                    formik.setFieldValue(control, data || null);
                                }
                                if (onAddressSelect) {
                                    setSelectedAddress(data || null);
                                    onAddressSelect(data || null);
                                }
                                setManualEditDialog(false);
                            }}
                            validationSchemaExtension={validationSchemaExtension}
                            onlyUk={onlyUk}
                            countriesList={countries}
                            initialErrors={getInitialErrors().errors}
                            initialTouched={getInitialErrors().touched}
                        />
                    </div>
                    {!isMobile && (
                        <div className={clsx(globalClasses.halfWidth, globalClasses.flexColumnCenter)}>
                            <img height={250} src={NoDataImage} alt="arrow" />
                        </div>
                    )}
                </div>
            </ApariDialog>
        </>
    );
};
export default AddressLookup;
