import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { InputAdornment } from '@material-ui/core';
import { FormikContextType, getIn } from 'formik';
import { ApariTextField } from '../../common/ui';
import styles from './styles';

type Props = Omit<NumberFormatProps, 'name'> & {
    control: string;
    formik: FormikContextType<any>;
};

const MTDFormAmountInputField: React.FC<Props> = ({ formik, control, allowNegative, ...rest }) => {
    const classes = styles();
    return (
        <NumberFormat
            classes={{
                root: classes.root
            }}
            name={control}
            fullWidth
            customInput={ApariTextField}
            variant="standard"
            margin="none"
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator=","
            allowNegative={allowNegative === true}
            isNumericString
            onValueChange={values => {
                formik.setFieldValue(control, values.floatValue);
            }}
            inputProps={{ inputMode: 'decimal' }}
            InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                classes: { input: classes.input }
            }}
            value={getIn(formik.values, control)}
            placeholder={getIn(formik.values, control) ? '' : '0.00'}
            error={getIn(formik.touched, control) && Boolean(getIn(formik.errors, control))}
            helperText={getIn(formik.touched, control) && getIn(formik.errors, control)}
            {...rest}
        />
    );
};

export default MTDFormAmountInputField;
