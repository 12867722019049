import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { FormikContextType } from 'formik';
import { TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Country } from '@apari/core-api';
import { ApariTextField } from 'components';
import { COUNTRIES } from 'constants/countries';
import styles from './styles';

interface Props {
    control: string;
    label?: string;
    formik: FormikContextType<any>;
}
const MTDFormCountryField: React.FC<Props & TextFieldProps> = ({ control, formik, label, disabled, placeholder }) => {
    const [countries, setCountries] = useState<Country[]>([]);
    const [loading, setLoading] = useState(false);

    const classes = styles();

    useEffect(() => {
        async function getCountries() {
            setLoading(true);
            try {
                const response = { data: COUNTRIES };
                setCountries(response.data);

                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        }
        getCountries();
    }, []);

    return (
        <Autocomplete
            fullWidth
            className={clsx(classes.root)}
            options={countries}
            renderOption={option => <>{option.name}</>}
            getOptionLabel={option => option.name!}
            getOptionSelected={(country: Country, newValue: Country) => {
                return country.name! === newValue.name!;
            }}
            value={formik.values[control]}
            loading={loading}
            disabled={disabled}
            popupIcon={<KeyboardArrowDownIcon />}
            onChange={(_, data) => formik.setFieldValue(control, data)}
            renderInput={params => (
                <ApariTextField
                    {...params}
                    name={control}
                    label={label}
                    margin="none"
                    error={formik.touched[control] && Boolean(formik.errors[control])}
                    helperText={formik.touched[control] && formik.errors[control]}
                    variant="standard"
                    placeholder={placeholder}
                    inputProps={{ ...params.inputProps, form: { autocomplete: 'off' } }}
                    InputProps={{ ...params.InputProps, disableUnderline: true }}
                />
            )}
        />
    );
};

export default MTDFormCountryField;
