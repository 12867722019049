import { Theme, makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

type Props = {
    taxYearAlreadySubmitted?: boolean;
};

export default makeStyles<Theme, Props>(() => ({
    tileCheckbox: {
        position: 'relative',
        width: '100px',
        height: '100px',
        margin: 7.5,
        float: 'left',
        boxSizing: 'border-box',
        textAlign: 'center',

        '& div': {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: '25px',
            transition: '0.5s ease',
            borderRadius: '4px'
        },

        '& input': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
            cursor: 'pointer'
        }
    },
    tile__text: {
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '20px',
        padding: '5px'
    },
    tile__info_icon: {
        display: 'flex',
        position: 'absolute',
        bottom: 0,
        right: 0,
        padding: '2px'
    },
    activeInfoTooltip: {
        color: COLORS.apariWhite
    },
    inactiveInfoTooltip: {
        color: COLORS.apariWhite
    },
    inactiveTile: ({ taxYearAlreadySubmitted }) => {
        return {
            borderRadius: 6,
            ...(!taxYearAlreadySubmitted && { border: '1px solid transparent' })
        };
    },
    tile: {
        "& input[type='checkbox'].tile ~ div": {
            backgroundColor: 'rgba(103, 63, 250, 0.5) !important',
            color: '#fff'
        },
        "& input[type='checkbox']:checked.tile ~ div": {
            backgroundColor: COLORS.apariLightBlue + ' !important',
            color: '#fff'
        }
    },
    fontSize16: {
        fontSize: '16px'
    }
}));
