import React from 'react';
// Will be used later
// import { FaFacebook } from 'react-icons/fa';
import { SingleSignOnTypes } from 'types';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';

type Props = {
    type: SingleSignOnTypes;
};

const SingleSignOn: React.FC<Props> = () => {
    // Will be used later
    // const signInGoogle = async () => {
    //     showLoadingBar();
    //     const response = await oauth2Api.getAntiForgeryToken();
    //     const securityToken = response.data.token;
    //
    //     window.location.href =
    //         'https://accounts.google.com/o/oauth2/v2/auth?' +
    //         'response_type=code&' +
    //         'client_id=' +
    //         GLOBAL.GOOGLE_CLIENT_ID +
    //         '&scope=openid%20email%20profile&' +
    //         'redirect_uri=' +
    //         GLOBAL.GOOGLE_REDIRECT_URI +
    //         '&' +
    //         'state=' +
    //         securityToken;
    // };
    //
    // const signInApple = async () => {
    //     showLoadingBar();
    //     const response = await oauth2Api.getAntiForgeryToken();
    //     const securityToken = response.data.token;
    //     localStorage.setItem('singleSignOnType', String(type));
    //     window.location.href =
    //         'https://appleid.apple.com/auth/authorize?' +
    //         'response_type=code&' +
    //         'response_mode=query&' +
    //         'client_id=' +
    //         GLOBAL.APPLE_CLIENT_ID +
    //         '&' +
    //         'scope=openid&' +
    //         'redirect_uri=' +
    //         GLOBAL.APPLE_REDIRECT_URI +
    //         '&' +
    //         'state=' +
    //         securityToken;
    // };
    //
    // const signInFacebook = async () => {
    //     showLoadingBar();
    //     const response = await oauth2Api.getAntiForgeryToken();
    //     const securityToken = response.data.token;
    //     localStorage.setItem('singleSignOnType', String(type));
    //     window.location.href =
    //         ' https://www.facebook.com/v8.0/dialog/oauth?' +
    //         'response_type=code&' +
    //         'client_id=' +
    //         GLOBAL.FACEBOOK_CLIENT_ID +
    //         '&' +
    //         'scope=email&' +
    //         'redirect_uri=' +
    //         GLOBAL.FACEBOOK_REDIRECT_URI +
    //         '&' +
    //         'state=' +
    //         securityToken;
    // };

    const globalClasses = globalStyles();
    return <div className={clsx(globalClasses.flexColumnCenter)} />;
};

export default SingleSignOn;
