import {makeStyles} from '@material-ui/core';

export default makeStyles(() => ({
    indicator: {
        display: 'none'
    },
    flexContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}));
