import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { InputAdornment, TextFieldProps } from '@material-ui/core';
import clsx from 'clsx';
import { ApariTextField } from 'components';
import { NUMBERS } from 'constants/index';
import styles from './styles';

type Props = TextFieldProps &
    NumberFormatProps & {
        darkBackground?: boolean;
        noAdornment?: boolean;
        asString?: boolean;
        formatType?: 'string' | 'target' | 'number';
    };

type DefaultNumberFormatProps = NumberFormatProps & {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (value: number | undefined) => void;
    name: string;
};

type StringNumberFormatProps = NumberFormatProps & {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (value: string | undefined) => void;
    name: string;
};

type Target = {
    target: {
        value?: number | string;
        name?: string;
    };
};

type TargetNumberFormatProps = NumberFormatProps & {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (target: Target) => void;
    name: string;
};

const ApariNumberFormat: React.FC<DefaultNumberFormatProps> = props => {
    const { inputRef, name, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            name={name}
            getInputRef={inputRef}
            thousandSeparator={NUMBERS.default.thousandSeparator}
            decimalSeparator={NUMBERS.default.decimalSeparator}
            onValueChange={values => {
                onChange(values.floatValue);
            }}
            allowNegative={false}
            suffix={' EUR'}
            allowLeadingZeros={false}
            decimalScale={2}
            fixedDecimalScale
            isNumericString
        />
    );
};

const ApariNumberFormatAsString: React.FC<StringNumberFormatProps> = props => {
    const { inputRef, name, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            name={name}
            getInputRef={inputRef}
            thousandSeparator={NUMBERS.default.thousandSeparator}
            decimalSeparator={NUMBERS.default.decimalSeparator}
            onValueChange={values => {
                onChange(values.value);
            }}
            allowNegative={false}
            suffix={' EUR'}
            min={0}
            decimalScale={2}
            fixedDecimalScale
            allowLeadingZeros={false}
            isNumericString
            placeholder="0,00 EUR"
        />
    );
};

const TargetNumberFormat: React.FC<TargetNumberFormatProps> = props => {
    const { onChange, name, inputRef, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            thousandSeparator={NUMBERS.default.thousandSeparator}
            decimalSeparator={NUMBERS.default.decimalSeparator}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.floatValue,
                        name: name
                    }
                });
            }}
            allowNegative={false}
            suffix={' EUR'}
            allowLeadingZeros={false}
            decimalScale={2}
            fixedDecimalScale
        />
    );
};

const ApariAmountField: React.FC<Props> = props => {
    const { type, label, placeholder, InputProps, darkBackground, noAdornment, formatType, ...rest } = props;
    const classes = styles();

    const getFormatType = () => {
        switch (formatType) {
            case 'string':
                return ApariNumberFormatAsString as any; // eslint-disable-line @typescript-eslint/no-explicit-any
            case 'target':
                return TargetNumberFormat as any; // eslint-disable-line @typescript-eslint/no-explicit-any
            case 'number':
            default:
                return ApariNumberFormat as any; // eslint-disable-line @typescript-eslint/no-explicit-any
        }
    };

    return (
        <ApariTextField
            label={label}
            placeholder={placeholder || ''}
            darkBackground={darkBackground}
            InputProps={{
                ...InputProps,
                ...(noAdornment
                    ? {}
                    : {
                          endAdornment: <InputAdornment position="end">EUR</InputAdornment>
                      }),
                inputComponent: getFormatType()
            }}
            type={type || 'string'}
            className={clsx(classes.inputAdornment, darkBackground && classes.darkInputAdornment)}
            {...rest}
        />
    );
};
export default ApariAmountField;
