import React from 'react';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import styles from './styles';

type FilteredLabelProps = {
    text: string;
    onDelete?: () => void;
};

const FilteredLabel: React.FC<FilteredLabelProps> = ({ text, onDelete }) => {
    const classes = styles();
    return (
        <div className={clsx(classes.filteredLabel)}>
            <div className={clsx(classes.filteredLabelText)}>{text}</div>
            {onDelete && <Close className={clsx(classes.filteredLabelIcon)} onClick={() => onDelete()} />}
        </div>
    );
};
export default FilteredLabel;
