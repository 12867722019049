import { GlobalServices, Localisation } from 'utils';
import { CalculationTableCell, MultiRowTableCell } from 'components';
import React from 'react';

class UIServices {
    static isMobile(width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'): boolean {
        switch (width) {
            case 'xs':
                return true;
            case 'sm':
                return true;
            case 'md':
                return true;
            case 'lg':
                return false;
            case 'xl':
                return false;
            default:
                return false;
        }
    }

    static getDayInWeek = (dayNumber: number | string): string => {
        switch (Number(dayNumber)) {
            case 1:
                return Localisation.localize('general.monday');
            case 2:
                return Localisation.localize('general.tuesday');
            case 3:
                return Localisation.localize('general.wednesday');
            case 4:
                return Localisation.localize('general.thursday');
            case 5:
                return Localisation.localize('general.friday');
            case 6:
                return Localisation.localize('general.saturday');
            case 7:
                return Localisation.localize('general.sunday');
            default:
                return '';
        }
    };

    static renderTableCells = (data: Record<string, number | string>) =>
        Object.entries(data).map(([key, value]) =>
            GlobalServices.isDefined(value) ? (
                <CalculationTableCell key={key} valueName={Localisation.localize(`CALCULATION_OVERVIEW.TABLE.${key}`)} value={value} />
            ) : (
                <div />
            )
        );

    static renderObjects = (objectValue: Record<string, any>, ignoreKeys?: string[]) => {
        const returnElements: JSX.Element[] = [];

        for (const [key, value] of Object.entries(objectValue)) {
            if (GlobalServices.isDefined(ignoreKeys) && ignoreKeys.includes(key)) {
                continue;
            }
            if (Array.isArray(value) && typeof value !== 'string') {
                for (const element of value) {
                    returnElements.push(<MultiRowTableCell key={key} data={element} ignoreKeys={ignoreKeys} />);
                }
            } else {
                const [numberValues, objectValues] = GlobalServices.partition<number | string, Record<string, any>>(
                    Object.entries(value),
                    ([, value]) => typeof value === 'number' || typeof value === 'string'
                );
                if (!GlobalServices.isEmpty(numberValues)) {
                    returnElements.push(
                        <MultiRowTableCell
                            key={key}
                            data={numberValues}
                            title={Localisation.localize(`CALCULATION_OVERVIEW.TABLE.${key}`)}
                            ignoreKeys={ignoreKeys}
                        />
                    );
                }
                if (!GlobalServices.isEmpty(objectValues)) {
                    returnElements.push(...UIServices.renderObjects(objectValues, ignoreKeys));
                }
            }
        }

        return returnElements;
    };
}

export default UIServices;
