import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    chip: {
        border: '1px solid #673FFA',
        borderRadius: '4px',
        background: 'transparent',
        color: COLORS.apariPurple,
        fontWeight: 600,
        height: 20,
        overflow: 'hidden',
        margin: '2px',
        '&:focus': { background: COLORS.apariLightSilver }
    },
    chipRemoveButton: {
        color: COLORS.apariPurple,
        width: 15,
        height: 15
    },
    input: {
        display: 'flex',
        flexWrap: 'wrap',
        '& input': {
            width: '100px',
            flex: '1 0 100px'
        }
    }
}));
