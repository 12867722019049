import { makeStyles, Theme } from '@material-ui/core';
import { COLORS } from 'constants/index';

type Props = {
    recommended?: boolean;
};

export default makeStyles<Theme, Props>(() => ({
    root: ({ recommended }) => {
        return {
            paddingTop: recommended ? 0 : 40,
            width: '100%',
            height: '100%'
        };
    },
    card: ({ recommended }) => {
        const recommendedStyle = recommended
            ? {
                  border: '2px solid ' + COLORS.apariLightBlue,
                  borderTop: 0
              }
            : {};

        return {
            ...recommendedStyle,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            minHeight: 320,
            padding: 16,
            borderRadius: recommended ? '0px 0px 2px 2px' : 2,
            background: COLORS.apariWhite,
            boxShadow:
                '0px 4px 122px rgba(0, 0, 0, 0.04), 0px 1.28121px 27.2503px rgba(0, 0, 0, 0.0238443), 0px 0.517444px 8.11312px rgba(0, 0, 0, 0.0161557)'
        };
    },
    recommendedIndicator: {
        height: 40,
        background: COLORS.apariLightBlue,
        borderRadius: '2px 2px 0px 0px',
        '& p': {
            textTransform: 'uppercase',
            fontSize: 12,
            fontWeight: 700,
            color: COLORS.apariWhite
        }
    },
    cardHeader: {},
    cardTitle: {},
    cardTitleFont: ({ recommended }) => {
        return {
            fontSize: 20,
            fontWeight: 600,
            color: recommended ? COLORS.apariLightBlue : COLORS.apariLightPurple
        };
    },
    exclTrial: ({ recommended }) => {
        return {
            whiteSpace: 'pre-wrap',
            color: recommended ? COLORS.apariLightBlue : COLORS.apariLightPurple
        };
    },
    activeIndicator: ({ recommended }) => {
        return {
            display: 'flex',
            fontSize: 12,
            fontWeight: 600,
            color: recommended ? COLORS.apariLightBlue : COLORS.apariLightPurple
        };
    },
    activeIcon: {
        marginLeft: 4,
        fontSize: 15
    },
    featuresPart: {},
    featuresIcon: ({ recommended }) => {
        return {
            fontSize: 15,
            marginRight: 10,
            color: recommended ? COLORS.apariLightBlue : COLORS.apariLightPurple
        };
    },
    accentColor: ({ recommended }) => {
        return {
            color: recommended ? COLORS.apariLightBlue : COLORS.apariLightPurple
        };
    },
    actionButton: ({ recommended }) => {
        const notRecommendedStyle = recommended
            ? {}
            : {
                  backgroundColor: COLORS.apariLightPurple,
                  color: COLORS.apariWhite,
                  borderColor: COLORS.apariLightPurple,

                  '&:hover': {
                      borderColor: COLORS.apariLightPurple,
                      backgroundColor: '#8900cb'
                  }
              };

        return {
            ...notRecommendedStyle,
            fontSize: '14px !important',
            height: 30
        };
    },
    payNowEndTrialButton: ({ recommended }) => {
        const notRecommendedStyle = recommended
            ? {}
            : {
                  color: COLORS.apariLightPurple,
                  borderColor: COLORS.apariLightPurple,

                  '&:hover': {
                      borderColor: COLORS.apariLightPurple
                  }
              };

        return {
            ...notRecommendedStyle,
            fontSize: '14px !important',
            height: 30
        };
    },
    priceParagraph: {
        whiteSpace: 'pre-line'
    }
}));
