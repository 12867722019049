import React from 'react';
import clsx from 'clsx';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Localisation } from 'utils';
import styles from './styles';

type Props = {
    containerClasses?: string;
    iconClasses?: string;
    textClasses?: string;
    customText?: string;
};

const FinalisedTag: React.FC<Props> = ({ containerClasses, iconClasses, textClasses, customText }) => {
    const classes = styles();

    return (
        <div className={clsx(classes.finalisedTag, containerClasses)}>
            <div className={clsx(classes.finalisedTagIcon, iconClasses)}>
                <CheckCircleOutlineIcon />
            </div>
            <span className={clsx(classes.finalisedTagText, textClasses)}>{customText ?? Localisation.localize('general.FINALISED')}</span>
        </div>
    );
};

export default FinalisedTag;
