import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    statusColorOrange: {
        color: COLORS.apariSemanticOrange
    },
    statusColorRed: {
        color: COLORS.red
    },
    infoIcon: {
        fontSize: 16,
        marginLeft: 8,
        marginBottom: 2
    },
    table: {
        '& tr': {
            display: 'flex',
            width: '100%'
        },
        '& td': {
            padding: 0,
            paddingTop: 2,
            border: 'none',
            verticalAlign: 'text-top',
            width: '100%'
        },
        '& thead': {
            paddingRight: 8,
            verticalAlign: 'text-top',
            [theme.breakpoints.up('md')]: {
                minWidth: 170,
                whiteSpace: 'nowrap'
            },
            [theme.breakpoints.down('sm')]: {
                minWidth: '40%'
            }
        }
    }
}));
