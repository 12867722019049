import moment, { Moment } from 'moment';
import { MonthOptions, Period, Timeframe } from 'types';
import { GlobalServices } from 'utils/index';
import dateFormat from 'constants/dateFormat';
import { DATE_FORMAT, DATES } from 'constants/index';

class DateTimeServices {
    static getDisplayFormatDate(inputDate: Date | string): Date | string {
        const date = moment(inputDate);
        if (date.isValid() && inputDate) {
            return moment(date).local().format('D MMM YYYY');
        } else {
            return '';
        }
    }

    static getGermanFormatDate(inputDate: Date | string): Date | string {
        if (inputDate !== null && inputDate !== undefined) {
            const date = moment(inputDate);
            if (date.isValid() && inputDate) {
                return moment(date).local().format('yyyy-MM-DD');
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    static getDateInGivenFormat(inputDate: Date | string | undefined, format: string, parseFormat?: string): string {
        const date = moment(inputDate, parseFormat);
        if (inputDate && date.isValid()) {
            return moment(date).local().format(format);
        } else {
            return '';
        }
    }

    static parseDate(inputDate: Date | string, parseFormat: string | string[]): Moment {
        if (Array.isArray(parseFormat)) {
            let date = moment();
            for (const format of parseFormat) {
                date = moment(inputDate, format);
                if (date.isValid()) {
                    return date;
                }
            }
            date = moment(inputDate);
            return date;
        } else {
            return moment(inputDate, parseFormat);
        }
    }

    static parseAccountingPeriodDate(inputDate: string | null, format: string, parseFormat = DATE_FORMAT.DD_MM): string {
        if (GlobalServices.isDefined(inputDate)) {
            if (inputDate.includes('_')) {
                return inputDate;
            }
            const date = moment(inputDate, parseFormat);
            if (date.isValid()) {
                return moment(date).local().format(format);
            }
        }
        return '';
    }

    static getDateForBankAIFilter(inputDate: Date): Date | string {
        const date = moment(inputDate);
        if (date.isValid() && inputDate !== undefined) {
            return moment(date).local().format('yyyy-MM-DD');
        } else {
            return '';
        }
    }

    static isToday(date: Date | string): boolean {
        return moment().isSame(date, 'day');
    }

    static isValid(date: Date | string): boolean {
        return !date ? false : moment(date).isValid();
    }

    static getDatesBetween(startDate: Date | string, endDate: Date | string): (Date | string)[] {
        const dates = [startDate];

        const currDate = moment(startDate).startOf('day');
        const lastDate = moment(endDate).startOf('day');

        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            dates.push(this.getGermanFormatDate(currDate.clone().toDate()));
        }

        return dates;
    }

    static getNumberOfWeeks(startDate: Date | string, endDate: Date | string): number {
        const momentEndDate = moment(endDate).isSame(new Date(), 'year') ? moment(endDate) : moment().endOf('year');
        const momentStartDate = moment(new Date(startDate));
        return momentEndDate.diff(momentStartDate, 'weeks');
    }

    static getMonthName(date: Date | string, short?: boolean): string {
        return moment(date).format(short ? dateFormat.MMM : dateFormat.MMMM);
    }

    static getDayName(date: Date | string, short?: boolean): string {
        return moment(date).format(short ? dateFormat.ddd : dateFormat.dddd);
    }

    static getDateRangeForWeek(numberOfWeek: string): string[] {
        const date = moment().isoWeek(Number(numberOfWeek));
        return [`${moment(date).startOf('isoWeek').format(dateFormat.DD_MM)}`, `${moment(date).endOf('isoWeek').format(dateFormat.DD_MM)}`];
    }

    static getMonthNameByNumber(monthNumber: number): string {
        return moment().month(monthNumber).format(dateFormat.MMMM);
    }

    static getMonthNumber(date: Date | string): number {
        return moment(date).month();
    }

    static getDateInMonth(date: Date | string): number {
        return moment(date).date();
    }

    static setMonthToDate(date: Date | string, monthNumber: number): Date | string {
        return moment(date).month(monthNumber).format(dateFormat.YYYY_MM_DD);
    }

    static setDayToDate(date: Date | string, dayNumber: number): Date | string {
        return moment(date).date(dayNumber).format(dateFormat.YYYY_MM_DD);
    }

    static getAccountingPeriod(startOfAccountingPeriod = '--04-06', year?: number): Moment {
        const accountingPeriod = moment(startOfAccountingPeriod, dateFormat.ACCOUNTING_PERIOD_FORMAT);
        if (GlobalServices.isDefined(year)) {
            accountingPeriod.year(year);
        } else if (accountingPeriod.isAfter(moment().endOf('day'))) {
            accountingPeriod.subtract(1, 'year');
        }
        return accountingPeriod;
    }

    static getAccountingPeriodYearString(startOfAccountingPeriod = '--04-06', pastYears?: number): string {
        const startDate = moment(startOfAccountingPeriod, dateFormat.ACCOUNTING_PERIOD_FORMAT);

        if (startDate.isAfter(moment())) {
            startDate.subtract(1, 'year');
        }

        if (GlobalServices.isDefined(pastYears)) {
            startDate.subtract(pastYears, 'year');
        }
        return startDate.format(dateFormat.YY) + '/' + startDate.add(1, 'year').format(dateFormat.YY);
    }
    static getAccountingPeriodRange(startOfAccountingPeriod = '--04-06', taxYear?: string): string {
        if (taxYear) {
            const startYear = Number(taxYear.split('-')[0]);
            const endYear = startYear + 1;
            const startDate = moment(startOfAccountingPeriod, dateFormat.ACCOUNTING_PERIOD_FORMAT).year(startYear);
            return (
                startDate.format(dateFormat.UK_STANDARD_2) +
                ' - ' +
                startDate.subtract(1, 'day').year(endYear).format(dateFormat.UK_STANDARD_2)
            );
        } else {
            const startDate = moment(startOfAccountingPeriod, dateFormat.ACCOUNTING_PERIOD_FORMAT);

            if (startDate.isAfter(moment())) {
                startDate.subtract(1, 'year');
            }

            return (
                startDate.format(dateFormat.UK_STANDARD_2) +
                ' - ' +
                startDate.add(1, 'year').subtract(1, 'day').format(dateFormat.UK_STANDARD_2)
            );
        }
    }

    static getAccountingPeriodString(startOfAccountingPeriod = '--04-06', format = dateFormat.ACCOUNTING_PERIOD_FORMAT): string {
        const startDate = moment(startOfAccountingPeriod, format);
        return startDate.format(dateFormat.DD_MM) + ' - ' + startDate.subtract(1, 'day').format(dateFormat.DD_MM);
    }

    static getTimeFramePeriod(timeframe: Timeframe, accountingPeriod: Moment): Period {
        const timeframeOptionsSwitch = this.timeframeOptionsSwitch(timeframe, accountingPeriod);

        return {
            from: timeframeOptionsSwitch.from.format(dateFormat.YYYY_MM_DD),
            to: timeframeOptionsSwitch.to.format(dateFormat.YYYY_MM_DD)
        };
    }

    static getTimeFramePeriodWithFormating(timeframe: Timeframe, accountingPeriod: Moment): Period {
        const timeframeOptionsSwitch = this.timeframeOptionsSwitch(timeframe, accountingPeriod);

        return {
            from: timeframeOptionsSwitch.from.format(dateFormat.UK_STANDARD_2),
            to: timeframeOptionsSwitch.to.format(dateFormat.UK_STANDARD_2)
        };
    }

    private static timeframeOptionsSwitch(timeframe: Timeframe, accountingPeriod: moment.Moment) {
        let to = moment();
        let from = moment();

        switch (timeframe) {
            case Timeframe.LAST_7_DAYS:
                from.subtract(6, 'days');
                break;
            case Timeframe.LAST_MONTH:
                from.subtract(1, 'months').startOf('month');
                to.endOf('month');
                break;
            case Timeframe.LAST_6_MONTHS:
                from.subtract(6, 'months').startOf('month');
                to.endOf('month');
                break;
            case Timeframe.LAST_12_MONTHS:
                from.subtract(1, 'years').startOf('month');
                to.endOf('month');
                break;
            case Timeframe.LAST_YEAR:
                from.subtract(1, 'years');
                break;
            case Timeframe.ANNUAL:
                from = moment(accountingPeriod);
                to = moment(accountingPeriod.add(1, 'year').subtract(1, 'day'));
                break;
            case Timeframe.CURRENT_TAX_YEAR_Q1:
                from = moment(accountingPeriod);
                to = moment(accountingPeriod.add(1, 'Q').subtract(1, 'day'));
                break;
            case Timeframe.CURRENT_TAX_YEAR_Q2:
                from = moment(accountingPeriod.add(1, 'Q'));
                to = moment(accountingPeriod.add(1, 'Q').subtract(1, 'day'));
                break;
            case Timeframe.CURRENT_TAX_YEAR_Q3:
                from = moment(accountingPeriod.add(2, 'Q'));
                to = moment(accountingPeriod.add(1, 'Q').subtract(1, 'day'));
                break;
            case Timeframe.CURRENT_TAX_YEAR_Q4:
                from = moment(accountingPeriod.add(3, 'Q'));
                to = moment(accountingPeriod.add(1, 'Q').subtract(1, 'day'));
                break;
            case Timeframe.LAST_TAX_YEAR_Q1:
                accountingPeriod.subtract(1, 'year');
                from = moment(accountingPeriod);
                to = moment(accountingPeriod.add(1, 'Q').subtract(1, 'day'));
                break;
            case Timeframe.LAST_TAX_YEAR_Q2:
                accountingPeriod.subtract(1, 'year');
                from = moment(accountingPeriod.add(1, 'Q'));
                to = moment(accountingPeriod.add(1, 'Q').subtract(1, 'day'));
                break;
            case Timeframe.LAST_TAX_YEAR_Q3:
                accountingPeriod.subtract(1, 'year');
                from = moment(accountingPeriod.add(2, 'Q'));
                to = moment(accountingPeriod.add(1, 'Q').subtract(1, 'day'));
                break;
            case Timeframe.LAST_TAX_YEAR_Q4:
                accountingPeriod.subtract(1, 'year');
                from = moment(accountingPeriod.add(3, 'Q'));
                to = moment(accountingPeriod.add(1, 'Q').subtract(1, 'day'));
                break;
            case Timeframe.LAST_TAX_YEAR:
                from = moment(accountingPeriod.subtract(1, 'year'));
                to = moment(accountingPeriod.add(1, 'year').subtract(1, 'day'));
                break;
        }
        return { from, to };
    }

    static generateMonthOptions(
        startDate: Date | string,
        endDate: Date | string,
        addRecurringTransaction: (month: number, checked: boolean) => void
    ): { name: string; monthNumber: number; action: (month: number, checked: boolean) => void }[] {
        const options: MonthOptions[] = [];
        const startMonth = !GlobalServices.isEmpty(startDate) ? DateTimeServices.getMonthNumber(startDate) : 0;
        const endMonth = !GlobalServices.isEmpty(startDate) ? DateTimeServices.getMonthNumber(endDate) : 11;
        if (!GlobalServices.isEmpty(endDate) || (GlobalServices.isEmpty(startDate) && GlobalServices.isEmpty(endDate))) {
            for (let i = startMonth; i <= endMonth; i++) {
                options.push({
                    name: DateTimeServices.getMonthNameByNumber(i),
                    monthNumber: i,
                    action: addRecurringTransaction
                });
            }
        }
        return options;
    }

    static getFrontendFormatTaxYear(taxYear?: string): string {
        return taxYear ? taxYear.replace('-', '/').substring(2) : '';
    }

    static getBackendFormatTaxYear(taxYear: string): string {
        return '20' + taxYear.replace('/', '-');
    }

    static addYearsToTaxYear(taxYear: string, yearsToAdd: number, frontendFormat?: boolean): string {
        if (taxYear.includes('-')) {
            const parts = taxYear.split('-');
            const newFirstPart = String(parts && parts[0] ? Number(parts[0]) + yearsToAdd : '');
            const newSecondPart = String(parts && parts[1] ? Number(parts[1]) + yearsToAdd : '');
            return frontendFormat ? newFirstPart.substring(2) + '/' + newSecondPart : newFirstPart + '/' + newSecondPart;
        } else if (taxYear.includes('/')) {
            const parts = taxYear.split('/');
            const newFirstPart = String(parts && parts[0] ? Number(parts[0]) + yearsToAdd : '');
            const newSecondPart = String(parts && parts[1] ? Number(parts[1]) + yearsToAdd : '');
            return newFirstPart + '-' + newSecondPart;
        } else {
            return '';
        }
    }

    static subtractYearsToTaxYear(taxYear: string, yearsToSubtract: number, frontendFormat?: boolean): string {
        if (taxYear.includes('-')) {
            const parts = taxYear.split('-');
            const newFirstPart = String(parts && parts[0] ? Number(parts[0]) - yearsToSubtract : '');
            const newSecondPart = String(parts && parts[1] ? Number(parts[1]) - yearsToSubtract : '');
            return frontendFormat ? newFirstPart.substring(2) + '/' + newSecondPart : newFirstPart + '/' + newSecondPart;
        } else if (taxYear.includes('/')) {
            const parts = taxYear.split('/');
            const newFirstPart = String(parts && parts[0] ? Number(parts[0]) - yearsToSubtract : '');
            const newSecondPart = String(parts && parts[1] ? Number(parts[1]) - yearsToSubtract : '');
            return newFirstPart + '-' + newSecondPart;
        } else {
            return '';
        }
    }

    static generateStartEndPointsForDate(date: Date | string): any {
        const suppliedDate = moment(date).utcOffset(0, true);
        const { format } = suppliedDate.creationData();

        if (typeof format === 'string') {
            if (format.toLowerCase().includes('d')) {
                return [suppliedDate.startOf('day').format(), suppliedDate.endOf('day').format()];
            } else if (format.toLowerCase().includes('m')) {
                return [suppliedDate.startOf('month').format(), suppliedDate.endOf('month').format()];
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    static getTaxYearFromCurrentCalendarYear() {
        const currentDate = new Date();
        const currentCalendarYear = Number(moment(currentDate).format(DATE_FORMAT.YYYY));
        const newTaxYear = moment(`${currentCalendarYear}-${DATES.TAXYEAR_UK_START} 00:00:00`).toDate();
        if (currentDate >= newTaxYear) return currentCalendarYear;
        else return currentCalendarYear - 1;
    }
}

export default DateTimeServices;
