import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    root: {
        marginTop: 4,
        '& .MuiInputBase-input': {
            paddingBottom: 4,
            fontSize: 12
        },
        '& .MuiInputAdornment-root p': {
            fontSize: 12
        },
        '& .MuiInputBase-root.Mui-disabled': {
            '& p': {
                color: COLORS.apariBlack40
            }
        }
    },
    input: {
        '&::placeholder': {
            fontSize: 12
        }
    }
}));
