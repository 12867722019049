import { makeStyles } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';

export default makeStyles(theme => ({
    root: {},
    contentWrapperOverride: {
        paddingTop: 42,
        [theme.breakpoints.down('md')]: {
            alignSelf: 'center',
            paddingTop: 10,
            paddingBottom: 40
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 50,
            paddingRight: 50
        },
        [theme.breakpoints.up(2100)]: {
            marginTop: '45%',
            marginBottom: 'auto'
        }
    },
    directionsContainer: {
        background: theme.palette.primary.light,
        display: 'none',
        padding: 16
    },
    mobileLink: {
        textAlign: 'center',
        textDecoration: 'underline',
        fontFamily: FONTS.QUATERNARY_FONT,
        fontSize: 14,
        fontWeight: 400
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        fontFamily: FONTS.QUATERNARY_FONT,
        [theme.breakpoints.up('lg')]: {
            maxWidth: 288
        },
        '& .MuiFormHelperText-root': {
            fontFamily: FONTS.QUATERNARY_FONT
        }
    },
    resetPassword: {
        fontFamily: FONTS.QUATERNARY_FONT,
        fontSize: 12,
        fontWeight: 500,
        color: COLORS.apariBlack80,
        textDecoration: 'underline',
        marginBottom: 40
    },
    signInButton: {
        width: '100%',
        maxWidth: 288
    },
    alignItemsCenter: {
        alignItems: 'center'
    }
}));
