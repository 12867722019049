import React, { useState } from 'react';
import { CloseReason, OpenReason, SpeedDial, SpeedDialAction, SpeedDialIcon, SpeedDialProps } from '@material-ui/lab';
import { AddCircleOutline } from '@material-ui/icons';
import { FloatingButtonActions } from 'types';
import styles from './styles';

type Props = Omit<SpeedDialProps, 'open' | 'ariaLabel'> & {
    open?: boolean;
    ariaLabel?: string;
    actions: FloatingButtonActions[];
};

const FloatingActionButton: React.FC<Props> = ({ open = false, ariaLabel, actions, ...rest }) => {
    const [openSpeedDial, setOpenSpeedDial] = useState(open);

    const classes = styles();

    const handleOpen = (event: React.SyntheticEvent<Record<string, unknown>>, reason: OpenReason) => {
        if (reason === 'toggle') {
            setOpenSpeedDial(true);
        }
    };
    const handleClose = (event: React.SyntheticEvent<Record<string, unknown>>, reason: CloseReason) => {
        if (reason === 'toggle') {
            setOpenSpeedDial(false);
        }
    };

    return (
        <SpeedDial
            classes={{ root: classes.root, fab: classes.fab }}
            icon={<SpeedDialIcon icon={<AddCircleOutline color="primary" />} />}
            onClose={handleClose}
            onOpen={handleOpen}
            ariaLabel={ariaLabel || 'SpeedDial'}
            open={openSpeedDial}
            {...rest}
        >
            {actions.map(action => (
                <SpeedDialAction
                    classes={{ staticTooltipLabel: classes.staticTooltipLabel }}
                    tooltipOpen
                    key={action.name}
                    tooltipTitle={action.name}
                    icon={action.icon}
                    onClick={action.action}
                />
            ))}
        </SpeedDial>
    );
};

export default FloatingActionButton;
