import { makeStyles } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';

export default makeStyles(() => ({
    messageBlockContainer: {
        marginTop: 30
    },
    errorIcon: {
        color: COLORS.red
    },
    warningIcon: {
        color: COLORS.apariSemanticOrange
    },
    infoIcon: {
        color: COLORS.apariBlack60
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 32
    },
    errorExplanation: {
        fontFamily: FONTS.PRIMARY_FONT,
        color: '#FF004C',
        fontSize: 11,
        fontWeight: 500,
        marginTop: 6
    },
    textWrapper: {
        '& a': {
            fontWeight: 500,
            color: COLORS.apariLightBlue,
            textDecoration: 'underline'
        }
    }
}));
