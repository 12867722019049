import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    textField: {
        '& .MuiInputBase-root': {
            fontSize: 15
        }
    },
    inputLabel: {
        color: COLORS.apariBlack20,
        fontSize: 15
    }
}));
