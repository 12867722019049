import React, { useContext } from 'react';
import { Link, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { withWidth, WithWidthProps } from '@material-ui/core';
import { AnimatedTransition, ApariButton, ApPasswordField, ApTextField, SingleSignOn, SubHeaderClean } from 'components';
import BackToWebsite from 'components/shared/BackToWebsite';
import NewLayout from 'containers/layouts/NewLayout';
import globalStyles from 'styles/globalStyles';
import { SingleSignOnTypes } from 'types';
import { UIServices, Localisation } from 'utils';
import styles from './styles';
import validationSchema from './validationSchema';
import { AppContext } from 'context/AppContext';

const SignInScreen: React.FC<RouteComponentProps & WithWidthProps> = ({ width }) => {
    const globalClasses = globalStyles();
    const classes = styles();
    const history = useHistory();
    // const isInitialMount = useRef(true);
    const { search } = useLocation();
    const { showLoadingBar, hideLoadingBar } = useContext(AppContext);

    const submitForm = async () => {
        showLoadingBar();

        setTimeout(() => {
            history.push('/onboarding/tiles');
            hideLoadingBar();
        }, 1500);
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: submitForm,
        validationSchema: validationSchema
    });

    const renderMainContent = () => {
        return (
            <div
                className={clsx(globalClasses.fullWidth, globalClasses.flexColumn, UIServices.isMobile(width) && globalClasses.fullHeight)}
            >
                <BackToWebsite />
                <SubHeaderClean
                    title={Localisation.localize('SignInScreen.welcomeToApari')}
                    noSidePadding
                    alternativeFont
                    noBackButton
                    messages={[Localisation.localize('SignInScreen.primaryText')]}
                />
                <form
                    className={clsx(
                        globalClasses.fullWidth,
                        classes.formContainer,
                        UIServices.isMobile(width) && globalClasses.fullHeight && globalClasses.flexRowSpaceBetween
                    )}
                    onSubmit={formik.handleSubmit}
                >
                    <div className={globalClasses.alignSelfFlexStart}>
                        <ApTextField
                            data-cy="email"
                            control="email"
                            formik={formik}
                            alternativeFont
                            margin="normal"
                            label={Localisation.localize('general.emailAddress')}
                        />
                        <ApPasswordField
                            data-cy="password"
                            autoComplete="current-password"
                            control="password"
                            alternativeFont
                            label={Localisation.localize('general.password')}
                            formik={formik}
                        />

                        <div className={clsx(classes.resetPassword, globalClasses.marginTop8, globalClasses.marginBottom12)}>
                            <Link to="/forgot-password">{Localisation.localize('SignInScreen.forgotPassword')}</Link>
                        </div>
                    </div>
                    <div className={clsx(classes.signInButton)}>
                        <ApariButton
                            data-cy="loginButton"
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="small"
                            alternativeFont
                        >
                            {Localisation.localize('general.signIn')}
                        </ApariButton>
                        <SingleSignOn type={SingleSignOnTypes.SIGN_IN} />
                        <div
                            className={clsx(
                                globalClasses.font12weight600Dark,
                                globalClasses.marginTop16,
                                globalClasses.marginBottom4,
                                classes.mobileLink
                            )}
                        >
                            <Link to={'/sign-up-with-email' + search}>{Localisation.localize('SignInScreen.signUpForFree')}</Link>
                        </div>
                    </div>
                </form>
            </div>
        );
    };

    const renderMobileContent = () => {
        return (
            <div className={clsx(globalClasses.contentWrapper, classes.contentWrapperOverride)}>
                <div className={clsx(globalClasses.fullHeight, globalClasses.fullWidth)}>
                    <div className={clsx(globalClasses.fullHeight)}>{renderMainContent()}</div>
                </div>
            </div>
        );
    };

    const renderDesktopContent = () => {
        return (
            <div className={clsx(globalClasses.contentWrapper, classes.contentWrapperOverride)}>
                <div className={clsx(globalClasses.fullHeight, globalClasses.fullWidth, globalClasses.flexRow)}>
                    <div className={clsx(globalClasses.flexColumnCenter, globalClasses.fullHeight)}>{renderMainContent()}</div>
                </div>
            </div>
        );
    };

    // return authenticated ? (
    //     <div />
    // ) : (
    return (
        <NewLayout
            whiteBackground
            hideHeader={true}
            hideMenu={true}
            hideBack
            hideTitle={true}
            hideFooter
            enableInfoBar
            noSidePadding
            enableBackgroundArrows
            transparentHeader
            headerText={Localisation.localize('signUpWithEmailScreen.welcomeToApari')}
        >
            <AnimatedTransition addHeight className={UIServices.isMobile(width) ? globalClasses.marginTop20 : globalClasses.marginTop32}>
                {UIServices.isMobile(width) ? renderMobileContent() : renderDesktopContent()}
            </AnimatedTransition>
        </NewLayout>
    );
};

export default withWidth()(SignInScreen);
