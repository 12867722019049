import React, { createContext, useContext, useEffect, useState } from 'react';
import { BusinessSourceBasicInfo, Business } from '@apari/core-api';
import { GlobalServices } from 'utils';
import { AuthenticationContext } from './AuthenticationContext';
import { MockDataContext } from './MockDataContext';

export interface IncomeTypesContextProps {
    landlordBusiness?: Business;
    incomeTypes: BusinessSourceBasicInfo[];
    setLandlordBusiness: React.Dispatch<React.SetStateAction<Business | undefined>>;
    foreignLandlordBusiness?: Business;
    setForeignLandlordBusiness: React.Dispatch<React.SetStateAction<Business | undefined>>;
    selfEmployedBusinesses: Business[];
    setSelfEmployedBusinesses: React.Dispatch<React.SetStateAction<Business[]>>;
    incomeTypesLoading: boolean;
    getIncomeTypes: () => Promise<BusinessSourceBasicInfo[]>;
    fetchIncomeTypes: () => void;
    resetIncomeTypes: () => void;
    setIncomeTypes: React.Dispatch<React.SetStateAction<BusinessSourceBasicInfo[]>>;
}

const IncomeTypesContext = createContext({} as IncomeTypesContextProps);

export const IncomeTypesProvider: React.FC = ({ children }) => {
    const [incomeTypesLoading, setIncomeTypesLoading] = useState(false);
    const [landlordBusiness, setLandlordBusiness] = useState<Business>();
    const [foreignLandlordBusiness, setForeignLandlordBusiness] = useState<Business>();
    const [selfEmployedBusinesses, setSelfEmployedBusinesses] = useState<Business[]>([]);
    const [incomeTypes, setIncomeTypes] = useState<BusinessSourceBasicInfo[]>([]);

    const { authenticated } = useContext(AuthenticationContext);
    const { mockIncomeTypes } = useContext(MockDataContext);

    useEffect(() => {
        async function fetchData() {
            try {
                await fetchIncomeTypes();
            } catch (e) {
                console.log('e', e);
            }
        }

        authenticated && fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated]);

    const fetchIncomeTypes = async (): Promise<BusinessSourceBasicInfo[]> => {
        if (authenticated) {
            try {
                setIncomeTypesLoading(true);
                setIncomeTypes(mockIncomeTypes && mockIncomeTypes.length ? mockIncomeTypes : []);
                setIncomeTypesLoading(false);
                return mockIncomeTypes && mockIncomeTypes.length ? mockIncomeTypes : [];
            } catch (e) {
                console.log('e', e);
                setIncomeTypes([]);
                setIncomeTypesLoading(false);
                return [];
            }
        } else {
            return [];
        }
    };

    const getIncomeTypes = async () => {
        if (GlobalServices.isEmpty(incomeTypes)) {
            return fetchIncomeTypes();
        } else {
            return incomeTypes;
        }
    };

    const resetIncomeTypes = () => {
        setIncomeTypes([]);
        setLandlordBusiness(undefined);
        setForeignLandlordBusiness(undefined);
        setSelfEmployedBusinesses([]);
    };

    return (
        <IncomeTypesContext.Provider
            value={{
                landlordBusiness,
                setLandlordBusiness,
                foreignLandlordBusiness,
                setForeignLandlordBusiness,
                selfEmployedBusinesses,
                setSelfEmployedBusinesses,
                incomeTypes: mockIncomeTypes && mockIncomeTypes.length ? mockIncomeTypes : incomeTypes,
                fetchIncomeTypes,
                getIncomeTypes,
                resetIncomeTypes,
                incomeTypesLoading,
                setIncomeTypes
            }}
        >
            {children}
        </IncomeTypesContext.Provider>
    );
};

export default IncomeTypesContext;
