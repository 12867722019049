import {makeStyles} from '@material-ui/core';

export default makeStyles(() => ({
    root: {},
    checkBoxOptionsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gridGap: '10px',
        width: '100%',
        ['@media (min-width:650px)']: { // eslint-disable-line no-useless-computed-key
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
            gridGap: '10px 16px'
        }
    }
}));
