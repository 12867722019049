import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { AppContext } from 'context/AppContext';
import { AuthenticationApi } from '@apari/core-api';
import { FullScreenLoading } from 'components';
import { GlobalServices } from 'utils';
import { NotificationTypes, TokenErrorTypes } from 'types';
import AccountConfirmationError from './components/AccountConfrimationError';
import AccountConfirmationSuccess from './components/AccontConfirmationSuccess';

const authenticationApi = new AuthenticationApi();

const AccountConfirmationScreen: React.FC<RouteComponentProps> = ({ location }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [tokenErrorType, setTokenErrorType] = useState<TokenErrorTypes | undefined>(undefined);

    const history = useHistory();

    const { showNotifications } = useContext(AppContext);

    useEffect(() => {
        async function confirmAccount() {
            setLoading(true);
            const params = new URLSearchParams(location.search);
            const token = GlobalServices.getQueryParameterIfItExists(params, 'token');
            const info = GlobalServices.getQueryParameterIfItExists(params, 'info');
            if (GlobalServices.isEmpty(token) || GlobalServices.isEmpty(info)) {
                setConfirmed(false);
                setTokenErrorType(TokenErrorTypes.INVALID);
                setLoading(false);
                return;
            }
            try {
                await authenticationApi.confirmRegistration(info!, token!);
                setConfirmed(true);
            } catch (e) {
                if (e.accountAlreadyConfirmed) {
                    history.push('/sign-in');
                    showNotifications({ type: NotificationTypes.SUCCESS, message: e.message });
                } else if (e.tokenErrorType === TokenErrorTypes.INVALID) {
                    setTokenErrorType(TokenErrorTypes.INVALID);
                } else {
                    setTokenErrorType(TokenErrorTypes.EXPIRED);
                }
                setConfirmed(false);
            }
            setLoading(false);
        }

        confirmAccount();
    }, [location.search]);

    return loading ? (
        <FullScreenLoading />
    ) : confirmed ? (
        <AccountConfirmationSuccess />
    ) : (
        <AccountConfirmationError errorType={tokenErrorType} />
    );
};

export default AccountConfirmationScreen;
