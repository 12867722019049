import { makeStyles } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';

export default makeStyles(theme => ({
    root: {
        height: 72,
        left: 135,
        width: 'calc(100% - 135px)',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            left: 0
        },
        padding: '14px 16px 10px 16px',
        background: theme.palette.background.paper,
        [theme.breakpoints.up('lg')]: {
            padding: `${theme.spacing(2) + 'px ' + theme.spacing(0) + 'px'}`
        },
        backfaceVisibility: 'hidden',
        transform: 'translateZ(0)',
        transition: 'box-shadow 0.3s',
        WebkitTransition: 'box-shadow 0.3s',
        MozTransition: 'box-shadow 0.3s',
        MsTransition: 'box-shadow 0.3s',
        OTransition: 'box-shadow 0.3s',
        alignItems: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'space-between',
        willChange: 'box-shadow'
    },
    transparentHeader: {
        background: 'transparent'
    },
    bottomLine: {
        borderBottom: '1px solid #E6EAF1'
    },
    rootShadow: {
        [theme.breakpoints.down('md')]: {
            MozBoxShadow: '11px 10px 37px -5px rgba(0,0,0,0.52)',
            WebkitBoxShadow: '11px 10px 37px -5px rgba(0,0,0,0.52)',
            BoxShadow: '11px 10px 37px -5px rgba(0,0,0,0.52)'
        }
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        height: 30,
        fontSize: 14,
        fontWeight: 600,
        color: COLORS.apariBlack80
    },
    signOutIcon: {
        marginLeft: 8
    },
    monumentFont: {
        fontFamily: FONTS.SECONDARY_FONT,
        fontStyle: 'normal'
    },
    menuIconColor: {
        color: COLORS.apariWhite
    },
    closeIcon: {
        fontSize: 20,
        paddingLeft: 0
    },
    dialogTitle: {
        padding: '20px 16px 30px 16px'
    },
    rightBorderPartialHeight: {
        borderRight: '1px solid #ECEFF5',
        height: '90%'
    },
    navigationDialog: {
        '& .MuiPaper-root': {
            backgroundColor: COLORS.apariLightBlue
        }
    },
    logoWithBackground: {
        backgroundColor: COLORS.apariLightBlue
    },
    overrideRoot: {
        left: '0 !important',
        width: 'auto !important'
    },
    impersonate: {
        background: '#7CBD82',
        position: 'fixed',
        minWidth: '100%',
        height: 72,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
        top: 0,
        padding: '8px 16px',
        [theme.breakpoints.down('md')]: {
            marginTop: 72,
            height: 50,
            marginLeft: '-16px',
            marginRight: '-16px'
        }
    },
    staticPaymentIcon: {
        position: 'absolute',
        top: -2,
        left: 28,
        zIndex: 1,
        fontSize: 22
    }
}));
