import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(() => ({
    input: {
        background: COLORS.apariWhite,
        borderBottomWidth: 2,
        border: 'none',
        width: '23px !important',
        marginRight: 4,
        borderBottom: '2px solid ' + COLORS.apariBlack20
    },
    inputWrapper: {
        '& input:focus': {
            outline: 0
        }
    }
}));
