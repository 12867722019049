import Papa from 'papaparse';
import moment from 'moment';
import {
    TransactionCategory,
    TransactionCategoryApi,
    TransactionCategoryCategoryTypeEnum,
    TransactionControllerApi,
    TransactionDto,
    TransactionExportDto
} from '@apari/banking-api';
import { BusinessSourceBasicInfo, BusinessSourceBasicInfoTypeEnum } from '@apari/core-api';
import { DATE_FORMAT } from 'constants/index';
import { DateTimeServices, GlobalServices, Localisation } from 'utils';

const transactionCategoryApi = new TransactionCategoryApi();
const transactionApi = new TransactionControllerApi();

const RequiredApariCsvColumns: string[] = [
    'Booking Date',
    'Account',
    'APARI Business',
    'APARI Property',
    'APARI Category',
    'Type',
    'VAT',
    'Amount',
    'Original Amount'
];

const mappedColumns: Record<string, string> = {
    'Booking Date': 'transactionDate',
    Account: 'bankAccount',
    'APARI Business': 'businessId',
    'APARI Property': 'propertyId',
    'APARI Property Address': 'propertyAddress',
    'APARI Category': 'category',
    Type: 'type',
    Purpose: 'purpose',
    Counterpart: 'counterpartName',
    VAT: 'vat',
    Amount: 'amount',
    'Original Amount': 'originalAmount',
    Notes: 'notes'
};

const expenseCategories: TransactionCategoryCategoryTypeEnum[] = [
    TransactionCategoryCategoryTypeEnum.FHL_LL_EXPENSE,
    TransactionCategoryCategoryTypeEnum.HMRC_EXPENSE,
    TransactionCategoryCategoryTypeEnum.SE_EXPENSE,
    TransactionCategoryCategoryTypeEnum.FOREIGN_FHL_LL_EXPENSE,
    TransactionCategoryCategoryTypeEnum.FOREIGN_NON_FHL_LL_EXPENSE,
    TransactionCategoryCategoryTypeEnum.JELLYFISH_EXPENSE,
    TransactionCategoryCategoryTypeEnum.NON_BUS_EXPENSE,
    TransactionCategoryCategoryTypeEnum.NON_FHL_LL_EXPENSE
];

class RecordKeepingServices {
    static getBusinessName = (businesses: BusinessSourceBasicInfo[], transaction: TransactionDto) => {
        const business = businesses.filter(incomeType => {
            if (transaction.propertyId) {
                return incomeType.propertyId === transaction.propertyId;
            } else {
                if (
                    incomeType.type === BusinessSourceBasicInfoTypeEnum.LANDLORD ||
                    incomeType.type === BusinessSourceBasicInfoTypeEnum.SELF_EMPLOYED ||
                    incomeType.type === BusinessSourceBasicInfoTypeEnum.NON_BUSINESS
                ) {
                    return incomeType.businessId === transaction.businessId;
                }
            }
        });
        return business[0] ? business[0].name : '';
    };

    static async isApariCsv(
        columns: string[],
        data: string[][],
        bankAccount: any
    ): Promise<{ validApariCsv: boolean; transactionsByProperty: any[] }> {
        let validApariCsv = true;
        const transactionsByProperty: { property: any; transactions: any[] }[] = [];
        const missingApariColumn = RequiredApariCsvColumns.some(apariCsvColumn => !columns.includes(apariCsvColumn));

        if (missingApariColumn) {
            return { validApariCsv: false, transactionsByProperty };
        }

        let transactionCategories: TransactionCategory[] = [];
        try {
            const response = await transactionCategoryApi.getCategories(true, undefined, undefined, 1000000);
            transactionCategories = response.data.content!;
        } catch (e) {
            console.log('err', e);
        }

        const bookingDateColumn = 'Booking Date';
        const accountColumn = 'Account';
        const businessColumn = 'APARI Business';
        const propertyColumn = 'APARI Property';
        const categoryColumn = 'APARI Category';
        const typeColumn = 'Type';
        const vatColumn = 'VAT';
        const amountColumn = 'Amount';
        const originalAmountColumn = 'Original Amount';

        const amountColumnIndex = columns.indexOf(amountColumn);
        const originalAmountColumnIndex = columns.indexOf(originalAmountColumn);
        data.some((item: any) => {
            let currentTransaction: any = {};
            columns.some((element: string, index: number) => {
                if (element === bookingDateColumn && !DateTimeServices.isValid(item[index])) {
                    console.log('invalid bookingDateColumn ', item[index]);
                    validApariCsv = false;
                    return true;
                } else if (element === categoryColumn) {
                    if (!transactionCategories.some(category => category.name === item[index])) {
                        console.log('invalid categoryColumn ', item[index]);
                        validApariCsv = false;
                        return true;
                    } else {
                        const transactionCategory: TransactionCategory = transactionCategories.filter(
                            category => category.name === item[index]
                        )[0];
                        item[index] = {
                            id: transactionCategory.id,
                            countryId: transactionCategory.countryId,
                            parentCategory: transactionCategory.parentCategory,
                            name: transactionCategory.name,
                            categoryType: transactionCategory.categoryType
                        };
                    }
                } else if (
                    (element === propertyColumn || element === businessColumn || element == accountColumn) &&
                    GlobalServices.isEmpty(item[index])
                ) {
                    console.log('invalid propertyColumn... ', element, item[index]);
                    validApariCsv = false;
                    return true;
                } else if (
                    (element === vatColumn || element === amountColumn || element === originalAmountColumn) &&
                    (GlobalServices.isEmpty(item[index]) || isNaN(item[index]))
                ) {
                    console.log('invalid amounts ', item[index]);
                    validApariCsv = false;
                    return true;
                } else if (element === originalAmountColumn && item[originalAmountColumnIndex] < item[amountColumnIndex]) {
                    console.log('invalid originalAmountColumn ', item[index]);
                    validApariCsv = false;
                    return true;
                } else if (element === typeColumn) {
                    if (item[index] !== 'INCOME' && item[index] !== 'EXPENSE') {
                        console.log('invalid typeColumn ', item[index]);
                        validApariCsv = false;
                        return true;
                    } else {
                        currentTransaction = {
                            ...currentTransaction,
                            isIncome: item[index] === 'INCOME'
                        };
                    }
                }

                currentTransaction = {
                    ...currentTransaction,
                    [mappedColumns[element]]: item[index],
                    bankAccount: { id: bankAccount }
                };

                delete currentTransaction.type;
                delete currentTransaction.vat;
            });
            if (!validApariCsv) {
                return true;
            } else {
                if (!transactionsByProperty.some((propertyData: any) => propertyData.property.name === currentTransaction.propertyId)) {
                    transactionsByProperty.push({
                        property: { name: currentTransaction.propertyId, address: currentTransaction.propertyAddress },
                        transactions: [currentTransaction]
                    });
                } else {
                    const propertyIndex = transactionsByProperty.findIndex(
                        (propertyData: any) => propertyData.property.name == currentTransaction.propertyId
                    );
                    transactionsByProperty[propertyIndex] = {
                        ...transactionsByProperty[propertyIndex],
                        transactions: transactionsByProperty[propertyIndex].transactions.concat([currentTransaction])
                    };
                }
            }
            delete currentTransaction.propertyAddress;
        });

        return { validApariCsv, transactionsByProperty: validApariCsv ? transactionsByProperty : [] };
    }

    static isRefundTransaction = (transactionData: TransactionDto) => {
        if (transactionData.category?.categoryType) {
            const isExpenseCategory = expenseCategories.includes(
                transactionData.category.categoryType as TransactionCategoryCategoryTypeEnum
            );
            if (transactionData.isIncome && isExpenseCategory) {
                return true;
            }
        }
        return false;
    };

    static submitExport = async (state: Record<string, any>) => {
        const isIncome =
            state.initialValues.selectedType === 'INCOME' ? 'true' : state.initialValues.selectedType === 'EXPENSE' ? 'false' : '';
        const transactionResponse = await transactionApi.getTransactionsForExport(
            'bankAccount,category.parentCategory',
            state.initialValues.showPrivate,
            state.initialValues.showShared,
            state.initialValues.isProperty ? '' : state.initialValues.incomeType,
            undefined,
            undefined,
            GlobalServices.isEmpty(state.initialValues.sortBy) ? [] : [state.initialValues.sortBy],
            isIncome,
            !state.initialValues.isProperty ? '' : state.initialValues.incomeType,
            GlobalServices.isEmpty(state.initialValues.account) ? [] : [state.initialValues.account],
            'true',
            [state.initialValues.category],
            state.initialValues.timeRangeFrom
                ? moment(state.initialValues.timeRangeFrom).startOf('day').format(DATE_FORMAT.YYYY_MM_DD)
                : undefined,
            state.initialValues.timeRangeTo
                ? moment(state.initialValues.timeRangeTo).endOf('day').format(DATE_FORMAT.YYYY_MM_DD)
                : undefined,
            '',
            '',
            '',
            '',
            state.initialValues.searchText!,
            []
        );

        const response = transactionResponse.data.map((item: TransactionExportDto) => ({
            Date: item.transactionDate,
            Account: item.bankAccount?.name,
            'APARI Business': item.business?.name,
            'APARI Property': item.property?.name,
            'APARI Category': item.category?.name,
            Type: item.isIncome ? 'INCOME' : 'EXPENSE',
            Purpose: item.purpose,
            Counterparty: item.counterpartName,
            Amount: item.amount,
            'Original Amount': item.originalAmount,
            Notes: item.notes
        }));

        const csv = Papa.unparse(response);
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        let csvURL: any = null;
        const exportTransactionsPdfName = `${Localisation.localize('DASHBOARD_SCREEN.GENERAL.EXPORT_TRANSACTIONS_PDF_NAME')}.csv`;
        navigator.msSaveBlob
            ? (csvURL = navigator.msSaveBlob(csvData, exportTransactionsPdfName))
            : (csvURL = window.URL.createObjectURL(csvData));

        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', exportTransactionsPdfName);
        tempLink.click();
    };
}

export default RecordKeepingServices;
