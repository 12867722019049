import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment';
import { AppContext } from 'context/AppContext';
import { AuthenticationContext } from 'context/AuthenticationContext';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

const InfoBar: React.FC = () => {
    const { showInfoBar, maintenanceAnnouncement, countdown } = useContext(AppContext);
    const { authenticated } = useContext(AuthenticationContext);

    const globalClasses = globalStyles();
    const classes = styles({ authenticated });

    return showInfoBar ? (
        <div className={clsx(classes.root)}>
            <div className={clsx(classes.content)}>
                <span className={clsx(globalClasses.font11weight400Dark)}>
                    <Trans
                        i18nKey="INFO_BAR.MAINTENANCE"
                        values={{ countdown: countdown, endPlanedAt: moment(maintenanceAnnouncement?.endAt).format('HH:mm') }}
                    >
                        S<strong>A maintenance window is approaching.</strong> The application might not be available in 00:10:00. The
                        planned end of this maintenance is at 09:00.
                    </Trans>
                </span>
            </div>
        </div>
    ) : null;
};

export default InfoBar;
