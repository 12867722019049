import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie/lib/Cookies';
import { AnimatePresence } from 'framer-motion';
import { FullScreenLoading } from 'components/index';
import { GLOBAL, QUERY_PARAMS } from 'constants/index';
import { SettingsProvider } from 'context/SettingsContext';
import { DataOverviewProvider } from 'context/DataOverviewContext';
import { AuthenticationContext } from 'context/AuthenticationContext';
import { AuthRoute, PublicRoute } from 'routers';
import { AccountConfirmationScreen, AuthorizingScreen } from 'containers/pages/authentication';
import { NotFoundScreen } from 'containers/pages/user';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import useQuery from 'hooks/useQuery';
import { GlobalServices, Localisation, LocalStorageServices } from 'utils';
import { AppContext } from 'context/AppContext';

const AirBnbDashboard = lazy(() => import('containers/pages/airbnb/Dashboard'));
const AirBnbBreakdown = lazy(() => import('containers/pages/airbnb/Breakdown'));
const AirBnbTaxes = lazy(() => import('containers/pages/airbnb/Taxes'));
const SignInScreen = lazy(() => import('containers/pages/authentication/SignInScreen'));
const SignUpWithEmailScreen = lazy(() => import('containers/pages/authentication/SignUpWithEmail'));
const ForgotPasswordScreen = lazy(() => import('containers/pages/authentication/ForgotPasswordScreen'));
const NewPasswordScreen = lazy(() => import('containers/pages/authentication/NewPasswordScreen'));
const SetUpPasswordScreen = lazy(() => import('containers/pages/authentication/SetUpPasswordScreen'));
const AcceptTermsAndConditionsScreen = lazy(() => import('containers/pages/authentication/AcceptTermsAndConditionsScreen'));
const ResendEmailVerificationScreen = lazy(() => import('containers/pages/authentication/ResendEmailVerificationScreen'));
const TFAMethodScreen = lazy(() => import('containers/pages/authentication/TFAMethodScreen'));
const TFABindGoogleAuthenticatorScreen = lazy(() => import('containers/pages/authentication/TFABindGoogleAuthenticatorScreen'));
const TFASafeRecoveryKeyScreen = lazy(() => import('containers/pages/authentication/TFASafeRecoveryKeyScreen'));
const TFAGoogleAuthenticatorSetupCodeInputScreen = lazy(
    () => import('containers/pages/authentication/TFAGoogleAuthenticatorSetupCodeInputScreen')
);
const TFACodeConfirmation = lazy(() => import('containers/pages/authentication/TFALinkYourPhoneNumberScreen/tfaCodeConfirmation'));
const TFACodeInputScreen = lazy(() => import('containers/pages/authentication/TFACodeInputScreen'));
const TFAUseRecoveryKeyScreen = lazy(() => import('containers/pages/authentication/TFAUseRecoveryKeyScreen'));
const TFALinkYourPhoneNumberScreen = lazy(() => import('containers/pages/authentication/TFALinkYourPhoneNumberScreen'));
const TFAConfirmOldMethodScreen = lazy(() => import('containers/pages/authentication/TFAConfirmOldMethodScreen'));
const TermsAndConditionsScreen = lazy(() => import('containers/pages/legal/TermsAndConditionsScreen'));
const PrivacyPolicyScreen = lazy(() => import('containers/pages/legal/PrivacyPolicyScreen'));
const DashboardScreen = lazy(() => import('country-uk/containers/dashboard/DashboardScreen'));

const RecordKeepingIntroductionScreen = lazy(() => import('country-uk/containers/record-keeping/IntroductionScreen'));
const CheckoutSuccessScreen = lazy(() => import('country-uk/containers/jellyfish/CheckoutSuccessScreen'));
const JellyFish = lazy(() => import('country-uk/containers/jellyfish'));
const EditTiles = lazy(() => import('country-uk/containers/SSA100/EditTiles'));
const RecordKeepingImportCsvScreen = lazy(() => import('country-uk/containers/record-keeping/ImportCsvScreen'));
const AddAccountScreen = lazy(() => import('country-uk/containers/record-keeping/AddAccountScreen'));
const RecordKeepingUnassignedTransactionsScreen = lazy(() => import('country-uk/containers/record-keeping/UnassignedTransactionsScreen'));
const Categorize = lazy(() => import('country-uk/containers/record-keeping/Categorize'));
const DataOverviewScreen = lazy(() => import('country-uk/containers/data-overview/DataOverviewScreen'));

const SettingsScreen = lazy(() => import('containers/pages/settings/SettingsScreen'));
const AccountSettingsScreen = lazy(() => import('containers/pages/settings/AccountSettingsScreen'));
const PersonalDataScreen = lazy(() => import('containers/pages/settings/PersonalDataScreen'));
const BankAccountsScreen = lazy(() => import('containers/pages/settings/BankAccountsScreen'));
const ConnectBank = lazy(() => import('containers/pages/settings/BankAccountsScreen/ConnectBank'));
const IncomeTypesScreen = lazy(() => import('containers/pages/settings/IncomeTypesScreen'));
const AccountDeletionCancellationScreen = lazy(() => import('containers/pages/settings/AccountDeletionCancellationScreen'));
const ConnectionToHMRC = lazy(() => import('containers/pages/settings/ConnectionToHMRC'));

const HMRCPostSubmissionScreen = lazy(() => import('country-uk/containers/jellyfish/PostSubmissionScreen'));
const SubmissionTypeScreen = lazy(() => import('country-uk/containers/authentication/SubmissionTypeScreen'));

const InvalidCredentials = lazy(() => import('containers/pages/user/InvalidCredentials'));

// const OAuthScreenGoodLord = lazy(() => import('country-uk/containers/oauth-screens/GoodLord/OAuthScreenGoodLord'));
const OAuthScreenPayProp = lazy(() => import('country-uk/containers/oauth-screens/PayProp/OAuthScreenPayProp'));
const OAuthScreenAirbnb = lazy(() => import('country-uk/containers/oauth-screens/Airbnb/OAuthScreenAirbnb'));
const OAuthScreenAmazon = lazy(() => import('country-uk/containers/oauth-screens/Amazon/OAuthScreenAmazon'));

const Onboarding = lazy(() => import('country-uk/containers/SSA100/Onboarding'));
const OnboardingPropertySettings = lazy(() => import('country-uk/containers/SSA100/PropertySettings'));

type Props = {
    authenticated: boolean;
    acceptedTermsAndConditions: boolean;
    mtdWizardDone: boolean;
};

export const newPublicRoutes = [
    '/airbnb',
    '/authorizing/google',
    '/authorizing/apple',
    '/authorizing/facebook',
    '/sign-in',
    '/sign-up-with-email',
    '/resend-email-verification',
    '/confirm-account',
    '/authentication-code',
    '/use-recovery-key',
    '/terms-and-conditions',
    '/forgot-password',
    '/new-password',
    '/setup-password',
    '/privacy-policy',
    '/agent-redirect',
    '/logout-session'
];
const newAuthRoutes = [
    '/start-trial',
    '/tax-year',
    '/income-type',
    '/self-employed',
    '/landlord',
    '/landlord-property',
    '/foreign-landlord',
    '/foreign-landlord-property',
    '/authentication-method',
    '/safe-recovery-key',
    '/data-overview',
    '/data-overview/:initialView',
    '/non-business-overview/:taxYearId',
    '/non-business-overview/add/:type/:taxYearId',
    '/non-business-overview/edit/:employmentId/:taxYearId',
    '/crystallisation-preview/:submissionId',
    '/bind-google-authenticator',
    '/google-authentication-code',
    '/record-keeping/import',
    '/record-keeping/unassigned-transactions',
    '/record-keeping/add-account',
    '/hmrc/sign-up',
    '/hmrc/national-insurance-number',
    '/dashboard',
    '/record-keeping',
    '/jellyfish',
    '/terms-and-conditions',
    '/settings',
    '/settings/account-settings',
    '/settings/account-settings/confirm-2fa',
    '/settings/account-settings/bind-google-authenticator',
    '/settings/account-settings/safe-recovery-key',
    '/settings/account-settings/google-authentication-code',
    '/settings/account-settings/link-your-phone-number',
    '/settings/personal-data',
    '/settings/bank-accounts',
    '/settings/bank-accounts/connection',
    '/settings/income-types',
    '/settings/hmrc-connection',
    '/checkout/success',
    '/checkout/cancel',
    '/mtd/quarterly-submission-self-employed/:submissionId',
    '/mtd/quarterly-submission-landlord/:submissionId',
    '/mtd/quarterly-submission-foreign-landlord/:submissionId',
    '/mtd/allowances-landlord/:apiVersion/:submissionId',
    '/mtd/allowances-foreign-landlord/:submissionId',
    '/mtd/allowances-self-employed/:submissionId',
    '/mtd/adjustments/:businessId',
    '/contract-keeping',
    '/accept-terms-and-conditions',
    '/eops-preview',
    '/hmrc-submitted',
    '/plans',
    '/submission-type',
    '/data-collector-search',
    '/data-collector-dashboard',
    '/data-collector-dashboard/tax-returns',
    '/data-collector-dashboard/MTD',
    '/data-collector-dashboard/apari-records',
    '/data-collector-dashboard/tax-return-validation',
    '/data-collector-dashboard/income-expenditure-analysis',
    '/data-collector-dashboard/bank-accounts',
    '/data-collector-dashboard/:tableView',
    '/data-collector-dashboard/contracts',
    '/data-collector-login',
    '/dividends/:taxYearId',
    '/pension/:taxYearId',
    '/edit-tiles/:currentYear',
    '/charitable-giving/:taxYearId',
    '/cis-deductions/:taxYearId',
    '/contractor/:employerRef/:taxYearId',
    '/savings/:taxYearId',
    '/savings/account/add/:taxYearId',
    '/savings/account/edit/:taxYearId/:accountId',
    '/savings/foreign/add',
    '/savings/foreign/edit/:countryId',
    '/savings/securities',
    '/state-benefits/:taxYearId',
    '/one-time-payment/checkout/success',
    '/state-benefit/preview/:stateBenefitId/:taxYearId/:source',
    '/cancel-deletion',
    '/invalid-credentials',
    '/settings/account-settings/sms-authentication-code',
    '/link-your-phone-number',
    '/sms-authentication-code',
    // '/oauth/good-lord',
    '/oauth/payprop',
    '/oauth/airbnb',
    '/oauth/amazon',
    '/onboarding/tiles',
    '/onboarding/property-settings'
];

const AgentRedirectionScreen: React.FC = () => {
    const handleToken = async () => {
        const cookie = new Cookies();
        return new Promise(resolve => resolve(cookie.get(GLOBAL.JWT_COOKIE_KEY)));
    };

    useEffect(() => {
        const getCookie = async () => {
            const res = await handleToken();
            if (res) setTimeout(() => window.open('/agent', '_self'), 500);
        };
        getCookie();
    }, []);
    return <FullScreenLoading />;
};

const LogoutSessionOnPasswordChangeOnAnotherBrowser = () => {
    const { signOut } = useContext(AuthenticationContext);
    const { hideLoadingBar } = useContext(AppContext);
    const history = useHistory();
    useEffect(() => {
        signOut();
        hideLoadingBar();
        setTimeout(() => history.replace('/sign-in'), 3000);
    }, []);
    return <FullScreenLoading message={Localisation.localize('DASHBOARD_SCREEN.GENERAL.EXPIRED_SESSION')} />;
};

const AuthScreens: React.FC<Props> = ({ authenticated, acceptedTermsAndConditions, mtdWizardDone }) => {
    return (
        <>
            <SettingsScreens
                authenticated={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                mtdWizardDone={mtdWizardDone}
            />
            <DataOverviewScreens
                authenticated={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                mtdWizardDone={mtdWizardDone}
            />
            <JellyfishScreens
                authenticated={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                mtdWizardDone={mtdWizardDone}
            />
            <AuthRoute
                exact
                onboardingIsDone={true}
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/one-time-payment/checkout/success"
                component={CheckoutSuccessScreen}
            />
            <AuthRoute
                onboardingIsDone={true}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/authentication-method"
                component={TFAMethodScreen}
            />
            <AuthRoute
                onboardingIsDone={true}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/bind-google-authenticator"
                component={TFABindGoogleAuthenticatorScreen}
            />
            <AuthRoute
                onboardingIsDone={true}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/safe-recovery-key"
                component={TFASafeRecoveryKeyScreen}
            />
            <AuthRoute
                onboardingIsDone={true}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/google-authentication-code"
                component={TFAGoogleAuthenticatorSetupCodeInputScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/record-keeping"
                component={RecordKeepingIntroductionScreen}
            />
            <Route exact path="/terms-and-conditions" component={TermsAndConditionsScreen} />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/record-keeping/import"
                component={RecordKeepingImportCsvScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/record-keeping/add-account"
                component={AddAccountScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/record-keeping/unassigned-transactions"
                component={RecordKeepingUnassignedTransactionsScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/record-keeping/categorize/:businessId?/:date?/:transactiontype?"
                component={Categorize}
            />
            {/* <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/oauth/good-lord"
                component={OAuthScreenGoodLord}
            /> */}
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/oauth/payprop"
                component={OAuthScreenPayProp}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/oauth/airbnb"
                component={OAuthScreenAirbnb}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/oauth/amazon"
                component={OAuthScreenAmazon}
            />
            <Route exact path="/accept-terms-and-conditions" component={AcceptTermsAndConditionsScreen} />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/hmrc-submitted/:taxYear"
                component={HMRCPostSubmissionScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/cancel-deletion"
                component={AccountDeletionCancellationScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/invalid-credentials"
                component={InvalidCredentials}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/dashboard"
                component={DashboardScreen}
            />
        </>
    );
};

const JellyfishScreens: React.FC<Props> = ({ authenticated, acceptedTermsAndConditions, mtdWizardDone }) => {
    return (
        <>
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/jellyfish/:currentYear"
                component={JellyFish}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/submission-type"
                component={SubmissionTypeScreen}
            />
            <AuthRoute
                exact
                onboardingIsDone={mtdWizardDone}
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/edit-tiles/:currentYear"
                component={EditTiles}
            />
            <Route exact path="/onboarding/tiles" component={Onboarding} />
            <Route exact path="/onboarding/property-settings" component={OnboardingPropertySettings} />
        </>
    );
};

const DataOverviewScreens: React.FC<Props> = ({ authenticated, acceptedTermsAndConditions, mtdWizardDone }) => {
    return (
        <DataOverviewProvider>
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                exact
                authorized={authenticated}
                path="/data-overview"
                component={DataOverviewScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                exact
                authorized={authenticated}
                path="/data-overview/:initialView"
                component={DataOverviewScreen}
            />
        </DataOverviewProvider>
    );
};

const SettingsScreens: React.FC<Props> = ({ authenticated, acceptedTermsAndConditions, mtdWizardDone }) => {
    return (
        <SettingsProvider>
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/settings/account-settings"
                component={AccountSettingsScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/settings/account-settings/confirm-2fa/:newMethod"
                component={TFAConfirmOldMethodScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/settings/account-settings/link-your-phone-number"
                component={TFALinkYourPhoneNumberScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/settings/account-settings/bind-google-authenticator"
                component={TFABindGoogleAuthenticatorScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/settings/account-settings/safe-recovery-key"
                component={TFASafeRecoveryKeyScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/settings/account-settings/google-authentication-code"
                component={TFAGoogleAuthenticatorSetupCodeInputScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/settings/income-types"
                component={IncomeTypesScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/settings/personal-data"
                component={PersonalDataScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/settings"
                component={SettingsScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/settings/bank-accounts"
                component={BankAccountsScreen}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/settings/bank-accounts/connection"
                component={ConnectBank}
            />
            <AuthRoute
                onboardingIsDone={mtdWizardDone}
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/settings/account-settings/sms-authentication-code"
                component={TFACodeConfirmation}
            />
            <AuthRoute
                onboardingIsDone
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/link-your-phone-number"
                component={TFALinkYourPhoneNumberScreen}
            />
            <AuthRoute
                onboardingIsDone
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/sms-authentication-code"
                component={TFACodeConfirmation}
            />
            <AuthRoute
                onboardingIsDone
                exact
                authorized={authenticated}
                acceptedTermsAndConditions={acceptedTermsAndConditions}
                path="/settings/hmrc-connection"
                component={ConnectionToHMRC}
            />
        </SettingsProvider>
    );
};

const AppRouter: React.FC<Props> = props => {
    const { authenticated, acceptedTermsAndConditions, mtdWizardDone } = props;

    const { trackPageView } = useMatomo();
    const location = useLocation();

    const { queryValues } = useQuery(location, [
        QUERY_PARAMS.UTM_SOURCE,
        QUERY_PARAMS.UTM_MEDIUM,
        QUERY_PARAMS.UTM_CAMPAIGN,
        QUERY_PARAMS.UTM_TERM,
        QUERY_PARAMS.UTM_CONTENT,
        QUERY_PARAMS.GCLID,
        QUERY_PARAMS.FCLID,
        QUERY_PARAMS.REFERRAL_CODE
    ]);

    useEffect(() => {
        if (!GlobalServices.isEmpty(queryValues)) {
            LocalStorageServices.setJSON('QUERY_PARAMETERS', queryValues);
        }
    }, []);

    React.useEffect(() => {
        trackPageView({
            documentTitle: location.pathname
        });
    }, [location]);

    return (
        <AnimatePresence>
            <Suspense fallback={<div></div>}>
                <Switch>
                    <Redirect exact from="/" to={'/airbnb'} />
                    {/* <Redirect exact from="/" to={!authenticated ? '/sign-in' : mtdWizardDone ? '/onboarding' : '/start-trial'} /> */}
                    {/* <Redirect exact from="/dashboard" to={'/record-keeping/categorize'} /> */}
                    <Route path={newPublicRoutes}>
                        <Route exact path="/terms-and-conditions" component={TermsAndConditionsScreen} />
                        <Route exact path="/airbnb" component={AirBnbDashboard} />
                        <Route exact path="/airbnb/breakdown" component={AirBnbBreakdown} />
                        <Route exact path="/airbnb/taxes" component={AirBnbTaxes} />
                        <Route exact path="/sign-in" component={SignInScreen} />
                        <Route exact path="/authorizing/google" component={AuthorizingScreen} />
                        <Route exact path="/authorizing/apple" component={AuthorizingScreen} />
                        <Route exact path="/authorizing/facebook" component={AuthorizingScreen} />
                        <PublicRoute exact authorized={authenticated} path="/sign-up-with-email" component={SignUpWithEmailScreen} />
                        <PublicRoute exact authorized={authenticated} path="/forgot-password" component={ForgotPasswordScreen} />
                        <PublicRoute exact authorized={authenticated} path="/new-password" component={NewPasswordScreen} />
                        <PublicRoute exact authorized={authenticated} path="/setup-password" component={SetUpPasswordScreen} />
                        <Route exact path="/authentication-code" component={TFACodeInputScreen} />
                        <Route exact path="/use-recovery-key" component={TFAUseRecoveryKeyScreen} />
                        <PublicRoute
                            exact
                            authorized={authenticated}
                            path="/resend-email-verification"
                            component={ResendEmailVerificationScreen}
                        />
                        <PublicRoute exact authorized={authenticated} path="/confirm-account" component={AccountConfirmationScreen} />
                        <Route exact path="/privacy-policy" component={PrivacyPolicyScreen} />
                        <Route exact path="/agent-redirect" component={AgentRedirectionScreen} />
                        <Route exact path="/logout-session" component={LogoutSessionOnPasswordChangeOnAnotherBrowser} />
                    </Route>
                    <Route path={newAuthRoutes}>
                        <AuthScreens
                            authenticated={authenticated}
                            acceptedTermsAndConditions={acceptedTermsAndConditions}
                            mtdWizardDone={mtdWizardDone}
                        />
                    </Route>
                    <Route path="*" exact={true} component={NotFoundScreen} />
                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default AppRouter;
