import { makeStyles } from '@material-ui/core';
import { COLORS, FONTS } from 'constants/index';
import { Theme } from '@material-ui/core/styles';
type Props = {
    uploading?: boolean;
    uploadStatus?: number;
    hasActivePlan?: boolean;
    failed?: boolean;
    usedInSplitTransaction?: boolean;
};
export default makeStyles<Theme, Props>(theme => ({
    input: {
        display: 'none'
    },
    root: {
        textTransform: 'none'
    },
    marginVertical: {
        marginTop: 5,
        marginBottom: 5
    },
    marginHorizontal: {
        marginLeft: 5,
        marginRight: 5
    },
    warningIcon: {
        color: COLORS.apariWarning
    },
    uploadButton: {
        textTransform: 'none',
        display: 'flex'
    },
    deleteDialogButton: {
        maxHeight: 32,
        width: 130
    },
    icon: {
        fontSize: 24,
        color: COLORS.apariDarkSilver
    },
    iconButton: {
        padding: 5
    },
    dropzone: {
        width: '315px',
        height: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px',
        fontWeight: 600,
        color: COLORS.apariBlack,
        borderRadius: '2px',
        border: `1px dashed ${COLORS.apariGraphGray}`,
        [theme.breakpoints.down('md')]: {
            width: '300px',
            height: '100px',
            border: 0
        },
        '&:disabled': {
            color: COLORS.apariGray2,
            background: COLORS.apariBlack
        }
    },
    previewCard: ({ uploadStatus, uploading, failed }) => ({
        padding: '0',
        backgroundColor: '#fff',
        borderRadius: 2,
        marginBottom: 10,
        minHeight: 72,
        paddingRight: 16,
        width: 315,
        opacity: (uploadStatus !== 100 && uploading) || failed ? 0.5 : 1,
        boxShadow:
            '0px 4px 122px rgba(0, 0, 0, 0.04), 0px 1.28121px 27.2503px rgba(0, 0, 0, 0.0238443), 0px 0.517444px 8.11312px rgba(0, 0, 0, 0.0161557)'
    }),
    label: {
        fontFamily: FONTS.PRIMARY_FONT,
        width: 200
    },
    progressBarContainer: {
        width: '100%',
        height: 2,
        backgroundColor: COLORS.apariDarkGrey
    },
    progressBar: ({ uploadStatus }) => ({
        height: '100%',
        width: `${uploadStatus}%`,
        backgroundColor: COLORS.apariPurple
    }),
    button: ({ hasActivePlan }) => ({
        width: hasActivePlan ? 200 : 260,
        marginTop: 20
    }),
    mobileAttachments: ({ usedInSplitTransaction }) => ({
        border: usedInSplitTransaction ? 0 : `1px solid ${COLORS.apariGray}`,
        borderLeft: 0,
        borderRight: 0,
        borderStyle: 'solid',
        borderColor: COLORS.apariGray,
        paddingBottom: 5,
        marginTop: 10
    }),
    photoButton: {
        width: 250,
        height: 110,
        backgroundColor: COLORS.apariWhite,
        boxShadow:
            '0px 4px 122px rgba(0, 0, 0, 0.04), 0px 1.28121px 27.2503px rgba(0, 0, 0, 0.0238443), 0px 0.517444px 8.11312px rgba(0, 0, 0, 0.0161557) !important;'
    },
    deleteDialog: {
        width: 290,
        height: 130,
        padding: 12,
        display: 'flex',
        flexDirection: 'column'
    },
    camera: {
        display: 'none'
    },
    previewImage: {
        width: 72,
        height: 72
    },
    mobilePreviewImage: {
        width: 35,
        height: 35,
        marginRight: 2
    },
    uploadIcon: ({ hasActivePlan }) => ({
        color: hasActivePlan ? COLORS.apariPurple : COLORS.apariGray2
    }),
    uploadText: ({ hasActivePlan }) => ({
        color: hasActivePlan ? COLORS.apariBlack80 : COLORS.apariGray2,
        fontSize: 12,
        fontWeight: 600
    }),
    rowCard: {
        background: '#FFFFFF',
        boxShadow:
            '0px 4px 122px rgba(0, 0, 0, 0.04), 0px 1.28121px 27.2503px rgba(0, 0, 0, 0.0238443), 0px 0.517444px 8.11312px rgba(0, 0, 0, 0.0161557)',
        borderRadius: '2px',
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        height: 72,
        width: 315,
        alignItems: 'center',
        '&:hover': {
            background: COLORS.transparentLight
        }
    },
    attachmentIcon: {
        color: COLORS.apariBlack80,
        fontSize: 16,
        transform: 'rotate(45deg)'
    }
}));
