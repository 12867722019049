import React, { createRef, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Box, Menu, MenuItem, Tab, Tabs, Tooltip, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import { isEmpty, join } from 'lodash';
import { Localisation } from 'utils';
import ApSelectField from 'components/common/form-elements-v2/ApSelectField';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

type Props = {
    tabValue: number;
    setTabValue: (value: number) => void;
    externalCountries?: string[];
    tabItems: Dropdown;
    validationData?: Record<string, any>;
};

type Dropdown = { value: number; label: string }[];

const TabsComponent: React.FC<Props> = ({ tabValue, setTabValue, externalCountries, tabItems, validationData }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showMoreItem, setShowMoreItem] = useState(false);
    const [isCalculate, setIsCalculate] = useState(true);
    const [displayMenuNodes, setDisplayMenuNodes] = useState(0);
    const [dropDownItems, setDropDownItems] = useState<Dropdown>([]);
    const container = useRef<HTMLButtonElement>(null);
    const moreButton = useRef<HTMLDivElement>(null);
    const globalClasses = globalStyles();
    const classes = styles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const handleOpenTabsDropDown = useCallback(event => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleCloseTabsDropDown = useCallback(() => {
        setAnchorEl(null);
    }, []);

    function a11yProps(index: number) {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`
        };
    }

    const renderTabItemRefs: React.RefObject<HTMLDivElement>[] = useMemo(() => tabItems.map(() => createRef()), []);

    const calculateAndSeparateCategoriesOnTabsAndDropDown = useCallback(() => {
        const offsetContainerWidth = container.current?.offsetWidth || 0;
        const scrollContainerWidth = container.current?.scrollWidth || 0;
        const moreButtonWidth = moreButton.current?.offsetWidth || 0;

        if (scrollContainerWidth >= offsetContainerWidth) {
            setIsCalculate(true);

            let totalItemsWidth = 0;
            let count = 0;

            for (let index = 0; index < renderTabItemRefs.length; index += 1) {
                const menuItemWidth = renderTabItemRefs[index].current?.getBoundingClientRect().width || 0;
                const currentTotalWidth = totalItemsWidth + menuItemWidth;
                if (currentTotalWidth >= offsetContainerWidth) {
                    let newCurrentTotal = currentTotalWidth + moreButtonWidth;

                    if (newCurrentTotal > offsetContainerWidth) {
                        for (let j = count; j > 0; j -= 1) {
                            const newCurrentMenuItemWidth = renderTabItemRefs[j].current?.getBoundingClientRect().width || 0;

                            if (newCurrentTotal - newCurrentMenuItemWidth < offsetContainerWidth) {
                                break;
                            } else {
                                newCurrentTotal -= newCurrentMenuItemWidth;
                                count -= 1;
                            }
                        }
                    }
                    break;
                } else {
                    count += 1;
                    totalItemsWidth = currentTotalWidth;
                }
            }

            const newDropDownItems = tabItems.slice(count);

            setDisplayMenuNodes(count);
            !isEmpty(newDropDownItems) && setShowMoreItem(true);
            setDropDownItems(newDropDownItems);
        }
        setIsCalculate(false);
    }, [renderTabItemRefs]);

    useLayoutEffect(() => {
        calculateAndSeparateCategoriesOnTabsAndDropDown();
    }, [calculateAndSeparateCategoriesOnTabsAndDropDown]);

    const renderTabLabel = (label: string, index: number) => {
        return (
            <div className={globalClasses.flexRow}>
                {label}
                {tabValue !== index &&
                    validationData &&
                    !isEmpty(validationData.formik.touched) &&
                    validationData.formik.errors[validationData.keys[index]] && (
                        <Tooltip title={Localisation.localize('CHARITABLE_GIVING_OVERVIEW_SCREEN.TOOLTIPS.TAB_VALIDATION_ERROR')}>
                            <WarningIcon className={clsx(classes.errorIcon)} />
                        </Tooltip>
                    )}
            </div>
        );
    };

    const renderTabItems = useMemo(() => {
        const menuItemsWithoutHidden = displayMenuNodes === 0 ? tabItems : tabItems.slice(0, displayMenuNodes);
        return menuItemsWithoutHidden.map((item, index) => {
            return (
                <Tab
                    key={item.value}
                    label={renderTabLabel(item.label, index)}
                    className={clsx(classes.tabText)}
                    innerRef={renderTabItemRefs[index]}
                    {...a11yProps(index)}
                />
            );
        });
    }, [renderTabItemRefs, displayMenuNodes, validationData, tabValue]);

    const isOpenedMenu = Boolean(anchorEl);

    const handleTabChange = (event: any, newValue: number) => {
        newValue !== renderTabItems.length && setTabValue(newValue);
    };

    const handleMenuItemClick = (newValue: number) => {
        setTabValue(newValue);
        handleCloseTabsDropDown();
    };

    const selectMoreTabBasedOnDropDownItemValue = () => (tabValue <= renderTabItems.length ? tabValue : renderTabItems.length);

    const renderTabs = () => {
        return (
            <Tabs
                value={selectMoreTabBasedOnDropDownItemValue()}
                indicatorColor="primary"
                scrollButtons="off"
                ref={container}
                className={clsx(classes.tabs, globalClasses.flexRow)}
                onChange={handleTabChange}
            >
                {renderTabItems}

                {(showMoreItem || isCalculate) && (
                    <Tab
                        label={`+${dropDownItems.length} ${Localisation.localize('QUARTERLY_SUBMISSION_SCREEN.MORE')}`}
                        className={clsx(classes.tabText, classes.padding18)}
                        innerRef={moreButton}
                        onClick={handleOpenTabsDropDown}
                    />
                )}
            </Tabs>
        );
    };

    const renderMobileDropDown = () => {
        return (
            <ApSelectField
                className={clsx(globalClasses.noMargin, classes.dropDown)}
                formControlClassName={clsx(globalClasses.noMargin)}
                variant="standard"
                disableUnderline
                control="navigationSelector"
                options={tabItems}
                value={tabValue}
                onChange={(e: any) => setTabValue(e.target.value)}
            />
        );
    };

    return (
        <div className={clsx(globalClasses.fullWidth, globalClasses.flexRow, classes.alignItemsEnd)}>
            {isMobile ? renderMobileDropDown() : renderTabs()}
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={isOpenedMenu}
                onClose={handleCloseTabsDropDown}
                getContentAnchorEl={null}
                classes={{ paper: classes.dropDownMenu }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                {dropDownItems.map((item: any) => {
                    return (
                        <MenuItem
                            key={item.value}
                            selected={item.value === tabValue}
                            className={clsx(classes.menuItem)}
                            onClick={() => handleMenuItemClick(item.value)}
                        >
                            <Box>{item.label}</Box>
                        </MenuItem>
                    );
                })}
            </Menu>
            {!isEmpty(externalCountries) && (
                <Tooltip
                    title={Localisation.localize('ANNUAL_SUMMARY_PREVIEW.EXTERNAL_COUNTRIES_LIST', {
                        externalCountries: join(externalCountries, ', ')
                    })}
                >
                    <WarningIcon className={clsx(classes.orangeColor)} />
                </Tooltip>
            )}
        </div>
    );
};

export default TabsComponent;
