import { BusinessTypeEnum } from '@apari/core-api';
import { TransactionCategoryCategoryTypeEnum } from '@apari/banking-api';

const formatCategoryType = (type: string, isFHL: boolean, isIncome: boolean | undefined): string => {
    let categoryType = '';

    if (type === BusinessTypeEnum.LANDLORD) {
        if (!isIncome || (!isIncome && !isFHL)) {
            categoryType = TransactionCategoryCategoryTypeEnum.NON_FHL_LL_EXPENSE;
        }

        if (isIncome || (isIncome && isFHL)) {
            categoryType = TransactionCategoryCategoryTypeEnum.NON_FHL_LL_INCOME;
        }

        if (isFHL && !isIncome) {
            categoryType = TransactionCategoryCategoryTypeEnum.FHL_LL_EXPENSE;
        }

        if (isFHL && isIncome) {
            categoryType = TransactionCategoryCategoryTypeEnum.FHL_LL_INCOME;
        }
    }

    if (type === BusinessTypeEnum.FOREIGN_LANDLORD) {
        if (!isIncome || (!isIncome && !isFHL)) {
            categoryType = TransactionCategoryCategoryTypeEnum.FOREIGN_NON_FHL_LL_EXPENSE;
        }

        if (isIncome || (isIncome && isFHL)) {
            categoryType = TransactionCategoryCategoryTypeEnum.FOREIGN_NON_FHL_LL_INCOME;
        }

        if (isFHL && !isIncome) {
            categoryType = TransactionCategoryCategoryTypeEnum.FOREIGN_FHL_LL_EXPENSE;
        }

        if (isFHL && isIncome) {
            categoryType = TransactionCategoryCategoryTypeEnum.FOREIGN_FHL_LL_INCOME;
        }
    }

    if (type === BusinessTypeEnum.SELF_EMPLOYED) {
        if (!isIncome) {
            categoryType = TransactionCategoryCategoryTypeEnum.SE_EXPENSE;
        } else if (isIncome) {
            categoryType = TransactionCategoryCategoryTypeEnum.SE_INCOME;
        }
    }

    if (type === BusinessTypeEnum.NON_BUSINESS) {
        if (!isIncome) {
            categoryType = TransactionCategoryCategoryTypeEnum.NON_BUS_EXPENSE;
        } else {
            categoryType = TransactionCategoryCategoryTypeEnum.NON_BUS_INCOME;
        }
    }

    return categoryType;
};

export default formatCategoryType;
