import { makeStyles } from '@material-ui/core';
import { COLORS } from 'constants/index';

export default makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: '100vw',
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    drawer: {
        boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '2px 2px 0 0',
        overflow: 'hidden',
        '& .MuiBackdrop-root': {
            background: 'transparent'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    toggleButtonWrapper: {
        zIndex: 2000,
        cursor: 'pointer',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 35,
        background: COLORS.apariWhite
    },
    toggleButton: {
        zIndex: 2000,
        background: COLORS.apariBlack80,
        width: 50,
        height: 3,
        borderRadius: 2
    },
    drawerOpen: {
        width: '100%'
    },
    drawerClose: {
        width: '100%'
    },
    menuContainer: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        // pointerEvents: 'none',
        borderRadius: '2px 2px 0px 0px',
        boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.2)',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    desktopMenu: {
        width: 270,
        background: COLORS.apariWhite
    }
}));
